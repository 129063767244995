import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatToNumberWithCommas, linkTransformer } from 'helpers'
import { radius, space, breakpoints } from 'theme'
import {
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
  BLUESKY,
  DEFAULT_VIDEO_THUMBNAIL,
} from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Link from 'components/atoms/Link'
import Counter from 'components/molecules/Counter'
import Switch from 'components/atoms/Switch'
import Divider from 'components/atoms/Divider'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_VIDEO,
  POST_LINK,
  POST_MIXED_MEDIA,
  PREVIEW_MEDIA_CLASS_NAME,
  PUBLISHED,
  BLUESKY_COLORS,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import {
  blueskyGetPostLength,
  blueskyLinkSymbolCounter,
  mentionsTransformer,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
} from '../../helpers'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import ImagesGridTwitter from './components/ImagesGridTwitter'
// eslint-disable-next-line import/no-cycle
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const PROFILE_IMAGE_SIZE = '2.625em'

const StyledFlex = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid rgb(207, 217, 222);
  border-bottom: ${({ isPostFormDirty }) => (isPostFormDirty ? 'none' : 'inset')};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  max-width: 573.67px;
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
  width: ${PROFILE_IMAGE_SIZE};
  height: ${PROFILE_IMAGE_SIZE};
`

const SourceWrapper = styled.div`
  width: 100%;
  max-width: 513.67px;
  height: 100%;
  border-color: rgb(207, 217, 222);
  border-radius: 16px;
  border-width: 1px;
  border-style: double;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledWrapper = styled(Flex)`
  border-bottom-width: 1px;
  border-bottom-color: rgb(239, 243, 244);
  border-bottom-style: solid;
`

const StyledCommentWrapper = styled(Flex)`
  padding: 0.75em 1em;
  cursor: pointer;
  width: 100%;
  white-space: pre-wrap;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`

const StyledImageActionWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 2.171875em;
  height: 2.171875em;
  border-radius: 50%;
  cursor: pointer;
`

const StyledWrapperDefaultAction = styled(StyledImageActionWrapper)`
  &:hover {
    background-color: rgb(241, 243, 245);
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const Excerpt = styled(Flex)`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 1;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: auto;
  flex-shrink: 1;
`

const StyledLinkWrapper = styled(Flex)`
  flex-direction: column;

  ${({ $isLink }) =>
    $isLink &&
    `
   border-radius: 0.75em;
   border: 1px solid ${BLUESKY_COLORS.LIGHT_GRAY};
  `}
`

const StyledLinkTitleWrapper = styled(Flex)`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const StyledLinkDescriptionWrapper = styled(Flex)`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const MAX_MEDIA_HEIGHT = '16.5em'

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === BLUESKY)

const moment_mapping = {
  'a few seconds': '1 s',
  'a minute': '1 m',
  'an hour': '1 h',
  'a day': '1 d',
  'a month': '1 m',
  'a year': '1 y',
}

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  maxImages: MAX_IMAGES,
  maxVideos: MAX_VIDEOS,
  maxImagesInComments: MAX_IMAGES_IN_COMMENTS,
  hasMobileNotificationOption,
} = networkLimits

const BlueskyPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  isSmallPreview,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  blockCommentsMediaAttachment,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [agencyMentions, setAgencyMentions] = useState([])
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 510
  const MAX_PREVIEW_HEIGHT = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const {
    profileId,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const profileName = profile.profileName || profileUsername

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    insights = {},
    status,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { url, title, description, picture_url, id: linkId } = link || {}
  const { published_link } = result || {}

  let updatedPostText = postText
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let publish_as_image
  let comments = []
  let like = false
  let device_gid = null
  let publishing_option = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      publish_as_image,
      comments = [],
      like = false,
      device_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      commentTextMaxLimit,
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationDeviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { updatedPostTextWithMentions = '', transformed_text_to_html_for_textarea } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: BLUESKY_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: updatedText,
      color: BLUESKY_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      transformHashstag: true,
      hashtagColor: BLUESKY_COLORS.LAST_LIGHT_BLUE,
      hashtagFontWeight: 400,
      linkShorteningFunction: ({ word }) => {
        const { text } = blueskyLinkSymbolCounter({ word })

        return text
      },
    })

    return { updatedPostTextWithMentions, transformed_text_to_html_for_textarea }
  }, [updatedPostText, savedVariables, agencyMentions])

  let updatedTextForCounterLength = 0
  if (isPostTextEditOpen) {
    updatedTextForCounterLength = blueskyGetPostLength({ text: updatedPostText })
  }

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_likes = 0
  let c_comments = 0
  let c_retweets = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, comments: c_comments = 0, retweets: c_retweets = 0 } = profileInsights)
  }

  c_likes = status !== PUBLISHED && like ? c_likes + 1 : c_likes
  c_comments =
    c_comments || comments.filter(({ message, postImages }) => message || (postImages && postImages.length > 0)).length

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (e) {
      urlHost = url
    }
  }

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  const postVideosUpdated =
    customizedPostComponent === POST_VIDEO ? customizedPostVideos.filter(({ tempId }) => !tempId) : []

  let postMixedMediasUpdated = []

  if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_medias = customizedPostMixedMedias.filter(({ tempId }) => !tempId)
    if (filtered_medias.length !== 0) {
      if (filtered_medias[0].type === IMAGE || filtered_medias[0].type === IMAGE_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias
          .filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
          .slice(0, MAX_IMAGES)
      } else if (filtered_medias[0].type === VIDEO || filtered_medias[0].type === VIDEO_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias
          .filter(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
          .slice(0, MAX_VIDEOS)
      }
    }
  }

  const generateImageSourceTypeTemplate = ({ data }) => {
    return (
      <Flex flexDirection="column">
        <SourceWrapper>
          <ImagesGridTwitter
            images={data}
            gridHeight={isCustomizationsOpen ? '12.875em' : '25.75em'}
            network={network}
            socialPreviewProfiles={[profile]}
            post={post}
          />
        </SourceWrapper>
      </Flex>
    )
  }

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { type, id: videoId, thumbnail_url, url: videoUrl, height: videoHeight, isNew } = data[0] || {}

    const max_preview_video_height = smallPreview ? 191.38 : 513.67

    const videoHeightTemp =
      videoHeight && videoHeight < max_preview_video_height ? videoHeight : max_preview_video_height

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <Flex flexDirection="column">
        <SourceWrapper>
          {noVideoAttachment ? (
            <Flex justifyContent="center" alignItems="center" position="relative">
              {type === VIDEO && (
                <Fragment>
                  <Box position="absolute" top={space.xs} right={space.xs}>
                    <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                  </Box>
                  <StyledVideoThumbnailImage
                    maxHeight={MAX_MEDIA_HEIGHT}
                    height="100%"
                    width="100%"
                    source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                    fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                    name={videoId}
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                  />
                </Fragment>
              )}
              {type === VIDEO_DYNAMIC && (
                <Flex
                  width={smallPreview ? '250px' : '510px'}
                  maxHeight={MAX_MEDIA_HEIGHT}
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                >
                  <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
                </Flex>
              )}
            </Flex>
          ) : (
            <StyledBox position="relative" height="100%" width="100%" justifyContent="center" hasError={postVideoError}>
              {isNew ? (
                <Box className="cliploader-wrapper">
                  <ClipLoader size="50" />
                </Box>
              ) : (
                <Fragment>
                  {type === VIDEO && (
                    <VideoWithFallback
                      id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                      url={videoUrl}
                      controls
                      width="100%"
                      height={videoHeightTemp}
                      style={{ maxHeight: videoHeightTemp, background: '#000000' }}
                      config={{
                        file: {
                          attributes: {
                            poster: videoThumbnailUrl,
                          },
                        },
                      }}
                      name={videoId}
                    />
                  )}
                  {type === VIDEO_DYNAMIC && (
                    <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
                  )}
                </Fragment>
              )}
            </StyledBox>
          )}
        </SourceWrapper>
      </Flex>
    )
  }

  let sourceTypeTemplate = ''

  switch (customizedPostComponent) {
    case POST_IMAGE:
      if (postImagesUpdated.length !== 0) {
        sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postImagesUpdated.slice(0, MAX_IMAGES) })
      }
      break

    case POST_VIDEO:
      if (
        customizedPostVideos.length !== 0 &&
        (customizedPostVideos[0].url ||
          customizedPostVideos[0].thumbnail_url ||
          customizedPostVideos[0].type === VIDEO_DYNAMIC)
      ) {
        sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
      }

      break

    case POST_LINK:
      if (picture_url || urlHost || title || description) {
        sourceTypeTemplate = (
          <StyledLinkWrapper $isLink={!publish_as_image}>
            {picture_url && (
              <ImageWithFallback
                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${linkId}`}
                source={picture_url}
                width="100%"
                maxHeight={MAX_MEDIA_HEIGHT}
                fallbackSourceWidth="0px"
                fallbackSourceHeight="0px"
                fallbackSource={null}
                name={linkId}
                borderRadius={publish_as_image ? '0.75em' : '0.75em 0.75em 0 0'}
              />
            )}

            {!publish_as_image && (
              <Flex flexDirection="column">
                <Divider mb="0.5em" height="1px" bg={BLUESKY_COLORS.LIGHT_GRAY} />

                <Flex flexDirection="column" px="0.75em">
                  {title && (
                    <StyledLinkTitleWrapper mb="0.1875em">
                      <Text fontSize="0.9375em" color={BLUESKY_COLORS.GLUON_GREY} fontWeight="bold">
                        {title}
                      </Text>
                    </StyledLinkTitleWrapper>
                  )}

                  {description && (
                    <StyledLinkDescriptionWrapper>
                      <Text fontSize="0.82em" color={BLUESKY_COLORS.GLUON_GREY}>
                        {description}
                      </Text>
                    </StyledLinkDescriptionWrapper>
                  )}

                  <Divider height="1px" bg={BLUESKY_COLORS.LIGHT_GRAY} mt="0.25em" mb="0.375em" />

                  <Flex alignItems="center" pb="0.5em">
                    <Image width="0.75em" src="/assets/bluesky_globe.svg" />

                    <Link
                      ml="0.125em"
                      as="a"
                      href={url}
                      target="_blank"
                      rel="noreferrer noopener"
                      color={BLUESKY_COLORS.TARNISHED_SILVER}
                      fontSize="0.703em"
                    >
                      {urlHost}
                    </Link>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </StyledLinkWrapper>
        )
      }
      break

    case POST_MIXED_MEDIA:
      if (postMixedMediasUpdated.length !== 0) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postMixedMediasUpdated })
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: postMixedMediasUpdated })
        }
      }
      break
    default:
      break
  }

  let whenToPublishTime = publish_at ? moment(publish_at).fromNow(true) : 'Now'

  if (publish_at) {
    if (moment_mapping[whenToPublishTime]) {
      whenToPublishTime = moment_mapping[whenToPublishTime]
    }

    const splitted = whenToPublishTime.split(' ')
    whenToPublishTime = `${splitted[0]}${splitted[1] ? splitted[1].charAt(0) : ''}`
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isSmallPreview) {
      gridTemplateColumns = `282px`
    } else if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostMixedMedias={postMixedMediasUpdated}
          customizedPostVideos={customizedPostVideos}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_item_light' : 'transparent'}
            p={isEditable ? 'm' : 0}
            borderRadius={radius.m}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
              >
                {isPostFormDirty ? (
                  <StyledWrapper bg="white">
                    <Flex width="100%" px="1em" pt="1em" mb="0.25em">
                      <Box width={`${PROFILE_IMAGE_SIZE}`}>
                        {isEditable ? (
                          <StyledImg
                            source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                            fallbackSource={fallbackLogoImage}
                          />
                        ) : (
                          <SocialProfileImage
                            code={network}
                            picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                            width={`${PROFILE_IMAGE_SIZE}`}
                            height={`${PROFILE_IMAGE_SIZE}`}
                            imageBorderRadius={radius.pill}
                          />
                        )}
                      </Box>
                      <Flex ml="0.625em" flexDirection="column" width={`calc(100% - ${PROFILE_IMAGE_SIZE})`}>
                        <Flex alignItems="center">
                          <Excerpt>
                            <StyledLink
                              color={BLUESKY_COLORS.GLUON_GREY}
                              fontWeight="bold"
                              as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                              href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                              target="_blank"
                              fontSize="0.9375em"
                              mr="0.5em"
                              onMouseEnter={(e) => {
                                if (!isMultipleProfilesPreview) {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code: network,
                                          picture_url: profilePictureUrl,
                                          profile_url: profileUrl,
                                          name: profileName,
                                          username: profileUsername,
                                          display,
                                          profile_id,
                                          type: profile_type,
                                        }}
                                        message=""
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                            </StyledLink>

                            {profileUsername && (
                              <StyledLink
                                color={BLUESKY_COLORS.TARNISHED_SILVER}
                                as={profileUrl ? 'a' : 'span'}
                                href={profileUrl || ''}
                                target="_blank"
                                fontSize="0.9375em"
                              >
                                @{profileUsername}
                              </StyledLink>
                            )}
                          </Excerpt>

                          <Flex alignItems="center" minWidth="45px">
                            <Text as="span" color={BLUESKY_COLORS.TARNISHED_SILVER} fontSize="0.9375em">
                              &nbsp;·&nbsp;
                            </Text>
                            <StyledLink
                              as={published_link ? 'a' : 'span'}
                              href={published_link || ''}
                              target="_blank"
                              color={BLUESKY_COLORS.TARNISHED_SILVER}
                              fontSize="0.9375em"
                            >
                              {whenToPublishTime}
                            </StyledLink>
                          </Flex>
                        </Flex>

                        <Flex width="100%" flexDirection="column">
                          <Flex
                            flexDirection="column"
                            width="100%"
                            onClick={() => {
                              if (published_link) {
                                window.open(published_link)
                              }
                            }}
                          >
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: transformed_text_to_html_for_textarea,
                              }}
                              mt="0.5em"
                              color={BLUESKY_COLORS.GLUON_GREY}
                              whiteSpace="pre-line"
                              fontSize="0.875em"
                            />
                            {customizedPostComponent ? (
                              <Box mt="0.5em" mb="0.5em">
                                {sourceTypeTemplate}
                              </Box>
                            ) : (
                              <Box mt="0.5em" mb="0.5em" />
                            )}
                            <Grid
                              mb="0.5em"
                              color={BLUESKY_COLORS.TARNISHED_SILVER}
                              gridTemplateColumns="repeat(4, 1fr)"
                            >
                              <Flex alignItems="center">
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_comment.svg" alt="Comments" />
                                </StyledWrapperDefaultAction>
                                {c_comments > 0 && (
                                  <Text fontSize="0.75em" mt="0.125em">
                                    {formatToNumberWithCommas(c_comments)}
                                  </Text>
                                )}
                              </Flex>
                              <Flex alignItems="center">
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_retweet.svg" alt="Retweets" />
                                </StyledWrapperDefaultAction>
                                {c_retweets > 0 && (
                                  <Text fontSize="0.75em" mt="0.125em">
                                    {formatToNumberWithCommas(c_retweets)}
                                  </Text>
                                )}
                              </Flex>
                              <Flex alignItems="center">
                                <StyledWrapperDefaultAction>
                                  <Image
                                    width="1.125em"
                                    src={like ? '/assets/bluesky_heart_filled.svg' : '/assets/bluesky_heart.svg'}
                                    alt="Likes"
                                  />
                                </StyledWrapperDefaultAction>
                                {c_likes > 0 && (
                                  <Text
                                    fontSize="0.75em"
                                    mt="0.125em"
                                    color={like ? BLUESKY_COLORS.PURPLE : 'primaryText'}
                                  >
                                    {formatToNumberWithCommas(c_likes)}
                                  </Text>
                                )}
                              </Flex>
                              <Flex alignItems="center">
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_more.svg" alt="More" />
                                </StyledWrapperDefaultAction>
                              </Flex>
                            </Grid>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </StyledWrapper>
                ) : (
                  <Box mt="m" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
                <Flex flexDirection="column" width="100%" bg="white">
                  {comments.map((comment) => {
                    const { id, message, postComponent, postImages } = comment

                    if (!message && (!postImages || postImages.length === 0)) {
                      return null
                    }

                    const { variableTransformedText } = variableTransformer({
                      str: message,
                      savedVariables,
                      post,
                      profileId,
                    })

                    let sourceTypeTemplate = ''

                    switch (postComponent) {
                      case POST_IMAGE:
                        // eslint-disable-next-line no-case-declarations
                        const images = postImages ? postImages.filter(({ tempId }) => !tempId) : []

                        if (images.length !== 0) {
                          sourceTypeTemplate = (
                            <Flex flexDirection="column">
                              <SourceWrapper>
                                <ImagesGridTwitter
                                  images={images.slice(0, MAX_IMAGES_IN_COMMENTS)}
                                  gridHeight="12.875em"
                                  network={network}
                                  socialPreviewProfiles={[profile]}
                                  post={{ id, postText: message }}
                                  showAltText={false}
                                />
                              </SourceWrapper>
                            </Flex>
                          )
                        }
                        break
                      default:
                        break
                    }

                    return (
                      <StyledWrapper key={id}>
                        <StyledCommentWrapper width="100%">
                          <Box width={`${PROFILE_IMAGE_SIZE}`}>
                            {isEditable ? (
                              <StyledImg
                                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                                fallbackSource={fallbackLogoImage}
                              />
                            ) : (
                              <SocialProfileImage
                                code={network}
                                picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                                width={`${PROFILE_IMAGE_SIZE}`}
                                height={`${PROFILE_IMAGE_SIZE}`}
                                imageBorderRadius={radius.pill}
                              />
                            )}
                          </Box>
                          <Flex pl="0.5em" flexDirection="column" width={`calc(100% - ${PROFILE_IMAGE_SIZE})`}>
                            <Flex alignItems="center">
                              <Excerpt>
                                <StyledLink
                                  color={BLUESKY_COLORS.GLUON_GREY}
                                  fontWeight="bold"
                                  as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                                  href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                  target="_blank"
                                  fontSize="0.75em"
                                  mr="0.5em"
                                >
                                  {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                                </StyledLink>
                                {profileUsername && (
                                  <StyledLink
                                    color={BLUESKY_COLORS.TARNISHED_SILVER}
                                    as={profileUrl ? 'a' : 'span'}
                                    href={profileUrl || ''}
                                    target="_blank"
                                    fontSize="0.75em"
                                  >
                                    @{profileUsername}
                                  </StyledLink>
                                )}
                              </Excerpt>
                            </Flex>

                            <Flex mt="0.5em" pt="0.25em" width="100%" flexDirection="column" pr="1em">
                              <Text
                                color={BLUESKY_COLORS.GLUON_GREY}
                                dangerouslySetInnerHTML={{
                                  __html: variableTransformedText,
                                }}
                                whiteSpace="pre-line"
                                fontSize="0.875em"
                              />

                              {sourceTypeTemplate ? (
                                <Box mt="0.5em" mb="0.5em">
                                  {sourceTypeTemplate}
                                </Box>
                              ) : (
                                <Box mt="0.5em" mb="0.5em" />
                              )}

                              <Flex pt="0.25em" color={BLUESKY_COLORS.TARNISHED_SILVER} justifyContent="space-between">
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_comment.svg" alt="Comments" />
                                </StyledWrapperDefaultAction>
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_retweet.svg" alt="Retweets" />
                                </StyledWrapperDefaultAction>
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_heart.svg" alt="Likes" />
                                </StyledWrapperDefaultAction>
                                <StyledWrapperDefaultAction>
                                  <Image width="1.125em" src="/assets/bluesky_more.svg" alt="More" />
                                </StyledWrapperDefaultAction>
                              </Flex>
                            </Flex>
                          </Flex>
                        </StyledCommentWrapper>
                      </StyledWrapper>
                    )
                  })}
                </Flex>
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textLength={updatedTextForCounterLength}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter counter={updatedTextForCounterLength} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              if (isEditable) {
                                setIsPostTextEditOpen(true)
                              }
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                color={
                                  transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'
                                }
                                whiteSpace="pre-line"
                              />

                              <Box>
                                <Counter
                                  counter={blueskyGetPostLength({ text: updatedPostTextWithMentions })}
                                  max={MAX_TEXT_LENGTH}
                                  fontSize="xxs"
                                />
                              </Box>
                            </BodyWrapper>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={BLUESKY} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CommentsComponent
                            user={user}
                            network={BLUESKY}
                            profileId={profileId}
                            handleUpdateCustomization={handleUpdateCustomization}
                            handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                            commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                            comments={comments}
                            commentTextMaxLimit={commentTextMaxLimit}
                            selectedEntities={selectedEntities}
                            selectedProfiles={selectedProfiles}
                            textVariantOffsets={textVariantOffsets}
                            showMediaPicker={!blockCommentsMediaAttachment}
                            showImageComponent
                            hasPreview={hasPreview}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments" />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={like}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'like',
                                value: !like,
                              })
                            }}
                            text="Create first like"
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="like" network={BLUESKY} />
                      </Flex>

                      {customizedPostComponent === POST_LINK && picture_url && (
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <Switch
                              isOn={!!publish_as_image}
                              onClick={() => {
                                handleUpdateCustomization({
                                  type: 'publish_as_image',
                                  value: !publish_as_image,
                                })
                              }}
                              text="Post as an image instead of a Bluesky Card"
                              fontWeight="normal"
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="publish_as_image" network={BLUESKY} />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

BlueskyPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  isSmallPreview: false,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isAdvocacy: false,
}

BlueskyPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

export default BlueskyPost
