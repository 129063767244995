import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import ErrorLabel from 'components/atoms/ErrorLabel'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { POST_TYPE_SOCIAL_POST, ROUTE_BOOST_CONFIGURATION } from '../../consts'
import BoostsDropdownComponent from '../Posts/components/BoostsDropdownComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 450px;
    height: auto;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  flex-direction: column;
  outline: none;
  height: 100%;
  flex: 1;
  min-height: 200px;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const { BOOST } = { BOOST: 'boost' }

const FormValidationSchema = () => {
  const isDropdownValueRequiredShape = {
    value: Yup.string().required(),
    type: Yup.string().required(),
  }

  return Yup.object().shape({
    [BOOST]: Yup.object()
      .shape(isDropdownValueRequiredShape)
      .typeError('Boost configuration is required.'),
  })
}

const BoostPostModal = ({ user, isOpen, handleDismiss, post }) => {
  const formRef = useRef(null)

  const [isGettingBoosts, setIsGettingBoosts] = useState(true)
  const [isSavingBoost, setIsSavingBoost] = useState(false)
  const [boosts, setBoosts] = useState([])

  const { profile } = post

  const getBoosts = async () => {
    let boosts = []
    try {
      const response = await request({ path: `${ROUTE_BOOST_CONFIGURATION}?include_configuration=true` })

      const { error, data } = response || {}

      if (response && !error) {
        boosts = data.filter(({ profile: { id } }) => id === post.profile.profileId)
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostPostModal.displayName, functionName: 'getBoosts' })
    } finally {
      setBoosts([...boosts])

      setIsGettingBoosts(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      getBoosts()
    }
  }, [isOpen])

  const handleSubmit = () => {
    formRef.current.handleSubmit()
  }

  const handleSubmitForm = async (values) => {
    try {
      setIsSavingBoost(true)

      const body = {
        id: post.id,
        post_type: post.type,
      }

      if (post.type === POST_TYPE_SOCIAL_POST) {
        body.message_id = post.id
      } else {
        body.publication_id = post.id
      }

      const response = await request({
        path: `${ROUTE_BOOST_CONFIGURATION}/${values[BOOST].value}/existing`,
        method: 'POST',
        body,
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setIsSavingBoost(false)
      } else {
        const { profile: { profileId, network } = {} } = post

        if (!post.customizations) {
          post.customizations = {}
        }

        if (!post.customizations[network]) {
          post.customizations[network] = {}
        }

        if (!post.customizations[network][profileId]) {
          post.customizations[network][profileId] = {}
        }

        post.customizations[network][profileId].boost_gid = data.id
        post.hasBoost = !!post.customizations[network][profileId].boost_gid

        Alert.success('Post will be boosted shortly.', { timeout: 5000 })
        handleDismiss({ post })
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostPostModal.displayName, functionName: 'handleSubmitForm' })
      setIsSavingBoost(false)
    }
  }

  const handleClickCloseModal = () => {
    if (!isGettingBoosts && !isSavingBoost) {
      handleDismiss()
    } else {
      Alert.error('Please wait a bit longer while boosting data is being processed.', { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">Select boost settings</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper tabIndex={0}>
            {isGettingBoosts ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                flex="1"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Formik
                initialValues={{
                  [BOOST]: null,
                }}
                validationSchema={FormValidationSchema}
                onSubmit={handleSubmitForm}
                autocomplete="off"
                ref={formRef}
              >
                {({ values, touched, errors, setFieldValue }) => {
                  const error_message = errors[BOOST] && touched[BOOST] && errors[BOOST]

                  return (
                    <Box mb="m" px="m" width="100%">
                      <BoostsDropdownComponent
                        boosts={boosts && boosts.length !== 0 ? boosts : null}
                        handleUpdateCustomization={(option) => {
                          setFieldValue(BOOST, option)
                        }}
                        boostId={values[BOOST] ? values[BOOST].value : null}
                        user={user}
                        profile={profile}
                        menuPlacement="auto"
                      />

                      {error_message && <ErrorLabel error={error_message} mt="xs" />}
                    </Box>
                  )
                }}
              </Formik>
            )}
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>

            <ButtonWithLoading isLoading={isSavingBoost} onClick={handleSubmit} isSmall type="button">
              <Text fontWeight="medium">{isSavingBoost ? 'Boosting' : 'Boost'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

BoostPostModal.defaultProps = {}

BoostPostModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
}

BoostPostModal.displayName = 'BoostPostModal'

export default BoostPostModal
