import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_SEARCH_PROMPT_TEXT } from 'consts'
import { pxToRem } from 'helpers'
import { Box } from 'components/atoms/Layout'
import DropDown from 'shared/DropDown'
import { PROFILE, LOCATIONS, GENDERS, AGES, FUNCTIONS, INDUSTRIES, ACCOUNT, INCLUDE_AUDIENCES } from '../consts'

const DEFAULT_HEIGHT = pxToRem(40)

const LinkedInConfiguration = ({ values, setFieldValue, errors, touched, formDataProfile, loadSuggestedOptions }) => {
  let audiences = []

  if (values[ACCOUNT]) {
    audiences = formDataProfile[values[ACCOUNT].value] ? formDataProfile[values[ACCOUNT].value].audiences : []
  }

  const has_included_audiences = values[INCLUDE_AUDIENCES] && values[INCLUDE_AUDIENCES].length > 0

  return (
    <Fragment>
      <Box mt="m">
        <DropDown
          label="Include audience"
          placeholder="Select audience"
          value={has_included_audiences ? values[INCLUDE_AUDIENCES][0] : null}
          onChange={(option) => {
            setFieldValue(INCLUDE_AUDIENCES, option ? [option] : [])
          }}
          options={audiences}
          openMenuOnFocus
          height={DEFAULT_HEIGHT}
          error={errors[INCLUDE_AUDIENCES] && touched[INCLUDE_AUDIENCES] && errors[INCLUDE_AUDIENCES]}
        />
      </Box>

      {!has_included_audiences && (
        <Fragment>
          <Box mt="m">
            <DropDown
              label="Locations"
              placeholder="Search for locations"
              value={values[LOCATIONS]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(LOCATIONS, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[PROFILE].value,
                    method: 'searchLocations',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              // defaultOptions={formDataProfile[LOCATIONS] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              error={errors[LOCATIONS] && touched[LOCATIONS] && errors[LOCATIONS]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Functions"
              placeholder="Select functions"
              value={values[FUNCTIONS]}
              onChange={(options) => {
                setFieldValue(FUNCTIONS, options || [])
              }}
              options={formDataProfile[FUNCTIONS] || []}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[FUNCTIONS] && touched[FUNCTIONS] && errors[FUNCTIONS]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Industries"
              placeholder="Select industries"
              value={values[INDUSTRIES]}
              onChange={(options) => {
                setFieldValue(INDUSTRIES, options || [])
              }}
              options={formDataProfile[INDUSTRIES] || []}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[INDUSTRIES] && touched[INDUSTRIES] && errors[INDUSTRIES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Genders"
              placeholder="Select genders"
              value={values[GENDERS]}
              onChange={(options) => {
                setFieldValue(GENDERS, options || [])
              }}
              options={formDataProfile[GENDERS] || []}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              menuPlacement="top"
              error={errors[GENDERS] && touched[GENDERS] && errors[GENDERS]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Ages"
              placeholder="Select ages"
              value={values[AGES]}
              onChange={(options) => {
                setFieldValue(AGES, options || [])
              }}
              options={formDataProfile[AGES] || []}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              menuPlacement="top"
              error={errors[AGES] && touched[AGES] && errors[AGES]}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  )
}

LinkedInConfiguration.defaultProps = {
  formDataProfile: {},
}

LinkedInConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  formDataProfile: PropTypes.object,
  loadSuggestedOptions: PropTypes.func.isRequired,
}

LinkedInConfiguration.displayName = 'LinkedInConfiguration'

export default LinkedInConfiguration
