import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { ROUTE_PUBLISHING_OPTIMAL } from '../consts'
import SocialProfileImage from './SocialProfileImage'

const StyledText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const SubMenu = styled(Box)`
  position: absolute;
  z-index: 2147483001;
  top: 25px;
  left: 0px;
  width: 250px;
  padding: ${space.s};
  background-color: ${({ theme }) => theme.colors.background_card};
  box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
`

const ProgressLine = styled(Flex)`
  border-radius: ${radius.xl};
`

const ProgressLineBg = styled(ProgressLine)`
  opacity: 0.2;
`

const DEFAULT_PUBLISHING_TIME_DATA = {
  publishingTime: [],
  profiles: null,
}

const BestPublishingTimeSettings = ({ selectedProfiles, timezone, combined, handleChangeSelectedTime }) => {
  const subMenuRef = useRef(null)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const [publishingTimeData, setPublishingTimeData] = useState({ ...DEFAULT_PUBLISHING_TIME_DATA })
  const [isGettingData, setIsGettingData] = useState(true)

  const { publishingTime = [], publishingTimeProfiles } = publishingTimeData

  const generateTime = ({ time }) => {
    time.interval = 'AM'
    if (time.hour === 12) {
      time.interval = 'PM'
    } else if (time.hour > 12) {
      time.interval = 'PM'
      time.hour -= 12
    }

    if (time.hour < 10) {
      time.hour = `0${time.hour}`
    } else {
      time.hour = `${time.hour}`
    }

    if (time.minute < 10) {
      time.minute = `0${time.minute}`
    } else {
      time.minute = `${time.minute}`
    }
  }

  const getPublishingTime = async () => {
    let needToUploadData = false

    if (!publishingTimeProfiles) {
      needToUploadData = true
    }

    const body = { targets: [], combined }

    body.targets = selectedProfiles.map(({ id, timezone: profileTimezone }) => {
      if (publishingTimeProfiles && !publishingTimeProfiles[id]) {
        needToUploadData = true
      }
      return {
        profile_gid: id,
        timezone: profileTimezone || timezone,
      }
    })

    if (needToUploadData) {
      try {
        setIsGettingData(true)
        const response = await request({
          method: 'POST',
          path: ROUTE_PUBLISHING_OPTIMAL,
          body,
        })

        const { error } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setPublishingTimeData({ ...DEFAULT_PUBLISHING_TIME_DATA })
        } else {
          const publishingTimeTemp = []
          const publishingTimeProfilesTemp = {}

          if (combined) {
            response.times.forEach((time) => {
              generateTime({ time })
            })
            publishingTimeTemp.push(response)
            selectedProfiles.forEach(({ id }) => {
              publishingTimeProfilesTemp[id] = id
            })
          } else {
            selectedProfiles.forEach(({ name, picture_url, code, id }) => {
              publishingTimeProfilesTemp[id] = id
              if (response[id]) {
                response[id].times.forEach((time) => {
                  generateTime({ time })
                })
                publishingTimeTemp.push({ profile: { name, picture_url, code }, ...response[id] })
              }
            })
          }
          setPublishingTimeData({
            ...{ publishingTime: publishingTimeTemp, publishingTimeProfiles: publishingTimeProfilesTemp },
          })
        }
      } catch (error) {
        errorHelper({ error, componentName: BestPublishingTimeSettings.displayName, functionName: 'getPublishingTime' })
        setPublishingTimeData({ ...DEFAULT_PUBLISHING_TIME_DATA })
      } finally {
        setIsGettingData(false)
      }
    }
  }

  useEffect(() => {
    if (isSubMenuOpen) {
      getPublishingTime()
    }
  }, [isSubMenuOpen])

  const handleMouseClick = (event) => {
    if (subMenuRef.current) {
      if (subMenuRef.current.contains(event.target)) {
        return
      }
    }

    if (isSubMenuOpen) {
      setIsSubMenuOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseClick, false)
    return () => window.removeEventListener('mousedown', handleMouseClick, false)
  })

  return (
    <Box position="relative">
      <StyledText
        fontSize="xs"
        mt="xs"
        color="primary"
        onClick={() => {
          setIsSubMenuOpen(true)
        }}
      >
        Show optimal times
      </StyledText>
      <Box ref={subMenuRef}>
        {isSubMenuOpen && (
          <SubMenu>
            {isGettingData ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Flex flexDirection="column">
                <Text mb="s" fontSize="xs">
                  Optimal time recommendations based on share of total engagement.{' '}
                  <Text
                    fontSize="xs"
                    color="primary"
                    as="a"
                    href="https://support.vistasocial.com/hc/en-us/articles/6140669938971"
                    target="_blank"
                  >
                    Learn more
                  </Text>
                  .
                </Text>
                {publishingTime.map(({ profile, times }, index) => (
                  <Flex mb="s" key={index} flexDirection="column">
                    {profile && (
                      <Flex mb="xs" alignItems="center">
                        <SocialProfileImage picture_url={profile.picture_url} code={profile.code} />
                        <Text ml="xs">{profile.name}</Text>
                      </Flex>
                    )}
                    {times.map(({ hour, minute, interval, value }, index) => (
                      <Flex mt="s" key={index} alignItems="center">
                        <Flex alignItems="center" width="60px">
                          <StyledText
                            fontSize="xs"
                            color="primary"
                            onClick={() => {
                              handleChangeSelectedTime({ hour, minute, interval })
                              setIsSubMenuOpen(false)
                            }}
                          >
                            {hour}:{minute} {interval}
                          </StyledText>
                        </Flex>
                        <Flex ml="m" position="relative" width="150px" height="12px">
                          <ProgressLineBg
                            width="100%"
                            bg="progress_line_background"
                            height="100%"
                            position="absolute"
                          />
                          <ProgressLine
                            width={`calc(${value}%)`}
                            bg="progress_line_color"
                            height="100%"
                            position="absolute"
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </Flex>
            )}
          </SubMenu>
        )}
      </Box>
    </Box>
  )
}

BestPublishingTimeSettings.defaultProps = {
  timezone: null,
  selectedProfiles: [],
}

BestPublishingTimeSettings.propTypes = {
  selectedProfiles: PropTypes.array,
  timezone: PropTypes.string,
  combined: PropTypes.bool.isRequired,
  handleChangeSelectedTime: PropTypes.func.isRequired,
}

BestPublishingTimeSettings.displayName = 'BestPublishingTimeSettings'

export default BestPublishingTimeSettings
