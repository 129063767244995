import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/atoms/Layout'
import Player from './Player'

const AudioPlayer = ({ playlist, isSmall, showCover }) => {
  const [currentPlayer, setCurrentPlayer] = useState()

  let current

  const togglePlay = (id) => {
    const currentAudio = document.getElementById(id)

    const players = document.getElementsByClassName('trending-audio-player')

    const playersArray = [...players]

    const notPlaying = playersArray.filter((item) => {
      if (item !== currentAudio) {
        return item
      }
      return null
    })

    if (!currentAudio.paused) {
      currentAudio.pause()
    } else {
      currentAudio.play()

      notPlaying.map((player) => {
        player.pause()
        setCurrentPlayer(!player)
        return null
      })
    }

    current = currentAudio
    setCurrentPlayer(current)
  }

  return (
    <Box width="100%" height="100%">
      {playlist.map((track) => {
        return (
          <Player
            key={track.id}
            trackId={track.id}
            source={track.source}
            togglePlay={togglePlay}
            trackName={track.trackName}
            trackArtist={track.trackArtist}
            trackImage={track.trackImage}
            loop={track.loop}
            isCurrent={currentPlayer && currentPlayer.id === track.id}
            duration={track.duration}
            isSmall={isSmall}
            showCover={showCover}
          />
        )
      })}
    </Box>
  )
}

AudioPlayer.defaultProps = {
  isSmall: true,
  showCover: true,
}

AudioPlayer.propTypes = {
  playlist: PropTypes.array.isRequired,
  isSmall: PropTypes.bool,
  showCover: PropTypes.bool,
}

export default AudioPlayer
