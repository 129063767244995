import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import Radio from 'components/atoms/Radio'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 450px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const TemplatesContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TEMPLATES = [
  {
    name: 'facebookVideoStoryAN',
    label: 'Facebook Video story',
    selected: false,
  },
  {
    name: 'instagramVideoStoryAN',
    label: 'Instagram Reel or Story',
    selected: false,
    link: 'https://www.canva.com/instagram-posts/templates/',
  },
  {
    name: 'tikTokVideoAN',
    label: 'TikTok Video',
    selected: true,
  },
]

const VistaCreateesignModal = ({ isOpen, handleDismiss, handleClickAddMedias }) => {
  const [template, setTemplate] = useState(TEMPLATES.find((item) => item.selected).name)
  const [templates, setTemplates] = useState(TEMPLATES)

  const designWithCanva = () => {
    handleDismiss()
    window.CrelloButton.init({
      apiKey: '31PFZZF-4ZD4TFS-NZT2MWB-PNEWA0Y',

      // choose format from documentation
      designType: template,

      // for adding custom format
      // customDesignFormatId: customDesignFormatId,

      // for reopen your created design
      // designId,

      activeSidebarTab: 'video',

      // for registration in one clcik
      // user: {
      //   // Set email of the customer:
      // 	email: 'customeremail@example.com',
      //   // Set unique user id of the customer with that email:
      //   externalUserId: 'customerUserId',
      // },

      // To embed it into existing container:
      // container: '#iframe-container',
      // or just pass a DOM Node
      // container: document.getElementById('iframe-container'),

      onPublishAction(data) {
        console.log('published data', data)

        handleClickAddMedias(data.url)

        // Handle publish event
        // replaceImage('#imageContainer', publishData)
      },
    }).then((api) => {
      console.log('Crello iframe is loaded now')
    })
  }

  const selectTemplate = (name) => {
    setTemplate(name)

    for (const template of templates) {
      template.selected = template.name === name
    }

    setTemplates(templates)
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Select type of video</H4>
          </StyledDialogEnvironmentWrapper>
          <TemplatesContent mb="m" mt="m">
            {templates.map(({ name, label, selected }) => {
              return (
                <Flex key={name} alignItems="center" m="s" pl="m">
                  <Radio
                    id={name}
                    onChange={({ target: { id } }) => {
                      selectTemplate(id)
                    }}
                    name="canva_design_template"
                    value={name}
                    checked={selected}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor={name}>
                    <Text ml="m" pl="xs">
                      {label}
                    </Text>
                  </label>
                </Flex>
              )
            })}
          </TemplatesContent>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>
            <Button.Primary onClick={designWithCanva} isSmall>
              <Text>Design with Vista Create</Text>
            </Button.Primary>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

VistaCreateesignModal.defaultProps = {}

VistaCreateesignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickAddMedias: PropTypes.func.isRequired,
}

export default VistaCreateesignModal
