import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import { Slider } from '@material-ui/core'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  FEATURE_DRIVES,
  FEATURE_DYNAMIC_MEDIA,
  FEATURE_FIND_CONTENT,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
} from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import withConfirm from 'utils/withConfirm'
import errorHelper from 'utils/errorHelper'
import Select from 'shared/DropDown'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Checkbox from 'components/atoms/Checkbox'
import AudioPlayer from 'components/molecules/AudioPlayer'
import Icon from 'components/atoms/Icon'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Link from 'components/atoms/Link'
import VideoPickSoundModal from 'routes/Media/components/VideoPickSoundModal'
import MediaBodyComponent from 'routes/Media/MediaBodyComponent'
import { transformTextVariantsToUnicodeVariant, getSelectedProfileGroupsAndTimezone } from '../../helpers'
import TextAreaComponentWithControls from '../Posts/components/CommentsComponent/TextAreaComponentWithControls'
import {
  THEME_LIGHT,
  PALETTE_TYPE_COLOR,
  PALETTE_TYPE_GRADIENT,
  PALETTE_TYPE_MEDIA,
  ROUTE_MEDIA,
  POST_IMAGE,
  POST_VIDEO,
  ROUTE_CALENDAR,
  THEMES,
  COLORS_PALETTE,
  GRADIENT_PALETTE,
} from '../../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 80vw;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const RightColumnWrapper = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledColorLabelWrapper = styled(Flex)`
  border-radius: ${radius.s};
  box-shadow: 0 0 0 4px ${({ theme }) => transparentize(0.95, theme.colors.box_shadow)};
  width: 18px;
  height: 9px;
`

const StyledInputColor = styled.input`
  width: 100%;
  padding: ${space.xs} ${space.m};
  border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.error : theme.colors.border_color)};
  background: transparent;
  border-radius: ${radius.l};
  height: ${pxToRem(40)};
  cursor: pointer;
`

const StyledColorPaletteItemWrapper = styled(Box)`
  width: 28px;
  height: 28px;
  margin-right: 14px;
  margin-bottom: 14px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
  &:hover {
    transform: scale(1.2);
  }
`

const StyledColorPaletteItem = styled(Box)`
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: ${({ boxColor }) => boxColor} 0px 0px 0px ${({ selected }) => (selected ? '3px' : '14px')} inset;
  transition: box-shadow 100ms ease 0s;
  border: 1px solid ${({ hasBorder, theme }) => (hasBorder ? theme.colors.border_color : 'transparent')};
`

const StyledGradientPaletteItemWrapper = styled(Flex)`
  width: 64px;
  height: 64px;
  border: 1px solid transparent;
  background-image: ${({ backgroundImage }) => backgroundImage};
  cursor: pointer;
  border-radius: ${radius.l};
  ${({ selected, theme }) =>
    selected &&
    `border: 1px solid ${theme.colors.primaryText}; box-shadow: 0 0 5px 5px ${({ theme }) =>
      transparentize(0.95, theme.colors.box_shadow)};`}
  &:hover {
    box-shadow: 0 0 5px 5px ${({ theme }) => transparentize(0.95, theme.colors.box_shadow)};
  }
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const NETWORK_SIZES_DYNAMIC_MEDIA = [
  { width: 1080, height: 1080, value: '1:1' },
  { width: 1080, height: 1920, value: '9:16' },
  { width: 1920, height: 1080, value: '16:9' },
  { width: 1200, height: 630, value: '1.91:1' },
  { width: 1024, height: 512, value: '2:1' },
  { width: 1000, height: 1500, value: '2:3' },
]

const DEFAULT_DYNAMIC_IMAGE = {
  type: IMAGE_DYNAMIC,
  width: 1080,
  height: 1080,
  aspect_ratio: 1,
  dynamic: {
    post_caption: true,
    //  text: '', this field by default is undefined
    show_profile: true,
    background: {
      background_type: PALETTE_TYPE_GRADIENT,
      background_color: GRADIENT_PALETTE[1].color,
    },
    theme: THEME_LIGHT,
    content_width: 70,
    scale: 1.2,
    corner_radius: 1,
    shadow: 0,
    transparency: 0,
    watermark: true,
  },
}

const PALETTE_TABS = [
  { name: 'Color', type: PALETTE_TYPE_COLOR },
  { name: 'Gradient', type: PALETTE_TYPE_GRADIENT },
  { name: 'Media library', type: PALETTE_TYPE_MEDIA },
]

const TEXT_PROMPTS = {
  [IMAGE_DYNAMIC]: {
    text: `Design your custom style for this image and we'll automatically insert your post's caption when you attach this image to a post.`,
    link: 'https://support.vistasocial.com/hc/en-us/articles/12725376917147',
  },
  [VIDEO_DYNAMIC]: {
    text: `Design your custom style for this video and we'll automatically insert your post's caption when you attach this video to a post.`,
    link: 'https://support.vistasocial.com/hc/en-us/articles/12726170426011',
  },
}

const formatNetworkSizesOption = ({ width, height, value }) => {
  return (
    <Flex alignItems="center" key={value}>
      <Text fontSize="xs">
        {width} x {height} px ({value})
      </Text>
    </Flex>
  )
}

const formatThemeOption = ({ value, label, color }) => (
  <Flex alignItems="center" key={value}>
    <StyledColorLabelWrapper bg={color} mx="xs" />
    <Text ml="s" fontSize="xs">
      {label}
    </Text>
  </Flex>
)

const DynamicMediaModal = ({
  isOpen,
  handleDismiss,
  user,
  media,
  post,
  selectedEntities,
  selectedProfiles,
  handleUpdateDynamicMedia,
  confirm,
  folderPath,
}) => {
  const mediaComponentRef = useRef(null)

  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_DYNAMIC_MEDIA]: { enabled: true },
    [FEATURE_FIND_CONTENT]: { enabled: true },
    [FEATURE_DRIVES]: { enabled: true },
  })
  const [dynamicMedia, setDynamicMedia] = useState({})
  const [activePaletteTab, setActivePaletteTab] = useState(PALETTE_TYPE_GRADIENT)
  const [isSavingMedia, setIsSavingMedia] = useState(false)
  const [isOpenVideoPickSoundModal, setIsOpenVideoPickSoundModal] = useState(false)
  const [isGettingPublications, setIsGettingPublications] = useState(true)
  const [publications, setPublications] = useState([])
  const [textVariantOffsets, setTextVariantOffsets] = useState([])

  const {
    type,
    width,
    height,
    dynamic: {
      post_caption = true,
      text,
      show_profile = true,
      background: { background_color } = {},
      theme,
      content_width,
      scale,
      corner_radius,
      shadow,
      watermark = true,
      audio,
      transparency = 0,
    } = {},
  } = dynamicMedia

  const { audio_gid, audio_root_entity_gid } = audio || {}

  const { postComponent } = post || {}

  const mediaText = text || ''

  let inputColor = COLOR_CONSTANTS.BLACK

  if (background_color && background_color[0] === '#') {
    inputColor = background_color
  }

  let modalTitle = 'Dynamic image'
  if (type === VIDEO_DYNAMIC) {
    modalTitle = 'Dynamic video'
  }

  let audioUrl = ''
  if (audio_gid) {
    audioUrl = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/media/${audio_root_entity_gid}/${audio_gid}.mp3`
  }

  const isOnlyPublication =
    publications &&
    (publications.length === 0 || (publications.length === 1 && post && post.id && publications[0] === post.id))

  const getMediaPublications = async () => {
    const { publications = [] } = media || {}
    if (publications.length === 0 || (publications.length === 1 && post && post.id && publications[0] === post.id)) {
      setIsGettingPublications(false)
      setPublications([...publications])
    } else {
      try {
        const response = await request({
          path: `${ROUTE_CALENDAR}/dynamic-media-publications`,
          method: 'POST',
          body: { publicationIds: publications },
        })

        const { data = [], error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        }

        setPublications([...data])
      } catch (error) {
        errorHelper({
          error,
          componentName: DynamicMediaModal.displayName,
          functionName: 'getMediaPublications',
          errorMessage: `Unable to get media publications. Please contact support.`,
        })
      } finally {
        setIsGettingPublications(false)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_DYNAMIC_MEDIA, FEATURE_FIND_CONTENT, FEATURE_DRIVES],
        }),
      })

      setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])

      if (media) {
        setDynamicMedia({ ...media })

        const { dynamic: { background: { background_type } = {} } = {} } = media
        if (background_type !== PALETTE_TYPE_MEDIA) {
          setActivePaletteTab(background_type)
        }
      } else {
        const data = JSON.parse(JSON.stringify(DEFAULT_DYNAMIC_IMAGE))

        let mediaType = IMAGE_DYNAMIC
        if (postComponent) {
          if (postComponent === POST_IMAGE) {
            mediaType = IMAGE_DYNAMIC
          } else if (postComponent === POST_VIDEO) {
            mediaType = VIDEO_DYNAMIC
          }
        }

        data.type = mediaType

        setDynamicMedia({ ...data })
      }
      getMediaPublications()
    }
  }, [isOpen])

  const handleClickOpenVideoPickSoundModal = () => {
    setIsOpenVideoPickSoundModal(true)
  }

  const handleClickCloseVideoPickSoundModal = () => {
    setIsOpenVideoPickSoundModal(false)
  }

  const handleUpdateDynamicParameters = ({ key, value }) => {
    dynamicMedia.dynamic[key] = value
    setDynamicMedia({ ...dynamicMedia })
  }

  const handleUpdateMediaTextDebounce = debounce(({ value }) => {
    handleUpdateDynamicParameters({ key: 'text', value })
  }, 300)

  const handleUpdateVideoPickSound = ({ audio, audio_gid }) => {
    const { duration, media_entity_gid } = audio
    dynamicMedia.dynamic.audio = { audio_gid, audio_root_entity_gid: media_entity_gid }
    dynamicMedia.duration = duration
    handleClickCloseVideoPickSoundModal()
  }

  const handleClickSaveMedia = async () => {
    setIsSavingMedia(true)
    try {
      let response

      const dynamicData = JSON.parse(JSON.stringify(dynamicMedia))
      if (dynamicData.dynamic.background.background_type === PALETTE_TYPE_MEDIA) {
        dynamicData.dynamic.background.background_media = dynamicData.dynamic.background.background_media.id
      }

      if (dynamicData.id) {
        response = await request({
          method: 'PATCH',
          body: dynamicData,
          path: `${ROUTE_MEDIA}/${dynamicData.id}`,
        })
      } else {
        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({ user })

        dynamicData.entities = selectedEntitiesWithSelectedProfiles.map(({ id }) => id)

        dynamicData.media_path = folderPath

        response = await request({
          method: 'POST',
          body: { dynamicData },
          path: ROUTE_MEDIA,
        })
      }

      const { error, id } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setIsSavingMedia(false)
      } else {
        dynamicMedia.id = id
        dynamicMedia.media_gid = id
        setIsSavingMedia(false)
        handleUpdateDynamicMedia({ media: dynamicMedia })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: DynamicMediaModal.displayName,
        functionName: 'handleClickSaveMedia',
      })
      setIsSavingMedia(false)
    }
  }

  const handleClickSaveMediaPreprocess = () => {
    if (isGettingPublications) {
      Alert.error(`Please wait a bit while we collect your media data.`, { timeout: 5000 })
    } else if (isOnlyPublication) {
      handleClickSaveMedia()
    } else {
      confirm({
        fn: () => () => handleClickSaveMedia(),
        message: `This media is used in ${publications.length} other ${
          publications.length === 1 ? 'post' : 'posts'
        } that haven't been published yet. Updating this media will apply to those posts as well.`,
        action: 'Save media',
      })
    }
  }

  const handleClickCloseModal = () => {
    if (isGettingPublications) {
      Alert.error(`Please wait a bit while we collect your media data.`, { timeout: 5000 })
    } else if (isSavingMedia) {
      Alert.error(`Please wait a bit while we save your media.`, { timeout: 5000 })
    } else {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" flexDirection="column" tabIndex={0} $isTop>
            <H4 my="m">{modalTitle}</H4>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper flex="1" flexDirection={{ mobile: 'column', tablet: 'row' }}>
            <Flex
              height="100%"
              bg="background"
              px="m"
              pt="m"
              alignItems="center"
              justifyContent="center"
              flex="1 1 50%"
              flexDirection="column"
            >
              <Flex
                flexDirection="column"
                width="100%"
                height={`calc(100% - ${type === VIDEO_DYNAMIC ? 52 : 0}px)`}
                maxWidth={{ mobile: '300px', tablet: '500px' }}
                maxHeight={{ mobile: '300px', tablet: '500px' }}
              >
                <DynamicMedia media={dynamicMedia} post={post} />
              </Flex>
              {type === VIDEO_DYNAMIC && (
                <Flex height="52px" width="100%" flexDirection="row" py="s" alignItems="center" justifyContent="center">
                  {audio_gid ? (
                    <Flex alignItems="center" justifyItems="space-between" width="100%">
                      <AudioPlayer
                        playlist={[
                          {
                            id: `trending-audio-${audio_gid}`,
                            source: audioUrl,
                            trackName: 'Selected sound',
                            trackArtist: '',
                            trackImage: '',
                            loop: false,
                          },
                        ]}
                        showCover={false}
                      />
                      <CheckBoxWrapper
                        pl="m"
                        pr="s"
                        onClick={() => {
                          handleUpdateDynamicParameters({
                            key: 'audio',
                            value: null,
                          })
                        }}
                      >
                        <Icon.Trash />
                      </CheckBoxWrapper>
                    </Flex>
                  ) : (
                    <Button.Primary isSmall onClick={handleClickOpenVideoPickSoundModal}>
                      Pick sound
                    </Button.Primary>
                  )}
                </Flex>
              )}
            </Flex>
            <RightColumnWrapper flex="1 1 50%" height="100%" flexDirection="column">
              <Scrollbars universal>
                <Flex flexDirection="column" px="m" py="m">
                  {TEXT_PROMPTS[type] && (
                    <Text mb="m">
                      <Text as="span" color="primaryText">
                        {TEXT_PROMPTS[type].text}
                      </Text>{' '}
                      <Link as="a" target="_blank" href={TEXT_PROMPTS[type].link} color="primary">
                        Learn more.
                      </Link>
                    </Text>
                  )}
                  <Box>
                    <CheckBoxWrapper>
                      <Checkbox
                        isChecked={post_caption}
                        onClick={() => {
                          handleUpdateDynamicParameters({
                            key: 'post_caption',
                            value: !post_caption,
                          })
                        }}
                      >
                        <Text ml="m" pl="s">
                          Use post caption
                        </Text>
                      </Checkbox>
                    </CheckBoxWrapper>
                  </Box>

                  {!post_caption && (
                    <Flex flexDirection="column" width="100%" mt="m">
                      <TextAreaComponentWithControls
                        user={user}
                        handleUpdateText={(text) => {
                          handleUpdateMediaTextDebounce({ value: text })
                        }}
                        text={mediaText}
                        textVariantOffsets={textVariantOffsets}
                        textareaPlaceholder="Write your content ..."
                        errorText=""
                        selectedEntities={selectedEntities}
                        selectedProfiles={selectedProfiles}
                        disabled={post_caption}
                      />
                    </Flex>
                  )}

                  <Flex alignItems="center" mt="m">
                    <Box width="50%" pr="s">
                      <Select
                        placeholder="Select size"
                        label=""
                        value={NETWORK_SIZES_DYNAMIC_MEDIA.find(
                          (network_size) => network_size.width === width && network_size.height === height
                        )}
                        onChange={({ width, height }) => {
                          dynamicMedia.width = width
                          dynamicMedia.height = height
                          dynamicMedia.aspect_ratio = Number((width / height).toFixed(2))
                          setDynamicMedia({ ...dynamicMedia })
                        }}
                        formatOptionLabel={formatNetworkSizesOption}
                        options={NETWORK_SIZES_DYNAMIC_MEDIA}
                        openMenuOnFocus
                        height="48px"
                        isSearchable={false}
                      />
                    </Box>
                    <Box width="50%" pl="s">
                      <Select
                        placeholder="Select theme color"
                        label=""
                        value={theme ? THEMES.find((item) => item.value === theme) : THEMES[0]}
                        onChange={({ value }) => {
                          handleUpdateDynamicParameters({ key: 'theme', value })
                        }}
                        formatOptionLabel={formatThemeOption}
                        options={THEMES}
                        openMenuOnFocus
                        height="48px"
                        isSearchable={false}
                      />
                    </Box>
                  </Flex>

                  <Box mt="m">
                    <Flex height="30px" alignItems="center">
                      {PALETTE_TABS.map(({ name, type }) => {
                        return (
                          <Tab
                            key={name}
                            onClick={() => {
                              setActivePaletteTab(type)
                            }}
                            isActive={type === activePaletteTab}
                          >
                            <Text color={type === activePaletteTab ? 'primary' : 'secondaryText'} fontSize="s">
                              {name}
                            </Text>
                          </Tab>
                        )
                      })}
                    </Flex>

                    {activePaletteTab === PALETTE_TYPE_COLOR && (
                      <Flex mt="s" flexDirection="column" width="100%">
                        <StyledInputColor
                          type="color"
                          value={inputColor}
                          onChange={(event) => {
                            handleUpdateDynamicParameters({
                              key: 'background',
                              value: { background_type: PALETTE_TYPE_COLOR, background_color: event.target.value },
                            })
                          }}
                        />
                        <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(35px, max-content))">
                          {COLORS_PALETTE.map(({ color, hasBorder }) => (
                            <StyledColorPaletteItemWrapper key={color}>
                              <span>
                                <StyledColorPaletteItem
                                  boxColor={color}
                                  hasBorder={hasBorder}
                                  selected={color === background_color}
                                  onClick={() => {
                                    handleUpdateDynamicParameters({
                                      key: 'background',
                                      value: { background_type: PALETTE_TYPE_COLOR, background_color: color },
                                    })
                                  }}
                                />
                              </span>
                            </StyledColorPaletteItemWrapper>
                          ))}
                        </Grid>
                      </Flex>
                    )}

                    {activePaletteTab === PALETTE_TYPE_GRADIENT && (
                      <Grid mt="s" gridTemplateColumns="repeat(auto-fit, minmax(60px, max-content))" gridGap="s">
                        {GRADIENT_PALETTE.map(({ color }) => (
                          <StyledGradientPaletteItemWrapper
                            key={color}
                            selected={color === background_color}
                            onClick={() => {
                              handleUpdateDynamicParameters({
                                key: 'background',
                                value: { background_type: PALETTE_TYPE_GRADIENT, background_color: color },
                              })
                            }}
                            backgroundImage={color}
                          />
                        ))}
                      </Grid>
                    )}
                  </Box>

                  {activePaletteTab === PALETTE_TYPE_MEDIA && (
                    <Flex height="400px" width="100%" flexDirection="column" mx={`-${space.m}`} zIndex="0">
                      <MediaBodyComponent
                        ref={mediaComponentRef}
                        user={user}
                        confirm={confirm}
                        selectedProfiles={selectedProfiles}
                        selectedEntities={selectedEntities}
                        entities={[]}
                        defaultTypes={[{ value: IMAGE, label: 'Images' }]}
                        isSmall
                        isSelectable={false}
                        isFavorable={false}
                        hasItemControls={false}
                        handleSpecialItemOnClick={({ media }) => {
                          if (media.type === IMAGE) {
                            handleUpdateDynamicParameters({
                              key: 'background',
                              value: {
                                background_type: PALETTE_TYPE_MEDIA,
                                background_media: media,
                              },
                            })
                          } else {
                            Alert.error('Please select an image for the background.', { timeout: 5000 })
                          }
                        }}
                      />
                    </Flex>
                  )}

                  <Grid mt="m" gridTemplateColumns="repeat(2, 1fr)" gridGap="m" pr="s">
                    <Flex width="100%" alignItems="center" flexDirection="column">
                      <Box width="100%">
                        <Text>Content width</Text>
                      </Box>
                      <Slider
                        defaultValue={content_width}
                        aria-labelledby="discrete-slider"
                        step={1}
                        min={50}
                        max={100}
                        value={content_width}
                        onChange={(event, value) => {
                          handleUpdateDynamicParameters({ key: 'content_width', value })
                        }}
                      />
                    </Flex>
                    <Flex width="100%" alignItems="center" flexDirection="column">
                      <Box width="100%">
                        <Text>Scale</Text>
                      </Box>
                      <Slider
                        defaultValue={scale}
                        aria-labelledby="discrete-slider"
                        step={0.1}
                        min={1}
                        max={2}
                        value={scale}
                        onChange={(event, value) => {
                          handleUpdateDynamicParameters({ key: 'scale', value })
                        }}
                      />
                    </Flex>

                    <Flex width="100%" alignItems="center" flexDirection="column">
                      <Box width="100%">
                        <Text>Corner roundness</Text>
                      </Box>
                      <Slider
                        defaultValue={corner_radius}
                        aria-labelledby="discrete-slider"
                        step={0.1}
                        min={0}
                        max={2}
                        value={corner_radius}
                        onChange={(event, value) => {
                          handleUpdateDynamicParameters({ key: 'corner_radius', value })
                        }}
                      />
                    </Flex>

                    <Flex width="100%" alignItems="center" flexDirection="column">
                      <Box width="100%">
                        <Text>Shadow</Text>
                      </Box>
                      <Slider
                        defaultValue={shadow}
                        aria-labelledby="discrete-slider"
                        step={0.1}
                        min={0}
                        max={3}
                        value={shadow}
                        onChange={(event, value) => {
                          handleUpdateDynamicParameters({ key: 'shadow', value })
                        }}
                      />
                    </Flex>

                    <Flex width="100%" alignItems="center" flexDirection="column">
                      <Box width="100%">
                        <Text>Transparency</Text>
                      </Box>
                      <Slider
                        defaultValue={transparency}
                        aria-labelledby="discrete-slider"
                        step={0.1}
                        min={0}
                        max={1}
                        value={transparency}
                        onChange={(event, value) => {
                          handleUpdateDynamicParameters({ key: 'transparency', value })
                        }}
                      />
                    </Flex>
                  </Grid>

                  <Box mt="m">
                    <CheckBoxWrapper>
                      <Checkbox
                        isChecked={show_profile}
                        onClick={() => {
                          handleUpdateDynamicParameters({
                            key: 'show_profile',
                            value: !show_profile,
                          })
                        }}
                      >
                        <Text ml="m" pl="s">
                          Show profile information
                        </Text>
                      </Checkbox>
                    </CheckBoxWrapper>
                  </Box>

                  <Box mt="m">
                    <CheckBoxWrapper>
                      <Checkbox
                        isChecked={watermark}
                        onClick={() => {
                          if (watermark) {
                            if (featuresEnabled[FEATURE_DYNAMIC_MEDIA].enabled) {
                              handleUpdateDynamicParameters({
                                key: 'watermark',
                                value: !watermark,
                              })
                            } else {
                              Alert.error(featuresEnabled[FEATURE_DYNAMIC_MEDIA].description, {
                                timeout: 5000,
                              })
                            }
                          } else {
                            handleUpdateDynamicParameters({
                              key: 'watermark',
                              value: !watermark,
                            })
                          }
                        }}
                      >
                        <Text ml="m" pl="s">
                          Show Made with Vista Social
                        </Text>
                      </Checkbox>
                    </CheckBoxWrapper>
                  </Box>
                </Flex>
              </Scrollbars>
            </RightColumnWrapper>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isSavingMedia}>
              Cancel
            </Button.Gray>

            <ButtonWithLoading
              onClick={() => {
                handleClickSaveMediaPreprocess()
              }}
              isSmall
              isLoading={isSavingMedia}
            >
              <Text fontWeight="medium">{isSavingMedia ? 'Saving' : 'Save'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          {isOpenVideoPickSoundModal && (
            <VideoPickSoundModal
              user={user}
              handleDismiss={handleClickCloseVideoPickSoundModal}
              isOpen={isOpenVideoPickSoundModal}
              selectedVideo={dynamicMedia}
              handleUpdateMedia={handleUpdateVideoPickSound}
              featuresEnabled={featuresEnabled}
            />
          )}

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isSavingMedia}>
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

DynamicMediaModal.defaultProps = {
  media: null,
  post: null,
  selectedEntities: [],
  selectedProfiles: [],
  folderPath: null,
}

DynamicMediaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  media: PropTypes.object,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  handleUpdateDynamicMedia: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  folderPath: PropTypes.array,
}

DynamicMediaModal.displayName = 'DynamicMediaModal'

export default withConfirm(DynamicMediaModal)
