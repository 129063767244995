import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'

export default styled.button`
  z-index: 10;
  background: none;
  border-style: none;
  font-size: 50px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  transition: color 0.2s linear;
`
