import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import { radius, COLOR_CONSTANTS, space, fontWeights, breakpoints } from 'theme'
import {
  DEFAULT_VIDEO_THUMBNAIL,
  TIKTOK,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
  IMAGE,
  IMAGE_DYNAMIC,
} from 'consts'
import { linkTransformer } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Image from 'components/atoms/Image'
import Switch from 'components/atoms/Switch'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Counter from 'components/molecules/Counter'
import MediaCarousel from 'components/molecules/MediaCarousel'
import Tooltip from 'components/molecules/Tooltip'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  NETWORK_LIMITS,
  TIKTOK_COLORS,
  PREVIEW_MEDIA_CLASS_NAME,
  POST_VIDEO,
  POST_IMAGE,
  POST_MIXED_MEDIA,
  VIEW_OPTIONS,
  VIEW_GRID,
  VIEW_FEED,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import {
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
  mentionsTransformer,
  getTikTokProfileMedias,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import BoostsDropdownComponent from './components/BoostsDropdownComponent'
import InstagramGrid from './components/InstagramGrid'

const StyledPreviewWrapper = styled(Flex)`
  flex-direction: column;
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  margin: 0 auto;
`

const StyledImg = styled(ImageWithFallback)`
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  border-radius: ${radius.pill};
  background-color: rgba(136, 136, 136, 0.5);
  border-color: rgba(22, 24, 35, 0.12);
  border-width: 0.5px;
  cursor: pointer;
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'auto'};
`

const SourceWrapper = styled(Box)`
  width: 23.4375em;
  min-height: 38.5625em;
  background-color: ${COLOR_CONSTANTS.BLACK};
  position: relative;
  box-sizing: border-box;
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  &:before {
    z-index: -1;
    content: '';
    width: 100%;
    height: 225px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const StyledFirstImage = styled(ImageWithFallback)`
  object-fit: cover;
  width: auto;
  max-width: 100%;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledControlWrapper = styled(Flex)`
  width: 4.375em !important;
  align-items: center;
  justify-content: center;
`

const StyledCommentsWrapper = styled(Flex)`
  background-color: rgb(248, 248, 248);
  border-top: 1px solid rgba(18, 18, 18, 0.12);
  border-bottom: 1px solid rgba(18, 18, 18, 0.12);
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 4;
  box-orient: vertical;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  span {
    color: ${({ theme }) => theme.colors.white} !important;
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledCommentWrapper = styled(Flex)`
  width: 100%;
  white-space: pre-wrap;
`

const StyledPlusWrapper = styled(Flex)`
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%);
  margin-bottom: -11px;
`

const StyledHtmlCollapseInnerWrapper = styled(Flex)`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: unset;
`

const StyledHtmlCollapseText = styled(Text)`
  font-size: 0.9375em;
  white-space: normal;
  word-break: break-word;
  display: inline;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledHtmlCollapseBodyWrapper = styled(Box)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  box-orient: vertical;
  &:before {
    content: '';
    height: calc(100% - 1.125em);
    float: right;
    width: 0;
    margin-top: 0px;
  }
`

const CollapseWrapper = styled(Flex)`
  float: right;
  clear: both;
  cursor: pointer;
  align-items: center;
  line-height: 1.125em;
`

const CollapseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  color: ${({ textColor }) => textColor};
  font-size: 0.9375em;
  line-height: 1.125em;
`

const StyledAudioWrapper = styled(Flex)`
  font-size: 0.9375em;
  margin-bottom: 0.75em;
  color: #fff;
  letter-spacing: -0.2px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

const StyledMusicNote = styled(Box)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('/assets/tiktok_music_note.png');
  background-size: 0.8125em 0.8125em;
  background-repeat: no-repeat;
  background-position: left center;
  width: 0.9375em;
  height: 0.9375em;
`

const CaptionWrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  font-size: 0.9375em;
  font-weight: normal;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const StyledViewSwitchWrapper = styled(Flex)`
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 2;
  cursor: pointer;
`

const StyledGrayButton = styled(Button.Gray)`
  background-color: ${({ theme }) => theme.colors.white};
  min-width: ${({ minWidth }) => minWidth};
  ${({ isFirst }) => isFirst && `border-top-right-radius: 0px; border-bottom-right-radius: 0px;`}
  ${({ isLast }) => isLast && `border-top-left-radius: 0px; border-bottom-left-radius: 0px;`}
`

const HtmlCollapse = ({ html, textColor }) => {
  const maxLength = 80
  const [isOpen, setIsOpen] = useState(false)
  const messageEnd = html && html.length > maxLength ? html.substr(maxLength) : ''

  return (
    <StyledHtmlCollapseInnerWrapper>
      <StyledHtmlCollapseBodyWrapper maxHeight={isOpen ? 'auto' : '36px'}>
        {messageEnd && (
          <CollapseWrapper>
            <CollapseButton
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
              textColor={textColor}
            >
              {isOpen ? 'less' : 'more'}
            </CollapseButton>
          </CollapseWrapper>
        )}
        <StyledHtmlCollapseText
          color={textColor}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </StyledHtmlCollapseBodyWrapper>
    </StyledHtmlCollapseInnerWrapper>
  )
}

HtmlCollapse.propTypes = {
  html: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
}

const PRIVACY_STATUSES = [
  { value: 'PUBLIC_TO_EVERYONE', label: 'Visible to all TikTok users' },
  { value: 'MUTUAL_FOLLOW_FRIENDS', label: 'Only visible to mutual followers' },
  { value: 'FOLLOWER_OF_CREATOR', label: 'Only visible to followers of the account' },
  { value: 'SELF_ONLY', label: 'Private, only visible to the account itself' },
]

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === TIKTOK)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  imagesTitleLimit: MAX_IMAGES_TITLE_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const PROCESSED_VIDEO_ENDING = '_facebook_reel'

const TikTokPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  isSmallPreview,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [profile, setProfile] = useState({ ...props.profile })
  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_HEIGHT = smallPreview ? 333.33 : 510
  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const {
    id: postId,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    customizations = {},
    postText,
    insights = {},
    postComponent,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let comments = []
  let comments_enabled = true
  let pin_comment = false
  let duets_enabled = true
  let stitches_enabled = true
  let device_gid = null
  let publishing_option = null
  let is_promotional_content = false
  let is_paid_partnership = false
  let boost_gid = null
  let privacy_level = PRIVACY_STATUSES[0].value
  let title = ''
  let auto_add_music = false
  let view = VIEW_OPTIONS[0].value

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      comments = [],
      comments_enabled = true,
      pin_comment = false,
      duets_enabled = true,
      stitches_enabled = true,
      device_gid = null,
      publishing_option = null,
      is_promotional_content = false,
      is_paid_partnership = false,
      boost_gid = null,
      privacy_level = PRIVACY_STATUSES[0].value,
      title = '',
      auto_add_music = false,
      view = VIEW_OPTIONS[0].value,
    } = profileCustomizations)
  }

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { feed: showGridTypeMedias = {} } = networkDataForCustomizations || {}
  const { medias = [], isGettingGridMedia = true } = showGridTypeMedias || {}

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  useEffect(() => {
    if (view === VIEW_GRID && isEditable) {
      getTikTokProfileMedias({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        postIds: [postId],
      })
    }
  }, [view])

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      commentTextMaxLimit,
      deviceLengthLimit,
      notificationDeviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const {
    transformed_text_to_html_for_textarea,
    transformed_text_to_html_for_preview,
    updatedPostTextWithMentions = '',
  } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: TIKTOK_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea =
      linkTransformer({
        body: updatedText,
        color: TIKTOK_COLORS.LAST_LIGHT_BLUE,
        transformHashstag: true,
        hashtagColor: TIKTOK_COLORS.MAIN,
      }) || ''

    const transformed_text_to_html_for_preview =
      linkTransformer({
        body: updatedText,
        color: COLOR_CONSTANTS.WHITE,
        transformHashstag: true,
        hashtagColor: COLOR_CONSTANTS.WHITE,
      }) || ''

    return { transformed_text_to_html_for_textarea, transformed_text_to_html_for_preview, updatedPostTextWithMentions }
  }, [updatedPostText, savedVariables, agencyMentions])

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_likes = 0
  let c_comments = 0
  let c_shares = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, comments: c_comments = 0, shares: c_shares = 0 } = profileInsights)
  }

  c_comments = c_comments || comments_enabled ? comments.filter(({ message }) => message).length : 0

  const postMixedMediasUpdated =
    customizedPostComponent === POST_MIXED_MEDIA ? customizedPostMixedMedias.filter(({ tempId }) => !tempId) : []

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const generateMediaTemplate = ({ media }) => {
    const { url, id, [network]: networkErrors = {}, isNew, type } = media
    const { hasAspectRatioError, hasSizeError, hasResolutionError, hasExtensionError } = networkErrors || {}
    const hasError = hasAspectRatioError || hasSizeError || hasResolutionError || hasExtensionError
    return (
      <Flex width={`${MAX_PREVIEW_WIDTH}px`} height={`${MAX_PREVIEW_HEIGHT}px`} key={id}>
        <StyledBox position="relative" width="100%" justifyContent="center" hasError={hasError}>
          {isNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              {type === IMAGE && (
                <StyledFirstImage
                  name={id}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  source={url}
                  width="100%"
                  height="100%"
                />
              )}
              {type === IMAGE_DYNAMIC && (
                <Flex width={`${MAX_PREVIEW_HEIGHT}px`} height={`${MAX_PREVIEW_HEIGHT}px`}>
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={smallPreview} />
                </Flex>
              )}
            </Fragment>
          )}
        </StyledBox>
      </Flex>
    )
  }

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { url: videoUrl, thumbnail_url, type, id: videoId, isNew } = data[0]

    const postVideoError =
      postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <Fragment>
        {noVideoAttachment ? (
          <Flex justifyContent="center" alignItems="center" position="relative" width="100%" height="100%">
            {type === VIDEO && (
              <Fragment>
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
                <StyledVideoThumbnailImage
                  height="100%"
                  width="100%"
                  source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                  fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                  name={videoId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                />
              </Fragment>
            )}
            {type === VIDEO_DYNAMIC && (
              <DynamicMedia media={data[0]} post={post} profiles={[profile]} verticalPreview isSmall={smallPreview} />
            )}
          </Flex>
        ) : (
          <StyledBox position="relative" width="100%" height="100%" justifyContent="center" hasError={postVideoError}>
            {isNew ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Fragment>
                {type === VIDEO && (
                  <VideoWithFallback
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                    url={videoUrl}
                    controls={false}
                    loop
                    width="100%"
                    style={{ position: 'relative' }}
                    height="100%"
                    config={{
                      file: {
                        attributes: {
                          poster: videoThumbnailUrl,
                        },
                      },
                    }}
                    fallbackSourceImage={videoThumbnailUrl}
                    name={videoId}
                    processedVideoEnding={PROCESSED_VIDEO_ENDING}
                  />
                )}
                {type === VIDEO_DYNAMIC && (
                  <DynamicMedia
                    media={data[0]}
                    post={post}
                    profiles={[profile]}
                    verticalPreview
                    isSmall={smallPreview}
                  />
                )}
              </Fragment>
            )}
          </StyledBox>
        )}
      </Fragment>
    )
  }

  let sourceTypeTemplate = ''
  let instagramGridParams = null

  if (view === VIEW_FEED) {
    switch (customizedPostComponent) {
      case POST_IMAGE:
        // eslint-disable-next-line no-case-declarations
        const postImagesUpdated = customizedPostImages.filter(({ tempId }) => !tempId)

        sourceTypeTemplate = (
          <MediaCarousel
            post={post}
            width={MAX_PREVIEW_WIDTH}
            height={MAX_PREVIEW_HEIGHT}
            medias={postImagesUpdated}
            type={IMAGE}
            sliderElements={postImagesUpdated.map((media) => {
              return (
                <Flex width="100%" height="100%" bg={COLOR_CONSTANTS.BLACK}>
                  {generateMediaTemplate({ media })}
                </Flex>
              )
            })}
            activeDotsColor={COLOR_CONSTANTS.WHITE}
            showArrows={false}
          />
        )

        break
      case POST_VIDEO:
        if (customizedPostVideos.length !== 0) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
        }

        break
      case POST_MIXED_MEDIA:
        if (postMixedMediasUpdated.length !== 0) {
          if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
            const postMixedMediasFiltered = postMixedMediasUpdated.filter(
              ({ type }) => type === IMAGE || type === IMAGE_DYNAMIC
            )
            sourceTypeTemplate = (
              <MediaCarousel
                post={post}
                width={MAX_PREVIEW_WIDTH}
                height={MAX_PREVIEW_HEIGHT}
                medias={postMixedMediasFiltered}
                type={IMAGE}
                sliderElements={postMixedMediasFiltered.map((media) => {
                  return (
                    <Flex width="100%" height="100%" bg={COLOR_CONSTANTS.BLACK}>
                      {generateMediaTemplate({ media })}
                    </Flex>
                  )
                })}
                activeDotsColor={COLOR_CONSTANTS.WHITE}
                showArrows={false}
              />
            )
          } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
            sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: [postMixedMediasUpdated[0]] })
          }
        }
        break
      default:
        break
    }
  } else if (view === VIEW_GRID && isEditable) {
    if (customizedPostComponent === POST_IMAGE) {
      instagramGridParams = {
        medias,
        isGettingGridMedia,
        isCarousel: customizedPostImages.length > 1,
        postMedias: [customizedPostImages[0] || {}],
        post,
        profiles: [profile],
      }
    } else if (customizedPostComponent === POST_VIDEO) {
      instagramGridParams = {
        medias,
        isGettingGridMedia,
        isCarousel: false,
        postMedias: [customizedPostVideos[0] || {}],
        showAsReels: true,
        post,
        profiles: [profile],
      }
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      if (postMixedMediasUpdated[0]) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          instagramGridParams = {
            medias,
            isGettingGridMedia,
            isCarousel: postMixedMediasUpdated.length > 1,
            postMedias: [postMixedMediasUpdated[0] || {}],
            post,
            profiles: [profile],
          }
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          instagramGridParams = {
            medias,
            isGettingGridMedia,
            isCarousel: false,
            postMedias: [postMixedMediasUpdated[0] || {}],
            showAsReels: true,
            post,
            profiles: [profile],
          }
        }
      }
    }

    let gridComponent = null

    if (instagramGridParams) {
      gridComponent = <InstagramGrid {...instagramGridParams} isSmall={smallPreview} />
    }

    sourceTypeTemplate = (
      <Flex flexDirection="column">
        <Flex pt="0.5em" minHeight="3.25em" alignItems="center" position="relative">
          <Flex alignItems="initial" ml="0.5em">
            {isEditable ? (
              <StyledImg
                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                fallbackSource={fallbackLogoImage}
                width="2em"
                height="2em"
              />
            ) : (
              <SocialProfileImage
                code={network}
                picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                width="2em"
                height="2em"
                imageBorderRadius={radius.pill}
              />
            )}
            <Flex ml="0.5em" flexDirection="column" justifyContent="center">
              <Flex
                alignItems="center"
                onMouseEnter={(e) => {
                  if (!isMultipleProfilesPreview) {
                    tooltipRef.current.handleShowTooltip({
                      contentComp: (
                        <ProfileItemTooltip
                          profile={{
                            code: network,
                            picture_url: profilePictureUrl,
                            profile_url: profileUrl,
                            name: profileName,
                            username: profileUsername,
                            display,
                            profile_id,
                          }}
                          message=""
                        />
                      ),
                      wrapperComp: e.currentTarget,
                    })
                  }
                }}
                onMouseLeave={() => {
                  tooltipRef.current.handleHideTooltip()
                }}
                flexWrap="wrap"
              >
                <StyledLink
                  as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                  href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                  target="_blank"
                  color={TIKTOK_COLORS.GLUON_GREY}
                  fontWeight="bold"
                  fontSize="0.875em"
                >
                  {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                </StyledLink>
              </Flex>
              {/* <Flex> */}
              {/*   <StyledLink */}
              {/*     fontWeight="normal" */}
              {/*     fontSize="0.625em" */}
              {/*     color={INSTAGRAM_COLORS.GLUON_GREY} */}
              {/*     as={published_link ? 'a' : 'p'} */}
              {/*     href={published_link || ''} */}
              {/*     target="_blank" */}
              {/*   > */}
              {/*     {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'} */}
              {/*   </StyledLink> */}
              {/* </Flex> */}
            </Flex>
          </Flex>
        </Flex>
        {gridComponent}
      </Flex>
    )
  }

  const hasSwitchButtonView =
    isEditable &&
    (customizedPostImages.length !== 0 || customizedPostVideos.length !== 0 || postMixedMediasUpdated.length !== 0)

  if (
    view === VIEW_GRID &&
    ((customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
      (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
      (customizedPostComponent === POST_MIXED_MEDIA && postMixedMediasUpdated.length === 0))
  ) {
    handleUpdateCustomization({
      type: 'view',
      value: VIEW_FEED,
    })
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}

      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_item_light' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <StyledPreviewWrapper isEditable={smallPreview} isPostFormDirty={isPostFormDirty}>
              <Flex pb="m" width="100%" height="100%" justifyContent="center">
                {isPostFormDirty ? (
                  <Flex flexDirection="column" bg="white">
                    <SourceWrapper
                      borderRadius={hasSwitchButtonView ? `${radius.xl} 0 ${radius.xl} ${radius.xl}` : radius.xl}
                    >
                      {hasSwitchButtonView && (
                        <StyledViewSwitchWrapper>
                          <Tooltip
                            wrapperComp={
                              <StyledGrayButton
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'view',
                                    value: view === VIEW_FEED ? VIEW_GRID : VIEW_FEED,
                                  })
                                }}
                                height="2.125em"
                                minWidth="2.125em"
                                isFirst
                                isLast
                              >
                                <Flex alignItems="center" justifyContent="center">
                                  <Image
                                    source={view === VIEW_FEED ? '/assets/grid.svg' : '/assets/landscape.svg'}
                                    width="1em"
                                    height="1em"
                                  />
                                </Flex>
                              </StyledGrayButton>
                            }
                            defaultTopOffset={-115}
                            message={
                              view === VIEW_FEED
                                ? 'See how this post will appear alongside your other media once published'
                                : 'Return to post preview'
                            }
                            isTriangleVisible={false}
                            left="initial"
                            right="0"
                          />
                        </StyledViewSwitchWrapper>
                      )}

                      {sourceTypeTemplate}

                      {view === VIEW_FEED && (
                        <Fragment>
                          <Flex
                            position="absolute"
                            bottom="0.75em"
                            right="0.75em"
                            width="3.0625em"
                            zIndex="6"
                            maxHeight="calc(100vh - 9.375em)"
                            flexDirection="column"
                            justifyContent="flex-end"
                          >
                            <Flex
                              width="3.0625em"
                              height="3.0625em"
                              mb="2em"
                              alignItems="center"
                              justifyContent="center"
                              position="relative"
                            >
                              <ImageWithFallback
                                source={
                                  isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage
                                }
                                fallbackSource={fallbackLogoImage}
                                borderRadius={radius.pill}
                                width="3.0625em"
                                height="3.0625em"
                              />
                              <StyledPlusWrapper>
                                <Image src="/assets/tiktok_plus.svg" width="1.75em" height="1.75em" />
                              </StyledPlusWrapper>
                            </Flex>
                            <Flex flexDirection="column" mb="3.0625em" alignItems="center">
                              <Flex mb="1em" flexDirection="column" alignItems="center">
                                <StyledControlWrapper>
                                  <Image src="/assets/tiktok_heart_filled.svg" width="2.5em" height="2.5em" />
                                </StyledControlWrapper>
                                <Text color="white" fontSize="0.75em">
                                  {c_likes}
                                </Text>
                              </Flex>
                              <Flex mb="1em" flexDirection="column" alignItems="center">
                                <StyledControlWrapper>
                                  <Image src="/assets/tiktok_comment.svg" width="2.5em" height="2.5em" />
                                </StyledControlWrapper>
                                <Text color="white" fontSize="0.75em">
                                  {c_comments}
                                </Text>
                              </Flex>
                              <Flex mb="1em" flexDirection="column" alignItems="center">
                                <StyledControlWrapper>
                                  <Image src="/assets/tiktok_share.svg" width="2.5em" height="2.5em" />
                                </StyledControlWrapper>
                                <Text color="white" fontSize="0.75em">
                                  {c_shares}
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex
                              width="3.0625em"
                              height="3.0625em"
                              position="relative"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box width="100%" height="100%" position="absolute">
                                <Image src="/assets/tiktok_music_disk.svg" width="100%" height="100%" />
                              </Box>
                              <Box width="70%" height="70%" zIndex="99">
                                <ImageWithFallback
                                  source={
                                    isMultipleProfilesPreview
                                      ? fallbackLogoImage
                                      : profilePictureUrl || fallbackLogoImage
                                  }
                                  fallbackSource={fallbackLogoImage}
                                  borderRadius={radius.pill}
                                  width="100%"
                                  height="100%"
                                />
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex
                            width="17.125em"
                            ml="0.75em"
                            position="absolute"
                            bottom="0"
                            left="0"
                            zIndex="5"
                            flexDirection="column"
                          >
                            <Flex alignItems="center">
                              <Flex alignItems="center">
                                <Flex
                                  alignItems="center"
                                  onMouseEnter={(e) => {
                                    if (!isMultipleProfilesPreview) {
                                      tooltipRef.current.handleShowTooltip({
                                        contentComp: (
                                          <ProfileItemTooltip
                                            profile={{
                                              code: network,
                                              picture_url: profilePictureUrl,
                                              profile_url: profileUrl,
                                              name: profileName,
                                              username: profileUsername,
                                              display,
                                              profile_id,
                                              type: profile_type,
                                            }}
                                            message=""
                                          />
                                        ),
                                        wrapperComp: e.currentTarget,
                                      })
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    tooltipRef.current.handleHideTooltip()
                                  }}
                                >
                                  {!isMultipleProfilesPreview && (
                                    <StyledLink
                                      as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                                      href={(!isMultipleProfilesPreview && profileUrl) || ''}
                                      target="_blank"
                                      color="white"
                                      fontSize="1em"
                                      fontWeight="bold"
                                      mb="0.5em"
                                    >
                                      @
                                      {isMultipleProfilesPreview
                                        ? 'Multiple TikTok Profiles'
                                        : profileUsername || profileName}
                                    </StyledLink>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                            <Box>
                              <BodyWrapper>
                                <HtmlCollapse
                                  html={transformed_text_to_html_for_preview}
                                  textColor={COLOR_CONSTANTS.WHITE}
                                  title="Post"
                                />
                              </BodyWrapper>
                            </Box>
                            <StyledAudioWrapper>
                              <Flex width="100%" position="relative">
                                <StyledMusicNote />
                                <Box ml="2em">
                                  <Flex mr="0" ml="-1em" width="max-content">
                                    <Text
                                      pl="1.25em"
                                      fontSize="0.9375em"
                                      color="white"
                                      onMouseEnter={(e) => {
                                        tooltipRef.current.handleShowTooltip({
                                          contentComp: (
                                            <Flex px="m" py="s" maxWidth="250px" textAlign="center">
                                              <Text>
                                                TikTok determines whether to label your sound or mark it as an original
                                                sound.
                                              </Text>
                                            </Flex>
                                          ),
                                          wrapperComp: e.currentTarget,
                                        })
                                      }}
                                      onMouseLeave={() => {
                                        tooltipRef.current.handleHideTooltip()
                                      }}
                                    >
                                      original sound
                                    </Text>
                                  </Flex>
                                </Box>
                              </Flex>
                            </StyledAudioWrapper>
                          </Flex>
                        </Fragment>
                      )}
                    </SourceWrapper>
                  </Flex>
                ) : (
                  <Box px="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </Flex>

              {c_comments > 0 && (
                <StyledCommentsWrapper bg="white" flexDirection="column" py="1.5em" px="2em">
                  {comments.map(({ message }, index) => {
                    if (!message) {
                      return null
                    }

                    const { variableTransformedText } = variableTransformer({
                      str: message,
                      savedVariables,
                      post,
                      profileId,
                    })
                    return (
                      <StyledCommentWrapper mt="1em" pb="0.5em" key={index}>
                        {isEditable ? (
                          <StyledImg
                            source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                            fallbackSource={fallbackLogoImage}
                            minWidth="2.5em"
                            minHeight="2.5em"
                            width="2.5em"
                            height="2.5em"
                          />
                        ) : (
                          <SocialProfileImage
                            code={network}
                            picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                            minWidth="2.5em"
                            minHeight="2.5em"
                            width="2.5em"
                            height="2.5em"
                            imageBorderRadius={radius.pill}
                          />
                        )}
                        <Flex ml="0.5em" pl="0.25em" flexDirection="column" justifyContent="center" width="100%">
                          <Flex width="100%" alignItems="center">
                            <StyledLink
                              as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                              href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                              target="_blank"
                              color={TIKTOK_COLORS.MAIN}
                              fontWeight="bold"
                              fontSize="1.125em"
                            >
                              {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                            </StyledLink>
                            &nbsp;·&nbsp;
                            <Text fontSize="1em" fontWeight="bold" as="span" color={TIKTOK_COLORS.SECONDARY}>
                              Author
                            </Text>
                          </Flex>
                          <Flex width="100%">
                            <Text
                              width="100%"
                              mt="0.25em"
                              color={TIKTOK_COLORS.MAIN}
                              fontSize="0.875em"
                              dangerouslySetInnerHTML={{
                                __html: variableTransformedText,
                              }}
                            />
                            <Flex width="4.125em" alignItems="flex-end" flexDirection="column">
                              <Image src="/assets/tiktok_heart.svg" width="1.25em" height="1.25em" />
                              <Text mt="0.25em" mr="0.375em" fontSize="0.25em" color={TIKTOK_COLORS.TARNISHED_SILVER}>
                                0
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </StyledCommentWrapper>
                    )
                  })}
                </StyledCommentsWrapper>
              )}
            </StyledPreviewWrapper>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <CaptionWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column" width="100%">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </CaptionWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <CaptionWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'}
                              />
                            </CaptionWrapper>
                            <Box>
                              <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={TIKTOK} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={deviceLengthLimit || notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}

                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CommentsComponent
                            user={user}
                            handleUpdateCustomization={handleUpdateCustomization}
                            handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                            network={TIKTOK}
                            profileId={profileId}
                            commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                            comments={comments}
                            commentTextMaxLimit={commentTextMaxLimit}
                            selectedEntities={selectedEntities}
                            selectedProfiles={selectedProfiles}
                            textVariantOffsets={textVariantOffsets}
                            hasPreview={hasPreview}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments" />
                      </Flex>

                      {(customizedPostComponent === POST_VIDEO ||
                        (customizedPostComponent === POST_MIXED_MEDIA &&
                          postMixedMediasUpdated.length &&
                          (postMixedMediasUpdated[0].type === VIDEO ||
                            postMixedMediasUpdated[0].type === VIDEO_DYNAMIC))) && (
                        <Fragment>
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={is_promotional_content}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'is_promotional_content',
                                    value: !is_promotional_content,
                                  })
                                }}
                                text="Promotional content"
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="is_promotional_content" network={TIKTOK} />
                          </Flex>

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={is_paid_partnership}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'is_paid_partnership',
                                    value: !is_paid_partnership,
                                  })
                                }}
                                text="Paid partnership"
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="is_paid_partnership" network={TIKTOK} />
                          </Flex>
                        </Fragment>
                      )}

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={comments_enabled}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'comments_enabled',
                                value: !comments_enabled,
                              })
                            }}
                            text={`Comments ${comments_enabled ? 'enabled' : 'disabled'}`}
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments_enabled" network={TIKTOK} />
                      </Flex>

                      {(customizedPostComponent === POST_VIDEO ||
                        (customizedPostComponent === POST_MIXED_MEDIA &&
                          postMixedMediasUpdated.length &&
                          (postMixedMediasUpdated[0].type === VIDEO ||
                            postMixedMediasUpdated[0].type === VIDEO_DYNAMIC))) && (
                        <Fragment>
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={duets_enabled}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'duets_enabled',
                                    value: !duets_enabled,
                                  })
                                }}
                                text={`Duets ${duets_enabled ? 'enabled' : 'disabled'}`}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="duets_enabled" network={TIKTOK} />
                          </Flex>

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={stitches_enabled}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'stitches_enabled',
                                    value: !stitches_enabled,
                                  })
                                }}
                                text={`Stitches ${stitches_enabled ? 'enabled' : 'disabled'}`}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="stitches_enabled" network={TIKTOK} />
                          </Flex>
                        </Fragment>
                      )}

                      {(customizedPostComponent === POST_IMAGE ||
                        (customizedPostComponent === POST_MIXED_MEDIA &&
                          postMixedMediasUpdated.length &&
                          (postMixedMediasUpdated[0].type === IMAGE ||
                            postMixedMediasUpdated[0].type === IMAGE_DYNAMIC))) && (
                        <Fragment>
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={auto_add_music}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'auto_add_music',
                                    value: !auto_add_music,
                                  })
                                }}
                                text="Automatically add recommended music"
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="auto_add_music" network={TIKTOK} />
                          </Flex>

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <DropDown
                                placeholder="Select privacy status"
                                value={
                                  privacy_level ? PRIVACY_STATUSES.find(({ value }) => value === privacy_level) : null
                                }
                                onChange={(option) => {
                                  handleUpdateCustomization({
                                    type: 'privacy_level',
                                    value: option.value,
                                  })
                                }}
                                options={PRIVACY_STATUSES}
                                openMenuOnFocus
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="privacy_level" network={TIKTOK} />
                          </Flex>

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Input
                                placeholder="Post title"
                                label=""
                                defaultValue={title || ''}
                                name="title"
                                id="title"
                                onChange={(e) => {
                                  const { value } = e.target
                                  if (value.length <= MAX_IMAGES_TITLE_LENGTH) {
                                    handleUpdateCustomizationDebounce({ type: 'title', value })
                                  }
                                }}
                                width="100%"
                                maxLength={MAX_IMAGES_TITLE_LENGTH}
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="title" network={TIKTOK} />
                          </Flex>
                        </Fragment>
                      )}
                    </Flex>
                  </Flex>

                  {!isAdvocacy && (
                    <BoostsDropdownComponent
                      boosts={boosts && boosts.length !== 0 ? boosts : null}
                      handleUpdateCustomization={handleUpdateCustomization}
                      boostId={boost_gid}
                      user={user}
                      profile={profile}
                    />
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

TikTokPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  isSmallPreview: false,
  isAdvocacy: false,
}

TikTokPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  isSmallPreview: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

export default TikTokPost
