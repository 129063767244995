import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius } from 'theme'
import { Flex } from './Layout'
import { Text } from './Typography'

const MessageWrapper = styled(Flex)`
  background-color: ${({ color, theme }) => transparentize(0.9, theme.colors[color] || theme.colors.error)};
  border-radius: ${radius.l};
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`

const PromptComponent = ({ text, isShown, type, specialColor }) => {
  let color = 'error'

  if (type === 'info') {
    color = 'primary'
  } else if (type === 'warning') {
    color = 'warning'
  } else if (specialColor) {
    color = specialColor
  }

  return (
    <Fragment>
      {isShown && text && (
        <MessageWrapper alignItems="center" px="m" py="s" mb="m" color={color}>
          <Text color={color} dangerouslySetInnerHTML={{ __html: text }} />
        </MessageWrapper>
      )}
    </Fragment>
  )
}

PromptComponent.defaultProps = {
  text: '',
  type: 'error',
  specialColor: '',
}

PromptComponent.propTypes = {
  text: PropTypes.string,
  isShown: PropTypes.bool.isRequired,
  type: PropTypes.string,
  specialColor: PropTypes.string,
}

export default PromptComponent
