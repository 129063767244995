import React, { forwardRef, Fragment, useImperativeHandle } from 'react'
import { usePostCreationContext, usePostCreationDispatch } from '../contexts/PostCreationContext'

const SpecialMediaProcessingDataComponent = forwardRef((props, specialMediaProcessingDataComponentRef) => {
  const { mediaProcessingIntervalsDispatch } = usePostCreationDispatch()
  const { mediaProcessingIntervals } = usePostCreationContext()

  useImperativeHandle(specialMediaProcessingDataComponentRef, () => ({
    getProcessedMediaIntervals() {
      return mediaProcessingIntervals
    },
    removeAllMediasFromProcessingInterval() {
      mediaProcessingIntervalsDispatch({
        type: 'remove_all',
      })
    },
  }))

  return <Fragment />
})

SpecialMediaProcessingDataComponent.defaultProps = {}

SpecialMediaProcessingDataComponent.propTypes = {}

export default SpecialMediaProcessingDataComponent
