import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Box, Flex } from 'components/atoms/Layout'
import { radius } from 'theme'

const StyledBox = styled(Box)`
  padding: 0;
  background: ${({ theme }) => theme.colors.background_card};
  box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
`

const TooltipWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 20;
  top: ${({ top }) => top || '-55px'};
  left: ${({ left }) => left || 0};
  height: max-content;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`

const CalendarItemTooltip = forwardRef((props, tooltipRef) => {
  const tooltipWrapperRef = useRef(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [tooltipData, setTooltipData] = useState(null)

  const { contentComp: ContentComp, wrapperComp: WrapperComp, defaultXPosition, defaultYPosition } = tooltipData || {}

  const calculatePosition = () => {
    if (WrapperComp && WrapperComp.parentElement && WrapperComp.parentElement.parentElement) {
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      const elementWidth = tooltipWrapperRef.current.clientWidth

      const boundingBox = WrapperComp.parentElement.parentElement.getBoundingClientRect()

      if (!defaultXPosition) {
        let left = 0
        if (boundingBox.left >= elementWidth) {
          left = boundingBox.left - elementWidth
        } else if (boundingBox.right + elementWidth <= windowWidth) {
          left = boundingBox.right
        } else {
          left = windowWidth / 2
        }
        position.left = left

        if (boundingBox.width === 0) {
          position.left = windowWidth / 2
        }
      } else if (defaultXPosition === 'left') {
        let left = 0
        if (boundingBox.left >= elementWidth) {
          left = boundingBox.left / 2
        } else if (boundingBox.right + elementWidth <= windowWidth) {
          left = boundingBox.right
        } else {
          left = windowWidth / 2
        }
        position.left = left

        if (boundingBox.width === 0) {
          position.left = windowWidth / 2
        }
      } else if (defaultXPosition === 'right') {
        let left = 0
        if (windowWidth - (boundingBox.left + boundingBox.width) >= elementWidth) {
          // eslint-disable-next-line prefer-destructuring
          left = boundingBox.left + boundingBox.width
        } else if (boundingBox.left >= elementWidth) {
          // eslint-disable-next-line prefer-destructuring
          left = boundingBox.left
        } else {
          left = windowWidth / 2
        }
        position.left = left

        if (boundingBox.width === 0) {
          position.left = windowWidth / 2
        }
      }

      const elementHeight = tooltipWrapperRef.current.clientHeight

      if (defaultYPosition === 'top') {
        let top = 0

        if (boundingBox.top + elementHeight <= windowHeight) {
          top = boundingBox.top - elementHeight - 8
        } else {
          ;({ top } = boundingBox)
        }

        position.top = top
      } else {
        let top = 0
        if (boundingBox.top >= elementHeight) {
          top = boundingBox.bottom - elementHeight
        } else if (boundingBox.bottom + elementHeight <= windowHeight) {
          ;({ top } = boundingBox)
        } else {
          top = windowHeight / 2
        }
        position.top = top

        if (boundingBox.height === 0) {
          position.top = windowHeight / 2
        }
      }
    }

    setPosition({ ...position })
  }

  useEffect(() => {
    if (tooltipData) {
      calculatePosition()
    }
  }, [tooltipData])

  useImperativeHandle(tooltipRef, () => ({
    handleShowTooltip(data) {
      setTooltipData({ ...data })
    },
    handleHideTooltip() {
      if (tooltipData) {
        setTooltipData(null)
      }
    },
  }))

  return (
    <Flex>
      <TooltipWrapper {...position} isOpen={!!tooltipData}>
        <StyledBox ref={tooltipWrapperRef}>{ContentComp}</StyledBox>
      </TooltipWrapper>
    </Flex>
  )
})

CalendarItemTooltip.defaultProps = {}

CalendarItemTooltip.propTypes = {}

export default CalendarItemTooltip
