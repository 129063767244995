import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import Alert from 'react-s-alert'
import Modal from '@material-ui/core/Modal'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDropzone } from 'react-dropzone'
import ClipLoader from 'react-spinners/ClipLoader'
import { fontSizes, radius, space } from 'theme'
import {
  COMMENT_TYPE_PUBLICATION,
  DEFAULT_TIME_ZONE,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
  DOCUMENT,
} from 'consts'
import { initializeStringArrayWithRange } from 'helpers'
import withConfirm from 'utils/withConfirm'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Datepicker from 'components/molecules/Datepicker'
import Select from 'components/atoms/Select'
import Image from 'components/atoms/Image'
import Divider from 'components/atoms/Divider'
import Icon from 'components/atoms/Icon'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Radio from 'components/atoms/Radio'
import Tooltip from 'components/molecules/Tooltip'
import { handleMediaFileChange } from 'routes/Media/helper'
import PostCreationComponent from './PostCreationComponent'
import DeletePostModal from './DeletePostModal'
import {
  APPROVED,
  CUSTOM_TYPE,
  DATE_FORMAT,
  LOCAL_STORAGE_EDIT_POST_MODAL,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_DOCUMENT,
} from '../consts'
import {
  getPublicationDataFromLocalStorage,
  getSelectedProfilesFromStorage,
  mentionsTransformer,
  setPublicationDataToLocalStorageDebounce,
} from '../helpers'
import HistoryPostModal from './HistoryPostModal'
import BestPublishingTimeSettings from './BestPublishingTimeSettings'
import ProfileItemTooltip from './ProfileItemTooltip'
import SocialProfileImage from './SocialProfileImage'
import CalendarItemTooltip from './CalendarItemTooltip'
import SliderCommentsPreview from './SliderCommentsPreview'
import SpecialMediaProcessingDataComponent from './SpecialMediaProcessingDataComponent'
import { PostCreationProvider } from '../contexts/PostCreationContext'

const StyledText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledSelect = styled(Select)`
  padding: 0 ${space.xs};
  font-size: ${fontSizes.xs};
  background-position: calc(100% - ${space.s}) center;
`

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: hidden;
  height: 100%;
  outline: none;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledHistoryText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

const StyledFilesWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(39, 40, 49, 0.3);
  z-index: 2147483002;
  transition: border 0.24s ease-in-out;
  border: 2px dashed ${({ theme }) => theme.colors.white};
  visibility: hidden;
  ${({ show }) => show && `visibility: visible;`}
`

const StyledCommentIconWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HOURS = initializeStringArrayWithRange({ start: 1, end: 12 })
const MINUTES = initializeStringArrayWithRange({ end: 59 })

const { NOW, SPECIFIC_TIME } = {
  NOW: 'now',
  SPECIFIC_TIME: 'specific_time',
}

const CHOOSE_TIME = [
  { name: 'Publish now', id: NOW },
  { name: 'Choose specific time', id: SPECIFIC_TIME },
]

const DEFAULT_SLIDER_OPTIONS = {
  isOpen: false,
  data: null,
}

// THIS POST MODAL IS NOT USED. CHANGED TO PUBLISH MODAL
// If this modal would be used again, need to fix mediaProcessingIntervals cleaning up

const EditPostModal = ({
  user,
  isOpen,
  handleDismiss,
  handleUpdatePost,
  handleDeletePost,
  handleSetPostToDraft,
  isPostSubmitting,
  confirm,
  dataForCustomizations,
  handleClickSendToCompose,
  handleClickOpenPublishModal,
  handleClickEvent,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const postCreationComponentRef = useRef(null)
  const specialMediaProcessingDataComponentRef = useRef(null)

  const [postForEdit, setPostForEdit] = useState({})
  const [publishTime, setPublishTime] = useState({})
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false)
  const [isHistoryPostModalOpen, setIsHistoryPostModalOpen] = useState(false)
  const [sliderOptions, setSliderOptions] = useState({ ...DEFAULT_SLIDER_OPTIONS })
  const [dataToLocalStorageOptions, setDataToLocalStorageOptions] = useState({
    canSave: false,
    lastTimeSaved: 0,
    lastTimeSavedMessage: '',
  })
  const [postCreationComponentKey, setPostCreationComponentKey] = useState(new Date().getTime())
  const [uploadingMediasAmount, setUploadingMediasAmount] = useState(0)
  const [whenToPublish, setWhenToPublish] = useState(SPECIFIC_TIME)

  const { timezone = DEFAULT_TIME_ZONE, publication_group_data, profile_gid, status } = postForEdit

  const { selectedProfilesForSelectedPost = [], selectedEntitiesForSelectedPost = [], group_posts = [] } =
    publication_group_data || {}

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && uploadingMediasAmount === 0) {
      handleMediaFileChange({
        acceptedFiles,
        setUploadingMedias: (medias) => {
          postCreationComponentRef.current.setUploadingMedias(medias)
        },
        setUploadingMediasAmount,
      })
    }
  }, [])

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  const collectPost = () => {
    const { publish_at } = props.postForEdit
    if (publish_at) {
      const now = moment(publish_at)
      const date = now.format(DATE_FORMAT)
      const hour = now.format('hh')
      const minute = now.format('mm')
      const interval = now.format('A')
      setPublishTime({
        ...{
          date,
          hour,
          minute,
          interval,
        },
      })
    }
    setPostForEdit({ ...props.postForEdit })
  }

  useEffect(() => {
    if (isOpen) {
      getPublicationDataFromLocalStorage({
        key: LOCAL_STORAGE_EDIT_POST_MODAL,
        message: `You have an auto saved post. Do you want to load it?`,
        confirm,
        setData: ({ post, publishTime }) => {
          if (post.link) {
            post.link.needsUpload = true
          }
          setPostForEdit({ ...post })
          setPublishTime({ ...publishTime })
          setPostCreationComponentKey(new Date().getTime())
        },
        setDataToLocalStorageOptions,
      })
      collectPost()
    }
  }, [isOpen])

  useEffect(() => {
    collectPost()
    setPostCreationComponentKey(new Date().getTime())
  }, [props.postForEdit])

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    onChangeUpdatePost()
  }, [publishTime])

  const onChangeUpdatePost = () => {
    if (dataToLocalStorageOptions.canSave && postCreationComponentRef && postCreationComponentRef.current) {
      const { post } = postCreationComponentRef.current.handleReturnPost()
      const hasChangedByUser = postCreationComponentRef.current.handleHasPostChangedByUser()

      if (hasChangedByUser) {
        setPublicationDataToLocalStorageDebounce({
          key: LOCAL_STORAGE_EDIT_POST_MODAL,
          data: { post, publishTime },
          dataToLocalStorageOptions,
          setDataToLocalStorageOptions,
        })
      }
    }
  }

  const handleClickOpenHistoryModal = () => {
    setIsHistoryPostModalOpen(true)
  }

  const handleClickCloseHistoryModal = () => {
    setIsHistoryPostModalOpen(false)
  }

  const handleClickOpenComments = () => {
    const { entities, entity_gid, publication_group_id, timezone, profile_gid } = postForEdit

    let entity_gids = []

    const ProjectSelectedProfiles = getSelectedProfilesFromStorage({ user })

    if (ProjectSelectedProfiles) {
      ProjectSelectedProfiles.forEach(({ id, entityId }) => {
        if (id === profile_gid) {
          entity_gids.push(entityId)
        }
      })
    }

    if (entity_gids.length === 0) {
      entity_gids = entities.map(({ entityId }) => entityId)

      if (entity_gid) {
        const foundEntity = entity_gids.find((id) => id === entity_gid)
        if (!foundEntity) {
          entity_gids.push(entity_gid)
        }
      }
    }

    setSliderOptions({
      isOpen: true,
      data: {
        comment_type_id: publication_group_id,
        type: COMMENT_TYPE_PUBLICATION,
        entity_gids,
        timezone,
        context: { publication_id: postForEdit.id },
      },
    })
  }

  const handleClickCloseComments = ({ commentsCounter }) => {
    postForEdit.comments = commentsCounter
    setPostForEdit({ ...postForEdit })
    setSliderOptions({ ...DEFAULT_SLIDER_OPTIONS })
  }

  const handleChangeSelectedDate = ({ type, value }) => {
    if (type === 'date') {
      publishTime[type] = moment(value).format(DATE_FORMAT)
    } else if (type === 'hour' || type === 'minute' || type === 'interval') {
      publishTime[type] = value
    }
    setPublishTime({ ...publishTime })
  }

  const handleClickUpdatePost = async ({ draft = false }) => {
    const { post } = await postCreationComponentRef.current.handleCheckPostForError()

    let error = ''
    let showErrorAlert = true
    let networksString = ''

    const { networksErrors = {}, mediaUploadingError: { isMediaUploading = false, mediaType } = {} } = post.errors || {}

    for (const i in networksErrors) {
      if (networksErrors[i] && networksErrors[i].hasErrors) {
        networksString = `${networksString ? `${networksString},` : ''} ${networksErrors[i].code
          .charAt(0)
          .toUpperCase()}${networksErrors[i].code.substr(1)}`
      }
    }

    if (networksString) {
      error = `Please check your content on the following social networks: ${networksString}`
    }

    const doesntHaveUniversalMentions = postCreationComponentRef.current.handleCheckPostTextForNotCreatedMentions({
      callbackFunction: () => handleClickUpdatePost({ draft }),
    })
    if (doesntHaveUniversalMentions) {
      error = `Please create a 'universal mention' by specifying the handle on each social network.`
      showErrorAlert = false
    } else {
      const doesntHaveVariables = postCreationComponentRef.current.handleCheckPostTextForNotCreatedVariables({
        callbackFunction: () => handleClickUpdatePost({ draft }),
      })
      if (doesntHaveVariables) {
        error = `Please create a 'variable' by specifying on each social profile.`
        showErrorAlert = false
      }
    }

    if (!error && isMediaUploading) {
      error = `Your ${mediaType} is still uploading. Please wait just a bit longer before continuing.`
    }

    if (error) {
      if (showErrorAlert) {
        Alert.error(error)
      }
    } else {
      const { id, profileNetwork, result, profile_gid, publication_group_id, entity_gid } = postForEdit
      if (draft) {
        handleSetPostToDraft({ id })
      } else {
        const { date, hour, minute, interval } = publishTime
        const data = {
          id,
          publication_group_id,
          targets: [
            {
              profile_gid,
              network: profileNetwork,
              timezone,
              publish_at:
                whenToPublish === SPECIFIC_TIME
                  ? moment(`${date} ${hour}:${minute} ${interval}`, `${DATE_FORMAT} hh:mm A`).format(
                      `${DATE_FORMAT} HH:mm`
                    )
                  : NOW,
              entity_gid,
            },
          ],
          message: post.postText,
          result,
          labels: post.labels,
          customizations: [],
        }

        const postCustomization =
          post.customizations && post.customizations[profileNetwork]
            ? post.customizations[profileNetwork][profile_gid] || {}
            : {}

        if (post.customizations) {
          const { video_processing, image_processing } = post.customizations
          if (typeof video_processing !== 'undefined') {
            postCustomization.video_processing = video_processing
          }
          if (typeof image_processing !== 'undefined') {
            postCustomization.image_processing = image_processing
          }
        }

        postCustomization.kind = profileNetwork
        postCustomization.profile_gid = profile_gid

        delete postCustomization.view

        const { message = '' } = postCustomization

        const { mentionsFromPost } = mentionsTransformer({
          body: message || post.postText,
          color: 'rgb(0,0,0)',
          fontWeight: 400,
          agencyMentions: post.agencyMentions || [],
          network: profileNetwork,
        })

        const mentionsForNetwork = []
        mentionsFromPost.forEach((item) => {
          if (item[profileNetwork]) {
            const { id } = item[profileNetwork]
            mentionsForNetwork.push({ id, mention: item.name })
          }
        })
        if (mentionsForNetwork.length !== 0) {
          postCustomization.mentions = mentionsForNetwork
        }

        data.customizations.push(postCustomization)

        if (post.postComponent === POST_IMAGE) {
          data.attachments = post.postImages.map(({ id, dynamic }) => {
            const data = { media_gid: id, type: IMAGE.toUpperCase() }
            if (dynamic) {
              data.type = IMAGE_DYNAMIC.toUpperCase()
            }
            return data
          })
        } else if (post.postComponent === POST_VIDEO) {
          data.attachments = post.postVideos.map(({ id, dynamic }) => {
            const data = { media_gid: id, type: VIDEO.toUpperCase() }
            if (dynamic) {
              data.type = VIDEO_DYNAMIC.toUpperCase()
            }
            return data
          })
        } else if (post.postComponent === POST_DOCUMENT) {
          data.attachments = post.postDocuments.map(({ id }) => {
            return { media_gid: id, type: DOCUMENT.toUpperCase() }
          })
        }

        if (post.link && post.link.url) {
          if (!post.link.shorten) {
            post.link.shorten = { type: 'DEFAULT' }
          }
          data.link = {
            url: post.link.url,
            title: post.link.title || '',
            description: post.link.description || '',
            shorten: post.link.shorten,
            active: post.postComponent === POST_LINK,
          }
          if (post.link.utm_tracking) {
            data.link.tracking = {
              params: post.link.tracking.params.map(({ name, type, value }) => {
                const data = { name, type: type.value }
                if (data.type === CUSTOM_TYPE) {
                  data.value = value
                }
                return data
              }),
            }

            if (post.link.save_utm_tracking) {
              data.link.save_utm_tracking = true
              data.link.utmTrackingId = post.link.utmTrackingId
            }
          } else if (typeof post.link.utm_tracking !== 'undefined') {
            data.link.tracking = {
              disabled: true,
            }
          }

          if (post.link.id) {
            data.link.media_gid = post.link.id
          }
        } else {
          data.link = null
        }

        handleUpdatePost({ data })
      }
    }
  }

  const handleClickOpenDeletePostModal = () => {
    setIsDeletePostModalOpen(true)
  }

  const handleClickCloseDeletePostModal = () => {
    setIsDeletePostModalOpen(false)
  }

  const handleClickDeletePost = async (body) => {
    body.items = [{ id: postForEdit.id }]
    await handleDeletePost({ data: body, type: 'edit' })
    handleClickCloseDeletePostModal()
  }

  const handleClearAllIntervalsAndDismiss = (data) => {
    specialMediaProcessingDataComponentRef.current.removeAllMediasFromProcessingInterval()

    handleDismiss(data)
  }

  const handleClickCloseModal = () => {
    if (!isPostSubmitting) {
      let hasChangedByUser = false
      if (postCreationComponentRef && postCreationComponentRef.current) {
        hasChangedByUser = postCreationComponentRef.current.handleHasPostChangedByUser()
      }

      if (hasChangedByUser) {
        confirm({
          fn: () => () => {
            handleClearAllIntervalsAndDismiss({})
          },
          message: `Your post won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        handleClearAllIntervalsAndDismiss({})
      }
    }
  }

  const handleClickDuplicatePost = () => {
    const post = { ...postForEdit }
    const { selectedEntitiesForSelectedPost, selectedProfilesForSelectedPost } = post
    handleClickSendToCompose({
      post,
      selectedEntitiesForSelectedPost,
      selectedProfilesForSelectedPost,
    })
    handleClearAllIntervalsAndDismiss({ closePublisherWrapper: false })
  }

  const handleClickScheduledPost = ({ id, entityId }) => {
    const foundGroupPost = group_posts.find(
      ({ profile_gid, entity_gid }) => profile_gid === id && entity_gid === entityId
    )
    if (foundGroupPost) {
      let hasChangedByUser = false
      if (postCreationComponentRef && postCreationComponentRef.current) {
        hasChangedByUser = postCreationComponentRef.current.handleHasPostChangedByUser()
      }

      if (hasChangedByUser) {
        confirm({
          fn: () => () =>
            handleClickEvent({
              eventInfo: {
                event: {
                  id: foundGroupPost.id,
                  extendedProps: {
                    profile_gid: foundGroupPost.profile_gid,
                    user_gid: foundGroupPost.user_gid,
                  },
                },
              },
            }),
          message: `Your post won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        handleClickEvent({
          eventInfo: {
            event: {
              id: foundGroupPost.id,
              extendedProps: {
                profile_gid: foundGroupPost.profile_gid,
                user_gid: foundGroupPost.user_gid,
              },
            },
          },
        })
      }
    }
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent {...getRootProps({ isDragActive })} className="modal-content-shadow">
          <StyledFilesWrapper
            show={isDragActive || uploadingMediasAmount > 0}
            alignItems="center"
            justifyContent="center"
          >
            {uploadingMediasAmount === 0 ? (
              <Text fontSize="l" fontWeight="bold" color="white">
                Drag 'n' drop some files here
              </Text>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                px="m"
                className="cliploader-wrapper"
              >
                <Text mb="s" fontSize="l" fontWeight="bold" color="white">
                  {`${uploadingMediasAmount} ${uploadingMediasAmount === 1 ? 'file' : 'files'} left. `}
                  Please wait a moment...
                </Text>
                <ClipLoader size="50" />
              </Flex>
            )}
          </StyledFilesWrapper>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">Edit scheduled post</H4>
            <Flex alignItems="center">
              {dataToLocalStorageOptions.canSave && dataToLocalStorageOptions.lastTimeSaved > 0 && (
                <Flex alignItems="center" justifyContent="center" height="100%">
                  <Text fontSize="xs" color="secondaryText">
                    {dataToLocalStorageOptions.lastTimeSavedMessage}
                  </Text>
                </Flex>
              )}
              {postForEdit.createdBy && (
                <Text color="secondaryText" fontSize="s" mx="m">
                  {postForEdit.createdBy}
                </Text>
              )}
              <Flex alignItems="center" onClick={handleClickOpenHistoryModal}>
                <StyledHistoryText>History</StyledHistoryText>
              </Flex>
              <Tooltip
                wrapperComp={
                  <StyledCommentIconWrapper ml="m" position="relative" onClick={handleClickOpenComments}>
                    <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="24px" height="24px" />
                    <StyledCommentCounterWrapper>
                      <Text fontSize="xxs" color="ternaryText">
                        {postForEdit.comments || 0}
                      </Text>
                    </StyledCommentCounterWrapper>
                  </StyledCommentIconWrapper>
                }
                message="Comments - Platform"
                isTriangleVisible={false}
                width="170px"
                defaultTopOffset={-70}
                left="unset"
                right={0}
              />
            </Flex>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper flex="1" tabIndex={0}>
            <Box minWidth="256px" width="256px" px="m">
              <Flex flexDirection="column">
                <Text fontSize="m" mt="m">
                  Scheduled date and time
                </Text>
                <Flex flexDirection="column" mt="s">
                  {CHOOSE_TIME.map(({ name, id }) => (
                    <Flex flexDirection="column" key={id}>
                      <Flex alignItems="center" mr="m" mb="s">
                        <Radio
                          id={id}
                          name="publishTimeBulkEditPostModal"
                          onChange={({ target: { id } }) => {
                            setWhenToPublish(id)
                          }}
                          value={whenToPublish}
                          checked={whenToPublish === id}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label htmlFor={id}>
                          <Text ml="m" pl="xs" mt="-2px" fontSize="s">
                            {name}
                          </Text>
                        </label>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
                {whenToPublish === SPECIFIC_TIME && (
                  <Flex mt="m" flexDirection="column">
                    <Box>
                      <Datepicker
                        minDate={new Date()}
                        label="Date"
                        value={publishTime.date}
                        onChange={(value) => {
                          if (value) {
                            handleChangeSelectedDate({ type: 'date', value })
                          }
                        }}
                        isClearDateEnabled={false}
                      />
                    </Box>
                    <Flex mt="m" flexDirection="column" width="100%">
                      <Flex>
                        <Text>Time</Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <Box width="50px">
                            <StyledSelect
                              name="hours"
                              onChange={({ target: { value } }) => {
                                handleChangeSelectedDate({ type: 'hour', value })
                              }}
                              value={publishTime.hour}
                            >
                              {HOURS.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </StyledSelect>
                          </Box>
                          <Text fontSize="l" mx="s">
                            :
                          </Text>
                          <Box width="50px">
                            <StyledSelect
                              name="minutes"
                              onChange={({ target: { value } }) => {
                                handleChangeSelectedDate({ type: 'minute', value })
                              }}
                              value={publishTime.minute}
                            >
                              {MINUTES.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </StyledSelect>
                          </Box>
                        </Flex>
                        <Box ml="s" width="50px">
                          <StyledSelect
                            name="interval"
                            onChange={({ target: { value } }) => {
                              handleChangeSelectedDate({ type: 'interval', value })
                            }}
                            value={publishTime.interval}
                          >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </StyledSelect>
                        </Box>
                        <Flex alignItems="center">
                          <Text ml="s">
                            {moment()
                              .tz(timezone)
                              .format('z')}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <BestPublishingTimeSettings
                      selectedProfiles={postForEdit.selectedProfilesForSelectedPost}
                      timezone={timezone}
                      combined={false}
                      handleChangeSelectedTime={({ hour, minute, interval }) => {
                        publishTime.hour = hour
                        publishTime.minute = minute
                        publishTime.interval = interval
                        setPublishTime({ ...publishTime })
                      }}
                    />
                  </Flex>
                )}
              </Flex>
              {status === APPROVED && selectedProfilesForSelectedPost.length > 1 && (
                <Flex flexDirection="column" mt="m" height="330px">
                  <Divider my="s" />
                  <Flex alignItems="center" justifyContent="space-between" mt="m" mb="m">
                    <Text fontSize="m">Also scheduled to</Text>
                    <StyledFlex
                      isSmall
                      onClick={() => {
                        let hasChangedByUser = false
                        if (postCreationComponentRef && postCreationComponentRef.current) {
                          hasChangedByUser = postCreationComponentRef.current.handleHasPostChangedByUser()
                        }

                        if (hasChangedByUser) {
                          confirm({
                            fn: () => () =>
                              handleClickOpenPublishModal({
                                post: props.postForEdit,
                                selectedEntitiesForSelectedPost,
                                selectedProfilesForSelectedPost,
                              }),
                            message: `Your post won't be saved. Do you want to continue?`,
                            action: `Yes, I'm sure`,
                            cancel: 'No, cancel',
                          })
                        } else {
                          handleClickOpenPublishModal({
                            post: props.postForEdit,
                            selectedEntitiesForSelectedPost,
                            selectedProfilesForSelectedPost,
                          })
                        }
                      }}
                    >
                      <Icon.Gear />
                    </StyledFlex>
                  </Flex>
                  <Scrollbars
                    universal
                    renderTrackHorizontal={(props) => (
                      <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                    )}
                    renderThumbHorizontal={(props) => (
                      <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                    )}
                  >
                    {selectedProfilesForSelectedPost.map(
                      ({ id, entityId, code, display, name, username, picture_url, profile_url, profile_id, type }) => {
                        if (id !== profile_gid) {
                          return (
                            <StyledFlex
                              key={id}
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              mr="s"
                              mt="xs"
                              cssStyle="cursor: pointer;"
                              onClick={() => {
                                handleClickScheduledPost({ id, entityId })
                              }}
                            >
                              <Flex alignItems="center">
                                <Flex alignItems="center">
                                  <Flex
                                    alignItems="center"
                                    onMouseEnter={(e) => {
                                      tooltipRef.current.handleShowTooltip({
                                        contentComp: (
                                          <ProfileItemTooltip
                                            profile={{
                                              code,
                                              picture_url,
                                              profile_url,
                                              name,
                                              username,
                                              display,
                                              profile_id,
                                              type,
                                            }}
                                          />
                                        ),
                                        wrapperComp: e.currentTarget,
                                      })
                                    }}
                                    onMouseLeave={() => {
                                      tooltipRef.current.handleHideTooltip()
                                    }}
                                  >
                                    <SocialProfileImage
                                      picture_url={picture_url}
                                      code={code}
                                      width="20px"
                                      height="20px"
                                    />
                                    <StyledText ml="s" fontWeight="light" fontSize="xs" color="primary">
                                      {name || username}
                                    </StyledText>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </StyledFlex>
                          )
                        }
                        return null
                      }
                    )}
                  </Scrollbars>
                </Flex>
              )}
            </Box>
            <PostCreationProvider>
              <Flex width="calc(100% - 256px)" height="100%">
                <PostCreationComponent
                  key={postCreationComponentKey}
                  user={user}
                  selectedProfiles={postForEdit.selectedProfilesForSelectedPost}
                  selectedEntities={postForEdit.selectedEntitiesForSelectedPost}
                  postForEdit={postForEdit}
                  dataForCustomizations={dataForCustomizations}
                  ref={postCreationComponentRef}
                  onChangeUpdatePost={onChangeUpdatePost}
                  uploadingMediasAmount={uploadingMediasAmount}
                  setUploadingMediasAmount={setUploadingMediasAmount}
                  confirm={confirm}
                />
              </Flex>

              <SpecialMediaProcessingDataComponent ref={specialMediaProcessingDataComponentRef} />
            </PostCreationProvider>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Flex alignItems="center">
              <ButtonWithLoading buttonComp={Button.Gray} isSmall onClick={handleClickOpenDeletePostModal}>
                Delete post
              </ButtonWithLoading>
              <Button.Gray mx="m" isSmall onClick={handleClickDuplicatePost} isDisabled={isPostSubmitting}>
                Duplicate post
              </Button.Gray>
              <Button.Gray
                isSmall
                onClick={() => {
                  handleClickUpdatePost({ draft: true })
                }}
                isDisabled={isPostSubmitting}
              >
                Save as draft
              </Button.Gray>
            </Flex>

            <Flex alignItems="center">
              <ButtonWithLoading
                isSmall
                onClick={() => {
                  handleClickUpdatePost({})
                }}
                isLoading={isPostSubmitting}
              >
                Save changes
              </ButtonWithLoading>
            </Flex>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isPostSubmitting}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
        <DeletePostModal
          handleDismiss={handleClickCloseDeletePostModal}
          isOpen={isDeletePostModalOpen}
          isPostSubmitting={isPostSubmitting}
          handleDelete={handleClickDeletePost}
        />

        {isHistoryPostModalOpen && (
          <HistoryPostModal
            handleClickCloseModal={handleClickCloseHistoryModal}
            timezone={timezone}
            post={postForEdit}
            isOpen={isHistoryPostModalOpen}
          />
        )}

        {sliderOptions.isOpen && (
          <SliderCommentsPreview
            isOpen={sliderOptions.isOpen}
            data={sliderOptions.data}
            handleDismiss={handleClickCloseComments}
            user={user}
            showProfileGroupSelector
          />
        )}

        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

EditPostModal.defaultProps = {
  postForEdit: {},
  dataForCustomizations: {},
}

EditPostModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  postForEdit: PropTypes.object,
  selectedProfiles: PropTypes.array.isRequired,
  handleUpdatePost: PropTypes.func.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  handleSetPostToDraft: PropTypes.func.isRequired,
  isPostSubmitting: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  dataForCustomizations: PropTypes.object,
  handleClickSendToCompose: PropTypes.func.isRequired,
  handleClickOpenPublishModal: PropTypes.func.isRequired,
  handleClickEvent: PropTypes.func.isRequired,
}

export default withConfirm(EditPostModal)
