import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import styled from 'styled-components'

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const NoData = ({ headerText, message, image, showHeaderText, showImage, reload }) => (
  <Box textAlign="center" width="100%">
    {showImage && <Image src={image} maxWidth="250px" width="100%" />}
    {showHeaderText && (
      <H4 mt="l" color="secondaryText">
        {headerText}
      </H4>
    )}

    {message && (
      <Text
        dangerouslySetInnerHTML={{
          __html: message,
        }}
        mt="s"
        color="secondaryText"
      />
    )}

    {reload && (
      <StyledLink
        dangerouslySetInnerHTML={{
          __html: 'Reload',
        }}
        mt="s"
        color="primary"
        onClick={reload}
      />
    )}
  </Box>
)

NoData.defaultProps = {
  message: 'No data found. Please try again.',
  image: '/assets/emptyData.svg',
  showHeaderText: true,
  headerText: 'Whoops!',
  showImage: true,
  reload: undefined,
}

NoData.propTypes = {
  message: PropTypes.string,
  image: PropTypes.string,
  showHeaderText: PropTypes.bool,
  headerText: PropTypes.string,
  showImage: PropTypes.bool,
  reload: PropTypes.func,
}

export default NoData
