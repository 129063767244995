export const { GRID_TYPE_FEED, GRID_TYPE_REELS, GRID_TYPE_STORY } = {
  GRID_TYPE_FEED: 'feed',
  GRID_TYPE_REELS: 'reels',
  GRID_TYPE_STORY: 'story',
}

export const { TABLE_POSTS_ID_PREFIX, PHONE_POSTS_ID_PREFIX } = {
  TABLE_POSTS_ID_PREFIX: 'table-posts',
  PHONE_POSTS_ID_PREFIX: 'phone-posts',
}
