import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import Modal from '@material-ui/core/Modal'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, radius } from 'theme'
import { AUDIO, ERROR_MESSAGE, IMAGE, VIDEO } from 'consts'
import { formatToNumberWithCommas } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Divider from 'components/atoms/Divider'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Link from 'components/atoms/Link'
import Button from 'components/atoms/Button'
import AudioWithFallback from 'components/atoms/AudioWithFallback'
import {
  ROUTE_MEDIA,
  TABS_TYPE_IMAGE_UNSPLASH,
  IMPORT_FROM_FIND_IMAGES,
  IMPORT_FROM_FIND_VIDEOS,
  IMPORT_FROM_FIND_GIFS,
  IMPORT_FROM_INSTAGRAM_USER,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
} from '../../consts'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 800px;
    min-height: 400px;
    height: 100%;
    max-height: 90vh;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledUserImage = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
`

const StyledUserLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
`

const StyledChevronWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledIconChevronRight = styled(Icon.VistaSocialChevronRight)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.secondaryText};
  &:hover {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const StyledIconChevronLeft = styled(Icon.VistaSocialChevronRight)`
  transform: rotate(-180deg);
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.secondaryText};
  &:hover {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const StyledExternalLinkWrapper = styled(Link)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

const StyledMetaInformationDescription = styled(Text)`
  cursor: ${({ externalLink }) => (externalLink ? 'pointer' : 'initial')};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryText};
`

const MediaDetailModal = ({
  isOpen,
  handleDismiss,
  data,
  handleClickAddToMediaLibrary,
  activeTab,
  importFrom,
  articles,
  handleClickSendToCompose,
}) => {
  const [isGettingData, setIsGettingData] = useState(false)
  const [activeArticleIndex, setActiveArticleIndex] = useState(-1)
  const [media, setMedia] = useState({})

  const articlesLength = articles.length

  const { tempId, media_type, mediaData, isLoading, mediaSystemInfo } = media

  let updatedMediaType

  if (media_type) {
    if (media_type.toLowerCase() === IMAGE || media_type === 'CAROUSEL_ALBUM') {
      updatedMediaType = IMAGE
    } else if (mediaData) {
      updatedMediaType = mediaData.type
    } else {
      updatedMediaType = VIDEO
    }
  }

  const {
    url,
    thumbnail_url,
    description,
    views,
    downloads,
    metaInformation = [],
    user: { userName, userImage, userLink } = {},
    externalLink,
  } = mediaSystemInfo || {}

  const collectData = async () => {
    const { mediaId, mediaSystemInfo, media_type } = articles[activeArticleIndex]
    if (
      importFrom === IMPORT_FROM_FIND_IMAGES ||
      importFrom === IMPORT_FROM_FIND_VIDEOS ||
      importFrom === IMPORT_FROM_FIND_GIFS
    ) {
      if (!mediaSystemInfo) {
        try {
          const response = await request({
            path: `${ROUTE_MEDIA}/search/${mediaId}?system=${activeTab}&type=${media_type.toLowerCase()}`,
          })
          const { error, data: responseData } = response || {}

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          } else {
            articles[activeArticleIndex].mediaSystemInfo = responseData
            setMedia(articles[activeArticleIndex])
          }
        } catch (error) {
          errorHelper({ error, componentName: MediaDetailModal.displayName, functionName: 'collectData' })
        }
      } else {
        setMedia(articles[activeArticleIndex])
      }
    } else if (importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG || importFrom === IMPORT_FROM_INSTAGRAM_USER) {
      const {
        image,
        description,
        source: { source, username_link, username_picture_url },
        published,
        url,
      } = articles[activeArticleIndex]

      articles[activeArticleIndex].mediaSystemInfo = {
        url: image,
        description,
        user: {},
        metaInformation: [],
        externalLink: url,
      }

      if (importFrom === IMPORT_FROM_INSTAGRAM_USER) {
        articles[activeArticleIndex].mediaSystemInfo.user = {
          userName: source,
          userLink: username_link,
          userImage: username_picture_url,
        }
      }

      if (published) {
        articles[activeArticleIndex].mediaSystemInfo.metaInformation.push({
          title: 'PUBLISHED',
          description: published,
          externalLink: url,
        })
      }

      setMedia(articles[activeArticleIndex])
    }
    setIsGettingData(false)
  }

  useEffect(() => {
    if (isOpen) {
      setActiveArticleIndex(data.index)
    }
  }, [isOpen])

  useEffect(() => {
    if (activeArticleIndex > -1) {
      setIsGettingData(true)
      collectData()
    }
  }, [activeArticleIndex])

  const handleClickShowMedia = ({ index }) => {
    setActiveArticleIndex(index)
  }

  const handleClickCloseModal = () => {
    if (!isGettingData) {
      handleDismiss()
    }
  }

  let importFromText = 'Instagram'

  if (activeTab) {
    importFromText = `${activeTab.charAt(0).toUpperCase()}${activeTab.substr(1)}`
  }

  let mediaComponent = ''

  if (updatedMediaType === IMAGE) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <ImageWithFallback source={url} width="100%" height="100%" fallbackSource="/assets/emptyData.svg" />
        ) : (
          <Image src="/assets/emptyData.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (updatedMediaType === VIDEO) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <VideoWithFallback
            url={url}
            controls
            width="100%"
            style={{ position: 'relative' }}
            height="100%"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: thumbnail_url || '',
                },
              },
            }}
            light={false}
          />
        ) : (
          <Image src="/assets/videocamera.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (updatedMediaType === AUDIO) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <AudioWithFallback
            url={mediaData.url || url}
            controls
            width="100%"
            style={{ position: 'relative' }}
            height="100%"
            fallbackSourceImage={mediaData.thumbnail_url || thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster:
                    mediaData && mediaData.audio_cover_mime
                      ? mediaData.thumbnail_url || thumbnail_url || ''
                      : '/assets/vistasocial/musical_note.svg',
                },
              },
            }}
            light={false}
          />
        ) : (
          <Image src="/assets/vistasocial/musical_note.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Flex m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent className="modal-content-shadow">
          {activeArticleIndex !== 0 && (
            <StyledChevronWrapper
              left="-64px"
              onClick={() => {
                if (!isGettingData) {
                  handleClickShowMedia({ index: activeArticleIndex - 1 })
                }
              }}
            >
              <StyledIconChevronLeft width="50px" height="50px" viewBox="0 0 6 16" />
            </StyledChevronWrapper>
          )}
          {activeArticleIndex !== articlesLength - 1 && (
            <StyledChevronWrapper
              right="-64px"
              onClick={() => {
                if (!isGettingData) {
                  handleClickShowMedia({ index: activeArticleIndex + 1 })
                }
              }}
            >
              <StyledIconChevronRight width="50px" height="50px" viewBox="0 0 6 16" />
            </StyledChevronWrapper>
          )}

          <StyledDialogBodyWrapper flex="1">
            {isGettingData ? (
              <Flex alignItems="center" justifyContent="center" width="100%" className="cliploader-wrapper">
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Fragment>
                <Flex width="460px" bg="background_item_light">
                  {mediaComponent}
                </Flex>
                <Flex width="340px" flexDirection="column" py="m">
                  <Flex alignItems="center" justifyContent="space-between" px="m">
                    {userName || userLink ? (
                      <StyledUserLink
                        target="_blank"
                        href={userLink ? `${userLink}?utm_source=VistaSocial&utm_medium=referral` : ''}
                      >
                        <StyledUserImage
                          source={userImage || '/assets/avatar.svg'}
                          fallbackSource="/assets/avatar.svg"
                          width="36px"
                          height="36px"
                        />
                        <Flex flexDirection="column" ml="s">
                          <Text fontWeight="medium" fontSize="s" color="primaryText">
                            {userName}
                          </Text>
                          {importFromText && (
                            <Text fontSize="xs" color="primaryText">
                              {importFromText}
                            </Text>
                          )}
                        </Flex>
                      </StyledUserLink>
                    ) : (
                      <Flex />
                    )}
                    {externalLink && (
                      <StyledExternalLinkWrapper
                        href={`${externalLink}?utm_source=VistaSocial&utm_medium=referral`}
                        target="_blank"
                      >
                        <Icon.OpenInNew fill="primaryText" width="12px" height="12px" />
                      </StyledExternalLinkWrapper>
                    )}
                  </Flex>
                  <Scrollbars universal>
                    {activeTab === TABS_TYPE_IMAGE_UNSPLASH && (
                      <Grid gridTemplateColumns="repeat(2, 1fr)" mt="m" px="m">
                        <Flex flexDirection="column">
                          <Text fontSize="m">{formatToNumberWithCommas(views)}</Text>
                          <Text color="secondaryText" fontWeight="light" fontSize="xs">
                            VIEWS
                          </Text>
                        </Flex>
                        <Flex flexDirection="column">
                          <Text fontSize="m">{formatToNumberWithCommas(downloads)}</Text>
                          <Text color="secondaryText" fontWeight="light" fontSize="xs">
                            DOWNLOADS
                          </Text>
                        </Flex>
                      </Grid>
                    )}
                    <Flex px="m">
                      <Divider my="m" />
                    </Flex>
                    <Flex flex="1" px="m" flexDirection="column">
                      <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="m">
                        {metaInformation.map(({ title, description, externalLink }) => (
                          <Flex flexDirection="column" key={title}>
                            <Text color="secondaryText" fontWeight="light" fontSize="xxs">
                              {title}
                            </Text>
                            <StyledMetaInformationDescription
                              fontSize="xs"
                              as={externalLink ? 'a' : 'p'}
                              target="_blank"
                              href={externalLink ? `${externalLink}?utm_source=VistaSocial&utm_medium=referral` : ''}
                              externalLink={externalLink}
                            >
                              {description}
                            </StyledMetaInformationDescription>
                          </Flex>
                        ))}
                      </Grid>
                      <Text mt="m" fontSize="xs">
                        {description}
                      </Text>
                    </Flex>
                  </Scrollbars>

                  <Flex flexDirection="column" px="m">
                    <Divider />
                    <Flex justifyContent="space-between" mt="m" alignItems="center">
                      {handleClickSendToCompose ? (
                        <Button.Gray
                          isSmall
                          onClick={() => {
                            handleClickSendToCompose([media])
                          }}
                        >
                          <Flex alignItems="center">
                            <Text>Create a post</Text>
                          </Flex>
                        </Button.Gray>
                      ) : (
                        <Flex />
                      )}
                      {mediaData ? (
                        <Text color="secondaryText">Added to media library</Text>
                      ) : (
                        <ButtonWithLoading
                          width="165px"
                          isSmall
                          onClick={() => {
                            if (!isLoading) {
                              handleClickAddToMediaLibrary({
                                url,
                                type: media_type.toLowerCase(),
                                uploadingId: tempId,
                                index: activeArticleIndex,
                              })
                            }
                          }}
                          isLoading={isLoading}
                        >
                          {isLoading ? 'Adding' : 'Add to media library'}
                        </ButtonWithLoading>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Fragment>
            )}
          </StyledDialogBodyWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isGettingData}>
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Flex>
    </StyledDialogOverlay>
  )
}

MediaDetailModal.defaultProps = {
  activeTab: '',
  handleClickSendToCompose: null,
}

MediaDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickAddToMediaLibrary: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  importFrom: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
  handleClickSendToCompose: PropTypes.func,
}

MediaDetailModal.displayName = 'FindContentModalMediaDetailModal'

export default MediaDetailModal
