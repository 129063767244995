import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'

const StyledPlayerWrapper = styled(Box)`
  border-radius: ${radius.l};

  iframe {
    width: 100%;
    height: 80px;
  }
`

const { LINK } = {
  LINK: 'link',
}

const p = /^(https:\/\/open.spotify.com\/user\/([a-zA-Z0-9]+)\/playlist\/|spotify:user:([a-zA-Z0-9]+):playlist:)([a-zA-Z0-9]+)(.*)$/

const FormValidationSchema = Yup.object().shape({
  [LINK]: Yup.string()
    .matches(p, 'Please enter a correct Spotify URL')
    .required('Spotify URL is required'),
})

const matchYoutubeUrl = ({ link }) => {
  const matches = link.match(p)

  if (matches) {
    return link
  }
  return ''
}

const SpotifyComponent = ({ data, isEditable, handleChangeBlockComponent }) => {
  const { page_id, _id: block_id, data: { link = '' } = {} } = data

  let link_updated = ''

  if (!isEditable) {
    link_updated = link
    // link_updated = matchYoutubeUrl({ link })
    // https://open.spotify.com/playlist/37i9dQZF1DWZd79rJ6a7lp?si=4be190dd96884038
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [LINK]: link,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Box>
                  <Input
                    placeholder="Spotify Music URL"
                    type="text"
                    defaultValue={link}
                    onChange={(event) => {
                      handleChangeBlockComponent({ page_id, block_id, key: LINK, value: event.target.value })
                      setFieldValue(LINK, event.target.value)
                    }}
                    error={values[LINK] && errors[LINK]}
                    width="100%"
                  />
                </Box>
              </Box>
            )}
          </Formik>
        </Flex>
      ) : (
        <StyledPlayerWrapper>
          {link_updated && (
            <iframe type="playlist" title="Spotify music" src={link_updated} frameBorder="0" allow="encrypted-media" />
          )}
        </StyledPlayerWrapper>
      )}
    </Fragment>
  )
}

SpotifyComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

SpotifyComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
}

SpotifyComponent.displayName = 'ButtonLinkComponent'

export default SpotifyComponent
