import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import DropDown from 'shared/DropDown'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const CUSTOM_TYPE = 'CUSTOM'

const UrlTrackingParameters = ({
  trackingTypes,
  params,
  errors,
  handleClickRemoveParam,
  handleChangeParameters,
  handleClickAddParam,
}) => {
  const handleUpdate = debounce((data) => {
    handleChangeParameters(data)
  }, 300)

  return (
    <Flex mt="m" flexDirection="column">
      <Flex flexDirection="column">
        {params.map(({ name = '', type, value = '' }, index) => {
          const showCustomField = type && type.value === CUSTOM_TYPE
          return (
            <Flex justifyContent="space-between" key={index} mb="m">
              <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="m" width="100%">
                <Input
                  label=""
                  placeholder="Tracking tag"
                  defaultValue={name}
                  onChange={(e) => {
                    handleUpdate({ key: 'name', value: e.target.value.trim(), index })
                  }}
                  error={
                    errors.PARAM_ERRORS && errors.PARAM_ERRORS[index]
                      ? errors.PARAM_ERRORS[index].PARAM_ERRORS_NAME
                      : ''
                  }
                  width="100%"
                />
                <Box>
                  <DropDown
                    placeholder="Tracking type"
                    label=""
                    value={type}
                    onChange={(option) => {
                      handleUpdate({ key: 'type', value: option, index })
                    }}
                    options={trackingTypes}
                    openMenuOnFocus
                    isSearchable={false}
                  />
                </Box>
                <Fragment>
                  {showCustomField && (
                    <Input
                      label=""
                      placeholder="Tracking value"
                      defaultValue={value}
                      onChange={(e) => {
                        handleUpdate({ key: 'value', value: e.target.value.trim(), index })
                      }}
                      error={
                        errors.PARAM_ERRORS && errors.PARAM_ERRORS[index]
                          ? errors.PARAM_ERRORS[index].PARAM_ERRORS_VALUE
                          : ''
                      }
                      width="100%"
                    />
                  )}
                </Fragment>
              </Grid>
              <StyledFlex
                alignItems="center"
                onClick={() => {
                  handleClickRemoveParam({ index })
                }}
                ml="s"
              >
                <Icon.Trash width="16px" height="16px" />
              </StyledFlex>
            </Flex>
          )
        })}
      </Flex>
      <Flex>
        <StyledFlex alignItems="center" onClick={handleClickAddParam}>
          <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
          <Text color="primary" fontSize="xs">
            Add custom parameter
          </Text>
        </StyledFlex>
      </Flex>
    </Flex>
  )
}

UrlTrackingParameters.defaultProps = {
  params: [],
  errors: {},
}

UrlTrackingParameters.propTypes = {
  trackingTypes: PropTypes.array.isRequired,
  params: PropTypes.array,
  errors: PropTypes.object,
  handleClickRemoveParam: PropTypes.func.isRequired,
  handleChangeParameters: PropTypes.func.isRequired,
  handleClickAddParam: PropTypes.func.isRequired,
}

export default UrlTrackingParameters
