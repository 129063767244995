import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { radius } from 'theme'

const StyledFlex = styled(Flex)`
  justify-content: ${({ isOn }) => (isOn ? 'flex-end' : 'flex-start')};
  align-items: center;
  padding: 2px 4px;
  width: 40px;
  min-width: 40px;
  height: 20px;
  border-radius: ${radius.pill};
  background-color: ${({ isOn, theme }) =>
    isOn ? theme.colors.switch_component_background_on : theme.colors.switch_component_background_off};
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.7;
  `}
  cursor: pointer;
`

const StyledBox = styled(Box)`
  width: 14px;
  height: 14px;
  border-radius: ${radius.pill};
  background-color: ${({ theme }) => theme.colors.white};
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const doNothing = () => null

const Switch = ({ isOn, isDisabled, onClick = undefined, text, fontSize, fontWeight, color }) => (
  <Flex alignItems="center" onClick={!isDisabled ? onClick : doNothing()}>
    <StyledFlex isOn={isOn} isDisabled={isDisabled}>
      <StyledBox isOn={isOn} />
    </StyledFlex>
    {text && (
      <StyledText ml="s" fontWeight={fontWeight} fontSize={fontSize} color={color || 'primaryText'}>
        {text}
      </StyledText>
    )}
  </Flex>
)

Switch.propTypes = {
  isOn: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
}

Switch.defaultProps = {
  isOn: false,
  isDisabled: false,
  onClick: () => {},
  text: '',
  fontSize: 'xs',
  fontWeight: 'light',
  color: '',
}

export default Switch
