import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { IMAGE_DYNAMIC, IMAGE } from 'consts'
import { radius, space } from 'theme'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import CoolLightBox from 'components/molecules/CoolLightBox'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import CalendarItemTooltip from '../../CalendarItemTooltip'
import { TWITTER_COLORS, PREVIEW_MEDIA_CLASS_NAME } from '../../../consts'

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledTooltip = styled(Flex)`
  padding: ${space.l};
  flex-direction: column;
  min-width: 260px;
  min-height: 30px;
  max-width: 360px;
  max-height: 480px;
  box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
  border-radius: ${radius.xxl};
`

const StyledAltTextBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.77);
  height: 1.25em;
  border-radius: 0.25em;
`

const StyledFlex = styled(Flex)`
  gap: ${({ gap }) => gap || 0};
`

const ImageTemplate = ({ media, post, socialPreviewProfiles, network, index, setCurrentIndex, setIsLightBoxOpen }) => {
  const { url, id, [network]: networkErrors = {}, isNew, type } = media
  const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
  const hasError = hasAspectRatioError || hasSizeError

  return (
    <StyledBox
      key={id || index}
      width="100%"
      height="100%"
      onClick={() => {
        setCurrentIndex(index)
        setIsLightBoxOpen(true)
      }}
    >
      <Box height="100%" position="relative">
        <StyledBox height="100%" width="100%" position="absolute" hasError={hasError}>
          {isNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Box width="100%" height="100%">
              {type === IMAGE && (
                <Image
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  name={id}
                  src={url}
                  width="inherit"
                  height="inherit"
                  objectFit="cover"
                />
              )}

              {type === IMAGE_DYNAMIC && (
                <DynamicMedia media={media} post={post} profiles={socialPreviewProfiles} isSmall />
              )}
            </Box>
          )}
        </StyledBox>
      </Box>
    </StyledBox>
  )
}

ImageTemplate.defaultProps = {
  post: null,
  socialPreviewProfiles: [],
}

ImageTemplate.propTypes = {
  media: PropTypes.object.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  index: PropTypes.number.isRequired,
  network: PropTypes.string.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  setIsLightBoxOpen: PropTypes.func.isRequired,
}

const ImagesGridTwitter = ({ gridHeight, images, network, post, socialPreviewProfiles, showAltText }) => {
  const tooltipRef = useRef(null)
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const imagesCount = images.length

  const { 0: { description: firstColumnImageDescription = '' } = {} } = images

  let firstRowImages = []
  let secondRowImages = []

  let firstRowWidth = '100%'
  let firstRowHeight = '100%'
  let secondRowWidth = '100%'
  let secondRowHeight = '100%'

  if (imagesCount <= 2) {
    firstRowImages = images
  } else if (imagesCount === 3) {
    firstRowWidth = '50%'
    secondRowWidth = '50%'
    firstRowImages = images.slice(0, 1)
    secondRowImages = images.slice(1, 3)
  } else if (imagesCount === 4) {
    firstRowHeight = '50%'
    secondRowHeight = '50%'

    firstRowImages = images.slice(0, 2)
    secondRowImages = images.slice(2, 4)
  }

  const firstRowImagesCount = firstRowImages.length
  const secondRowImagesCount = secondRowImages.length

  return (
    <Flex width="100%" height={gridHeight}>
      <CoolLightBox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isLightBoxOpen}
        onClose={() => setIsLightBoxOpen(false)}
        images={images.map(({ url, id, description, [network]: networkErrors = {} }) => {
          const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
          const hasError = hasAspectRatioError || hasSizeError
          return {
            src: url,
            alt: description || '',
            width: 1280,
            height: 600,
            name: id,
            className: hasError ? 'image-error' : '',
          }
        })}
      />

      {imagesCount !== 0 && (
        <StyledFlex
          flexDirection={firstRowImagesCount === 2 && secondRowImagesCount === 2 ? 'column' : 'row'}
          width="100%"
          height="100%"
          gap="0.125em"
          position="relative"
        >
          <StyledFlex
            flexDirection={firstRowImagesCount > 1 ? 'row' : 'column'}
            flexGrow="1"
            width={firstRowWidth}
            height={firstRowHeight}
            gap="0.125em"
          >
            {firstRowImages.map((media, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Flex width="100%" height="100%" key={index}>
                  <ImageTemplate
                    index={index}
                    network={network}
                    media={media}
                    post={post}
                    socialPreviewProfiles={socialPreviewProfiles}
                    setCurrentIndex={setCurrentIndex}
                    setIsLightBoxOpen={setIsLightBoxOpen}
                  />
                </Flex>
              )
            })}
          </StyledFlex>
          {secondRowImagesCount > 0 && (
            <StyledFlex
              flexDirection={firstRowImagesCount > 1 ? 'row' : 'column'}
              flexGrow="1"
              width={secondRowWidth}
              height={secondRowHeight}
              gap="0.125em"
            >
              {secondRowImages.map((media, index) => {
                let height = '100%'

                if (secondRowImagesCount === 1 && secondRowImagesCount === 2) {
                  height = '50%'
                }

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Flex width="100%" height={height} key={index}>
                    <ImageTemplate
                      index={firstRowImagesCount + index}
                      network={network}
                      media={media}
                      post={post}
                      socialPreviewProfiles={socialPreviewProfiles}
                      setCurrentIndex={setCurrentIndex}
                      setIsLightBoxOpen={setIsLightBoxOpen}
                    />
                  </Flex>
                )
              })}
            </StyledFlex>
          )}

          {showAltText && imagesCount === 1 && firstColumnImageDescription && (
            <Box position="absolute" left="0.75em" bottom="0.75em">
              <StyledAltTextBox
                onMouseEnter={(e) => {
                  tooltipRef.current.handleShowTooltip({
                    contentComp: (
                      <StyledTooltip>
                        <Text fontSize="xl" color={TWITTER_COLORS.BLACK} fontWeight="extraBold">
                          Image description
                        </Text>
                        <Text pt="s" color={TWITTER_COLORS.TARNISHED_SILVER}>
                          {firstColumnImageDescription}
                        </Text>
                      </StyledTooltip>
                    ),
                    wrapperComp: e.currentTarget,
                  })
                }}
                onMouseLeave={() => {
                  tooltipRef.current.handleHideTooltip()
                }}
              >
                <Text color="white" fontSize="0.8125em" fontWeight="bold">
                  ALT
                </Text>
              </StyledAltTextBox>
            </Box>
          )}
        </StyledFlex>
      )}
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

ImagesGridTwitter.defaultProps = {
  images: [],
  gridHeight: '360px',
  post: null,
  socialPreviewProfiles: [],
  showAltText: true,
}

ImagesGridTwitter.propTypes = {
  images: PropTypes.array,
  gridHeight: PropTypes.string,
  network: PropTypes.string.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  showAltText: PropTypes.bool,
}

export default ImagesGridTwitter
