import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { IMAGE_DYNAMIC, IMAGE } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import CoolLightBox from 'components/molecules/CoolLightBox'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import { PREVIEW_MEDIA_CLASS_NAME } from '../../../consts'

const StyledFlex = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const ImagesCounterWrapper = styled(Flex)`
  position: absolute;
  cursor: pointer;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledImage = styled(Image)`
  object-fit: ${({ isOneImage }) => (isOneImage ? 'contain' : 'cover')};
  border: 1px solid transparent;
`

const ImagesGridHorizontal = ({ gridHeight, images, network, post, profiles }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const imagesCount = images.length
  const shownImages = images.slice(0, 5)

  const hiddenImages = images.slice(5)
  const hiddenImagesCount = hiddenImages.length
  const hasHiddenImagesError =
    hiddenImages.findIndex(({ hasResolutionError, postImageSizeLimit }) => hasResolutionError || postImageSizeLimit) >
    -1

  return (
    <Flex width="100%" height={imagesCount === 1 ? 'auto' : `${gridHeight}px`} maxHeight={`${gridHeight}px`}>
      <CoolLightBox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isLightBoxOpen}
        onClose={() => setIsLightBoxOpen(false)}
        images={images.map(({ url, description, id }) => ({
          src: url,
          alt: description || '',
          width: 1280,
          height: 600,
          name: id,
        }))}
      />

      {imagesCount !== 0 && (
        <Flex width="100%" flexWrap="wrap" height={imagesCount === 1 ? 'auto' : gridHeight} maxHeight={gridHeight}>
          {shownImages.map((media, index) => {
            const { url, id, [network]: networkErrors, isNew, type } = media

            const { hasResolutionError, hasSizeError } = networkErrors || {}

            let height_coefficient = 1
            let width_coefficient = 1
            if (index === 0) {
              height_coefficient = imagesCount < 3 ? 1 : 0.6
              width_coefficient = imagesCount === 2 || imagesCount > 4 ? 0.5 : 1
            } else if (index === 1) {
              height_coefficient = 0.4
              width_coefficient = 0.5
              if (imagesCount === 2) {
                height_coefficient = 1
              } else if (imagesCount <= 4) {
                width_coefficient = 1 / (imagesCount - 1)
              } else if (imagesCount > 4) {
                height_coefficient = 0.6
              }
            } else {
              height_coefficient = 0.4
              if (imagesCount < 5) {
                width_coefficient = 1 / (imagesCount - 1)
              } else {
                width_coefficient = 1 / 3
              }
            }

            const height = `${height_coefficient * 100}%`
            const width = `${width_coefficient * 100}%`

            const imageHasError = hasResolutionError || hasSizeError

            return (
              <StyledFlex
                key={id || index}
                onClick={() => {
                  setCurrentIndex(0)
                  setIsLightBoxOpen(true)
                }}
                height={height}
                width={width}
                position="relative"
                hasError={imageHasError}
                justifyContent="center"
                alignItems="center"
              >
                {isNew ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="50" />
                  </Flex>
                ) : (
                  <Fragment>
                    {type === IMAGE && (
                      <StyledImage
                        src={url}
                        width="100%"
                        height="100%"
                        isOneImage={imagesCount === 1}
                        name={id}
                        id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                      />
                    )}

                    {type === IMAGE_DYNAMIC && (
                      <Flex
                        width={`${(width_coefficient > height_coefficient ? height_coefficient : width_coefficient) *
                          gridHeight}px`}
                        height={height}
                      >
                        <DynamicMedia
                          media={media}
                          post={post}
                          profiles={profiles}
                          isSmall={index !== 0 || shownImages.length > 1}
                        />
                      </Flex>
                    )}
                  </Fragment>
                )}
                {hiddenImagesCount > 0 && index === 4 && (
                  <ImagesCounterWrapper alignItems="center" justifyContent="center" hasError={hasHiddenImagesError}>
                    <Text fontSize="2.25em">+{hiddenImagesCount}</Text>
                  </ImagesCounterWrapper>
                )}
              </StyledFlex>
            )
          })}
        </Flex>
      )}
    </Flex>
  )
}
ImagesGridHorizontal.defaultProps = {
  images: [],
  gridHeight: 360,
  post: null,
  profiles: null,
}

ImagesGridHorizontal.propTypes = {
  images: PropTypes.array,
  gridHeight: PropTypes.number,
  network: PropTypes.string.isRequired,
  post: PropTypes.object,
  profiles: PropTypes.array,
}

export default ImagesGridHorizontal
