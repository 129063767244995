import React, { useRef } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { space as styledSpace } from 'styled-system'
import { radius } from 'theme'
import withConfirm from 'utils/withConfirm'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import TextArea from 'components/atoms/TextArea'
import Image from 'components/atoms/Image'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 450px;
    height: auto;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const { TITLE, MESSAGE } = {
  TITLE: 'title',
  MESSAGE: 'message',
}

const FormValidationSchema = Yup.object().shape({
  [TITLE]: Yup.string().required('Group name is required'),
  [MESSAGE]: Yup.string().required('Hashtags are required'),
})

const HashTagModal = ({
  hashTagForEdit,
  isOpen,
  handleDismiss,
  isSubmitting,
  handleSave,
  isRemoving,
  handleRemove,
  confirm,
}) => {
  const formRef = useRef(null)

  const handleSubmitForm = async (values) => {
    const { id } = hashTagForEdit
    const body = {
      [TITLE]: values[TITLE],
      [MESSAGE]: values[MESSAGE],
    }

    if (id) {
      body.id = id
    }

    handleSave(body)
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const handleClickCloseModal = () => {
    if (!isSubmitting) {
      handleDismiss()
    }
  }

  const { title, message } = hashTagForEdit

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">Group of hashtags</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flex="1" width="100%" height="220px">
            <Formik
              initialValues={{
                [TITLE]: title || '',
                [MESSAGE]: message || '',
              }}
              validationSchema={FormValidationSchema}
              onSubmit={handleSubmitForm}
              autocomplete="off"
              ref={formRef}
            >
              {({ values, touched, errors, handleChange, setFieldValue }) => (
                <Box mt="m" px="m" width="100%">
                  <Box>
                    <Input
                      placeholder="Group name"
                      value={values[TITLE]}
                      name={[TITLE]}
                      id={[TITLE]}
                      onChange={handleChange(TITLE)}
                      error={errors[TITLE] && touched[TITLE] && errors[TITLE]}
                      width="100%"
                    />
                  </Box>
                  <Box mt="m">
                    <TextArea
                      placeholder="Hashtags"
                      value={values[MESSAGE]}
                      name={[MESSAGE]}
                      id={[MESSAGE]}
                      onChange={(e) => {
                        let { value } = e.target
                        value = value
                          .split(' ')
                          .map((item) => (item.charAt(0) === '#' || !item ? item : `#${item}`))
                          .join(' ')
                        setFieldValue(MESSAGE, value)
                      }}
                      error={errors[MESSAGE] && touched[MESSAGE] && errors[MESSAGE]}
                      width="100%"
                      rows="4"
                    />
                  </Box>
                </Box>
              )}
            </Formik>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Flex alignItems="center">
              <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isSubmitting}>
                Cancel
              </Button.Gray>
              {hashTagForEdit.id ? (
                <ButtonWithLoading
                  buttonComp={Button.Gray}
                  isLoading={isRemoving}
                  onClick={() => {
                    confirm({
                      fn: () => () => handleRemove({ id: hashTagForEdit.id }),
                      message: `Are you sure you want to remove this hashtag group?`,
                      action: 'Remove',
                    })
                  }}
                  isSmall
                >
                  Remove
                </ButtonWithLoading>
              ) : (
                <Flex />
              )}
            </Flex>

            <ButtonWithLoading isLoading={isSubmitting} onClick={handleSubmitFormFromButton} isSmall type="button">
              <Text fontWeight="medium">{isSubmitting ? 'Saving' : 'Save'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

HashTagModal.defaultProps = {
  hashTagForEdit: {},
}

HashTagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  hashTagForEdit: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

export default withConfirm(HashTagModal)
