import React, { useState, useEffect, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { fontSizes, lineHeights, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  FACEBOOK,
  FEATURE_REVIEW_PROFILES,
  FEATURE_WEB_ANALYTICS,
  GOOGLE,
  INSTAGRAM,
  TWITTER,
  FACEBOOK_INSTAGRAM_PROFILE_CONNECT_SCOPES,
  USER_TYPES,
  BLUESKY,
} from 'consts'
import { getTimeZones, mapArrayToReactSelect, pxToRem, originCleanUp } from 'helpers'
import request from 'utils/request'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import DropDown from 'shared/DropDown'
import ErrorFocus from 'shared/ErrorFocus'
import ConnectModal from 'shared/ConnectModal'
import { H4, H5, Text } from 'components/atoms/Typography'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import BottomStepper, { STEPPER_HEIGHT } from 'components/organisms/BottomStepper'
import Container from 'components/atoms/Container'
import Input from 'components/atoms/Input'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Icon from 'components/atoms/Icon'
import Button from 'components/atoms/Button'
import Tooltip from 'components/molecules/Tooltip'
import Link from 'components/atoms/Link'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Badge from 'components/atoms/Badge'
import { ROUTE_ENTITIES } from 'routes/Calendar/consts'
import CustomizationsBlockComponent from 'routes/Calendar/components/Posts/components/CustomizationsBlockComponent'
import VideoModal from './VideoModal'
import ConnectingFacebookGroupsModal from './ConnectingFacebookGroupsModal'
import ConnectingBlueskyModal from './ConnectingBlueskyModal'
import { checkIfFBLibraryIsNotBlocked } from '../helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 1000px !important;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 850px;
    top: 50%;
    transform: translate(0, -50%);
    ${({ welcome }) =>
      welcome &&
      `border-radius: ${radius.xxl}; 
      padding: ${space.l}; 
      max-width: 1352px !important;    
      max-height: 747px;
      top: 50%;
      transform: translate(0, -50%);
      `}
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: hidden;
  height: 100%;
  outline: none;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledContainer = styled(Container)`
  overflow-y: auto;
`

const BottomStepperWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const StyledH5 = styled(H5)`
  line-height: ${lineHeights.xl};
  font-size: ${fontSizes.m};
  font-weight: normal;
`

const StyledNetworkWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_card_secondary};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.m};
  cursor: pointer;
  min-height: 85px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.error};
    svg {
      fill: ${({ theme }) => theme.colors.error};
    }
    p {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`

const StyledWelcomeNetworkWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_card_secondary};
  border: 1px solid #cdd6e5;
  border-radius: ${radius.xl};
  cursor: pointer;
  min-height: 110px;
  max-height: 117px;
  padding: ${space.m};
  &:hover {
    border-color: ${({ theme }) => theme.colors.border_color_light_hover};
    p {
      color: ${({ theme }) => theme.colors.border_color_light_hover};
    }
  }
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const StyledGrid = styled(Grid)`
  overflow: hidden;
`

const StyledButtonReversed = styled(Button.Reversed)`
  height: 36px;
  width: 100px;
  padding-left: ${space.m};
  padding-right: ${space.m};
  min-width: ${space.l};
  font-size: ${fontSizes.s};
  padding: 0;
  border: 1px solid #cdd6e5;
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledPlayIconWrapper = styled(Flex)`
  left: 50%;
  transform: translate(-50%, 0);
`

const ProfileTypeWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_card};
  height: 100%;
  border: 1px solid #b6cef0;
  border-radius: 24px;
  cursor: pointer;
  max-width: 350px;
  &:hover {
    box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
  ${({ isSelected, theme }) =>
    isSelected &&
    `border: 1px solid ${theme.colors.primary}; box-shadow: 0 4px 15px ${({ theme }) =>
      transparentize(0.7, theme.colors.box_shadow)};`}
`

const StyledRecommendedWrapper = styled(Flex)`
  padding: 6px ${space.m};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
`

const StyledFieldBox = styled(Box)`
  cursor: pointer;
`

const StyledSpecialLabelBadge = styled(Badge.Status)`
  background: linear-gradient(180deg, #1285fe 0, #0065cf);
  min-width: auto;
  p {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

const {
  ENTITY,
  ENTITY_EXISTING,
  ENTITY_NEW,
  ENTITY_GID,
  ENTITY_NAME,
  ENTITY_TIMEZONE,
  ENTITY_COUNTRY_ID,
  ENTITY_TYPE,
  REVIEW,
  WEB,
  NETWORK,
} = {
  ENTITY: 'entity',
  ENTITY_EXISTING: 'entity_existing',
  ENTITY_NEW: 'entity_new',
  ENTITY_GID: 'entityId',
  ENTITY_NAME: 'name',
  ENTITY_TIMEZONE: 'timezone',
  ENTITY_COUNTRY_ID: 'countryId',
  ENTITY_TYPE: 'type',
  REVIEW: 'REVIEW',
  WEB: 'WEB',
  NETWORK: 'network',
}

const STEP_COUNT = 3

const DEFAULT_COMPONENTS_HEIGHT = pxToRem(56)

const GET_STARTED_VIDEOS = {
  main: {
    url: 'https://youtu.be/P7OPaLJU0L4',
    thumbnail_url: '/assets/vistasocial/add-profile/initial_setup.webp',
  },
  secondary: [
    { url: 'https://youtu.be/P7OPaLJU0L4', thumbnail_url: '/assets/vistasocial/add-profile/initial_setup.webp' },
    {
      url: 'https://youtu.be/nnMYD2HqMAU',
      thumbnail_url: '/assets/vistasocial/add-profile/scheduling.webp',
    },
    {
      url: 'https://youtu.be/q3rjMQWbhzg',
      thumbnail_url: '/assets/vistasocial/add-profile/inbox.webp',
    },
    {
      url: 'https://youtu.be/WLDAcKHmNo4',
      thumbnail_url: '/assets/vistasocial/add-profile/reports.webp',
    },
  ],
  special: [
    {
      url: 'https://youtu.be/oEPpwzXpaF0',
      thumbnail_url: '/assets/vistasocial/add-profile/ai.webp',
    },
    {
      url: 'https://youtu.be/XFEqNXwivZw',
      thumbnail_url: '/assets/vistasocial/add-profile/bulk.webp',
    },
    {
      url: 'https://youtu.be/21vARvJwsZw',
      thumbnail_url: '/assets/vistasocial/add-profile/queue.webp',
    },
  ],
}

const { INSTAGRAM_ACCOUNT_OPTION_BUSINESS, INSTAGRAM_ACCOUNT_OPTION_CREATOR, INSTAGRAM_ACCOUNT_OPTION_PERSONAL } = {
  INSTAGRAM_ACCOUNT_OPTION_BUSINESS: 'business',
  INSTAGRAM_ACCOUNT_OPTION_CREATOR: 'creator',
  INSTAGRAM_ACCOUNT_OPTION_PERSONAL: 'personal',
}

const { FACEBOOK_ACCOUNT_OPTION_PAGE, FACEBOOK_ACCOUNT_OPTION_GROUP, FACEBOOK_ACCOUNT_OPTION_PROFILE } = {
  FACEBOOK_ACCOUNT_OPTION_PAGE: 'page',
  FACEBOOK_ACCOUNT_OPTION_GROUP: 'group',
  FACEBOOK_ACCOUNT_OPTION_PROFILE: 'profile',
}

const NETWORK_ACCOUNT_OPTIONS = {
  [INSTAGRAM]: {
    title: (
      <Fragment>
        <Text my="s" color="denimText" fontSize="xl" textAlign="center" fontWeight="bold">
          What type of{' '}
          <Text as="span" color="primary" fontSize="xl" fontWeight="bold">
            Instagram
          </Text>{' '}
          profile do you have?
        </Text>
        <Flex justifyContent="center">
          <Link
            color="primary"
            textAlign="center"
            target="_blank"
            as="a"
            href="https://support.vistasocial.com/hc/en-us/articles/14247888444059-Instagram-account-types-explained-Business-Creator-Personal"
          >
            Learn more about different Instagram profile types
          </Link>
        </Flex>
      </Fragment>
    ),
    types: [
      {
        key: INSTAGRAM_ACCOUNT_OPTION_BUSINESS,
        title: 'Business or Creator',
        recommended: true,
        description: `Instagram business or creator profiles that are connected to facebook pages`,
        fields: {
          direct_publishing: true,
          reminder_publishing: true,
          content_planning: true,
          engagement_tools: true,
          analytics_and_reporting: true,
          ai_tools: true,
        },
        special_code: '',
      },

      {
        key: INSTAGRAM_ACCOUNT_OPTION_PERSONAL,
        title: 'Personal',
        recommended: false,
        description: `Instagram personal or creator profiles not connected to facebook pages`,
        fields: {
          reminder_publishing: true,
          content_planning: true,
          ai_tools: true,
        },
        special_code: 'instagramdisplay',
      },
    ],
    fields: [
      {
        value: 'direct_publishing',
        label: 'Direct publishing',
        description: 'Posts are published directly in your Instagram profile.',
      },
      {
        value: 'reminder_publishing',
        label: 'Reminder publishing',
        description: 'Posts are scheduled in Vista Social and sent as reminder notifications to your mobile phone.',
      },
      {
        value: 'content_planning',
        label: 'Content planning',
        description: 'Visual plan and schedule your posts using Vista Social.',
      },
      {
        value: 'ai_tools',
        label: 'AI tools',
        description: 'Utilize built-in ChatGPT AI integration to craft amazing social media captions.',
      },
      {
        value: 'engagement_tools',
        label: 'Engagement tools',
        description: 'Respond to messages and comments right from your Vista Social account.',
      },
      {
        value: 'analytics_and_reporting',
        label: 'Analytics and reporting',
        description: 'Run and export performance reports.',
      },
    ],
  },
  [FACEBOOK]: {
    title: (
      <Fragment>
        <Text my="s" color="denimText" fontSize="xl" textAlign="center" fontWeight="bold">
          What type of{' '}
          <Text as="span" color="primary" fontSize="xl" fontWeight="bold">
            Facebook
          </Text>{' '}
          profile do you have?
        </Text>
        <Flex justifyContent="center">
          <Link
            color="primary"
            textAlign="center"
            target="_blank"
            as="a"
            href="https://support.vistasocial.com/hc/en-us/articles/4409614557723-Connecting-Facebook-Page-to-Vista-Social"
          >
            Learn more about different Facebook profile types
          </Link>
        </Flex>
      </Fragment>
    ),
    types: [
      {
        key: FACEBOOK_ACCOUNT_OPTION_PAGE,
        title: 'Page',
        recommended: true,
        description: `Facebook page that you are an owner or an admin of. All features.`,
        fields: {
          direct_publishing: true,
          reminder_publishing: true,
          content_planning: true,
          engagement_tools: true,
          analytics_and_reporting: true,
          ai_tools: true,
        },
        state: {
          connect: 'page',
        },
        special_code: '',
      },

      {
        key: FACEBOOK_ACCOUNT_OPTION_PROFILE,
        title: 'Profile',
        recommended: false,
        description: `Facebook personal or professional profile. Limited features.`,
        fields: {
          reminder_publishing: true,
          content_planning: true,
          ai_tools: true,
        },
        state: {
          connect: 'profile',
        },
        special_code: '',
      },

      {
        key: FACEBOOK_ACCOUNT_OPTION_GROUP,
        title: 'Group',
        recommended: false,
        description: `Facebook group and you are a member or an admin of. Limited features.`,
        fields: {
          reminder_publishing: true,
          content_planning: true,
          ai_tools: true,
        },
        special_code: 'facebookdisplay',
      },
    ],
    fields: [
      {
        value: 'direct_publishing',
        label: 'Direct publishing',
        description: 'Posts are published directly in your Instagram profile.',
      },
      {
        value: 'reminder_publishing',
        label: 'Reminder publishing',
        description: 'Posts are scheduled in Vista Social and sent as reminder notifications to your mobile phone.',
      },
      {
        value: 'content_planning',
        label: 'Content planning',
        description: 'Visual plan and schedule your posts using Vista Social.',
      },
      {
        value: 'ai_tools',
        label: 'AI tools',
        description: 'Utilize built-in ChatGPT AI integration to craft amazing social media captions.',
      },
      {
        value: 'engagement_tools',
        label: 'Engagement tools',
        description: 'Respond to messages and comments right from your Vista Social account.',
      },
      {
        value: 'analytics_and_reporting',
        label: 'Analytics and reporting',
        description: 'Run and export performance reports.',
      },
    ],
  },
}

const VIDEO_MODAL_OPTIONS = { isOpen: false, data: null }

const formatSelectEntityOption = ({ value, label, type = '', picture_url }) => (
  <Flex alignItems="center" key={value}>
    <ImageWithFallback
      source={picture_url}
      fallbackSource="/assets/company.svg"
      width="20px"
      height="20px"
      borderRadius={radius.l}
    />
    <Flex ml="s" flexDirection="column">
      <Text>
        {label} {type ? `(${type.toLowerCase()})` : ''}
      </Text>
    </Flex>
  </Flex>
)

const AddProfileModal = ({ user, isOpen, handleDismiss, selectedEntity, origin, welcome }) => {
  const formRef = useRef(null)

  const { isManager } = user
  const isAdvocate = user.type === USER_TYPES.ADVOCATE

  const [step, setStep] = useState(welcome ? 0 : 1)
  const [stepValues, setStepValues] = useState({})
  const [isWorkingWithData, setIsWorkingWithData] = useState(false)
  const [entities, setEntities] = useState([])
  const [formData, setFormData] = useState({})
  const [filteredNetworks, setFilteredNetworks] = useState([])
  const [searchNetworksString, setSearchNetworksString] = useState('')
  const [isConnectNotSocialModalOpen, setIsConnectNotSocialModalOpen] = useState(false)
  const [isBlogReviewSubmitting, setIsBlogReviewSubmitting] = useState(false)
  const [blogReview, setBlogReview] = useState({})
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_REVIEW_PROFILES]: { enabled: true },
    [FEATURE_WEB_ANALYTICS]: { enabled: true },
  })
  const [connectingToNetworkOptions, setConnectingToNetworkOptions] = useState({ isConnecting: false, network: null })
  const [showNetworkProfileTypesStep, setShowNetworkProfileTypesStep] = useState(null)
  const [videoModalOptions, setVideoModalOptions] = useState({ ...VIDEO_MODAL_OPTIONS })
  const [selectedNetworkProfileType, setSelectedProfileType] = useState(null)
  const [isConnectingFacebookGroupsModalOpen, setIsConnectingFacebookGroupsModalOpen] = useState(false)
  const [isConnectingBlueskyModalOpen, setIsConnectingBlueskyModalOpen] = useState(false)

  const { countries = [], timeZones = [], networks = [], entityTypes = [] } = formData

  const getFormData = async () => {
    try {
      const response = await request({ path: 'profiles/formdata' })

      if (response) {
        const { countries = [], networks = [], entityTypes = [] } = response || {}
        const timeZones = getTimeZones()
        setFormData({ countries, timeZones, networks, entityTypes })
        setFilteredNetworks([...networks])
      } else {
        Alert.error(ERROR_MESSAGE, { timeout: 5000 })
      }
    } catch (error) {
      errorHelper({ error, componentName: AddProfileModal.displayName, functionName: 'getFormData' })
      Alert.error(ERROR_MESSAGE, { timeout: 5000 })
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_REVIEW_PROFILES, FEATURE_WEB_ANALYTICS] }),
      })
      getFormData()

      const { entities } = user

      setEntities(
        entities
          .filter(({ entity_user: { profiles } }) => profiles === 'MANAGE')
          .sort((a, b) => {
            return a.name < b.name ? -1 : 1
          })
          .map(({ id, name, picture_url, type }) => ({ value: id, label: name, picture_url, type }))
      )

      if (!selectedEntity) {
        if (user.type === USER_TYPES.ADVOCATE) {
          if (user.entities && user.entities.length === 1) {
            setStepValues({
              [ENTITY]: ENTITY_EXISTING,
              [ENTITY_GID]: {
                value: user.entities[0].id,
                label: user.entities[0].name,
                type: user.entities[0].type,
                picture_url: user.entities[0].picture_url,
              },
            })
            setStep(3)
          } else {
            setStepValues({})
            setStep(welcome ? 0 : 1)
          }
        } else {
          setStepValues({})
          setStep(welcome ? 0 : 1)
        }
      } else {
        setStepValues({ [ENTITY]: ENTITY_EXISTING, [ENTITY_GID]: selectedEntity })
        setStep(3)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (!searchNetworksString) {
      setFilteredNetworks([...networks])
    } else {
      setFilteredNetworks(
        networks.filter(({ display }) => display.toLowerCase().includes(searchNetworksString.toLowerCase()))
      )
    }
  }, [searchNetworksString])

  const handleSaveStepValues = (key, value) => {
    stepValues[key] = value
    setStepValues({ ...stepValues })
  }

  const handleSubmitEntityDetails = async () => {
    setIsWorkingWithData(true)
    const body = {
      [ENTITY_TYPE]: stepValues[ENTITY_TYPE].value,
      [ENTITY_NAME]: stepValues[ENTITY_NAME],
      [ENTITY_TIMEZONE]: stepValues[ENTITY_TIMEZONE].value,
      [ENTITY_COUNTRY_ID]: stepValues[ENTITY_COUNTRY_ID].value,
    }

    try {
      const response = await request({
        method: 'POST',
        path: ROUTE_ENTITIES,
        body,
      })

      if (response) {
        if (response.error) {
          Alert.error(response.error)
        } else {
          Alert.success(`Profile group has been created.`, {
            timeout: 5000,
          })
          const { id } = response
          stepValues[ENTITY_GID] = { value: id, label: stepValues[ENTITY_NAME] }
          stepValues[ENTITY] = ENTITY_EXISTING
          setStepValues({ ...stepValues })
          entities.push({ value: id, label: stepValues[ENTITY_NAME], type: stepValues[ENTITY_TYPE].value })
          setEntities([
            ...entities.sort((a, b) => {
              return a.label < b.label ? -1 : 1
            }),
          ])
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: AddProfileModal.displayName, functionName: 'handleSubmitEntityDetails' })
    }
    setIsWorkingWithData(false)
  }

  const createValidationSchemaForStep = (step) => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }

    switch (step) {
      case 1:
        return Yup.object().shape({
          [ENTITY]: Yup.string().required('Please select one of the variants'),
        })
      case 2:
        return Yup.object().shape({
          [ENTITY_TYPE]: Yup.string()
            .nullable()
            .required('Type is required'),
          [ENTITY_NAME]: Yup.string().required('Name is required'),
          [ENTITY_COUNTRY_ID]: Yup.object()
            .shape(isDropdownValueRequiredShape)
            .typeError('Country is required'),
          [ENTITY_TIMEZONE]: Yup.object()
            .shape(isDropdownValueRequiredShape)
            .typeError('Timezone is required'),
        })
      default:
        return null
    }
  }

  const handleClickCloseModal = () => {
    if (!isWorkingWithData) {
      handleDismiss()
    } else {
      Alert.error('Please wait a bit before continuing.', { timeout: 5000 })
    }
  }

  const handleClickStepBack = () => {
    if (!connectingToNetworkOptions.isConnecting && !isWorkingWithData) {
      if (step !== 1) {
        if (showNetworkProfileTypesStep) {
          setSelectedProfileType(null)
          setShowNetworkProfileTypesStep(null)
          if (welcome) {
            if (stepValues[ENTITY] === ENTITY_EXISTING) {
              setStep(1)
            } else if (stepValues[ENTITY] === ENTITY_NEW) {
              setStep(step - 1)
            }
          }
        } else if (stepValues[ENTITY] === ENTITY_EXISTING) {
          setStep(1)
        } else if (stepValues[ENTITY] === ENTITY_NEW) {
          setStep(step - 1)
        }
      } else if (welcome) {
        setStep(step - 1)
      }
    } else {
      Alert.error(`Please wait just a bit longer before continuing.`, { timeout: 5000 })
    }
  }

  const handleClickOpenConnectNotSocialModal = ({ type, code, display }) => {
    setBlogReview({ type, code, display })
    setIsConnectNotSocialModalOpen(true)
  }

  const handleClickCloseConnectNotSocialModal = () => {
    setIsConnectNotSocialModalOpen(false)
    setBlogReview({})
  }

  const handleClickOpenVideoModal = ({ url, thumbnail_url }) => {
    setVideoModalOptions({ ...{ isOpen: true, data: { url, thumbnail_url } } })
  }

  const handleClickCloseVideoModal = () => {
    setVideoModalOptions({ ...VIDEO_MODAL_OPTIONS })
  }

  const connectNetworkFromWelcome = ({ code, id, type, display }) => {
    if (type === 'BLOG' || type === REVIEW) {
      handleClickOpenConnectNotSocialModal({ type, code, display })
    }
    // else if (code === INSTAGRAM) {
    //   setSelectedProfileType({ code, id, display, key: INSTAGRAM_ACCOUNT_OPTION_BUSINESS })
    //   setShowNetworkProfileTypesStep({ code, id, display })
    //   setStep(3)
    // }
    else if (code === FACEBOOK && !isAdvocate) {
      setSelectedProfileType({ code, id, display, key: FACEBOOK_ACCOUNT_OPTION_PAGE })
      setShowNetworkProfileTypesStep({ code, id, display })
      setStep(3)
    } else {
      // eslint-disable-next-line no-use-before-define
      handleClickConnectSocialProfile({ code, id })
    }
  }

  const handleSubmit = async () => {
    const { type, code, display, id } = stepValues[NETWORK] || {}
    if (step === 1) {
      if (stepValues[ENTITY] === ENTITY_EXISTING) {
        if (stepValues[NETWORK]) {
          connectNetworkFromWelcome({ code, id, type, display })
        } else {
          setStep(3)
        }
      } else if (stepValues[ENTITY] === ENTITY_NEW) {
        setStep(step + 1)
      }
    } else if (step === 2) {
      await handleSubmitEntityDetails()
      if (stepValues[NETWORK]) {
        connectNetworkFromWelcome({ code, id, type, display })
      } else {
        setStep(step + 1)
      }
    }
  }

  const handleClickConnectSocialProfile = async ({ code, id: profileId }) => {
    try {
      setConnectingToNetworkOptions({ ...{ isConnecting: true, network: networks.find((item) => item.code === code) } })

      const updatedOrigin = originCleanUp({ origin })

      let updatedCode = code

      if (selectedNetworkProfileType && selectedNetworkProfileType.special_code) {
        updatedCode = selectedNetworkProfileType.special_code
      }

      const { value: id } = stepValues[ENTITY_GID]

      let apiString = ''

      if (
        code === FACEBOOK ||
        code === INSTAGRAM
        // (code === INSTAGRAM &&
        //   selectedNetworkProfileType &&
        //   selectedNetworkProfileType.key === INSTAGRAM_ACCOUNT_OPTION_BUSINESS)
      ) {
        if (
          code === FACEBOOK &&
          selectedNetworkProfileType &&
          selectedNetworkProfileType.key === FACEBOOK_ACCOUNT_OPTION_GROUP
        ) {
          setIsConnectingFacebookGroupsModalOpen(true)
        } else {
          let updatedSelectedNetworkProfileType = selectedNetworkProfileType

          if (code === INSTAGRAM) {
            updatedCode = INSTAGRAM
            // INSTAGRAM_ACCOUNT_OPTION_BUSINESS
          }

          if (isAdvocate) {
            updatedSelectedNetworkProfileType = NETWORK_ACCOUNT_OPTIONS[code].types.find(
              ({ key }) => key === FACEBOOK_ACCOUNT_OPTION_PAGE
            )
          }

          apiString = `/api/${updatedCode}/callback?state=${encodeURIComponent(
            JSON.stringify({
              id,
              origin: updatedOrigin,
              ...(updatedSelectedNetworkProfileType && updatedSelectedNetworkProfileType.state
                ? updatedSelectedNetworkProfileType.state
                : {}),
            })
          )}`

          if (checkIfFBLibraryIsNotBlocked()) {
            FB.login(
              async (response) => {
                const { status, authResponse } = response || {}
                const { accessToken } = authResponse || {}

                if (status === 'connected' && accessToken) {
                  apiString = `${apiString}&access_token=${accessToken}`

                  document.location = apiString
                } else {
                  Alert.error(`Can't connect network. Please contact support.`, { timeout: 5000 })
                  setConnectingToNetworkOptions({ ...{ isConnecting: false, network: null } })
                }
              },
              {
                scope: FACEBOOK_INSTAGRAM_PROFILE_CONNECT_SCOPES,
              }
            )
          } else {
            setConnectingToNetworkOptions({ ...{ isConnecting: false, network: null } })
          }
        }
      } else if (code === BLUESKY) {
        setIsConnectingBlueskyModalOpen(true)
      } else {
        apiString = `/api/connect/${updatedCode}?origin=${updatedOrigin}&profileId=${profileId}&entityId=${id}`

        document.location = apiString
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: AddProfileModal.displayName,
        functionName: 'handleClickConnectSocialProfile',
        errorType: 'fblogin',
      })

      setConnectingToNetworkOptions({ ...{ isConnecting: false, network: null } })
    }
  }

  const handleClickConnectBlogReview = async ({ url, type, code, setErrors }) => {
    const body = { url, type, code }
    const { value: id } = stepValues[ENTITY_GID]
    body.id = id
    try {
      setIsBlogReviewSubmitting(true)
      const response = await request({
        method: 'POST',
        path: `connect/url`,
        body,
      })

      if (response) {
        if (response.error) {
          setErrors({ url: response.error })
        } else {
          if (response.message) {
            Alert.success(response.message, { timeout: 5000 })
          }
          handleClickCloseConnectNotSocialModal()
          handleClickCloseModal()
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: AddProfileModal.displayName, functionName: 'handleClickConnectBlogReview' })
      setErrors({ url: error })
    }
    setIsBlogReviewSubmitting(false)
  }

  let networkProfileTypeStep = null
  if (showNetworkProfileTypesStep) {
    networkProfileTypeStep = NETWORK_ACCOUNT_OPTIONS[showNetworkProfileTypesStep.code]
  }

  let networkPromptDisplay = ''
  if (showNetworkProfileTypesStep && networkProfileTypeStep) {
    const selectedProfileType = selectedNetworkProfileType
      ? networkProfileTypeStep.types.find((item) => item.key === selectedNetworkProfileType.key)
      : networkProfileTypeStep.types.find((item) => item.recommended)

    if (selectedProfileType) {
      networkPromptDisplay =
        showNetworkProfileTypesStep.code === INSTAGRAM
          ? `${selectedProfileType.title} Profile`
          : selectedProfileType.title
    }
  }

  const { profilesCount = 0 } = welcome || {}

  let modalTitle = 'Connect social profile or platform'
  if (step === 0) {
    modalTitle = 'Welcome to Vista Social!'

    if (profilesCount !== 0) {
      modalTitle = `You are off to a great start!`
    }
  } else if (step === STEP_COUNT) {
    let connectingTo = ''
    if (stepValues[ENTITY] === ENTITY_NEW) {
      connectingTo = stepValues[ENTITY_NAME]
    } else if (stepValues[ENTITY] === ENTITY_EXISTING) {
      ;({ label: connectingTo } = stepValues[ENTITY_GID])
    }
    if (networkPromptDisplay) {
      modalTitle = `Connect ${networkPromptDisplay}`
    }
    modalTitle = isAdvocate ? 'Connect social profiles' : `${modalTitle} to ${connectingTo}`
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent welcome={welcome}>
          {!welcome ? (
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
              <H4 my="m">{modalTitle}</H4>
            </StyledDialogEnvironmentWrapper>
          ) : (
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                fontWeight={step === 0 ? 'bold' : 'normal'}
                fontSize={step === 0 ? 'xxxl' : 'xl'}
                color={step === 0 ? 'primary' : 'primaryText'}
              >
                {modalTitle}
              </Text>
              <StyledFlex
                onClick={() => {
                  handleDismiss(true)
                }}
              >
                <Image width="15px" height="15px" src="/assets/clear.svg" />
              </StyledFlex>
            </Flex>
          )}

          <StyledDialogBodyWrapper flex="1" tabIndex={0} flexDirection="column" width="100%" alignItems="center">
            {step === 0 && (
              <Flex
                width="100%"
                mt="m"
                height="100%"
                flexDirection={{ mobile: 'column', tablet: 'row' }}
                alignItems={{ mobile: 'center', tablet: 'initial' }}
              >
                <Flex
                  maxWidth={{ mobile: '100%', tablet: '300px', desktopWide: '480px' }}
                  width="100%"
                  flexDirection="column"
                  justifyContent="space-between"
                  maxHeight="545px"
                >
                  {profilesCount === 0 ? (
                    <Text color="primaryText" fontSize="m">
                      We are beyond excited that you chose our tool to help you manage yours and take it to new heights!
                      To get started, please begin by connecting your social profiles. You can also watch these few
                      quick videos to help you get started!
                    </Text>
                  ) : (
                    <Text color="primaryText" fontSize="m">
                      Now that you have connected {profilesCount} {profilesCount === 1 ? 'profile' : 'profiles'} you can
                      continue connecting more profiles, watch tutorial or get started with using the dashboard.
                    </Text>
                  )}
                  <Flex flexDirection="column" mt={{ mobile: 's', tablet: 0 }}>
                    <Text fontWeight="bold" color="primaryText" fontSize="xl">
                      Watch a quick tutorial
                    </Text>
                    <Flex mt="m" flexDirection="column" display={{ mobile: 'none', tablet: 'flex' }}>
                      <StyledFlex
                        position="relative"
                        maxHeight={{ mobile: '170px', desktopWide: '270px' }}
                        onClick={() => {
                          handleClickOpenVideoModal({
                            url: GET_STARTED_VIDEOS.main.url,
                            thumbnail_url: GET_STARTED_VIDEOS.main.thumbnail_url,
                          })
                        }}
                      >
                        <StyledPlayIconWrapper position="absolute" height="100%" alignItems="center" draggable="false">
                          <Image
                            src="/assets/vistasocial/add-profile/play.webp"
                            height="calc(50%)"
                            maxWidth="85px"
                            maxHeight="85px"
                          />
                        </StyledPlayIconWrapper>
                        <Image src={GET_STARTED_VIDEOS.main.thumbnail_url} width="100%" height="100%" />
                      </StyledFlex>
                    </Flex>
                    <Grid
                      mt={{ mobile: 's', tablet: 'm' }}
                      gridTemplateColumns={{
                        mobile: 'repeat(2, 1fr)',
                        mobileLarge: 'repeat(4, 1fr)',
                        tablet: 'repeat(3, 1fr)',
                      }}
                      gridGap="m"
                    >
                      {GET_STARTED_VIDEOS.secondary.map(({ url, thumbnail_url }, index) => (
                        <StyledFlex
                          position="relative"
                          maxHeight={{ mobile: '170px', desktopWide: '270px' }}
                          key={url}
                          onClick={() => {
                            handleClickOpenVideoModal({
                              url,
                              thumbnail_url,
                            })
                          }}
                          display={{ mobile: 'flex', tablet: index === 0 ? 'none' : 'flex' }}
                        >
                          <StyledPlayIconWrapper
                            position="absolute"
                            height="100%"
                            alignItems="center"
                            draggable="false"
                          >
                            <Image
                              src="/assets/vistasocial/add-profile/play.webp"
                              height="calc(50%)"
                              maxWidth="39px"
                              maxHeight="40px"
                            />
                          </StyledPlayIconWrapper>
                          <Image src={thumbnail_url} width="100%" height="100%" borderRadius={radius.xl} />
                        </StyledFlex>
                      ))}
                    </Grid>
                    <Grid
                      mt={{ mobile: 's', tablet: 'm' }}
                      gridTemplateColumns="repeat(3, 1fr)"
                      gridGap="m"
                      display={{ mobile: 'none', desktop: 'grid', desktopWide: 'none' }}
                    >
                      {GET_STARTED_VIDEOS.special.map(({ url, thumbnail_url }) => (
                        <StyledFlex
                          position="relative"
                          maxHeight={{ mobile: '170px', desktopWide: '270px' }}
                          key={url}
                          onClick={() => {
                            handleClickOpenVideoModal({
                              url,
                              thumbnail_url,
                            })
                          }}
                        >
                          <StyledPlayIconWrapper
                            position="absolute"
                            height="100%"
                            alignItems="center"
                            draggable="false"
                          >
                            <Image
                              src="/assets/vistasocial/add-profile/play.webp"
                              height="calc(50%)"
                              maxWidth="39px"
                              maxHeight="40px"
                            />
                          </StyledPlayIconWrapper>
                          <Image src={thumbnail_url} width="100%" height="100%" />
                        </StyledFlex>
                      ))}
                    </Grid>
                  </Flex>
                </Flex>
                <Flex
                  flexDirection="column"
                  pl={{ mobile: 0, tablet: 'l', desktopWide: 'xl' }}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    mt={{ mobile: 'm', tablet: 0 }}
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={{ mobile: 'column', desktop: 'row' }}
                  >
                    <Text fontWeight="bold" color="primaryText" fontSize="xl">
                      {profilesCount === 0 ? `Connect your social profiles` : `Connect more social profiles`}
                    </Text>

                    <Flex
                      flexDirection="column"
                      width={{ mobile: '100%', desktop: '250px' }}
                      my={{ mobile: 'm', desktop: 0 }}
                      pr="m"
                    >
                      <Input
                        label=""
                        placeholder="Search for network"
                        value={searchNetworksString}
                        onChange={(e) => {
                          setSearchNetworksString(e.target.value)
                        }}
                        width="100%"
                        isSmall
                      />
                    </Flex>
                  </Flex>

                  <Flex
                    flexDirection="column"
                    mt={{ mobile: 's', tablet: 'm' }}
                    maxHeight={{ mobile: 'auto', desktop: '500px' }}
                    height="100%"
                    position="relative"
                  >
                    <Scrollbars universal>
                      <StyledGrid
                        gridTemplateColumns={{
                          mobile: 'repeat(2, 1fr)',
                          desktop: 'repeat(4,1fr)',
                        }}
                        gridGap={{ mobile: 'm', desktop: 's', desktopWide: 'auto' }}
                        justifyItems="flex-start"
                        position="relative"
                        pr="m"
                        pb={{ mobile: 's', desktop: '1px' }}
                      >
                        {filteredNetworks.map(({ id, display, display_short, code, type, isNew }) => {
                          const networkImage = code
                            ? `/assets/vistasocial/header/networks/${code === GOOGLE ? 'google' : code}.svg`
                            : `/assets/vistasocial/header/networks/cross.svg`
                          let isBlocked = false
                          if (type === REVIEW && !featuresEnabled[FEATURE_REVIEW_PROFILES].enabled) {
                            isBlocked = true
                          } else if (type === WEB && !featuresEnabled[FEATURE_WEB_ANALYTICS].enabled) {
                            isBlocked = true
                          }
                          return (
                            <StyledWelcomeNetworkWrapper
                              key={id}
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              position="relative"
                              textAlign="center"
                              maxWidth={{ mobile: '140px', mobileLarge: '100%', desktop: '100%' }}
                              width="100%"
                              p={{ mobile: 'm', desktop: 's', desktopWide: 'm' }}
                              onClick={() => {
                                if (type === WEB) {
                                  if (isBlocked) {
                                    Alert.error(featuresEnabled[FEATURE_WEB_ANALYTICS].description, {
                                      timeout: 10000,
                                    })
                                  } else {
                                    handleSaveStepValues(NETWORK, { type, code, display })
                                    setStep(1)
                                  }
                                } else if (type === 'BLOG' || type === REVIEW) {
                                  if (isBlocked) {
                                    Alert.error(featuresEnabled[FEATURE_REVIEW_PROFILES].description, {
                                      timeout: 10000,
                                    })
                                  } else {
                                    handleSaveStepValues(NETWORK, { type, code, display })
                                    setStep(1)
                                  }
                                } else if (code === TWITTER) {
                                  handleSaveStepValues(NETWORK, { type, code, display, id })
                                  setStep(1)
                                } else {
                                  // if (code === INSTAGRAM) {
                                  //   setSelectedProfileType({
                                  //     code,
                                  //     id,
                                  //     display,
                                  //     key: INSTAGRAM_ACCOUNT_OPTION_BUSINESS,
                                  //   })
                                  //   setShowNetworkProfileTypesStep({ code, id, display })
                                  // } else
                                  if (code === FACEBOOK) {
                                    setSelectedProfileType({
                                      code,
                                      id,
                                      display,
                                      key: FACEBOOK_ACCOUNT_OPTION_PAGE,
                                    })
                                    setShowNetworkProfileTypesStep({ code, id, display })
                                  }
                                  handleSaveStepValues(NETWORK, { type, code, display, id })
                                  setStep(1)
                                }
                              }}
                            >
                              {isBlocked && (
                                <CustomizationsBlockComponent
                                  message={`Your plan does not offer connecting ${display} profiles.`}
                                  showMessage={false}
                                  top="12px"
                                  left="-8px"
                                  fontSize="xxxs"
                                />
                              )}

                              <Flex alignItems="center" width="100%">
                                <Image src={networkImage} width="24px" height="24px" />
                                <Text ml="s" fontSize="xs" textAlign="left" color="denimText" fontWeight="medium">
                                  {display_short || display}
                                </Text>
                                {isNew && (
                                  <StyledSpecialLabelBadge ml="s">
                                    <Text fontSize="xxs" textAlign="center">
                                      New
                                    </Text>
                                  </StyledSpecialLabelBadge>
                                )}
                              </Flex>
                              <Flex mt="m" pt="xxs">
                                <StyledButtonReversed>Connect</StyledButtonReversed>
                              </Flex>
                            </StyledWelcomeNetworkWrapper>
                          )
                        })}
                      </StyledGrid>
                    </Scrollbars>

                    <Box
                      bottom={{ mobile: 0, desktop: '-70px' }}
                      right={{ mobile: 0, desktop: space.m }}
                      position={{ mobile: 'relative', desktop: 'absolute' }}
                      mt={{ mobile: 's', desktop: 0 }}
                    >
                      {profilesCount === 0 ? (
                        <Fragment>
                          <Button.Gray
                            isSmall
                            onClick={() => {
                              handleDismiss(true)
                            }}
                          >
                            Dismiss
                          </Button.Gray>
                        </Fragment>
                      ) : (
                        <Flex alignItems="center" justifyContent="space-between" width="100%">
                          <Button.Gray
                            isSmall
                            onClick={() => {
                              handleDismiss(true)
                            }}
                          >
                            Back
                          </Button.Gray>
                          <Button.Gradient
                            isSmall
                            onClick={() => {
                              handleDismiss(true, true)
                            }}
                            ml="s"
                            mr={{ mobile: space.m, desktop: 0 }}
                          >
                            <Flex alignItems="center">
                              <Icon.Send fill="white" /> <Text ml="s">Schedule your first post</Text>
                            </Flex>
                          </Button.Gradient>
                        </Flex>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {step === 1 && (
              <Container mt="xl" pb={STEPPER_HEIGHT} maxWidth="400px" width="100%" textAlign="center">
                {isWorkingWithData ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="50" />
                  </Flex>
                ) : (
                  <Fragment>
                    <StyledH5 mt="xs">Where are you adding this profile to?</StyledH5>
                    <Formik
                      initialValues={{
                        [ENTITY_GID]: stepValues[ENTITY_GID] || null,
                        [ENTITY]: stepValues[ENTITY] || '',
                      }}
                      validationSchema={createValidationSchemaForStep(1)}
                      onSubmit={handleSubmit}
                    >
                      {({ values, touched, errors, setFieldValue, handleSubmit }) => {
                        const error = errors[ENTITY] && touched[ENTITY_GID] && errors[ENTITY]
                        return (
                          <Box mt="l" mb="m" textAlign="left">
                            <DropDown
                              placeholder="Select profile group"
                              label=""
                              value={values[ENTITY_GID]}
                              id={[ENTITY_GID]}
                              onChange={async (option) => {
                                await setFieldValue(ENTITY_GID, option)
                                await setFieldValue(ENTITY, ENTITY_EXISTING)
                                handleSaveStepValues(ENTITY, ENTITY_EXISTING)
                                handleSaveStepValues(ENTITY_GID, option)
                                handleSubmit()
                              }}
                              options={entities.map(({ id, name, ...props }) => ({
                                value: id,
                                label: name,
                                ...props,
                              }))}
                              error={errors[ENTITY_GID] && touched[ENTITY_GID] && errors[ENTITY_GID]}
                              openMenuOnFocus
                              height={DEFAULT_COMPONENTS_HEIGHT}
                              formatOptionLabel={({ ...props }) => formatSelectEntityOption({ ...props })}
                            />
                            {isManager && (
                              <Flex mt="m" alignItems="center">
                                <StyledText
                                  color="primary"
                                  onClick={async () => {
                                    await setFieldValue(ENTITY_GID, null)
                                    await setFieldValue(ENTITY, ENTITY_NEW)
                                    handleSaveStepValues(ENTITY, ENTITY_NEW)
                                    handleSubmit()
                                  }}
                                >
                                  Create new profile group
                                </StyledText>
                              </Flex>
                            )}

                            {error && <ErrorLabel error={error} mt="m" />}
                            <BottomStepperWrapper>
                              <BottomStepper
                                total={STEP_COUNT}
                                step={step.toString()}
                                handleBack={handleClickStepBack}
                                handleSubmit={handleSubmit}
                                forceStepBack={!!welcome}
                                borderRadius={welcome ? `0 0 ${radius.xxl} ${radius.xxl}` : ''}
                              />
                            </BottomStepperWrapper>
                          </Box>
                        )
                      }}
                    </Formik>
                  </Fragment>
                )}
              </Container>
            )}
            {step === 2 && (
              <StyledDialogBodyWrapper flexDirection="column" width="100%" height="100%">
                <Scrollbars universal style={{ overflow: 'unset' }}>
                  <StyledContainer
                    py="xl"
                    maxWidth="400px"
                    textAlign="center"
                    height={{ mobile: '100%', tablet: 'auto' }}
                  >
                    <StyledH5 mt="xs">Profile group information</StyledH5>
                    <Formik
                      initialValues={{
                        [ENTITY_TYPE]: stepValues[ENTITY_TYPE] || null,
                        [ENTITY_NAME]: stepValues[ENTITY_NAME] || '',
                        [ENTITY_COUNTRY_ID]: stepValues[ENTITY_COUNTRY_ID] || null,
                        [ENTITY_TIMEZONE]: stepValues[ENTITY_TIMEZONE] || null,
                      }}
                      validationSchema={createValidationSchemaForStep(2)}
                      onSubmit={handleSubmit}
                      ref={formRef}
                    >
                      {({ values, setFieldValue, errors, touched }) => {
                        return (
                          <Flex mt="l" mb="m" flexDirection="column" textAlign="left">
                            <Box>
                              <DropDown
                                placeholder="Type"
                                label="Type"
                                value={values[ENTITY_TYPE]}
                                id={[ENTITY_TYPE]}
                                onChange={(option) => {
                                  setFieldValue(ENTITY_TYPE, option)
                                  handleSaveStepValues(ENTITY_TYPE, option)
                                }}
                                options={entityTypes}
                                error={errors[ENTITY_TYPE] && touched[ENTITY_TYPE] && errors[ENTITY_TYPE]}
                                openMenuOnFocus
                                height={DEFAULT_COMPONENTS_HEIGHT}
                                isSearchable={false}
                              />
                            </Box>
                            <Box mt="m">
                              <Input
                                label="Name"
                                placeholder="Name"
                                value={values[ENTITY_NAME]}
                                name={[ENTITY_NAME]}
                                id={[ENTITY_NAME]}
                                onChange={(e) => {
                                  const {
                                    target: { value },
                                  } = e
                                  setFieldValue(ENTITY_NAME, value)
                                  handleSaveStepValues(ENTITY_NAME, value)
                                }}
                                error={errors[ENTITY_NAME] && touched[ENTITY_NAME] && errors[ENTITY_NAME]}
                                width="100%"
                                height={DEFAULT_COMPONENTS_HEIGHT}
                              />
                            </Box>
                            <Box mt="m">
                              <DropDown
                                placeholder="Select Country"
                                label="Country"
                                value={values[ENTITY_COUNTRY_ID]}
                                id={[ENTITY_COUNTRY_ID]}
                                onChange={(option) => {
                                  setFieldValue(ENTITY_COUNTRY_ID, option)
                                  handleSaveStepValues(ENTITY_COUNTRY_ID, option)
                                }}
                                options={mapArrayToReactSelect(countries)}
                                error={
                                  errors[ENTITY_COUNTRY_ID] && touched[ENTITY_COUNTRY_ID] && errors[ENTITY_COUNTRY_ID]
                                }
                                openMenuOnFocus
                                height={DEFAULT_COMPONENTS_HEIGHT}
                                menuPlacement="top"
                              />
                            </Box>
                            <Box mt="m">
                              <DropDown
                                placeholder="Select timezone"
                                label="Timezone"
                                value={values[ENTITY_TIMEZONE]}
                                id={[ENTITY_TIMEZONE]}
                                onChange={(option) => {
                                  setFieldValue(ENTITY_TIMEZONE, option)
                                  handleSaveStepValues(ENTITY_TIMEZONE, option)
                                }}
                                options={timeZones}
                                error={errors[ENTITY_TIMEZONE] && touched[ENTITY_TIMEZONE] && errors[ENTITY_TIMEZONE]}
                                openMenuOnFocus
                                height={DEFAULT_COMPONENTS_HEIGHT}
                                menuPlacement="top"
                              />
                            </Box>
                            <ErrorFocus />
                          </Flex>
                        )
                      }}
                    </Formik>
                  </StyledContainer>
                </Scrollbars>
                <BottomStepperWrapper>
                  <BottomStepper
                    total={STEP_COUNT}
                    step={step.toString()}
                    handleBack={handleClickStepBack}
                    handleSubmit={() => {
                      formRef.current.handleSubmit()
                    }}
                    isLoading={isWorkingWithData}
                    borderRadius={welcome ? `0 0 ${radius.xxl} ${radius.xxl}` : ''}
                  />
                </BottomStepperWrapper>
              </StyledDialogBodyWrapper>
            )}
            {step === 3 && (
              <Flex flexDirection="column" width="100%" height="100%" pb={STEPPER_HEIGHT}>
                {connectingToNetworkOptions.isConnecting ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                    flexDirection="column"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="50" />
                    <Text mt="m">Connecting to {connectingToNetworkOptions.network.display}...</Text>
                  </Flex>
                ) : (
                  <Fragment>
                    {!showNetworkProfileTypesStep ? (
                      <Scrollbars universal>
                        <StyledContainer pt="xl" maxWidth="850px" textAlign="center">
                          <Flex
                            mt="xs"
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection={{ mobile: 'column', tablet: 'row' }}
                          >
                            <StyledH5>
                              {isAdvocate
                                ? 'Please select a social network'
                                : 'Please select a social network or a platform'}
                            </StyledH5>

                            <Flex
                              flexDirection="column"
                              width={{ mobile: '100%', tablet: '200px' }}
                              mt={{ mobile: 'm', tablet: 0 }}
                            >
                              <Input
                                label=""
                                placeholder="Search..."
                                value={searchNetworksString}
                                onChange={(e) => {
                                  setSearchNetworksString(e.target.value)
                                }}
                                width="100%"
                                isSmall
                              />
                            </Flex>
                          </Flex>

                          <Flex my="l" flexDirection="column">
                            <StyledGrid
                              gridTemplateColumns={{
                                mobile: 'repeat(2, 1fr)',
                                tablet: 'repeat(4,1fr)',
                                desktop: 'repeat(5,1fr)',
                              }}
                              gridGap="m"
                              justifyItems="center"
                            >
                              {filteredNetworks.map(({ id, display, code, type, extra, isNew }) => {
                                const networkImage = getSocialNetworkIconByName(code).enabled
                                let isBlocked = false
                                if (type === REVIEW && !featuresEnabled[FEATURE_REVIEW_PROFILES].enabled) {
                                  isBlocked = true
                                } else if (type === WEB && !featuresEnabled[FEATURE_WEB_ANALYTICS].enabled) {
                                  isBlocked = true
                                }
                                return (
                                  <StyledNetworkWrapper
                                    key={id}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                    textAlign="center"
                                    width="100%"
                                    onClick={() => {
                                      if (type === 'WEB') {
                                        if (isBlocked) {
                                          Alert.error(featuresEnabled[FEATURE_WEB_ANALYTICS].description, {
                                            timeout: 10000,
                                          })
                                        } else {
                                          handleClickConnectSocialProfile({ code, id })
                                        }
                                      } else if (type === 'BLOG' || type === REVIEW) {
                                        if (isBlocked) {
                                          Alert.error(featuresEnabled[FEATURE_REVIEW_PROFILES].description, {
                                            timeout: 10000,
                                          })
                                        } else {
                                          handleClickOpenConnectNotSocialModal({ type, code, display })
                                        }
                                      }
                                      // else if (code === INSTAGRAM) {
                                      //   setSelectedProfileType({
                                      //     code,
                                      //     id,
                                      //     display,
                                      //     key: INSTAGRAM_ACCOUNT_OPTION_BUSINESS,
                                      //   })
                                      //   setShowNetworkProfileTypesStep({ code, id, display })
                                      // }
                                      else if (code === FACEBOOK && !isAdvocate) {
                                        setSelectedProfileType({
                                          code,
                                          id,
                                          display,
                                          key: FACEBOOK_ACCOUNT_OPTION_PAGE,
                                        })
                                        setShowNetworkProfileTypesStep({ code, id, display })
                                      } else {
                                        handleClickConnectSocialProfile({ code, id })
                                      }
                                    }}
                                  >
                                    {isBlocked && (
                                      <CustomizationsBlockComponent
                                        message={`Your plan does not offer connecting ${display} profiles.`}
                                        showMessage={false}
                                        top="12px"
                                        left="-8px"
                                        fontSize="xxxs"
                                      />
                                    )}
                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                      position="absolute"
                                      top="8px"
                                      left="8px"
                                    >
                                      <Image src={networkImage} width="18px" height="18px" />
                                    </Flex>

                                    {isNew && (
                                      <Flex top="8px" left="32px" position="absolute">
                                        <StyledSpecialLabelBadge>
                                          <Text fontSize="xxs" textAlign="center">
                                            New
                                          </Text>
                                        </StyledSpecialLabelBadge>
                                      </Flex>
                                    )}

                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                      position="absolute"
                                      top="8px"
                                      right="8px"
                                    >
                                      <Icon.Plus width="14px" height="14px" />
                                    </Flex>
                                    <Text fontSize="m" color="secondaryText">
                                      {display}
                                    </Text>
                                    {extra && (
                                      <Text fontSize="xs" color="secondaryText">
                                        {extra}
                                      </Text>
                                    )}
                                  </StyledNetworkWrapper>
                                )
                              })}
                            </StyledGrid>
                          </Flex>
                        </StyledContainer>
                      </Scrollbars>
                    ) : (
                      <Fragment>
                        {showNetworkProfileTypesStep && networkProfileTypeStep && (
                          <Scrollbars universal>
                            <Flex flexDirection="column" alignItems="center" px={{ mobile: 'm', tablet: 0 }}>
                              {networkProfileTypeStep.title}
                              <Grid
                                mt="l"
                                gridTemplateColumns={{
                                  mobile: '1fr',
                                  tablet: `repeat(${networkProfileTypeStep.types.length}, 1fr)`,
                                }}
                                gridGap="m"
                                mx="auto"
                                maxWidth="900px"
                                justifyItems="center"
                              >
                                {networkProfileTypeStep.types.map(
                                  ({ key, title, recommended, description, fields, special_code, state }) => {
                                    const isSelected = selectedNetworkProfileType
                                      ? selectedNetworkProfileType.key === key
                                      : recommended
                                    return (
                                      <ProfileTypeWrapper
                                        key={key}
                                        flexDirection="column"
                                        position="relative"
                                        p="l"
                                        isSelected={isSelected}
                                        onClick={() => {
                                          setSelectedProfileType({
                                            ...{ ...showNetworkProfileTypesStep, key, special_code, state },
                                          })
                                        }}
                                      >
                                        {recommended && (
                                          <StyledRecommendedWrapper position="absolute" right={space.m} top="-12px">
                                            <Text fontSize="xxs" color="white">
                                              RECOMMENDED
                                            </Text>
                                          </StyledRecommendedWrapper>
                                        )}
                                        <Flex flexDirection="column">
                                          <Text fontWeight="bold" fontSize="xxl" color="denimText" textAlign="left">
                                            {title}
                                          </Text>
                                          <Box
                                            mt="s"
                                            mb={{ mobile: 'm', desktop: '0' }}
                                            minHeight={{ mobile: 'auto', tablet: 'auto' }}
                                          >
                                            <Text color="secondaryText" textAlign="left">
                                              {description}
                                            </Text>
                                          </Box>
                                        </Flex>
                                        <Flex flexDirection="column" mt="m">
                                          {networkProfileTypeStep.fields.map(({ value, label, description }) =>
                                            description ? (
                                              <StyledFieldBox my="s" key={value}>
                                                <Tooltip
                                                  width="250px"
                                                  wrapperComp={
                                                    <Flex alignItems="center">
                                                      <Flex
                                                        width="20px"
                                                        height="20px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                      >
                                                        <Image
                                                          src={
                                                            fields[value]
                                                              ? '/assets/checkmark_circle_checked.svg'
                                                              : '/assets/clear_red.svg'
                                                          }
                                                          width={fields[value] ? '20px' : '14px'}
                                                          height={fields[value] ? '20px' : '14px'}
                                                          alt=""
                                                        />
                                                      </Flex>

                                                      <Text ml="s" fontSize="xs" color="denimText">
                                                        {label}
                                                      </Text>
                                                    </Flex>
                                                  }
                                                  message={description}
                                                  left="0"
                                                  isCursorPointer
                                                  isTriangleVisible={false}
                                                />
                                              </StyledFieldBox>
                                            ) : (
                                              <Flex key={value} alignItems="center" my="s">
                                                <Flex
                                                  width="20px"
                                                  height="20px"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Image
                                                    src={
                                                      fields[value]
                                                        ? '/assets/checkmark_circle_checked.svg'
                                                        : '/assets/clear_red.svg'
                                                    }
                                                    width={fields[value] ? '20px' : '14px'}
                                                    height={fields[value] ? '20px' : '14px'}
                                                    alt=""
                                                  />
                                                </Flex>

                                                <Text ml="s" fontSize="xs" color="denimText">
                                                  {label}
                                                </Text>
                                              </Flex>
                                            )
                                          )}
                                        </Flex>
                                      </ProfileTypeWrapper>
                                    )
                                  }
                                )}
                              </Grid>
                            </Flex>
                          </Scrollbars>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}

                <BottomStepperWrapper>
                  <BottomStepper
                    total={STEP_COUNT}
                    step={step.toString()}
                    handleBack={handleClickStepBack}
                    handleSubmit={
                      selectedNetworkProfileType
                        ? () => {
                            handleClickConnectSocialProfile(selectedNetworkProfileType)
                          }
                        : null
                    }
                    submitButtonText={`Connect ${networkPromptDisplay}`}
                    borderRadius={welcome ? `0 0 ${radius.xxl} ${radius.xxl}` : ''}
                  />
                </BottomStepperWrapper>
              </Flex>
            )}
          </StyledDialogBodyWrapper>

          {!welcome && (
            <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
              <Image width="10px" height="10px" src="/assets/clear.svg" />
            </CloseIconWrapper>
          )}
        </StyledDialogContent>
      </Box>
      <ConnectModal
        onDismiss={handleClickCloseConnectNotSocialModal}
        onSubmit={handleClickConnectBlogReview}
        isOpen={isConnectNotSocialModalOpen}
        isSubmitting={isBlogReviewSubmitting}
        {...blogReview}
      />

      {videoModalOptions.isOpen && (
        <VideoModal
          handleDismiss={handleClickCloseVideoModal}
          url={videoModalOptions.data.url}
          thumbnail_url={videoModalOptions.data.thumbnail_url}
          isOpen={videoModalOptions.isOpen}
        />
      )}

      {isConnectingFacebookGroupsModalOpen && (
        <ConnectingFacebookGroupsModal
          handleDismiss={() => {
            setConnectingToNetworkOptions({ ...{ isConnecting: false, network: null } })
            setIsConnectingFacebookGroupsModalOpen(false)
          }}
          isOpen={isConnectingFacebookGroupsModalOpen}
          handleAddProfile={() => {
            setIsConnectingFacebookGroupsModalOpen(false)
            handleClickCloseModal()

            document.location.reload()
          }}
          entityId={stepValues[ENTITY_GID] ? stepValues[ENTITY_GID].value : null}
        />
      )}

      {isConnectingBlueskyModalOpen && (
        <ConnectingBlueskyModal
          handleDismiss={() => {
            setConnectingToNetworkOptions({ ...{ isConnecting: false, network: null } })
            setIsConnectingBlueskyModalOpen(false)
          }}
          isOpen={isConnectingBlueskyModalOpen}
          handleAddProfile={() => {
            setIsConnectingBlueskyModalOpen(false)
            handleClickCloseModal()

            document.location.reload()
          }}
          entityId={stepValues[ENTITY_GID] ? stepValues[ENTITY_GID].value : null}
        />
      )}
    </StyledDialogOverlay>
  )
}

AddProfileModal.defaultProps = {
  selectedEntity: null,
  origin: 'profiles',
  welcome: null,
}

AddProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedEntity: PropTypes.object,
  origin: PropTypes.string,
  user: PropTypes.object.isRequired,
  welcome: PropTypes.object,
}

AddProfileModal.displayName = 'AddProfileModal'

export default AddProfileModal
