import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius, fontSizes } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import { VISTA_PAGE_BLOCK_TYPE_THEME } from 'routes/VistaPage/consts'

const StyledBlockTextWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Excerpt = styled(Flex)`
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledBlockDescriptionText = styled(Flex)`
  display: flex;
  p {
    padding: 0;
    margin: 0;
    font-size: ${fontSizes.s};
  }
`

const FormatSelectInternalBlockOption = ({ value, label, type }) => {
  const { icon, bg_color, title: blockTitle = '' } = VISTA_PAGE_BLOCK_TYPE_THEME[type]

  return (
    <Flex alignItems="center" width="100%" key={value}>
      <Flex mr="s" minWidth="24px" height="24px">
        <Flex
          width="100%"
          height="100%"
          bg={transparentize(0.9, bg_color)}
          alignItems="center"
          justifyContent="center"
          borderRadius={radius.l}
        >
          <Image src={icon} width="12px" height="12px" />
        </Flex>
      </Flex>
      <StyledBlockTextWrapper width={label ? `calc(100% - 24px)` : '100%'}>
        <Excerpt>
          <Text as="span" fontWeight="medium" fontSize="s" mr="s">
            {blockTitle}
          </Text>
          <StyledBlockDescriptionText
            as="span"
            fontSize="s"
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
        </Excerpt>
      </StyledBlockTextWrapper>
    </Flex>
  )
}

FormatSelectInternalBlockOption.defaultProps = {
  label: '',
}

FormatSelectInternalBlockOption.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
}

FormatSelectInternalBlockOption.displayName = 'FormatSelectInternalBlockOption'

export default FormatSelectInternalBlockOption
