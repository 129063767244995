import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import withConfirm from 'utils/withConfirm'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import { H4 } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 450px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const AddVariableModal = ({
  isOpen,
  handleDismiss,
  selectedVariable,
  isVariableModalSaving,
  isVariableModalDeleting,
  handleSubmitVariableModal,
  handleRemoveVariable,
  confirm,
  variables,
}) => {
  const [variable, setVariable] = useState({})
  const [errorLabel, setErrorLabel] = useState('')

  const { id, label, defaultValue } = variable

  useEffect(() => {
    if (isOpen) {
      if (selectedVariable) {
        setVariable({ ...selectedVariable })
      }
    }
  }, [isOpen])

  const handleClickCloseModal = () => {
    if (!isVariableModalDeleting && !isVariableModalSaving) {
      handleDismiss()
    }
  }

  const handleClickDelete = async () => {
    confirm({
      fn: () => () => handleRemoveVariable({ id }),
      message: `Are you sure you want to remove {{${label}}} profile variable?`,
      action: 'Remove',
    })
  }

  const handleClickSubmit = async () => {
    if (!isVariableModalSaving && !isVariableModalDeleting) {
      if (!label) {
        setErrorLabel('Profile variable is required')
      } else {
        const cleared_label = label.replace(/[^a-zA-Z0-9_]/g, '')

        if (cleared_label.length !== label.length) {
          setErrorLabel('Please use only alphanumeric characters (letters A-Z, numbers 0-9) and underscores')
        } else {
          variable.label = cleared_label

          const foundVariable = variables.find((variable) => variable.label === label)
          if (foundVariable && ((id && foundVariable.id !== id) || !id)) {
            setErrorLabel(`Profile variable ${label} already exists`)
          } else {
            setErrorLabel('')
            await handleSubmitVariableModal({ variable })
          }
        }
      }
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{id ? 'Edit custom field' : 'Create custom field'}</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" p="m">
            <Input
              label="Name"
              placeholder=""
              secondaryLabel="Only alphanumeric characters (letters A-Z, numbers 0-9) and underscores are allowed in."
              value={label || ''}
              onChange={(e) => {
                variable.label = e.target.value.replace(/{|}/g, '')
                setVariable({ ...variable })
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClickSubmit()
                }
              }}
              width="100%"
              error={errorLabel}
            />

            <Box mt="m">
              <Input
                label="Default value (will be applied to all profiles)"
                placeholder=""
                value={defaultValue || ''}
                onChange={(e) => {
                  variable.defaultValue = e.target.value
                  setVariable({ ...variable })
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleClickSubmit()
                  }
                }}
                width="100%"
              />
            </Box>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            {id ? (
              <ButtonWithLoading
                buttonComp={Button.Gray}
                isSmall
                onClick={handleClickDelete}
                isLoading={isVariableModalDeleting}
              >
                Delete
              </ButtonWithLoading>
            ) : (
              <Button.Gray isSmall onClick={handleClickCloseModal} mr="s" isDisabled={isVariableModalSaving}>
                Close
              </Button.Gray>
            )}
            <Flex alignItems="center">
              {id && (
                <Button.Gray isSmall onClick={handleClickCloseModal} mr="s" isDisabled={isVariableModalDeleting}>
                  Close
                </Button.Gray>
              )}
              <ButtonWithLoading
                isSmall
                onClick={handleClickSubmit}
                isLoading={isVariableModalSaving}
                isDisabled={isVariableModalSaving || isVariableModalDeleting}
              >
                Save
              </ButtonWithLoading>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper
            className="modal-close-icon"
            onClick={handleClickCloseModal}
            isDisabled={isVariableModalSaving || isVariableModalDeleting}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

AddVariableModal.defaultProps = {
  selectedVariable: null,
}

AddVariableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedVariable: PropTypes.object,
  isVariableModalSaving: PropTypes.bool.isRequired,
  isVariableModalDeleting: PropTypes.bool.isRequired,
  handleSubmitVariableModal: PropTypes.func.isRequired,
  handleRemoveVariable: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  variables: PropTypes.array.isRequired,
}

export default withConfirm(AddVariableModal)
