import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { display, space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { fontSizes, fontWeights, radius, space } from 'theme'
import { ADMIN, ERROR_MESSAGE, FEATURE_COMPLIANCE, RESTRICTED } from 'consts'
import { convertTextToHTML } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import Badge from 'components/atoms/Badge'
import Icon from 'components/atoms/Icon'
import DropdownMenu from 'components/molecules/DropdownMenu'
import ReadMoreModal from 'components/organisms/ReadMoreModal'
import ComplianceModal from 'routes/Settings/Entities/components/ComplianceModal'
import CustomizationsBlockComponent from '../../Posts/components/CustomizationsBlockComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    height: 600px;
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  outline: none;
  height: 100%;
  overflow: hidden;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const cellStyle = `
  padding-left: ${space.m};
  border-width: 1px 0;
`

const StyledTable = styled('table')`
  width: 100%;
  font-size: ${fontSizes.s};
  text-align: left;
`

const StyledPicture = styled(Image)`
  border-radius: ${radius.pill};
`

const Row = styled('tr')`
  height: 50px;
`

const HeaderCell = styled('th')`
  ${display};
  font-weight: ${fontWeights.medium};
  border: solid ${({ theme }) => theme.colors.border_color};
  ${cellStyle};
  white-space: nowrap;
`

const BodyCell = styled('td')`
  border: solid ${({ theme }) => theme.colors.border_color};
  ${display};
  ${cellStyle};
  & > * {
    vertical-align: middle;
  }
`

const StyledLink = styled(Flex)`
  text-decoration: none;
  cursor: pointer;
`

const StyledImage = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  outline: none;
`

const DEFAULT_COMPLIANCE_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const DEFAULT_READ_MORE_MODAL_OPTIONS = {
  isOpen: false,
  text: '',
}

const CompliancePreviewModal = ({ isOpen, handleDismiss, user, entityIds }) => {
  const [isGettingData, setIsGettingData] = useState(true)
  const [entities, setEntities] = useState([])
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_COMPLIANCE]: { enabled: false },
  })

  const [complianceModalOptions, setComplianceModalOptions] = useState({ ...DEFAULT_COMPLIANCE_MODAL_OPTIONS })
  const [readMoreModalOptions, setReadMoreModalOptions] = useState({ ...DEFAULT_READ_MORE_MODAL_OPTIONS })

  const getEntities = async () => {
    try {
      const response = await request({
        method: 'POST',
        path: `entities/compliance`,
        body: { entity_gids: entityIds },
      })

      const { error, data = [] } = response || {}

      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const { isManager, entities } = user

        data.forEach((entity) => {
          let role = RESTRICTED

          const foundEntity = entities.find((item) => item.id === entity.id)

          if (foundEntity) {
            ;({ entity_user: { role } = {} } = foundEntity)
          }

          entity.canManage = isManager || role === ADMIN
        })

        setEntities([...data])

        // if (data.length === 1) {
        //   const { canManage } = data[0]

        //   if (canManage) {
        //     // eslint-disable-next-line no-use-before-define
        //     handleClickOpenComplianceModal({ entity: data[0] })
        //   } else if (data[0]?.entity_compliance?.policy) {
        //     // eslint-disable-next-line no-use-before-define
        //     handleClickOpenReadMoreModal({ entity: data[0] })
        //   }

        // }
      }
    } catch (error) {
      errorHelper({ error, componentName: CompliancePreviewModal.displayName, functionName: 'getEntities' })
    } finally {
      setIsGettingData(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      const features = checkIfPlanHasFeatureEnabled({
        user,
        features: [FEATURE_COMPLIANCE],
      })

      setFeaturesEnabled({ ...features })

      if (features[FEATURE_COMPLIANCE].enabled) {
        getEntities()
      } else {
        setIsGettingData(false)
      }
    }
  }, [isOpen])

  const handleClickOpenComplianceModal = ({ entity: { id, entity_compliance } }) => {
    const { policy = '', active = false } = entity_compliance || {}

    setComplianceModalOptions({
      ...{ isOpen: true, data: { policy, active, entity_gid: id } },
    })
  }

  const handleClickCloseComplianceModal = (data) => {
    if (data) {
      const foundEntityIndex = entities.findIndex((entity) => entity.id === complianceModalOptions.data.entity_gid)

      if (foundEntityIndex > -1) {
        entities[foundEntityIndex].entity_compliance = {
          policy: data.policy,
          active: data.active,
        }

        setEntities([...entities])
      }
    }

    setComplianceModalOptions({ ...DEFAULT_COMPLIANCE_MODAL_OPTIONS })
  }

  const handleClickOpenReadMoreModal = ({ entity }) => {
    const { policy = '' } = entity.entity_compliance || {}

    setReadMoreModalOptions({ ...{ isOpen: true, text: convertTextToHTML({ text: policy }) } })
  }

  const handleClickCloseReadMoreModal = () => {
    setReadMoreModalOptions({ ...DEFAULT_READ_MORE_MODAL_OPTIONS })
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Brand Safety & Compliance Policies</H4>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper>
            <Scrollbars universal autoHide>
              <Flex width="100%" height="100%" flexDirection="column" position="relative">
                {isGettingData ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="50" />
                  </Flex>
                ) : (
                  <Flex flexDirection="column" width="100%" height="100%">
                    {featuresEnabled[FEATURE_COMPLIANCE] && !featuresEnabled[FEATURE_COMPLIANCE].enabled ? (
                      <Flex flexDirection="column" p="m" width="100%" height="100%">
                        <Flex flexDirection="column" position="relative" width="100%" height="100%">
                          <CustomizationsBlockComponent
                            showUpgradeMessage={false}
                            showUpgradePlan={false}
                            message={featuresEnabled[FEATURE_COMPLIANCE].description}
                          />
                        </Flex>
                      </Flex>
                    ) : (
                      <StyledDialogBodyWrapper flexDirection="column" width="100%">
                        <Scrollbars universal>
                          <StyledTable>
                            <thead>
                              <Row>
                                <HeaderCell>Name</HeaderCell>
                                <HeaderCell>Status</HeaderCell>
                                <HeaderCell />
                              </Row>
                            </thead>
                            <tbody>
                              {entities.map((entity) => {
                                const { id, name, entity_compliance, picture_url, canManage } = entity

                                const { policy = '', active = false } = entity_compliance || {}

                                let status_text = 'Active'
                                let status_color = 'background_badge_active'

                                if (!policy) {
                                  status_text = 'Not setup'
                                  status_color = 'background_badge_inactive'
                                } else if (!active) {
                                  status_text = 'Inactive'
                                  status_color = 'error'
                                }

                                return (
                                  <Row key={id}>
                                    <BodyCell>
                                      <StyledLink
                                        color="primary"
                                        alignItems="center"
                                        onClick={() => {
                                          if (canManage) {
                                            handleClickOpenComplianceModal({ entity })
                                          } else {
                                            handleClickOpenReadMoreModal({ entity })
                                          }
                                        }}
                                      >
                                        <StyledPicture
                                          source={picture_url || '/assets/company.svg'}
                                          height={{ mobile: '20px', tablet: '30px' }}
                                          width={{ mobile: '20px', tablet: '30px' }}
                                          objectFit={picture_url ? 'cover' : 'contain'}
                                        />
                                        <Text ml="s" as="span" fontWeight="normal">
                                          {name}
                                        </Text>
                                      </StyledLink>
                                    </BodyCell>
                                    <BodyCell>
                                      <Badge.Status color={status_color}>{status_text}</Badge.Status>
                                    </BodyCell>

                                    <BodyCell>
                                      <Flex justifyContent="flex-end" alignItems="center" mr="m">
                                        {(policy || canManage) && (
                                          <DropdownMenu
                                            WrapperComp={
                                              <StyledImage>
                                                <Icon.More stroke="icon_color_gray" />
                                              </StyledImage>
                                            }
                                          >
                                            {canManage && (
                                              <DropdownMenu.Item
                                                label="Edit"
                                                iconName="Edit"
                                                onClick={() => {
                                                  handleClickOpenComplianceModal({ entity })
                                                }}
                                                isCursorPointer
                                              />
                                            )}

                                            {policy && (
                                              <DropdownMenu.Item
                                                label="Read"
                                                iconName="OpenInNew"
                                                iconWidth="12px"
                                                iconColor="icon_color_gray"
                                                onClick={() => {
                                                  handleClickOpenReadMoreModal({ entity })
                                                }}
                                                isCursorPointer
                                              />
                                            )}
                                          </DropdownMenu>
                                        )}
                                      </Flex>
                                    </BodyCell>
                                  </Row>
                                )
                              })}
                            </tbody>
                          </StyledTable>
                        </Scrollbars>
                      </StyledDialogBodyWrapper>
                    )}
                  </Flex>
                )}
              </Flex>
            </Scrollbars>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal}>
              Close
            </Button.Gray>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {complianceModalOptions.isOpen && (
          <ComplianceModal
            isOpen={complianceModalOptions.isOpen}
            handleDismiss={handleClickCloseComplianceModal}
            data={complianceModalOptions.data}
          />
        )}

        {readMoreModalOptions.isOpen && (
          <ReadMoreModal
            isOpen={readMoreModalOptions.isOpen}
            handleDismiss={handleClickCloseReadMoreModal}
            text={readMoreModalOptions.text}
            title="Brand Safety & Compliance Policy"
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

CompliancePreviewModal.defaultProps = {}

CompliancePreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  entityIds: PropTypes.array.isRequired,
}

CompliancePreviewModal.displayName = 'CompliancePreviewModal'

export default CompliancePreviewModal
