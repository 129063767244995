import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { space } from 'theme'
import { ERROR_MESSAGE, FEATURE_PROFILE_VARIABLES } from 'consts'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'
import Button from 'components/atoms/Button'
import Link from 'components/atoms/Link'
import { ROUTE_VARIABLE } from 'routes/Calendar/consts'

const Wrapper = styled(Flex)`
  height: 260px;
  overflow: auto;
`

const StyledText = styled(Text)`
  height: 100%;
  width: 100%;
  line-height: 30px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background_item_hover};
  }
`

const StyledButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0 ${space.s};
`

const SearchInputComponent = ({ handleFilterVariablesBySearch }) => {
  const [searchString, setSearchString] = useState('')

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterVariablesBySearch(text)
  }

  return (
    <Input
      placeholder="Search custom fields"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      mr="m"
      height="30px"
    />
  )
}

SearchInputComponent.propTypes = {
  handleFilterVariablesBySearch: PropTypes.func.isRequired,
}

const VariableSelector = forwardRef(
  ({ user, handleClickAddVariableToPost, handleClickOpenVariablesModal, ...props }, variableSelectorRef) => {
    const [searchString, setSearchString] = useState('')
    const [filteredVariables, setFilteredVariables] = useState([])
    const [featuresEnabled, setFeaturesEnabled] = useState({
      [FEATURE_PROFILE_VARIABLES]: { enabled: true },
    })
    const [isGettingVariables, setIsGettingVariables] = useState(false)
    const [savedVariables, setSavedVariables] = useState([])

    useImperativeHandle(variableSelectorRef, () => ({
      getSavedVariables() {
        return props.savedVariables && props.savedVariables.length !== 0 ? props.savedVariables : savedVariables
      },
      setSavedVariables({ savedVariables }) {
        setSavedVariables([...savedVariables])
      },
    }))

    useEffect(() => {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_PROFILE_VARIABLES],
        }),
      })
    }, [])

    const filterVariablesBySearchString = () => {
      if (searchString) {
        setFilteredVariables(
          savedVariables.filter(({ label }) => label.toLowerCase().includes(searchString.toLowerCase()))
        )
      } else {
        setFilteredVariables([...savedVariables])
      }
    }

    useEffect(() => {
      filterVariablesBySearchString()
    }, [searchString])

    const getSavedVariables = async () => {
      try {
        setIsGettingVariables(true)
        const response = await request({
          path: ROUTE_VARIABLE,
        })
        const { error, list = [] } = response || {}
        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setSavedVariables(list)
        }
      } catch (error) {
        errorHelper({ error, componentName: VariableSelector.displayName, functionName: 'getSavedVariables' })
      } finally {
        setIsGettingVariables(false)
      }
    }

    useEffect(() => {
      if (props.savedVariables && props.savedVariables.length !== 0) {
        setSavedVariables([...props.savedVariables])
      } else {
        getSavedVariables()
      }
    }, [props.savedVariables])

    useEffect(() => {
      filterVariablesBySearchString()
    }, [savedVariables])

    const handleFilterVariablesBySearch = debounce((text) => {
      setSearchString(text)
    }, 300)

    return (
      <Wrapper flexDirection="column" width="100%" py="s">
        {!featuresEnabled[FEATURE_PROFILE_VARIABLES].enabled ? (
          <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Text textAlign="center">
              Please upgrade to use custom fields.{' '}
              <Link
                color="primary"
                href="https://support.vistasocial.com/hc/en-us/articles/14439524021531"
                target="_blank"
              >
                Learn more
              </Link>
            </Text>
          </Flex>
        ) : (
          <Flex flexDirection="column" height="100%">
            <Flex justifyContent="space-between" pr="m" alignItems="center" mb="xs">
              <Text color="secondaryText">Custom fields</Text>
              {handleClickOpenVariablesModal && (
                <StyledButton isSmall onClick={handleClickOpenVariablesModal}>
                  <Icon.Gear width="13px" />
                </StyledButton>
              )}
            </Flex>
            <SearchInputComponent handleFilterVariablesBySearch={handleFilterVariablesBySearch} />

            {isGettingVariables ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Fragment>
                {savedVariables.length > 0 ? (
                  <Flex flexDirection="column" height="100%">
                    <Scrollbars universal>
                      {filteredVariables.map((item) => (
                        <Flex mt="s" alignItems="center" width="100%" pr="m" key={item.id}>
                          <StyledText
                            onClick={() => {
                              handleClickAddVariableToPost({ message: `{{${item.label}}}` })
                            }}
                          >
                            <Text as="span" fontWeight="bold">
                              {item.label}
                            </Text>
                          </StyledText>
                        </Flex>
                      ))}
                    </Scrollbars>
                  </Flex>
                ) : (
                  <Flex py="m" alignItems="center" justifyContent="center" height="100%" pr="m">
                    <Text textAlign="center" color="secondaryText">
                      You have not created any custom fields yet. Click{' '}
                      <StyledText as="span" color="primary" onClick={handleClickOpenVariablesModal}>
                        here
                      </StyledText>{' '}
                      to create
                    </Text>
                  </Flex>
                )}
              </Fragment>
            )}
          </Flex>
        )}
      </Wrapper>
    )
  }
)

VariableSelector.defaultProps = {}

VariableSelector.propTypes = {
  user: PropTypes.object.isRequired,
  handleClickAddVariableToPost: PropTypes.func.isRequired,
  savedVariables: PropTypes.array.isRequired,
  handleClickOpenVariablesModal: PropTypes.func.isRequired,
}

VariableSelector.displayName = 'VariableSelector'

export default VariableSelector
