import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { SwipeableDrawer, Paper, Slide } from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import { ACCESS_NONE, ACCESS_VIEW, ACCESS_MANAGE } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Initials from 'components/atoms/Initials'
import Icon from 'components/atoms/Icon'
import SocialNetworksFilterTemplate from './SocialNetworksFilterTemplate'
import SocialNetworksFilterBodyTemplate from './SocialNetworksFilterBodyTemplate'
import EntitySelector from '../EntitySelector'
import SocialProfileImage from '../SocialProfileImage'
import { formatTimezoneToGMT } from '../../helpers'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'

const DEFAULT_MENU_WIDTH = '40px'
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const CollapsedHeaderWrapper = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.colors.border_color_light};
  background-color: ${({ theme }) => theme.colors.background_card};
`

const StyledArrowWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: ${radius.pill};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const VistaSocialDashboardArrowRight = styled(Icon.VistaSocialDashboardArrow)`
  transform: rotate(-180deg);
`

const TotalBadge = styled(Flex)`
  min-width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  margin-left: ${space.xs};
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const SocialNetworksFilterInline = styled(Flex)`
  padding: ${space.m};
  box-shadow: 0 4px 15px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  background-color: ${({ theme }) => theme.colors.background_card};
  flex-direction: column;
  border-radius: ${radius.l};
`

const StyledPaper = styled(Paper)`
  background-color: transparent !important;
`

const TRANSITION_TIMEOUT = 500

const BLOCKED_ENTITY_PROFILE_TYPES = ['LINK', 'BLOG']

const EntitySelectorWrapper = ({
  user,
  entitySelectorKey,
  selectedProfiles,
  selectedEntities,
  handleSaveSelectedEntities,
  handleSaveSelectedProfiles,
  canSelectLostAccessProfiles,
  blockedSocialProfileChecker,
  entities,
  handleSaveEmptyProfiles,
  accessLevels,
  permission,
}) => {
  entities.forEach((entity) => {
    entity.profiles = entity.profiles
      ? entity.profiles.filter(({ network: { type } }) => !BLOCKED_ENTITY_PROFILE_TYPES.includes(type))
      : []
  })

  const tooltipRef = useRef(null)
  const entitySelectorRef = useRef(null)

  const [isSelectorCollapsed, setIsSelectorCollapsed] = useState(false)
  const [openSwipeableDrawer, setOpenSwipeableDrawer] = useState(false)
  const [socialNetworkFilter, setSocialNetworkFilter] = useState({
    socialNetworkFilterName: '',
    socialNetworkFilterSelectedProfiles: [],
  })
  const [isSocialNetworkFilterBodyOpen, setIsSocialNetworkFilterBodyOpen] = useState(false)

  const sortedEntities = selectedEntities.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
  )

  const handleClickOpenSocialNetworksFilterBody = () => {
    setIsSocialNetworkFilterBodyOpen(!isSocialNetworkFilterBodyOpen)
  }

  const handleClickOpenSelector = () => {
    setIsSelectorCollapsed(true)
  }

  const handleSaveSelectedEntitiesTemp = ({ data }) => {
    handleSaveSelectedEntities({ data })
  }

  const handleSaveSelectedProfilesTemp = ({ data, removedSocialProfiles }) => {
    handleSaveSelectedProfiles({ data, removedSocialProfiles })
  }

  const collapsedEntitySelector = (
    <Flex flexDirection="column" height="100%" width="100%">
      <Scrollbars universal autoHide>
        {sortedEntities.map(({ name, id: entityId, profiles = [], type: entityType, timezone }) => {
          const selectedEntityProfilesLength = selectedProfiles
            ? selectedProfiles.filter((item) => item.entityId === entityId).length
            : 0
          return (
            <Flex py="s" flexDirection="column" key={entityId}>
              <Flex alignItems="center" justifyContent="center">
                <Flex alignItems="center" justifyContent="center">
                  <StyledFlex
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    onMouseEnter={(e) => {
                      tooltipRef.current.handleShowTooltip({
                        contentComp: (
                          <Flex
                            justifyContent="center"
                            flexDirection="column"
                            width="300px"
                            p="s"
                            bg="background_card"
                            borderRadius={radius.l}
                          >
                            <Text>Your profile group ({entityType.toLowerCase()})</Text>
                            <Text>{formatTimezoneToGMT(timezone).long}</Text>
                          </Flex>
                        ),
                        wrapperComp: e.currentTarget,
                      })
                    }}
                    onMouseLeave={() => {
                      tooltipRef.current.handleHideTooltip()
                    }}
                  >
                    <Initials
                      name={name}
                      height="32px"
                      width="32px"
                      isSmall
                      backgroundColor="background"
                      textColor="primaryText"
                    />
                    <TotalBadge alignItems="center" justifyContent="center" position="absolute" bottom="-7px" right="0">
                      <Text as="span" color="primary" fontSize="9px">
                        {selectedEntityProfilesLength}
                      </Text>
                    </TotalBadge>
                  </StyledFlex>
                </Flex>
              </Flex>
              {profiles.map(
                ({
                  id,
                  picture_url,
                  network: { code, display } = {},
                  profile_url,
                  name,
                  username,
                  status,
                  scope,
                  description,
                  profile_id,
                  type,
                }) => (
                  <Flex key={id} alignItems="center" justifyContent="center">
                    <Flex alignItems="center" justifyContent="center">
                      <StyledFlex
                        mt="s"
                        alignItems="center"
                        justifyContent="center"
                        display={
                          selectedProfiles &&
                          selectedProfiles.findIndex((value) => value.id === id && value.entityId === entityId) > -1
                            ? 'flex'
                            : 'none'
                        }
                        onMouseEnter={(e) => {
                          tooltipRef.current.handleShowTooltip({
                            contentComp: (
                              <ProfileItemTooltip
                                profile={{
                                  code,
                                  picture_url,
                                  profile_url,
                                  name,
                                  username,
                                  display,
                                  description,
                                  profile_id,
                                  type,
                                  scope,
                                }}
                                message={
                                  blockedSocialProfileChecker
                                    ? blockedSocialProfileChecker({
                                        profile: { network: { code, display }, profile_url, status, id },
                                      }).blockedProfileHoverText
                                    : ''
                                }
                              />
                            ),
                            wrapperComp: e.currentTarget,
                          })
                        }}
                        onMouseLeave={() => {
                          tooltipRef.current.handleHideTooltip()
                        }}
                      >
                        <SocialProfileImage picture_url={picture_url} code={code} width="20px" height="20px" />
                      </StyledFlex>
                    </Flex>
                  </Flex>
                )
              )}
            </Flex>
          )
        })}
      </Scrollbars>
    </Flex>
  )

  return (
    <Flex height="100%" position="relative" className="entity-selector">
      <Box height="100%" display={{ mobile: 'none', desktopWide: 'block' }}>
        <Slide
          direction="right"
          in={isSelectorCollapsed}
          timeout={{ enter: TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
          unmountOnExit
        >
          <StyledPaper elevation={0}>
            {isSelectorCollapsed && (
              <Flex width={DEFAULT_MENU_WIDTH} height="100%">
                <CollapsedHeaderWrapper
                  alignItems="center"
                  width={DEFAULT_MENU_WIDTH}
                  height="100%"
                  flexDirection="column"
                >
                  <Flex mt="m" alignItems="center" justifyContent="center">
                    <StyledArrowWrapper
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => {
                        setIsSelectorCollapsed(false)
                      }}
                    >
                      <VistaSocialDashboardArrowRight width="16px" height="16px" fill="primaryText" />
                    </StyledArrowWrapper>
                  </Flex>
                  <SocialNetworksFilterTemplate
                    selectedEntities={selectedEntities}
                    selectedProfiles={selectedProfiles}
                    socialNetworkFilter={socialNetworkFilter}
                    setSocialNetworkFilter={setSocialNetworkFilter}
                    showInline={false}
                    entitySelectorRef={entitySelectorRef}
                  />
                  {collapsedEntitySelector}
                </CollapsedHeaderWrapper>
              </Flex>
            )}
          </StyledPaper>
        </Slide>
        <Slide
          direction="right"
          in={!isSelectorCollapsed}
          timeout={{ enter: TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
          unmountOnExit
        >
          <Paper elevation={0}>
            <Flex pb="m" width="250px" bg="background_card" height="100%" position="relative" flexDirection="column">
              <Slide
                direction="down"
                in={isSocialNetworkFilterBodyOpen}
                timeout={{ enter: TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
                unmountOnExit
              >
                <StyledPaper elevation={1}>
                  {isSocialNetworkFilterBodyOpen && (
                    <SocialNetworksFilterInline>
                      <SocialNetworksFilterBodyTemplate
                        selectedEntities={selectedEntities}
                        selectedProfiles={selectedProfiles}
                        setSocialNetworkFilter={setSocialNetworkFilter}
                        socialNetworkFilter={socialNetworkFilter}
                        entitySelectorRef={entitySelectorRef}
                      />
                    </SocialNetworksFilterInline>
                  )}
                </StyledPaper>
              </Slide>

              <Flex flexDirection="column" pt="m" height="100%">
                <EntitySelector
                  ref={entitySelectorRef}
                  user={user}
                  id={`calendar-entity-selector-${entitySelectorKey}`}
                  key={entitySelectorKey}
                  entities={entities}
                  selectedProfiles={selectedProfiles}
                  selectedEntities={selectedEntities}
                  handleSaveSelectedEntities={handleSaveSelectedEntitiesTemp}
                  handleSaveSelectedProfiles={handleSaveSelectedProfilesTemp}
                  canSelectLostAccessProfiles={canSelectLostAccessProfiles}
                  blockedSocialProfileChecker={blockedSocialProfileChecker}
                  socialNetworkFilter={socialNetworkFilter}
                  handleSaveEmptyProfiles={handleSaveEmptyProfiles}
                  accessLevels={accessLevels}
                  permission={permission}
                  setSocialNetworkFilter={setSocialNetworkFilter}
                  handleClickOpenSocialNetworksFilterBody={handleClickOpenSocialNetworksFilterBody}
                  handleClickOpenSelector={handleClickOpenSelector}
                />
              </Flex>
            </Flex>
          </Paper>
        </Slide>
      </Box>
      <Box height="100%" display={{ mobile: 'block', desktopWide: 'none' }}>
        <CollapsedHeaderWrapper alignItems="center" width={DEFAULT_MENU_WIDTH} height="100%" flexDirection="column">
          <Flex mt="m" alignItems="center" justifyContent="center">
            <StyledArrowWrapper
              alignItems="center"
              justifyContent="center"
              onClick={() => setOpenSwipeableDrawer(true)}
            >
              <VistaSocialDashboardArrowRight width="16px" height="16px" fill="primaryText" />
            </StyledArrowWrapper>
          </Flex>
          <SocialNetworksFilterTemplate
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
            socialNetworkFilter={socialNetworkFilter}
            setSocialNetworkFilter={setSocialNetworkFilter}
            showInline={false}
            entitySelectorRef={entitySelectorRef}
          />
          {collapsedEntitySelector}
        </CollapsedHeaderWrapper>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          style={{ zIndex: 2147483001 }}
          anchor="left"
          open={openSwipeableDrawer}
          onClose={() => setOpenSwipeableDrawer(false)}
          onOpen={() => {}}
        >
          <Flex pb="m" minWidth="300px" bg="background_card" height="100%" width="100%" flexDirection="column">
            <Slide
              direction="down"
              in={isSocialNetworkFilterBodyOpen}
              timeout={{ enter: TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
              unmountOnExit
            >
              <StyledPaper elevation={1}>
                {isSocialNetworkFilterBodyOpen && (
                  <SocialNetworksFilterInline>
                    <SocialNetworksFilterBodyTemplate
                      selectedEntities={selectedEntities}
                      selectedProfiles={selectedProfiles}
                      setSocialNetworkFilter={setSocialNetworkFilter}
                      socialNetworkFilter={socialNetworkFilter}
                      entitySelectorRef={entitySelectorRef}
                    />
                  </SocialNetworksFilterInline>
                )}
              </StyledPaper>
            </Slide>
            <Flex flexDirection="column" pt="m" height="100%">
              <EntitySelector
                ref={entitySelectorRef}
                user={user}
                id={`calendar-entity-selector-swipeable-${entitySelectorKey}`}
                key={entitySelectorKey}
                entities={entities}
                selectedProfiles={selectedProfiles}
                selectedEntities={selectedEntities}
                handleSaveSelectedEntities={handleSaveSelectedEntitiesTemp}
                handleSaveSelectedProfiles={handleSaveSelectedProfilesTemp}
                canSelectLostAccessProfiles={canSelectLostAccessProfiles}
                blockedSocialProfileChecker={blockedSocialProfileChecker}
                socialNetworkFilter={socialNetworkFilter}
                handleSaveEmptyProfiles={handleSaveEmptyProfiles}
                accessLevels={accessLevels}
                permission={permission}
                setSocialNetworkFilter={setSocialNetworkFilter}
                handleClickOpenSocialNetworksFilterBody={handleClickOpenSocialNetworksFilterBody}
                handleClickOpenSelector={handleClickOpenSelector}
              />
            </Flex>
            <Flex width="0px" height="0px" onClick={() => setOpenSwipeableDrawer(false)} className="modal-close-icon" />
          </Flex>
        </SwipeableDrawer>
      </Box>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

EntitySelectorWrapper.defaultProps = {
  entities: [],
  selectedProfiles: null,
  selectedEntities: [],
  canSelectLostAccessProfiles: false,
  blockedSocialProfileChecker: null,
  entitySelectorKey: moment().valueOf(),
  handleSaveEmptyProfiles: null,
  accessLevels: [ACCESS_NONE, ACCESS_VIEW, ACCESS_MANAGE],
}

EntitySelectorWrapper.propTypes = {
  user: PropTypes.object.isRequired,
  entitySelectorKey: PropTypes.number,
  entities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  handleSaveSelectedEntities: PropTypes.func.isRequired,
  handleSaveSelectedProfiles: PropTypes.func.isRequired,
  canSelectLostAccessProfiles: PropTypes.bool,
  blockedSocialProfileChecker: PropTypes.func,
  handleSaveEmptyProfiles: PropTypes.func,
  accessLevels: PropTypes.array,
  permission: PropTypes.string.isRequired,
}

export default EntitySelectorWrapper
