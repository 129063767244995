import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { fontSizes, radius, space } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

const StyledPlayerWrapper = styled(Box)`
  border-radius: ${radius.l};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 56.25%;
  height: 0px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`

const StyledAccordion = styled(Accordion)`
  padding: 0;

  .MuiAccordionDetails-root {
    padding: 0;
    color: ${({ theme }) => theme.colors.primaryText};
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 ${space.m} !important;
  min-height: 48px !important;
  background-color: ${({ theme }) => theme.colors.background_item_light} !important;

  .MuiAccordionSummary-content {
    margin: ${space.m} 0 !important;
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background_card};
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledComponentWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_item_light};
`

const StyledList = styled.ol`
  margin: 0;
  padding: 0 ${space.xs};
  list-style-position: inside;
`

const StyledListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: ${fontSizes.xs};
`

const TIPS = [
  'On desktop, go to your YouTube channel',
  'Navigate to the "Uploads" section',
  'Right click on the "Play All" button and copy the link',
  'Paste the link in the "Video link" field on top',
]

const { LINK } = {
  LINK: 'link',
}

const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

const FormValidationSchema = Yup.object().shape({
  [LINK]: Yup.string()
    .matches(p, 'Please enter a correct YouTube URL')
    .required('YouTube URL is required'),
})

const matchYoutubeUrl = ({ link }) => {
  const matches = link.match(p)

  if (matches) {
    return `https://www.youtube.com/embed/${matches[1]}`
  }
  return ''
}

const YouTubeComponent = ({ data, isEditable, handleChangeBlockComponent }) => {
  const { page_id, _id: block_id, data: { link = '' } = {} } = data

  let link_updated = ''

  if (!isEditable) {
    link_updated = matchYoutubeUrl({ link })
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [LINK]: link,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Box>
                  <Input
                    placeholder="YouTube Video URL"
                    type="text"
                    defaultValue={link}
                    onChange={(event) => {
                      handleChangeBlockComponent({ page_id, block_id, key: LINK, value: event.target.value })
                      setFieldValue(LINK, event.target.value)
                    }}
                    error={values[LINK] && errors[LINK]}
                    width="100%"
                  />
                </Box>
              </Box>
            )}
          </Formik>

          <Flex flexDirection="column" width="100%" mt="m">
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={
                  <StyledArrowWrapper>
                    <Icon.VistaSocialChevronDown />
                  </StyledArrowWrapper>
                }
                aria-controls="youtube-prompt-content"
                id="youtube-prompt-header"
              >
                <Flex alignItems="center">
                  <Image source="/assets/magic_wand.svg" width="30px" height="30px" />
                  <Flex ml="xs" flexDirection="column">
                    <Text fontSize="xxs" color="secondaryText">
                      Pro Tip
                    </Text>
                    <Text fontSize="xs">Show your channel's latest video</Text>
                  </Flex>
                </Flex>
              </StyledAccordionSummary>
              <AccordionDetails>
                <StyledComponentWrapper px="m" pb="m">
                  <StyledList>
                    {TIPS.map((item, index) => (
                      <StyledListItem key={index}>
                        <Text fontSize="xs" as="span">
                          {item}
                        </Text>
                      </StyledListItem>
                    ))}
                  </StyledList>
                </StyledComponentWrapper>
              </AccordionDetails>
            </StyledAccordion>
          </Flex>
        </Flex>
      ) : (
        <StyledPlayerWrapper>
          {link_updated && (
            <iframe
              id="video-player"
              width="100%"
              src={link_updated}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          )}
        </StyledPlayerWrapper>
      )}
    </Fragment>
  )
}

YouTubeComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

YouTubeComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
}

YouTubeComponent.displayName = 'ButtonLinkComponent'

export default YouTubeComponent
