import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'theme'
import { FEATURE_SENTIMENT, SENTIMENT_ICONS_TEMPLATE } from 'consts'
import Image from 'components/atoms/Image'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'

const StyledImage = styled(Image)`
  cursor: pointer;
  margin-top: -${space.xxs};
`

const SentimentComponent = ({ featuresEnabled, sentiment: sentiment_object, width, height }) => {
  const tooltipRef = useRef(null)

  const { name: sentiment, reason } = sentiment_object || {}
  const isShown =
    sentiment &&
    sentiment !== 'none' &&
    featuresEnabled[FEATURE_SENTIMENT] &&
    featuresEnabled[FEATURE_SENTIMENT].enabled &&
    SENTIMENT_ICONS_TEMPLATE[sentiment]

  return (
    <Fragment>
      {isShown && (
        <Fragment>
          <Box>
            <Box
              onMouseEnter={(e) => {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Box>
                      <Text p="s">
                        {SENTIMENT_ICONS_TEMPLATE[sentiment].description} {reason ? `: ${reason}` : ''}
                      </Text>
                    </Box>
                  ),
                  wrapperComp: e.currentTarget,
                })
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
            >
              <StyledImage src={SENTIMENT_ICONS_TEMPLATE[sentiment].image} width={width} height={height} />
            </Box>
          </Box>

          <CalendarItemTooltip ref={tooltipRef} />
        </Fragment>
      )}
    </Fragment>
  )
}

SentimentComponent.defaultProps = {
  sentiment: null,
  width: '14px',
  height: '14px',
}

SentimentComponent.propTypes = {
  sentiment: PropTypes.object,
  featuresEnabled: PropTypes.object.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default SentimentComponent
