import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { fontSizes, radius, space } from 'theme'
import { IMAGE } from 'consts'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import Switch from 'components/atoms/Switch'
import TextArea from 'components/atoms/TextArea'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import { PALETTE_TYPE_COLOR } from 'routes/Calendar/consts'
import {
  HEADER_THEME,
  DEFAULT_THEME_SCALE,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  DEFAULT_HEADER_MEDIA_SCALE,
  VISTA_PAGE_FONT_POPPINS,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_FONT_STYLE,
  HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
  HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND,
} from 'routes/VistaPage/consts'
// eslint-disable-next-line import/no-cycle
import { getBackgroundStyleString, getTextFontStyleString, getTextFontWeightString } from 'routes/VistaPage/helper'
import ThemeComponent from 'routes/VistaPage/VistaPageDetail/components/ThemeComponent'
import MediaUploadComponent from '../MediaUploadComponent'

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`

const StyledTextArea = styled(TextArea)`
  margin-top: 0;
`

const StyledApplyDefaultThemeText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const PostMediaWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  flex-direction: column;
  text-align: center;
  ${({ $border, theme }) => $border && `border: 1px solid ${theme.colors.border_color_light};`}
`

const StyledTextAreaWrapper = styled(Flex)`
  flex-direction: column;
  font-size: ${({ $fontSize }) => $fontSize}em;
  width: 100%;
`

const StyledHeaderFormatWithBannerVerticalFullBackgroundWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: ${radius.l};
  padding: ${space.m};
  ${({ $backgroundMedia }) =>
    $backgroundMedia
      ? `background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) center center / cover no-repeat, url(${$backgroundMedia});`
      : ''};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const StyledHeaderFormatWithBannerVerticalMediumBackgroundMedia = styled(ImageWithFallback)`
  ${({ $hasBackground }) =>
    $hasBackground &&
    `border: 4px solid ${$hasBackground}; 
    background-color: ${$hasBackground};`}
  object-fit: cover;
  border-radius: ${radius.pill};
  width: 100%;
  height: 100%;
`

const StyledText = styled(Text)`
  ${({ $color }) => $color};
  ${({ $fontStyle }) => $fontStyle};
  ${({ $fontWeight }) => $fontWeight};
  width: 100%;
`

const StyledHeaderImageWithFallback = styled(ImageWithFallback)`
  object-fit: cover;
  border-radius: ${radius.pill};
  width: 100%;
  height: 100%;
`

const StyledImageWithFallback = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledUploadProgressIndicatorText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${fontSizes.xs};
  color: ${({ theme }) => theme.colors.primary};
`

const { HEADER_MEDIAS, TEXT, ALT_TEXT, DESCRIPTION, DETAILED_APPEARANCE_THEME, HEADER_BACKGROUND_MEDIAS } = {
  HEADER_MEDIAS: 'header_medias',
  TEXT: 'text',
  ALT_TEXT: 'alt_text',
  DESCRIPTION: 'description',
  DETAILED_APPEARANCE_THEME: 'detailed_appearance_theme',
  HEADER_BACKGROUND_MEDIAS: 'background_medias',
}

const AVATAR_THUMBNAIL = '/assets/avatar.svg'
const LANDSCAPE_THUMBNAIL = '/assets/landscape.svg'

const FormValidationSchema = Yup.object().shape({
  [TEXT]: Yup.string().max(1000, `Text is too long - should be 1000 chars maximum.`),
  [ALT_TEXT]: Yup.string().max(300, `Alt text is too long - should be 300 chars maximum.`),
  [DESCRIPTION]: Yup.string().max(3000, `Description is too long - should be 3000 chars maximum.`),
})

const HeaderComponent = ({ user, data, vistaPage, isEditable, handleChangeBlockComponent, isSmallPreview }) => {
  const {
    page_id,
    _id: block_id,
    data: {
      header_medias = [],
      text = '',
      description = '',
      alt_text = '',
      background_medias = [],
      detailed_appearance_theme = false,
    } = {},
  } = data

  const { 0: header_media } = header_medias
  const { url, thumbnail_url = AVATAR_THUMBNAIL, isNew, type = IMAGE, tempId } = header_media || {}
  const { 0: background_media } = background_medias
  const {
    url: background_media_url,
    thumbnail_url: background_media_thumbnail_url = LANDSCAPE_THUMBNAIL,
    isNew: background_media_isNew,
    type: background_media_type = IMAGE,
    tempId: background_media_tempId,
  } = background_media || {}

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT } = vistaPage

  const { font } = appearance || {}
  const { header: headerFont } = font || {}

  const appearance_theme = appearance[HEADER_THEME] || {}

  const theme = data.data ? data.data[HEADER_THEME] || appearance_theme : appearance_theme

  const has_theme_changed = !!(data.data && data.data[HEADER_THEME])

  const {
    text_scale = appearance_theme.scale || DEFAULT_THEME_SCALE,
    text_color = appearance_theme.text_color,
    text_font_weight = appearance_theme.text_font_weight || DEFAULT_THEME_TEXT_FONT_WEIGHT,
    text_font_style = appearance_theme.text_font_style || DEFAULT_THEME_TEXT_FONT_STYLE,

    header_format = appearance_theme.header_format || HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
  } = theme || {}

  let { header_media_scale = appearance_theme.header_media_scale || DEFAULT_HEADER_MEDIA_SCALE } = theme || {}

  if (isSmallPreview) {
    header_media_scale /= 2
  }

  const { background } = appearance

  const { background_type, background_color } = background || {}

  const { color } = text_color || {}
  const text_font_color = getBackgroundStyleString({ background_color: color, type: 'text' })
  const text_font_style_string = getTextFontStyleString({ style: text_font_style })
  const text_font_weight_string = getTextFontWeightString({ weight: text_font_weight })

  const font_family_string = `${headerFont || VISTA_PAGE_FONT_POPPINS}, sans-serif}`

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Flex justifyContent="space-between" alignItems="flex-start">
            {header_media ? (
              <PostMediaWrapper width="80px" height="80px" $border>
                <CloseIconWrapper
                  onClick={() => {
                    handleChangeBlockComponent({ page_id, block_id, key: HEADER_MEDIAS, value: [] })
                  }}
                >
                  <Image width="10px" height="10px" src="/assets/clear.svg" />
                </CloseIconWrapper>
                {isNew ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="80px"
                    height="80px"
                    position="relative"
                    className="cliploader-wrapper"
                  >
                    <ClipLoader size="40" />
                    <StyledUploadProgressIndicatorText id={`media-upload-progress-${tempId}`} />
                  </Flex>
                ) : (
                  <Fragment>
                    {type === IMAGE && url && (
                      <ImageWithFallback
                        source={thumbnail_url || url}
                        fallbackSource={url}
                        width="100%"
                        height="100%"
                        borderRadius={radius.l}
                        objectFit="contain"
                      />
                    )}
                  </Fragment>
                )}
              </PostMediaWrapper>
            ) : (
              <Flex />
            )}

            <MediaUploadComponent
              user={user}
              vistaPage={vistaPage}
              handleChangeBlockComponent={handleChangeBlockComponent}
              data={{ page_id, block_id, medias: header_medias, medias_storage_key: HEADER_MEDIAS }}
              maxAttachmentsAllowed={1}
            />
          </Flex>

          <Fragment>
            <Formik
              initialValues={{
                [TEXT]: text,
                [ALT_TEXT]: alt_text,
                [DESCRIPTION]: description,
              }}
              validationSchema={FormValidationSchema}
              onSubmit={() => {}}
              autocomplete="off"
              validateOnBlur
            >
              {({ values, errors, setFieldValue }) => {
                return (
                  <Box width="100%">
                    {header_media && (
                      <Box mt="m">
                        <Input
                          placeholder="Alt text"
                          label="Alt text"
                          type="text"
                          defaultValue={alt_text}
                          onChange={(event) => {
                            handleChangeBlockComponent({ page_id, block_id, key: ALT_TEXT, value: event.target.value })
                            setFieldValue(ALT_TEXT, event.target.value)
                          }}
                          error={values[ALT_TEXT] && errors[ALT_TEXT]}
                          width="100%"
                        />
                      </Box>
                    )}

                    <Box mt="m">
                      <Text mb="xs" fontSize="s">
                        Your business or personal name
                      </Text>
                      <StyledTextArea
                        rows={3}
                        placeholder="Your business or personal name"
                        defaultValue={text}
                        onChange={(event) => {
                          handleChangeBlockComponent({ page_id, block_id, key: TEXT, value: event.target.value })
                          setFieldValue(TEXT, event.target.value)
                        }}
                        error={values[TEXT] && errors[TEXT]}
                        width="100%"
                      />
                    </Box>

                    <Box mt="m">
                      <Text mb="xs" fontSize="s">
                        A few words about you or your business
                      </Text>
                      <StyledTextArea
                        rows={3}
                        placeholder="A few words about you or your business"
                        defaultValue={description}
                        onChange={(event) => {
                          handleChangeBlockComponent({ page_id, block_id, key: DESCRIPTION, value: event.target.value })
                          setFieldValue(DESCRIPTION, event.target.value)
                        }}
                        error={values[DESCRIPTION] && errors[DESCRIPTION]}
                        width="100%"
                      />
                    </Box>
                  </Box>
                )
              }}
            </Formik>

            <Flex flexDirection="column" position="relative">
              {has_theme_changed && (
                <Box position="absolute" top={pxToRem(18)} right="0">
                  <StyledApplyDefaultThemeText
                    onClick={() => {
                      handleChangeBlockComponent({ page_id, block_id, key: HEADER_THEME, value: null })
                    }}
                  >
                    Apply default page theme
                  </StyledApplyDefaultThemeText>
                </Box>
              )}
              <ThemeComponent
                handleChangeParameter={({ key, value }) => {
                  handleChangeBlockComponent({ page_id, block_id, type: HEADER_THEME, key, value })
                }}
                data={theme}
                showBackgroundColorSettings={false}
                showBackgroundColorSettingsPaletteTypeMedia={false}
                showContentWidthSettings={false}
                showCornerRoundnessSettings={false}
                showTransparencySettings={false}
                showSocialLinksColoredSettings={false}
                showShadowSettings={false}
                showTextColorSettings={false}
                showTextFontStyleSettings={false}
                showTextFontWeightSettings={false}
                showTextScaleSettings={false}
                showImageLinkImageScaleSettings={false}
                showSocialLinksImagesScaleSettings={false}
              />

              {(header_format === HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND ||
                header_format === HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND) && (
                <Flex mt="m" justifyContent="space-between" alignItems="flex-start">
                  {background_media ? (
                    <PostMediaWrapper width="80px" height="80px" $border>
                      <CloseIconWrapper
                        onClick={() => {
                          handleChangeBlockComponent({
                            page_id,
                            block_id,
                            key: HEADER_BACKGROUND_MEDIAS,
                            value: [],
                          })
                        }}
                      >
                        <Image width="10px" height="10px" src="/assets/clear.svg" />
                      </CloseIconWrapper>
                      {background_media_isNew ? (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          width="80px"
                          height="80px"
                          position="relative"
                          className="cliploader-wrapper"
                        >
                          <ClipLoader size="40" />
                          <StyledUploadProgressIndicatorText id={`media-upload-progress-${background_media_tempId}`} />
                        </Flex>
                      ) : (
                        <Fragment>
                          {background_media_type === IMAGE && background_media_url && (
                            <ImageWithFallback
                              source={background_media_thumbnail_url || background_media_url}
                              fallbackSource={background_media_url}
                              width="100%"
                              height="100%"
                              borderRadius={radius.l}
                              objectFit="contain"
                            />
                          )}
                        </Fragment>
                      )}
                    </PostMediaWrapper>
                  ) : (
                    <Flex />
                  )}

                  <MediaUploadComponent
                    user={user}
                    vistaPage={vistaPage}
                    handleChangeBlockComponent={handleChangeBlockComponent}
                    data={{
                      page_id,
                      block_id,
                      medias: background_medias,
                      medias_storage_key: HEADER_BACKGROUND_MEDIAS,
                    }}
                    maxAttachmentsAllowed={1}
                    showOnTop
                  />
                </Flex>
              )}
            </Flex>

            <Flex mt="l" alignItems="center" justifyContent="space-between">
              <Switch
                isOn={detailed_appearance_theme}
                onClick={() => {
                  handleChangeBlockComponent({
                    page_id,
                    block_id,
                    key: DETAILED_APPEARANCE_THEME,
                    value: !detailed_appearance_theme,
                  })
                }}
                text="Edit theme"
                fontWeight="normal"
              />

              {detailed_appearance_theme && has_theme_changed && (
                <StyledApplyDefaultThemeText
                  onClick={() => {
                    handleChangeBlockComponent({ page_id, block_id, key: HEADER_THEME, value: null })
                  }}
                >
                  Apply default page theme
                </StyledApplyDefaultThemeText>
              )}
            </Flex>

            {detailed_appearance_theme && (
              <Flex flexDirection="column">
                <ThemeComponent
                  handleChangeParameter={({ key, value }) => {
                    handleChangeBlockComponent({ page_id, block_id, type: HEADER_THEME, key, value })
                  }}
                  data={theme}
                  showHeaderFormatSettings={false}
                  showHeaderMediasScaleSettings={false}
                  showBackgroundColorSettings={false}
                  showBackgroundColorSettingsPaletteTypeMedia={false}
                  showContentWidthSettings={false}
                  showCornerRoundnessSettings={false}
                  showTransparencySettings={false}
                  showSocialLinksColoredSettings={false}
                  showShadowSettings={false}
                  showImageLinkImageScaleSettings={false}
                  showSocialLinksImagesScaleSettings={false}
                />
              </Flex>
            )}
          </Fragment>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" width="100%" flexDirection="column">
          {header_format === HEADER_FORMAT_WITHOUT_BANNER_VERTICAL && (
            <Flex flexDirection="column" width="100%" alignItems="center">
              {header_media && (
                <Flex
                  minWidth={`${66 * header_media_scale}px`}
                  maxWidth={`${66 * header_media_scale}px`}
                  height={`${66 * header_media_scale}px`}
                  minHeight={`${66 * header_media_scale}px`}
                  mb={text || description ? 0 : 'm'}
                >
                  {isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === IMAGE && url && (
                        <StyledHeaderImageWithFallback
                          source={url}
                          fallbackSource={AVATAR_THUMBNAIL}
                          alt={alt_text}
                          priority
                          loading="lazy"
                          preload="image"
                        />
                      )}
                    </Fragment>
                  )}
                </Flex>
              )}

              {(text || description) && (
                <StyledTextAreaWrapper $fontSize={text_scale} alignItems="center">
                  {text && (
                    <StyledText
                      as="h1"
                      fontSize={`${1.33}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                      mt="m"
                    >
                      {text}
                    </StyledText>
                  )}
                  {description && (
                    <StyledText
                      as="h2"
                      fontSize={`${0.95}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                      mt="s"
                    >
                      {description}
                    </StyledText>
                  )}
                </StyledTextAreaWrapper>
              )}
            </Flex>
          )}

          {header_format === HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL && (
            <Flex flexDirection="row" width="100%" alignItems="center">
              {header_media && (
                <Flex
                  minWidth={`${53 * header_media_scale}px`}
                  maxWidth={`${53 * header_media_scale}px`}
                  height={`${53 * header_media_scale}px`}
                  minHeight={`${53 * header_media_scale}px`}
                >
                  {isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="40" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === IMAGE && url && (
                        <StyledHeaderImageWithFallback
                          source={url}
                          fallbackSource={AVATAR_THUMBNAIL}
                          alt={alt_text}
                          priority
                          loading="lazy"
                          preload="image"
                        />
                      )}
                    </Fragment>
                  )}
                </Flex>
              )}

              {(text || description) && (
                <StyledTextAreaWrapper $fontSize={text_scale} ml="m">
                  {text && (
                    <StyledText
                      as="h1"
                      fontWeight="bold"
                      fontSize={`${1.33}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="left"
                    >
                      {text}
                    </StyledText>
                  )}
                  {description && (
                    <StyledText
                      mt={text ? 's' : 0}
                      as="h2"
                      fontSize={`${0.95}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="left"
                    >
                      {description}
                    </StyledText>
                  )}
                </StyledTextAreaWrapper>
              )}
            </Flex>
          )}

          {header_format === HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND && (
            <StyledHeaderFormatWithBannerVerticalFullBackgroundWrapper
              $backgroundMedia={
                background_media && !background_media.isNew && background_media.url ? background_media.url : ''
              }
              height={isSmallPreview ? '265px' : '530px'}
            >
              {header_media && (
                <Flex
                  minWidth={`${66 * header_media_scale}px`}
                  maxWidth={`${66 * header_media_scale}px`}
                  height={`${66 * header_media_scale}px`}
                  minHeight={`${66 * header_media_scale}px`}
                >
                  {isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === IMAGE && url && (
                        <StyledHeaderImageWithFallback
                          source={url}
                          fallbackSource={AVATAR_THUMBNAIL}
                          alt={alt_text}
                          priority
                          loading="lazy"
                          preload="image"
                        />
                      )}
                    </Fragment>
                  )}
                </Flex>
              )}

              {(text || description) && (
                <StyledTextAreaWrapper $fontSize={text_scale} alignItems="center">
                  {text && (
                    <StyledText
                      as="h1"
                      fontWeight="extraBold"
                      fontSize={`${2.66}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                      my="m"
                      py="xs"
                    >
                      {text}
                    </StyledText>
                  )}
                  {description && (
                    <StyledText
                      as="h2"
                      fontWeight="extraBold"
                      fontSize={`${1.33}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                    >
                      {description}
                    </StyledText>
                  )}
                </StyledTextAreaWrapper>
              )}
            </StyledHeaderFormatWithBannerVerticalFullBackgroundWrapper>
          )}

          {header_format === HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND && (
            <Flex flexDirection="column" width="100%" alignItems="center">
              {background_media && (
                <Flex
                  height={isSmallPreview ? '100px' : '200px'}
                  width="100%"
                  borderRadius={radius.m}
                  alignItems="center"
                  justifyContent="center"
                >
                  {background_media_isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {background_media_type === IMAGE && background_media_url && (
                        <StyledImageWithFallback
                          source={background_media_url}
                          fallbackSource={LANDSCAPE_THUMBNAIL}
                          width="100%"
                          height={isSmallPreview ? '100px' : '200px'}
                          borderRadius={radius.m}
                          alt={alt_text}
                          priority
                          loading="lazy"
                          preload="image"
                        />
                      )}
                    </Fragment>
                  )}
                </Flex>
              )}

              {header_media && (
                <Flex
                  minWidth={`${66 * header_media_scale}px`}
                  maxWidth={`${66 * header_media_scale}px`}
                  height={`${66 * header_media_scale}px`}
                  minHeight={`${66 * header_media_scale}px`}
                  mt={background_media && !background_media.isNew ? `-${(66 / 2) * header_media_scale}px` : 0}
                  mb={text || description ? 0 : 'm'}
                >
                  {isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === IMAGE && url && (
                        <StyledHeaderFormatWithBannerVerticalMediumBackgroundMedia
                          source={url}
                          fallbackSource={AVATAR_THUMBNAIL}
                          alt={alt_text}
                          $hasBackground={background_type === PALETTE_TYPE_COLOR ? background_color : ''}
                          priority
                          loading="lazy"
                          preload="image"
                        />
                      )}
                    </Fragment>
                  )}
                </Flex>
              )}

              {(text || description) && (
                <StyledTextAreaWrapper $fontSize={text_scale} alignItems="center">
                  {text && (
                    <StyledText
                      as="h1"
                      fontWeight="bold"
                      fontSize={`${1.33}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                      mt="m"
                    >
                      {text}
                    </StyledText>
                  )}
                  {description && (
                    <StyledText
                      as="h2"
                      fontSize={`${0.95}em`}
                      $color={text_font_color}
                      $fontWeight={text_font_weight_string}
                      $fontStyle={text_font_style_string}
                      fontFamily={font_family_string}
                      textAlign="center"
                      mt="s"
                    >
                      {description}
                    </StyledText>
                  )}
                </StyledTextAreaWrapper>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Fragment>
  )
}

HeaderComponent.defaultProps = {
  user: {},
  isEditable: false,
  handleChangeBlockComponent: () => {},
  isSmallPreview: false,
}

HeaderComponent.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  isSmallPreview: PropTypes.bool,
}

HeaderComponent.displayName = 'ImageLinkComponent'

export default HeaderComponent
