import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const BarWrapper = styled(Flex)`
  user-select: none;
  width: 100%;
  align-items: center;
`

const StyledProgressBar = styled(Flex)`
  flex: 1;
  border-radius: ${radius.l};
  margin: 0 ${space.s};
  height: ${({ isSmall }) => (isSmall ? '10px' : '6px')};
  align-items: center;
  cursor: pointer;
  background-image: ${({ theme }) => `linear-gradient(
    to right,
    ${theme.colors.primaryText} ${({ curPercentage }) => curPercentage}%,
    ${transparentize(0.8, theme.colors.primaryText)} 0
  )`};
`

const secondsToTime = (secs) => {
  return `${Math.floor(secs / 60)}:${`0${Math.floor(secs % 60)}`.slice(-2)}`
}

const StyledProgressBarKnob = styled(Text)`
  position: relative;
  height: ${({ isSmall }) => (isSmall ? '16px' : '12px')};
  width: ${({ isSmall }) => (isSmall ? '16px' : '12px')};
  border: ${space.xxs} solid ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.pill};
  background-color: ${({ theme }) => theme.colors.primaryText};
  left: ${({ curPercentage }) => curPercentage - 1}%;
`

const Bar = ({ duration, curTime, onTimeUpdate, trackId, isSmall }) => {
  const curPercentage = duration ? (curTime / duration) * 100 : 0

  const barId = `${trackId}-bar-progress`

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX
    const bar = document.getElementById(barId)
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e))

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  // eslint-disable-next-line no-restricted-globals
  const currentTimePlayed = !isNaN(curTime) ? secondsToTime(+curTime) : 0

  const leftTimeToPlay =
    // eslint-disable-next-line no-restricted-globals,no-nested-ternary
    curTime && currentTimePlayed !== 0 ? currentTimePlayed : !isNaN(duration) ? secondsToTime(+duration) : 0

  return (
    <BarWrapper>
      <StyledProgressBar
        curPercentage={curPercentage}
        onMouseDown={(e) => handleTimeDrag(e)}
        id={barId}
        isSmall={isSmall}
      >
        <StyledProgressBarKnob
          as="span"
          curPercentage={curPercentage}
          style={{ left: `${curPercentage - 1}%` }}
          isSmall={isSmall}
        />
      </StyledProgressBar>
      <Flex minWidth={isSmall ? '30px' : 'auto'} alignItems="center" ml="s" mt="1px" justifyContent="flex-end">
        <Text as="span" color="primaryText" fontSize={isSmall ? 's' : 'xxs'}>
          {leftTimeToPlay}
        </Text>
      </Flex>
    </BarWrapper>
  )
}

Bar.defaultProps = {
  duration: 0,
  curTime: 0,
}

Bar.propTypes = {
  duration: PropTypes.number,
  curTime: PropTypes.number,
  onTimeUpdate: PropTypes.func.isRequired,
  trackId: PropTypes.string.isRequired,
  isSmall: PropTypes.bool.isRequired,
}

export default Bar
