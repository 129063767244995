import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { SwipeableDrawer } from '@material-ui/core'
import SliderCommentsBody from './SliderCommentsBody'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const SliderCommentsPreview = ({
  user,
  isOpen,
  data,
  handleDismiss,
  commentCallback,
  showProfileGroupSelector,
  isSmallPreview,
  ...props
}) => {
  const commentsBodyRef = useRef(null)

  const handleClickCloseDrawer = () => {
    let commentsCounter = 0

    if (commentsBodyRef && commentsBodyRef.current) {
      commentsCounter = commentsBodyRef.current.getCommentsCounter()
    }

    handleDismiss({ commentsCounter, data })
  }

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      style={{ zIndex: 2147483001 }}
      anchor="right"
      open={!!isOpen}
      onClose={() => {
        handleClickCloseDrawer()
      }}
      disableAutoFocus
      disableEnforceFocus
      onOpen={() => {}}
    >
      {isOpen && (
        <SliderCommentsBody
          ref={commentsBodyRef}
          handleDismiss={handleDismiss}
          user={user}
          data={data}
          sharedCalendarData={props.sharedCalendarData}
          commentCallback={commentCallback}
          showProfileGroupSelector={showProfileGroupSelector}
          isSmallPreview={isSmallPreview}
        />
      )}
    </SwipeableDrawer>
  )
}

SliderCommentsPreview.defaultProps = {
  data: {},
  sharedCalendarData: null,
  commentCallback: () => {},
  showProfileGroupSelector: false,
  isSmallPreview: false,
}

SliderCommentsPreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  data: PropTypes.object,
  user: PropTypes.object.isRequired,
  sharedCalendarData: PropTypes.object,
  commentCallback: PropTypes.func,
  showProfileGroupSelector: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
}

export default SliderCommentsPreview
