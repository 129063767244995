import { AUDIO, IMAGE, VIDEO, DOCUMENT, MEDIA_TYPE_USED, MEDIA_TYPE_UNUSED } from 'consts'

export const DEFAULT_FILTERS = {
  selectedTypes: [
    { value: IMAGE, label: 'Images' },
    { value: VIDEO, label: 'Videos' },
    { value: AUDIO, label: 'Audios' },
    { value: DOCUMENT, label: 'Documents' },
  ],
  selectedLabels: [],
  selectedExtensions: [],
  selectedUsers: [],
  selectedUsage: [],
}

export const DEFAULT_MEDIA_USAGE_TYPES = [
  { value: MEDIA_TYPE_USED, label: 'Used media' },
  { value: MEDIA_TYPE_UNUSED, label: 'Unused media' },
]
