import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_SEARCH_PROMPT_TEXT } from 'consts'
import { pxToRem } from 'helpers'
import { Box } from 'components/atoms/Layout'
import DropDown from 'shared/DropDown'
import { PROFILE, ACCOUNT, FUNDING_INSTRUMENT, LOCATIONS, LANGUAGES, INTERESTS, INCLUDE_AUDIENCES } from '../consts'

const DEFAULT_HEIGHT = pxToRem(40)

const TwitterConfiguration = ({
  values,
  setFieldValue,
  errors,
  touched,
  formDataProfile,
  loadSuggestedOptions,
  showFundingInstrumentSelector,
  showOtherSelectors,
}) => {
  let funding_instruments = []
  let audiences = []

  if (values[ACCOUNT]) {
    funding_instruments = formDataProfile[values[ACCOUNT].value]
      ? formDataProfile[values[ACCOUNT].value].funding_instruments || []
      : []

    audiences = formDataProfile[values[ACCOUNT].value] ? formDataProfile[values[ACCOUNT].value].audiences || [] : []
  }

  return (
    <Fragment>
      {showFundingInstrumentSelector && (
        <Box mt="m">
          <DropDown
            id={FUNDING_INSTRUMENT}
            label="Funding instrument"
            placeholder="Select funding instrument"
            value={values[FUNDING_INSTRUMENT]}
            onChange={(options) => {
              setFieldValue(FUNDING_INSTRUMENT, options || [])
            }}
            options={funding_instruments}
            openMenuOnFocus
            isMulti={false}
            height={DEFAULT_HEIGHT}
            error={errors[FUNDING_INSTRUMENT] && touched[FUNDING_INSTRUMENT] && errors[FUNDING_INSTRUMENT]}
          />
        </Box>
      )}

      {showOtherSelectors && (
        <Fragment>
          <Box mt="m">
            <DropDown
              label="Included audiences"
              placeholder="Select audiences"
              value={values[INCLUDE_AUDIENCES]}
              onChange={(options) => {
                setFieldValue(INCLUDE_AUDIENCES, options || [])
              }}
              options={audiences}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[INCLUDE_AUDIENCES] && touched[INCLUDE_AUDIENCES] && errors[INCLUDE_AUDIENCES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Locations"
              placeholder="Search for locations"
              value={values[LOCATIONS]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(LOCATIONS, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[PROFILE].value,
                    method: 'searchLocations',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              // defaultOptions={formDataProfile[LOCATIONS] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              error={errors[LOCATIONS] && touched[LOCATIONS] && errors[LOCATIONS]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Languages"
              placeholder="Search for languages"
              value={values[LANGUAGES]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(LANGUAGES, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[PROFILE].value,
                    method: 'searchLanguages',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              //  defaultOptions={formDataProfile[LANGUAGES] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              menuPlacement="top"
              error={errors[LANGUAGES] && touched[LANGUAGES] && errors[LANGUAGES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Interests"
              placeholder="Search for interests"
              value={values[INTERESTS]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(INTERESTS, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[PROFILE].value,
                    method: 'searchInterests',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              //  defaultOptions={formDataProfile[LANGUAGES] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              menuPlacement="top"
              error={errors[INTERESTS] && touched[INTERESTS] && errors[INTERESTS]}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  )
}

TwitterConfiguration.defaultProps = {
  formDataProfile: {},
  showFundingInstrumentSelector: true,
  showOtherSelectors: true,
}

TwitterConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  formDataProfile: PropTypes.object,
  loadSuggestedOptions: PropTypes.func.isRequired,
  showFundingInstrumentSelector: PropTypes.bool,
  showOtherSelectors: PropTypes.bool,
}

TwitterConfiguration.displayName = 'TwitterConfiguration'

export default TwitterConfiguration
