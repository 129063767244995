import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { radius } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Input from 'components/atoms/Input'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 700px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 330px;
    top: 40%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const { NAME, URL } = { NAME: 'name', URL: 'url' }

const p = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/groups\/(\d+)(?:\/)?$/

const ConnectingFacebookGroupsModal = ({ isOpen, handleDismiss, handleAddProfile, entityId }) => {
  const formRef = useRef(null)
  const [isConnectingGroup, setIsConnectingGroup] = useState(false)

  const handleSubmitForm = async (values) => {
    setIsConnectingGroup(true)
    try {
      const body = {
        [NAME]: values[NAME],
        [URL]: values[URL],
        entity_gid: entityId,
      }

      const response = await request({
        method: 'POST',
        body,
        path: `facebook/connectGroup`,
      })
      const { error } = response || {}

      console.log('group connected', response)
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setIsConnectingGroup(false)
      } else {
        Alert.success('Facebook group has been connected.', { timeout: 5000 })
        handleAddProfile()
      }
    } catch (error) {
      errorHelper({ error, componentName: ConnectingFacebookGroupsModal.displayName, functionName: 'handleSubmitForm' })
      setIsConnectingGroup(false)
    }
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const createTaskValidationSchema = () => {
    return Yup.object().shape({
      [NAME]: Yup.string()
        .max(50, 'Profile name is too long - should be 50 chars maximum.')
        .required(`Profile name is required`),
      [URL]: Yup.string()
        .matches(p, 'Please enter a correct Facebook group URL')
        .required('Facebook group URL is required'),
    })
  }

  const handleClickCloseModal = () => {
    if (!isConnectingGroup) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Connect Facebook group</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" mt="m" px="m" flex="1">
            <Text mb="xs" fontSize="s">
              Direct publishing to Facebook Groups is no longer supported by Meta. Vista Social seamlessly facilitates
              scheduling your posts with mobile-based notifications.
            </Text>
            <Flex flexDirection="column" width="100%">
              <Formik
                ref={formRef}
                initialValues={{
                  [NAME]: '',
                  [URL]: '',
                }}
                validationSchema={createTaskValidationSchema}
                onSubmit={handleSubmitForm}
                autocomplete="off"
              >
                {({ values, touched, errors, setFieldValue }) => (
                  <Flex width="100%" height="100%" mt="m" flexDirection="column">
                    <Box>
                      <Input
                        label="Name"
                        placeholder="Facebook group name"
                        value={values[NAME]}
                        name={[NAME]}
                        onChange={(e) => {
                          const { value } = e.target
                          setFieldValue(NAME, value)
                        }}
                        error={errors[NAME] && touched[NAME] && errors[NAME]}
                        width="100%"
                      />
                    </Box>
                    <Box my="m">
                      <Input
                        label="Facebook group URL"
                        placeholder="Looks like this: https://facebook.com/groups/123456789"
                        value={values[URL]}
                        name={[URL]}
                        onChange={(e) => {
                          const { value } = e.target
                          setFieldValue(URL, value)
                        }}
                        error={errors[URL] && touched[URL] && errors[URL]}
                        width="100%"
                      />
                    </Box>
                  </Flex>
                )}
              </Formik>
            </Flex>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isConnectingGroup}>
              Cancel
            </Button.Gray>
            <ButtonWithLoading isSmall onClick={handleSubmitFormFromButton} isLoading={isConnectingGroup} type="submit">
              {isConnectingGroup ? 'Connecting' : 'Connect'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ConnectingFacebookGroupsModal.defaultProps = {}

ConnectingFacebookGroupsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleAddProfile: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
}

ConnectingFacebookGroupsModal.displayName = 'ConnectingFacebookGroupsModal'

export default ConnectingFacebookGroupsModal
