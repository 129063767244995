import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { DEFAULT_TIME_ZONE } from 'consts'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Switch from 'components/atoms/Switch'
import Radio from 'components/atoms/Radio'
import Input from 'components/atoms/Input'
import DropDown from 'shared/DropDown'
import { DATE_FORMAT } from 'routes/Calendar/consts'
import { DELAY_OPTIONS } from 'routes/Settings/PublishingSettings/BoostConfigurationSettings/BoostConfigurationModal/consts'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import DatePicker from '../../PublishModal/components/DatePicker'

const StyledAdvocacyWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: ${space.m};
  background: ${({ theme }) => theme.colors.background_item_light};
  border-radius: ${radius.l};
`

const InfoIcon = styled(Icon.Info)`
  cursor: pointer;
`

const StyledAdvocacyHeaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: ${space.s};
  border-radius: ${radius.l} ${radius.l} 0 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.background};
`

const { SCHEDULE_PICK_TIME, SCHEDULE_DRAFT, SCHEDULE_DELAY, SCHEDULE_DELAY_NOW } = {
  SCHEDULE_PICK_TIME: 'pick_time',
  SCHEDULE_DRAFT: 'draft',
  SCHEDULE_DELAY: 'delay',
  SCHEDULE_DELAY_NOW: 'delay_now',
}

const POST_SCHEDULE_FROM_ADVOCACY = [
  { name: 'Scheduled time', id: SCHEDULE_PICK_TIME },
  { name: 'Save draft', id: SCHEDULE_DRAFT },
]

const POST_SCHEDULE_FROM_OTHER_PLACES = [
  { name: 'As soon as post published.', id: SCHEDULE_DELAY_NOW },
  { name: 'With delay once published.', id: SCHEDULE_DELAY },
  { name: 'Create advocacy draft once published.', id: SCHEDULE_DRAFT },
]

const DEFAULT_DELAY = { value: 1, type: 'HOURS' }
const DEFAULT_DELAY_NOW = { value: 0, type: 'MINUTES' }

const AdvocacyComponent = ({ advocacy, isAdvocacyOnly, postDispatch, isAdvocate, selectedEntities }) => {
  const [isInitial, setIsInitial] = useState(true)

  const { enabled, delay, whenToPublish, publish_at } = advocacy || {}

  let selectedTimezone = DEFAULT_TIME_ZONE

  if (selectedEntities.length !== 0 && selectedEntities[0].timezone) {
    selectedTimezone = selectedEntities[0].timezone
  }

  const handleSwitchAdvocacy = () => {
    if (isAdvocacyOnly) {
      Alert.error('This post is being created for advocacy.', { timeout: 5000 })
    } else {
      const advocacy_temp = advocacy || {}

      advocacy_temp.enabled = !enabled
      postDispatch({ type: 'advocacy', data: { advocacy: advocacy_temp } })
    }
  }

  const generateTimeData = () => {
    const now = publish_at
      ? moment.tz(publish_at, true, selectedTimezone)
      : moment
          .tz(moment(), true, selectedTimezone)
          .clone()
          .add(5, 'minutes')

    const date = now.format(DATE_FORMAT)
    const hour = now.format('hh')
    const minute = now.format('mm')
    const interval = now.format('A')

    return {
      date,
      hour,
      minute,
      interval,
      date_string: `${date} ${hour}:${minute} ${interval}`,
    }
  }

  const handleClickSwitchSchedule = ({ id }) => {
    const advocacy_temp = advocacy || {}

    if (id === SCHEDULE_DELAY) {
      // delay is only shown and used when SMM post is created

      delete advocacy_temp.draft

      if (!advocacy_temp.delay || whenToPublish === SCHEDULE_DELAY_NOW) {
        advocacy_temp.delay = { ...DEFAULT_DELAY }
      }
    } else if (id === SCHEDULE_DELAY_NOW) {
      // delay is only shown and used when SMM post is created

      delete advocacy_temp.draft

      if (!advocacy_temp.delay || (advocacy_temp.delay.value !== 0 && advocacy_temp.delay.type !== 'MINUTES')) {
        advocacy_temp.delay = { ...DEFAULT_DELAY_NOW }
      }
    } else if (id === SCHEDULE_DRAFT) {
      // draft can be saved when SMM or Advocacy posts are being created

      delete advocacy_temp.delay
      delete advocacy_temp.publish_at

      advocacy_temp.draft = true
    } else if (SCHEDULE_PICK_TIME) {
      // pick time is shown only when Advocacy post is being created

      delete advocacy_temp.draft

      advocacy_temp.publish_at = generateTimeData()
    }

    advocacy_temp.whenToPublish = id

    postDispatch({ type: 'advocacy', data: { advocacy: advocacy_temp } })
  }

  useEffect(() => {
    if (isInitial && (advocacy || isAdvocacyOnly)) {
      if (advocacy) {
        if (advocacy.draft) {
          handleClickSwitchSchedule({ id: SCHEDULE_DRAFT })
        } else if (advocacy.publish_at) {
          handleClickSwitchSchedule({ id: SCHEDULE_PICK_TIME })
        } else if (!isAdvocacyOnly) {
          handleClickSwitchSchedule({ id: SCHEDULE_DELAY_NOW })
        } else {
          handleClickSwitchSchedule({ id: SCHEDULE_PICK_TIME })
        }
      } else if (isAdvocacyOnly) {
        handleClickSwitchSchedule({ id: SCHEDULE_PICK_TIME })
      }

      setIsInitial(false)
    }
  }, [advocacy, isAdvocacyOnly])

  const ADVOCACY_SCHEDULE_OPTIONS = isAdvocacyOnly ? POST_SCHEDULE_FROM_ADVOCACY : POST_SCHEDULE_FROM_OTHER_PLACES

  return (
    <Fragment>
      {!isAdvocate && (
        <StyledAdvocacyWrapper>
          <StyledAdvocacyHeaderWrapper>
            <Flex flexDirection="row">
              <Icon.VistaSocialAdvocacy width="18px" height="18px" fill="primaryText" />
              <Text ml="s">Add to advocacy</Text>
            </Flex>

            <Flex flexDirection="row">
              <Switch isOn={!!(isAdvocacyOnly || enabled)} onClick={handleSwitchAdvocacy} text="" />
              <Tooltip
                onClick={() => {
                  window.open(
                    'https://support.vistasocial.com/hc/en-us/articles/28180775280155-What-is-employee-advocacy',
                    '_blank'
                  )
                }}
                wrapperComp={
                  <Flex alignItems="center" ml="xs">
                    <InfoIcon width="18px" height="18px" />
                  </Flex>
                }
                message="An employee advocacy program is a way for employees to promote a company's products, services, or brand on their social media networks. Click to learn more."
                width="285px"
                isTriangleVisible={false}
                left="unset"
                right="0"
                defaultTopOffset={10}
              />
            </Flex>
          </StyledAdvocacyHeaderWrapper>

          {(enabled || isAdvocacyOnly) && (
            <Flex flexDirection="column" px="s" pb="s">
              {ADVOCACY_SCHEDULE_OPTIONS.map(({ name, id }) => {
                const isChecked = id === whenToPublish

                return (
                  <Flex flexDirection="column" key={id} mt="m">
                    <Flex alignItems="center" mr="m">
                      <Radio
                        id={id}
                        name="advocacyPublishSettings"
                        onChange={({ target: { id } }) => {
                          handleClickSwitchSchedule({ id })
                        }}
                        value={whenToPublish}
                        checked={isChecked}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor={id}>
                        <Text ml="m" pl="xs" fontSize="xs">
                          {name}
                        </Text>
                      </label>
                    </Flex>

                    {isChecked && (
                      <Fragment>
                        {id === SCHEDULE_PICK_TIME && (
                          <Flex mt="m" flexDirection="column">
                            <DatePicker
                              publishTimeData={publish_at}
                              handleChangeDate={({ publishTimeData }) => {
                                advocacy.publish_at = publishTimeData

                                postDispatch({ type: 'advocacy', data: { advocacy } })
                              }}
                              combined
                              timezone={selectedTimezone}
                            />
                          </Flex>
                        )}

                        {id === SCHEDULE_DELAY && (
                          <Flex flexDirection="column" mt="m">
                            <Flex alignItems="center">
                              <Flex width="30%">
                                <Input
                                  placeholder="Pick a number"
                                  value={delay.value}
                                  onChange={(e) => {
                                    const { target: { value } = {} } = e

                                    advocacy.delay.value = value

                                    postDispatch({ type: 'advocacy', data: { advocacy } })
                                  }}
                                  width="100%"
                                  type="number"
                                />
                              </Flex>
                              <Flex width="70%" flexDirection="column" ml="m">
                                <DropDown
                                  placeholder="Please select a delay option"
                                  value={
                                    delay.type
                                      ? DELAY_OPTIONS.find((option) => option.value === delay.type)
                                      : DELAY_OPTIONS[0]
                                  }
                                  onChange={(option) => {
                                    advocacy.delay.type = option.value

                                    postDispatch({ type: 'advocacy', data: { advocacy } })
                                  }}
                                  options={DELAY_OPTIONS}
                                  openMenuOnFocus
                                  menuPlacement="top"
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        )}
                      </Fragment>
                    )}
                  </Flex>
                )
              })}
            </Flex>
          )}
        </StyledAdvocacyWrapper>
      )}
    </Fragment>
  )
}

AdvocacyComponent.defaultProps = {
  advocacy: null,
  isAdvocacyOnly: false,
  selectedEntities: [],
}

AdvocacyComponent.propTypes = {
  advocacy: PropTypes.object,
  isAdvocacyOnly: PropTypes.bool,
  postDispatch: PropTypes.func.isRequired,
  isAdvocate: PropTypes.bool.isRequired,
  selectedEntities: PropTypes.array,
}

export default AdvocacyComponent
