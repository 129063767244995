import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Divider from 'components/atoms/Divider'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'

const Title = ({ title, icon, linkComp, isDividerHidden, iconName, iconColor, iconStroke, iconWidth, iconHeight }) => {
  const IconComp = iconName ? Icon[iconName] : null
  const StyledIconComp =
    iconName &&
    styled(IconComp)`
      max-width: 34px;
      width: ${iconWidth || 'auto'};
      height: ${iconHeight || 'auto'};
      ${iconColor && `fill:${iconColor};`}
      ${iconStroke && `stroke:${iconStroke} !important;`}
    `

  return (
    <React.Fragment>
      <Flex my="s" justifyContent="space-between">
        <Flex alignItems="center">
          {iconName && (
            <Flex alignItems="center" justifyContent="center" mr="l">
              <StyledIconComp />
            </Flex>
          )}
          {icon && <Image source={icon} mr="l" />}
          <Text fontWeight="bold">{title}</Text>
        </Flex>
        {linkComp && <Flex alignItems="center">{linkComp}</Flex>}
      </Flex>
      {!isDividerHidden && <Divider mt="m" />}
    </React.Fragment>
  )
}

Title.defaultProps = {
  isDividerHidden: false,
  icon: '',
  iconName: '',
  iconColor: null,
  iconStroke: null,
  iconWidth: null,
  iconHeight: null,
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  linkComp: PropTypes.any,
  isDividerHidden: PropTypes.bool,
  iconName: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconColor: PropTypes.string,
  iconStroke: PropTypes.string,
}

export default Title
