import { sleep } from 'helpers'

export const generateWriter = async ({ text, setWriter, isNew }) => {
  const lines = text.split('\n\n')

  const transformData = []
  let lineCounter = 0
  for (const line of lines) {
    lineCounter++
    if (lineCounter > 1 && lineCounter !== lines.length) {
      transformData.push(`<br /><br />`)

      if (setWriter) {
        setWriter([...transformData])
      }

      if (isNew) {
        await sleep(50)
      }
    }

    const words = line.split(' ')

    let wordsCounter = 0

    for (const word of words) {
      if (wordsCounter > 0 && wordsCounter !== words.length) {
        transformData.push(`<span> </span>`)
      }
      transformData.push(`<span>${word}</span>`)

      if (setWriter) {
        setWriter([...transformData])
      }

      if (isNew) {
        await sleep(50)
      }
      wordsCounter++
    }
  }

  return transformData
}
