import request from './request'
import errorHelper from './errorHelper'
import { PERMISSION_ERRORS, PERMISSION_ERROR, PERMISSION_PROFILES, PLAN_FREE, PLAN_FEATURES } from '../consts'

export const checkFeature = ({ subscription, feature, default_when_missing_subscription = false }) => {
  if (!subscription) {
    return default_when_missing_subscription
  }

  const { plan } = subscription || {}

  const { features = [] } = plan || {}

  return features.find((item) => item.name === feature) !== undefined
}

export const checkIfNeedToShowStepHelper = async ({ step_helper, setIsHelperEnabled }) => {
  let isOpen = false
  try {
    const response = await request({
      path: `/me/intro`,
    })
    const { error } = response || {}
    if (response && !error) {
      if (!response[step_helper]) {
        setIsHelperEnabled(true)
        isOpen = true
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
    errorHelper({
      error,
      componentName: 'utils/feature',
      functionName: 'checkIfNeedToShowStepHelper',
      showAlert: false,
    })
  }
  return isOpen
}

export const handleChangeUserIntroFeature = async ({ step_helper, value }) => {
  try {
    await request({
      method: 'POST',
      path: `/me/intro`,
      body: { feature: step_helper, value },
    })
    // eslint-disable-next-line no-empty
  } catch (error) {
    errorHelper({
      error,
      componentName: 'utils/feature',
      functionName: 'handleChangeUserIntroFeature',
      showAlert: false,
    })
  }
}

export const hasGlobalAccessView = ({ user, permission }) => {
  const { permissions } = user || {}
  const { [permission]: foundPermission } = permissions || {}
  const { canView = false } = foundPermission || {}

  return canView
}

export const hasGlobalAccessManage = ({ user, permission }) => {
  const { permissions } = user || {}
  const { [permission]: foundPermission } = permissions || {}
  const { canManage = false } = foundPermission || {}
  return canManage
}

export const hasGlobalAccessManageTasks = ({ user, permissions }) => {
  if (user.assigned_tasks > 0) {
    return true
  } else {
    if (!user.permissions) {
      console.error('authenticated user is missing permisison information')
      return false
    }

    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i]

      const { canManage } = user.permissions[permission] || {}

      if (canManage) {
        return true
      }
    }
    return false
  }
}

export const hasEntitiesAccess = ({ user, permission, entity_gid, accessLevels }) => {
  if (permission) {
    const { entities = [] } = user
    const foundEntity = entities.find(({ id }) => id === entity_gid)

    if (foundEntity) {
      const {
        entity_user: { [permission]: entity_user_permission },
      } = foundEntity
      if (!accessLevels.includes(entity_user_permission)) {
        return PERMISSION_ERRORS[permission].ACCESS[entity_user_permission]
      }
      return false
    }
  }

  return false
}

export const hasProfileAccessManage = ({ user, permission, selectedEntities, profile_gid, accessLevels }) => {
  const { entities = [] } = user

  let selectedEntityHasAccessManage = false

  for (let i = 0; i < selectedEntities.length; i++) {
    const { profiles, id } = selectedEntities[i]
    const foundProfile = profiles.find((profile) => profile.id === profile_gid)
    if (foundProfile) {
      const foundEntity = entities.find((entity) => entity.id === id)

      if (foundEntity) {
        const {
          entity_user: { [permission]: entity_user_permission },
        } = foundEntity
        if (accessLevels.includes(entity_user_permission)) {
          selectedEntityHasAccessManage = true
        }
      }
    }
    if (selectedEntityHasAccessManage) {
      break
    }
  }
  return selectedEntityHasAccessManage
}

export const hasSelectedEntitiesAccessManage = ({ user, permission, selectedEntities, accessLevels }) => {
  if (selectedEntities.length === 0) {
    return false
  }

  const { entities = [] } = user

  const entitiesHaveAccessManage = []

  selectedEntities.forEach((entity) => {
    const foundEntity = entities.find(({ id }) => id === entity.id)
    if (foundEntity) {
      const {
        entity_user: { [permission]: entity_user_permission },
      } = foundEntity
      if (accessLevels.includes(entity_user_permission)) {
        entitiesHaveAccessManage.push(entity.id)
      }
    }
  })
  return entitiesHaveAccessManage.length === selectedEntities.length
}

// is not uses on the ui, the same function is on the backend in access.doesNotHaveManageAccessToMultipleProfiles
export const hasMultipleProfilesAccessManageError = ({ user, permission, selectedProfiles, accessLevels }) => {
  const { entities = [] } = user
  console.log(selectedProfiles)

  const profilesWithoutPermissions = []

  selectedProfiles.forEach(({ id, entityId, name, username }) => {
    const foundEntity = entities.find((entity) => entity.id === entityId)
    if (foundEntity) {
      const {
        entity_user: { [permission]: entity_user_permission },
      } = foundEntity
      if (!accessLevels.includes(entity_user_permission)) {
        profilesWithoutPermissions.push({ id, entityId, name, username })
      }
    } else {
      profilesWithoutPermissions.push({ id, entityId, name, username })
    }
  })

  if (profilesWithoutPermissions.length === 0) {
    return false
  } else {
    return `${PERMISSION_ERRORS[PERMISSION_PROFILES].NO_ACCESS_MULTIPLE_PROFILES} ${profilesWithoutPermissions
      .map(({ name, username }) => name || username)
      .join(',')}. ${PERMISSION_ERROR}`
  }
}

export const checkIfPlanHasFeatureEnabled = ({ user, features = [] }) => {
  const { entity } = user || {}
  const { subscription } = entity || {}
  const { plan, features: subscriptionFeatures = [] } = subscription || {}
  const { features: planFeatures = [] } = plan || {}

  const updatedFeatures = {}

  features.forEach((feature) => {
    updatedFeatures[feature] = {
      enabled: false,
      description: `Your plan doesn't support this feature. Please upgrade or contact support.`,
    }
    if (!(subscriptionFeatures || []).find(({ name }) => name === feature)) {
      if (!(planFeatures || []).find(({ name }) => name === feature)) {
        updatedFeatures[feature].description = PLAN_FEATURES[feature]
      } else {
        updatedFeatures[feature] = { enabled: true }
      }
    } else {
      updatedFeatures[feature] = { enabled: true }
    }
  })

  return updatedFeatures
}
