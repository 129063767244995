import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { radius, COLOR_CONSTANTS } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'

const Wrapper = styled(Flex)`
  user-select: none;
  position: relative;
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor:not-allowed; opacity:0.6;`}
`

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  position: absolute;
  border: ${({ isChecked, theme }) =>
    isChecked ? `1px solid ${theme.colors.primaryText}` : `1px solid ${COLOR_CONSTANTS.COTTON}`};
  border-radius: ${radius.s};
  background-color: ${({ theme }) => theme.colors.checkbox_background};
`

const Checkbox = ({ isChecked, onClick, children, disabled = false }) => {
  return (
    <Wrapper alignItems="center" onClick={onClick} disabled={disabled}>
      <StyledFlex>{isChecked && <Icon.Checkmark fill="checkbox_color" width="8px" height="8px" />}</StyledFlex>
      {children}
    </Wrapper>
  )
}

Checkbox.defaultProps = {
  disabled: false,
  onClick: () => {},
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export default Checkbox
