import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { Box } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import ButtonControl from './ButtonControl'

const Button = styled(ButtonControl)`
  position: absolute;
  left: ${({ position }) => (position === 'left' ? 0 : 'unset')};
  right: ${({ position }) => (position === 'right' ? 0 : 'unset')};
  top: 50%;
  transform: translate(0, -50%);
`

const VistaSocialDashboardArrowRight = styled(Icon.VistaSocialDashboardArrow)`
  transform: rotate(-180deg);
`

const ArrowButton = ({ position, onClick }) => {
  return (
    <Button position={position} type="button" onClick={onClick}>
      {position === 'left' && (
        <Box ml="s">
          <Icon.VistaSocialDashboardArrow width="35px" height="35px" fill={COLOR_CONSTANTS.GLACIER_PEARL} />
        </Box>
      )}
      {position === 'right' && (
        <Box mr="s">
          <VistaSocialDashboardArrowRight width="35px" height="35px" fill={COLOR_CONSTANTS.GLACIER_PEARL} />
        </Box>
      )}
    </Button>
  )
}

ArrowButton.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ArrowButton
