import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius } from 'theme'
import { DEFAULT_SEARCH_PROMPT_TEXT, DEFAULT_SOCIAL_PROFILE_SOURCE } from 'consts'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import DropDown from 'shared/DropDown'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import {
  PROFILE,
  ACCOUNT,
  IDENTITY,
  OBJECTIVE,
  LOCATIONS,
  LANGUAGES,
  AGES,
  INCLUDE_AUDIENCES,
  EXCLUDE_AUDIENCES,
  INTEREST_CATEGORIES,
} from '../consts'

const ProfileImage = styled(ImageWithFallback)`
  border-radius: ${radius.m};
  vertical-align: inherit;
  width: 24px;
  height: 24px;
`

const DEFAULT_HEIGHT = pxToRem(40)

const formatObjectiveOptions = ({ value, label, description }) => (
  <Flex flexDirection="column" key={value}>
    <Text>{label}</Text>
    <Text fontSize="xs" color="secondaryText">
      {description}
    </Text>
  </Flex>
)

const formatIdentityOptions = ({ picture_url, value, label }) => (
  <Flex alignItems="center" key={value}>
    <ProfileImage
      source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
      fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
    />
    <Flex ml="s" flexDirection="column">
      <Text>{label}</Text>
    </Flex>
  </Flex>
)

const TikTokConfiguration = ({
  values,
  setFieldValue,
  errors,
  touched,
  formDataProfile,
  loadSuggestedOptions,
  showIdentitySelector,
  showOtherSelectors,
}) => {
  let identities = []
  let audiences = []
  let interests = []

  if (values[ACCOUNT]) {
    identities = formDataProfile[values[ACCOUNT].profile_gid]
      ? formDataProfile[values[ACCOUNT].profile_gid].identities || []
      : []

    audiences = formDataProfile[values[ACCOUNT].profile_gid]
      ? formDataProfile[values[ACCOUNT].profile_gid].audiences || []
      : []

    interests = formDataProfile[values[ACCOUNT].profile_gid]
      ? formDataProfile[values[ACCOUNT].profile_gid].interests || []
      : []
  }

  return (
    <Fragment>
      {showIdentitySelector && (
        <Box mt="m">
          <DropDown
            id={IDENTITY}
            label="Identity"
            placeholder="Select identity"
            value={values[IDENTITY]}
            onChange={(options) => {
              setFieldValue(IDENTITY, options || [])
            }}
            options={identities}
            openMenuOnFocus
            isMulti={false}
            formatOptionLabel={formatIdentityOptions}
            height={DEFAULT_HEIGHT}
            error={errors[IDENTITY] && touched[IDENTITY] && errors[IDENTITY]}
          />
        </Box>
      )}

      {showOtherSelectors && (
        <Fragment>
          <Box mt="m">
            <DropDown
              label="Included audiences"
              placeholder="Select audiences"
              value={values[INCLUDE_AUDIENCES]}
              onChange={(options) => {
                setFieldValue(INCLUDE_AUDIENCES, options || [])
              }}
              options={audiences.filter(
                ({ value }) =>
                  !values[EXCLUDE_AUDIENCES] || !values[EXCLUDE_AUDIENCES].find((item) => item.value === value)
              )}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[INCLUDE_AUDIENCES] && touched[INCLUDE_AUDIENCES] && errors[INCLUDE_AUDIENCES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Excluded audiences"
              placeholder="Select audiences"
              value={values[EXCLUDE_AUDIENCES]}
              onChange={(options) => {
                setFieldValue(EXCLUDE_AUDIENCES, options || [])
              }}
              options={audiences.filter(
                ({ value }) =>
                  !values[INCLUDE_AUDIENCES] || !values[INCLUDE_AUDIENCES].find((item) => item.value === value)
              )}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[EXCLUDE_AUDIENCES] && touched[EXCLUDE_AUDIENCES] && errors[EXCLUDE_AUDIENCES]}
            />
          </Box>

          {/* 03-12-2024 Objectives removed from TikTok, no need to delete the code, maybe we would add this field later */}

          {/* <Box mt="m"> */}
          {/*  <DropDown */}
          {/*    label="Objectives" */}
          {/*    placeholder="Select objective" */}
          {/*    value={values[OBJECTIVE]} */}
          {/*    onChange={(options) => { */}
          {/*      setFieldValue(OBJECTIVE, options || []) */}
          {/*    }} */}
          {/*    options={formDataProfile.objectives || []} */}
          {/*    openMenuOnFocus */}
          {/*    isMulti={false} */}
          {/*    formatOptionLabel={formatObjectiveOptions} */}
          {/*    height={DEFAULT_HEIGHT} */}
          {/*    error={errors[OBJECTIVE] && touched[OBJECTIVE] && errors[OBJECTIVE]} */}
          {/*  /> */}
          {/* </Box> */}

          <Box mt="m">
            <DropDown
              label="Countries"
              placeholder="Search for countries"
              value={values[LOCATIONS]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(LOCATIONS, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[ACCOUNT].profile_gid,
                    method: 'searchCountries',
                    // objective_type: values[OBJECTIVE].value, // 03-12-2024 objectives are hidden from the ui
                    objective_type: 'REACH',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              // defaultOptions={formDataProfile[LOCATIONS] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              error={errors[LOCATIONS] && touched[LOCATIONS] && errors[LOCATIONS]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Languages"
              placeholder="Search for languages"
              value={values[LANGUAGES]}
              height={DEFAULT_HEIGHT}
              onChange={(options) => {
                setFieldValue(LANGUAGES, options || [])
              }}
              cacheOptions
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: values[PROFILE].network.code,
                    id: values[ACCOUNT].profile_gid,
                    method: 'getLanguages',
                    // objective_type: values[OBJECTIVE].value, // 03-12-2024 objectives are hidden from the ui
                    objective_type: 'REACH',
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              //  defaultOptions={formDataProfile[LANGUAGES] || []}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              noOptionsMessage={({ inputValue }) => {
                return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
              }}
              menuPlacement="top"
              error={errors[LANGUAGES] && touched[LANGUAGES] && errors[LANGUAGES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Ages"
              placeholder="Select ages"
              value={values[AGES]}
              onChange={(options) => {
                setFieldValue(AGES, options || [])
              }}
              options={formDataProfile[AGES] || []}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              menuPlacement="top"
              error={errors[AGES] && touched[AGES] && errors[AGES]}
            />
          </Box>

          <Box mt="m">
            <DropDown
              label="Interests"
              placeholder="Select insterests"
              value={values[INTEREST_CATEGORIES]}
              onChange={(options) => {
                setFieldValue(INTEREST_CATEGORIES, options || [])
              }}
              options={interests}
              openMenuOnFocus
              isMulti
              height={DEFAULT_HEIGHT}
              error={errors[INTEREST_CATEGORIES] && touched[INTEREST_CATEGORIES] && errors[INTEREST_CATEGORIES]}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  )
}

TikTokConfiguration.defaultProps = {
  formDataProfile: {},
  showIdentitySelector: true,
  showOtherSelectors: true,
}

TikTokConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  formDataProfile: PropTypes.object,
  loadSuggestedOptions: PropTypes.func.isRequired,
  showIdentitySelector: PropTypes.bool,
  showOtherSelectors: PropTypes.bool,
}

TikTokConfiguration.displayName = 'TikTokConfiguration'

export default TikTokConfiguration
