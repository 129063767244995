import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius } from 'theme'
import Icon from 'components/atoms/Icon'
import { Flex } from 'components/atoms/Layout'
import PublicPageControlsModal from './PublicPageControlsModal'

const StyledControlsWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  text-decoration: none;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
`

const StyledImage = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  outline: none;
`

const PublicPageControlsComponent = ({ vistaPage, isTemporary, handleTrackAnalyticsMetrics }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Fragment>
      <StyledControlsWrapper
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        <StyledImage>
          <Icon.More stroke="icon_color_gray" />
        </StyledImage>
      </StyledControlsWrapper>
      {isModalOpen && (
        <PublicPageControlsModal
          handleDismiss={() => {
            setIsModalOpen(false)
          }}
          isOpen={isModalOpen}
          vistaPage={vistaPage}
          isTemporary={isTemporary}
          handleTrackAnalyticsMetrics={handleTrackAnalyticsMetrics}
        />
      )}
    </Fragment>
  )
}

PublicPageControlsComponent.defaultProps = {
  isTemporary: false,
}

PublicPageControlsComponent.propTypes = {
  vistaPage: PropTypes.object.isRequired,
  isTemporary: PropTypes.bool,
  handleTrackAnalyticsMetrics: PropTypes.func.isRequired,
}

PublicPageControlsComponent.displayName = 'PublicPageControlsComponent'

export default PublicPageControlsComponent
