import React, { Fragment, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'

const StyledSampleWrapper = styled(Box)`
  width: 90px;
  height: 90px;
  overflow: hidden;
`

const Sample = styled(Flex)`
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -7px;
  top: 9px;
  width: 150px;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  cursor: pointer;
`

const SampleComponent = ({ hoverText }) => {
  const tooltipRef = useRef(null)
  return (
    <Fragment>
      <Box height="0" position="absolute" top="0" right="0" zIndex="1" className="sample-text">
        <StyledSampleWrapper>
          <Sample
            justifyContent="center"
            onMouseEnter={(e) => {
              if (hoverText) {
                tooltipRef.current.handleShowTooltip({
                  contentComp: <Text px="m">{hoverText}</Text>,
                  wrapperComp: e.currentTarget,
                })
              }
            }}
            onMouseLeave={() => {
              tooltipRef.current.handleHideTooltip()
            }}
          >
            <Text fontSize="xs" color="white" fontWeight="bold">
              Sample
            </Text>
          </Sample>
        </StyledSampleWrapper>
      </Box>
      <CalendarItemTooltip ref={tooltipRef} />
    </Fragment>
  )
}

SampleComponent.defaultProps = {
  hoverText: 'Report builder is not using real data. It is using sample data.',
}

SampleComponent.propTypes = {
  hoverText: PropTypes.string,
}

export default SampleComponent
