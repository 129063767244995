import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width } from 'styled-system'
import { space, fontSizes, fontWeights, radius } from 'theme'
import { pxToRem } from 'helpers'
import { Box } from 'components/atoms/Layout'

const SelectWrapper = styled.select`
  padding: ${space.s} ${space.m} ${space.s} ${space.s};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  height: ${pxToRem(40)};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
  font-size: ${fontSizes.xs};
  ${({ isError }) => isError && `color: red;`}
  &:focus {
    outline-style: none;
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.bold};
  }
  ${width};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='%239193A9'><path d='M.86 1.06a.5.5 0 0 1 0-.71l.2-.2A.49.49 0 0 1 1.42 0h7.16a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71L5.35 4.85a.48.48 0 0 1-.7 0L.86 1.06z' /></svg>")
    no-repeat;
  background-position: calc(100% - ${pxToRem(16)}) center;
  background-repeat: no-repeat;
`

const Select = ({ children, ...props }) => (
  <Box position="relative">
    <SelectWrapper {...props}>{children}</SelectWrapper>
  </Box>
)

Select.propTypes = {
  children: PropTypes.node,
}

export default Select
