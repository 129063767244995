import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import { Text } from 'components/atoms/Typography'

const TrackInfoWrapper = styled(Flex)`
  align-items: center;
  margin-right: ${space.m};
  position: relative;
  min-width: 120px;
`

const StyledImage = styled(ImageWithFallback)`
  height: 32px;
  max-height: 32px;
  width: 32px;
  border-radius: ${radius.pill};
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.primaryText)} !important;
`

const StyledInfoWrap = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: left;
  &:hover {
    direction: rtl;
    text-overflow: clip;
  }
  &:focus {
    direction: rtl;
    text-overflow: clip;
  }
`

const TrackInfo = ({ trackName, trackArtist, trackImage, showCover }) => {
  return (
    <TrackInfoWrapper maxWidth={{ mobile: '210px', tablet: '450px' }} width="100%" minWidth="auto">
      {showCover && (
        <StyledImage
          mx="s"
          source={trackImage}
          alt={`${trackName}-${trackArtist}`}
          fallbackSource="/assets/vistasocial/musical_note.svg"
        />
      )}
      <StyledInfoWrap>
        {trackArtist && (
          <Fragment>
            <Text as="span" fontSize="s" fontWeight="medium">
              {trackArtist}
            </Text>
            {trackName && (
              <Text as="span" fontSize="s">
                &nbsp; - &nbsp;
              </Text>
            )}
          </Fragment>
        )}
        {trackName && (
          <Text as="span" fontSize="s">
            {trackName}
          </Text>
        )}
      </StyledInfoWrap>
    </TrackInfoWrapper>
  )
}

TrackInfo.defaultProps = {
  trackArtist: '',
  trackImage: '',
  trackName: '',
}

TrackInfo.propTypes = {
  trackName: PropTypes.string,
  trackArtist: PropTypes.string,
  trackImage: PropTypes.string,
  showCover: PropTypes.bool.isRequired,
}

export default TrackInfo
