import { COLOR_CONSTANTS } from 'theme'
import { PALETTE_TYPE_COLOR, PALETTE_TYPE_GRADIENT } from '../Calendar/consts'

export const SCALE_CONSTANT = 0.7685264737027886
export const SCALE_FONT_SIZE = 12.2964235792

const { CORNER_RADIUS_SMALL, CORNER_RADIUS_MEDIUM, CORNER_RADIUS_LARGE } = {
  CORNER_RADIUS_SMALL: 0.3,
  CORNER_RADIUS_MEDIUM: 0.6,
  CORNER_RADIUS_LARGE: 0.9,
}

export const { ROUTE_VISTA_PAGE } = { ROUTE_VISTA_PAGE: 'vista_page' }

export const { VISTA_PAGE_DOMAIN_ENDING } = { VISTA_PAGE_DOMAIN_ENDING: '.vista.page' }

export const { HEADER_THEME, BUTTON_THEME, SOCIAL_LINKS_THEME, IMAGE_LINK_THEME, TEXT_THEME } = {
  HEADER_THEME: 'header_theme',
  BUTTON_THEME: 'button_theme',
  SOCIAL_LINKS_THEME: 'social_links_theme',
  IMAGE_LINK_THEME: 'image_link_theme',
  TEXT_THEME: 'text_theme',
}

export const {
  VISTA_PAGE_FONT_MONTSERRAT,
  VISTA_PAGE_FONT_PLAYFAIR_DISPLAY,
  VISTA_PAGE_FONT_OSWALD,
  VISTA_PAGE_FONT_ROBOTO_SLAB,
  VISTA_PAGE_FONT_QUICKSAND,
  VISTA_PAGE_FONT_LIBRE_BASKERVILLE,
  VISTA_PAGE_FONT_RALEWAY,
  VISTA_PAGE_FONT_AMATIC_SC,
  VISTA_PAGE_FONT_LORA,
  VISTA_PAGE_FONT_POPPINS,
  VISTA_PAGE_FONT_SOURCE_SANS_PRO,
  VISTA_PAGE_FONT_INTER,
  VISTA_PAGE_FONT_ROBOTO,
  VISTA_PAGE_FONT_CARDO,
  VISTA_PAGE_FONT_VOLLKORN,
} = {
  VISTA_PAGE_FONT_MONTSERRAT: 'Montserrat',
  VISTA_PAGE_FONT_PLAYFAIR_DISPLAY: 'Playfair Display',
  VISTA_PAGE_FONT_OSWALD: 'Oswald',
  VISTA_PAGE_FONT_ROBOTO_SLAB: 'Roboto Slab',
  VISTA_PAGE_FONT_QUICKSAND: 'Quicksand',
  VISTA_PAGE_FONT_LIBRE_BASKERVILLE: 'Libre Baskerville',
  VISTA_PAGE_FONT_RALEWAY: 'Raleway',
  VISTA_PAGE_FONT_AMATIC_SC: 'Amatic SC',
  VISTA_PAGE_FONT_LORA: 'Lora',
  VISTA_PAGE_FONT_POPPINS: 'Poppins',
  VISTA_PAGE_FONT_SOURCE_SANS_PRO: 'Source Sans Pro',
  VISTA_PAGE_FONT_INTER: 'Inter',
  VISTA_PAGE_FONT_ROBOTO: 'Roboto',
  VISTA_PAGE_FONT_CARDO: 'Cardo',
  VISTA_PAGE_FONT_VOLLKORN: 'Vollkorn',
}

export const VISTA_PAGE_FONT_FAMILIES_WEIGHT = {
  [VISTA_PAGE_FONT_AMATIC_SC]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_LIBRE_BASKERVILLE]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_LORA]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_MONTSERRAT]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_OSWALD]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_PLAYFAIR_DISPLAY]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_QUICKSAND]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_RALEWAY]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_ROBOTO_SLAB]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_ROBOTO]: '300,400,500,600,700',
  [VISTA_PAGE_FONT_POPPINS]: '300,400,500,600,700',
}

export const VISTA_PAGE_FONT_FAMILIES = [
  VISTA_PAGE_FONT_MONTSERRAT,
  VISTA_PAGE_FONT_PLAYFAIR_DISPLAY,
  VISTA_PAGE_FONT_OSWALD,
  VISTA_PAGE_FONT_ROBOTO_SLAB,
  VISTA_PAGE_FONT_QUICKSAND,
  VISTA_PAGE_FONT_LIBRE_BASKERVILLE,
  VISTA_PAGE_FONT_RALEWAY,
  VISTA_PAGE_FONT_AMATIC_SC,
  VISTA_PAGE_FONT_LORA,
  VISTA_PAGE_FONT_POPPINS,
  VISTA_PAGE_FONT_SOURCE_SANS_PRO,
  VISTA_PAGE_FONT_INTER,
  VISTA_PAGE_FONT_ROBOTO,
  VISTA_PAGE_FONT_CARDO,
  VISTA_PAGE_FONT_VOLLKORN,
]

export const {
  HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
  HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND,
} = {
  HEADER_FORMAT_WITHOUT_BANNER_VERTICAL: 'header_format_without_banner_vertical',
  HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL: 'header_format_without_banner_horizontal',
  HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND: 'header_format_with_banner_vertical_full_background',
  HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND: 'header_format_with_banner_vertical_medium_background',
}

export const {
  DEFAULT_THEME_CONTENT_WIDTH,
  DEFAULT_THEME_SCALE,
  DEFAULT_THEME_CORNER_RADIUS,
  DEFAULT_THEME_SHADOW,
  DEFAULT_THEME_TRANSPARENCY,
  DEFAULT_THEME_TEXT_SCALE,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_FONT_STYLE,
  DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
  DEFAULT_IMAGE_LINK_IMAGE_SCALE,
  DEFAULT_HEADER_MEDIA_SCALE,
  DEFAULT_PUBLICATIONS_MAXIMUM,
  DEFAULT_SOCIAL_LINKS_COLORED,
} = {
  DEFAULT_THEME_CONTENT_WIDTH: 100,
  DEFAULT_THEME_SCALE: 1.2,
  DEFAULT_THEME_CORNER_RADIUS: 1,
  DEFAULT_THEME_SHADOW: 0,
  DEFAULT_THEME_TRANSPARENCY: 0,
  DEFAULT_THEME_TEXT_SCALE: 1.2,
  DEFAULT_THEME_TEXT_FONT_WEIGHT: 400,
  DEFAULT_THEME_TEXT_FONT_STYLE: 0,
  DEFAULT_SOCIAL_LINKS_IMAGES_SCALE: 1.5,
  DEFAULT_IMAGE_LINK_IMAGE_SCALE: 10,
  DEFAULT_HEADER_MEDIA_SCALE: 1.5,
  DEFAULT_PUBLICATIONS_MAXIMUM: 5,
  DEFAULT_SOCIAL_LINKS_COLORED: 0,
}

export const {
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  VISTA_PAGE_THEME_TEMPLATE_PINK_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_GREEN,
  VISTA_PAGE_THEME_TEMPLATE_ORANGE,
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_DARK,
  VISTA_PAGE_THEME_TEMPLATE_YELLOW,
  VISTA_PAGE_THEME_TEMPLATE_BLACK,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_PURPLE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_GRAY,
  VISTA_PAGE_THEME_TEMPLATE_PURPLE_ORANGE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_LIGHT,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE,
  VISTA_PAGE_THEME_TEMPLATE_YELLOW_2,
  VISTA_PAGE_THEME_TEMPLATE_SALUTE,
  VISTA_PAGE_THEME_TEMPLATE_WHITE_AND_GREEN,
} = {
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_DEFAULT',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: COLOR_CONSTANTS.WHITE,
    },
    [HEADER_THEME]: {
      text_scale: DEFAULT_THEME_SCALE,
      text_font_weight: DEFAULT_THEME_TEXT_FONT_WEIGHT,
      text_font_style: DEFAULT_THEME_TEXT_FONT_STYLE,
    },
    [TEXT_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#f5f5f5',
      },
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      text_scale: DEFAULT_THEME_SCALE,
      text_font_weight: DEFAULT_THEME_TEXT_FONT_WEIGHT,
      text_font_style: DEFAULT_THEME_TEXT_FONT_STYLE,
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#e6b333',
      },
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      text_scale: DEFAULT_THEME_SCALE,
      text_font_weight: DEFAULT_THEME_TEXT_FONT_WEIGHT,
      text_font_style: DEFAULT_THEME_TEXT_FONT_STYLE,
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [IMAGE_LINK_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#f5f5f5',
      },
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      text_scale: DEFAULT_THEME_SCALE,
      text_font_weight: DEFAULT_THEME_TEXT_FONT_WEIGHT,
      text_font_style: DEFAULT_THEME_TEXT_FONT_STYLE,
      corner_radius: CORNER_RADIUS_MEDIUM,
      image_link_image_scale: DEFAULT_IMAGE_LINK_IMAGE_SCALE,
    },
    [SOCIAL_LINKS_THEME]: {
      social_links_colored: DEFAULT_SOCIAL_LINKS_COLORED,
      social_links_image_scale: DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
    },
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_PINK_GRADIENT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_PINK_GRADIENT',
    background: {
      background_type: PALETTE_TYPE_GRADIENT,
      background_color: 'linear-gradient(175deg, rgb(255, 186, 229) 28%, rgba(255, 237, 79, 0) 106%)',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#232323',
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_GREEN: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_GREEN',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#d3ecda',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#078654',
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_ORANGE: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_ORANGE',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#fbd4bd',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_LARGE,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#ef600e',
      },
      corner_radius: CORNER_RADIUS_LARGE,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_DARK: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_VIOLET_DARK',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#270656',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#8149cf',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_YELLOW: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_YELLOW',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#fade2a',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_LARGE,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#e0364f',
      },
      corner_radius: CORNER_RADIUS_LARGE,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_BLACK: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_BLACK',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#010101',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#2c2c2c',
      },
      corner_radius: CORNER_RADIUS_LARGE,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#f72040',
      },
      corner_radius: CORNER_RADIUS_LARGE,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_GRADIENT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_GRADIENT',
    background: {
      background_type: PALETTE_TYPE_GRADIENT,
      background_color: 'linear-gradient(rgb(207, 224, 255) 0%, rgb(230, 254, 241) 100%)',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#3d3af6',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_PURPLE_GRADIENT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_PURPLE_GRADIENT',
    background: {
      background_type: PALETTE_TYPE_GRADIENT,
      background_color: 'linear-gradient(rgb(197, 245, 243) 0%, rgb(254, 214, 227) 100%)',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#26dbd8',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_GRAY: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_GRAY',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#eaeaea',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#000000',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_PURPLE_ORANGE_GRADIENT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_PURPLE_ORANGE_GRADIENT',
    background: {
      background_type: PALETTE_TYPE_GRADIENT,
      background_color: 'linear-gradient(rgb(255, 92, 141) 0%, rgb(255, 199, 54) 100%)',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      transparency: 0.5,
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_LIGHT: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_VIOLET_LIGHT',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#671fdf',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#ffbb0d',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#e4f5fe',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#267ce5',
      },
      corner_radius: CORNER_RADIUS_MEDIUM,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_YELLOW_2: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_YELLOW_2',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#fade2a',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_LARGE,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#e0364f',
      },
      corner_radius: CORNER_RADIUS_LARGE,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_SALUTE: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_SALUTE',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: '#232323',
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: COLOR_CONSTANTS.WHITE,
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#ff7a00',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.WHITE },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  VISTA_PAGE_THEME_TEMPLATE_WHITE_AND_GREEN: {
    name: 'VISTA_PAGE_THEME_TEMPLATE_WHITE_AND_GREEN',
    background: {
      background_type: PALETTE_TYPE_COLOR,
      background_color: COLOR_CONSTANTS.WHITE,
    },
    [HEADER_THEME]: {},
    [TEXT_THEME]: {
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#f3f3f3',
      },
      corner_radius: CORNER_RADIUS_SMALL,
    },
    [BUTTON_THEME]: {
      background: {
        background_type: PALETTE_TYPE_COLOR,
        background_color: '#87c221',
      },
      corner_radius: CORNER_RADIUS_SMALL,
      text_color: { color_type: PALETTE_TYPE_COLOR, color: COLOR_CONSTANTS.DENIM },
    },
    [IMAGE_LINK_THEME]: {},
    [SOCIAL_LINKS_THEME]: {},
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
}

export const VISTA_PAGE_THEME_TEMPLATES = [
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  VISTA_PAGE_THEME_TEMPLATE_PINK_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_GREEN,
  VISTA_PAGE_THEME_TEMPLATE_ORANGE,
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_DARK,
  VISTA_PAGE_THEME_TEMPLATE_YELLOW,
  VISTA_PAGE_THEME_TEMPLATE_BLACK,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE_PURPLE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_GRAY,
  VISTA_PAGE_THEME_TEMPLATE_PURPLE_ORANGE_GRADIENT,
  VISTA_PAGE_THEME_TEMPLATE_VIOLET_LIGHT,
  VISTA_PAGE_THEME_TEMPLATE_LIGHT_BLUE,
  VISTA_PAGE_THEME_TEMPLATE_YELLOW_2,
  VISTA_PAGE_THEME_TEMPLATE_SALUTE,
  VISTA_PAGE_THEME_TEMPLATE_WHITE_AND_GREEN,
]

export const {
  VISTA_PAGE_BLOCK_TYPE_HEADER,
  VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK,
  VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS,
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK,
  VISTA_PAGE_BLOCK_TYPE_TEXT,
  VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO,
  VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC,
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID,
  VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS,
  VISTA_PAGE_BLOCK_TYPE_WATERMARK,
  VISTA_PAGE_BLOCK_TYPE_TYPEFORM,
  VISTA_PAGE_BLOCK_TYPE_CALENDLY,
  VISTA_PAGE_BLOCK_TYPE_QR_CODE,
} = {
  VISTA_PAGE_BLOCK_TYPE_HEADER: 'header',
  VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK: 'button_link',
  VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS: 'social_links',
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK: 'image_link',
  VISTA_PAGE_BLOCK_TYPE_TEXT: 'text',
  VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO: 'youtube_video',
  VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC: 'spotify_music',
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID: 'image_links_grid',
  VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS: 'publications',
  VISTA_PAGE_BLOCK_TYPE_WATERMARK: 'watermark',
  VISTA_PAGE_BLOCK_TYPE_TYPEFORM: 'typeform',
  VISTA_PAGE_BLOCK_TYPE_CALENDLY: 'calendly',
  VISTA_PAGE_BLOCK_TYPE_QR_CODE: 'qr_code',
}

export const { PAGE_BLOCK_ID_START, PAGE_LAST_SAVED_CLIPLOADER_ID, PAGE_LAST_SAVED_TEXT } = {
  PAGE_BLOCK_ID_START: 'page-block-',
  PAGE_LAST_SAVED_CLIPLOADER_ID: 'page-last-saved-cliploader',
  PAGE_LAST_SAVED_TEXT: 'page-last-saved-text',
}

export const VISTA_PAGE_BLOCK_TYPE_THEME = {
  [VISTA_PAGE_BLOCK_TYPE_HEADER]: {
    icon: '/assets/vista_page/header.svg',
    bg_color: '#9193A9',
    title: 'Header',
    description: 'Page header',
  },
  [VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK]: {
    icon: '/assets/vista_page/button_link.svg',
    bg_color: '#ff702d',
    title: 'Button link',
    description: 'Links for everything',
  },
  [VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS]: {
    icon: '/assets/vista_page/social_links.svg',
    bg_color: '#01c8cf',
    title: 'Social links',
    description: 'Ideal for multiple social channels',
  },
  [VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK]: {
    icon: '/assets/vista_page/image_link.svg',
    bg_color: '#2c4bff',
    title: 'Image link',
    description: 'Links for everything',
  },
  [VISTA_PAGE_BLOCK_TYPE_TEXT]: {
    icon: '/assets/vista_page/text.svg',
    bg_color: '#9d2fff',
    title: 'Text',
    description: 'A line or two to spill the beans',
  },
  [VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO]: {
    icon: '/assets/youtube.svg',
    bg_color: '#e0364f',
    title: 'YouTube Video',
    description: 'Say it with motion',
  },
  [VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC]: {
    icon: '/assets/spotify.svg',
    bg_color: '#39bf43',
    title: 'Spotify Music',
    description: 'Embed your favorite music',
  },
  [VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID]: {
    icon: '/assets/vista_page/image_links_grid.svg',
    bg_color: '#f00276',
    title: 'Image Links Grid',
    description: 'Display your images in a grid with links',
  },
  [VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS]: {
    icon: '/assets/vista_page/posts.svg',
    bg_color: '#01c8cf',
    title: 'Posts',
    description: 'Schedule publications to your Vista Page',
  },
  [VISTA_PAGE_BLOCK_TYPE_TYPEFORM]: {
    icon: '/assets/vista_page/typeform.svg',
    bg_color: '#e9eaf1',
    title: 'Typeform',
    description: 'Forms that break the norm',
  },
  [VISTA_PAGE_BLOCK_TYPE_CALENDLY]: {
    icon: '/assets/vista_page/calendly.svg',
    bg_color: '#4aa1eb',
    title: 'Calendly',
    description: 'Simplified scheduling',
  },
  [VISTA_PAGE_BLOCK_TYPE_QR_CODE]: {
    icon: '/assets/vista_page/qr.svg',
    bg_color: '#4A6B96',
    title: 'QR code',
    description: 'Quick-response code',
  },
}

export const AVAILABLE_BLOCKS = [
  {
    title: 'Essentials',
    description: '',
    blocks: [
      {
        type: VISTA_PAGE_BLOCK_TYPE_HEADER,
      },
      {
        type: VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK,
      },
      {
        type: VISTA_PAGE_BLOCK_TYPE_TEXT,
      },
      {
        type: VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK,
      },
      {
        type: VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS,
      },
    ],
  },
  {
    title: 'Media embed',
    description: '',
    blocks: [
      {
        type: VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO,
      },
      // {
      //   type: VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC,
      // },
    ],
  },
  {
    title: 'Advanced',
    description: '',
    blocks: [
      {
        type: VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID,
      },
      {
        type: VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS,
      },
      { type: VISTA_PAGE_BLOCK_TYPE_TYPEFORM },
      { type: VISTA_PAGE_BLOCK_TYPE_CALENDLY },
      { type: VISTA_PAGE_BLOCK_TYPE_QR_CODE },
    ],
  },
]

export const {
  VISTA_PAGE_APPEARANCE,
  VISTA_PAGE_LAYOUT,
  VISTA_PAGE_ADD_BLOCK,
  VISTA_PAGE_STATISTICS,
  VISTA_PAGE_SETTINGS,
  VISTA_PAGE_QR,
} = {
  VISTA_PAGE_APPEARANCE: 'appearance',
  VISTA_PAGE_LAYOUT: 'layout',
  VISTA_PAGE_ADD_BLOCK: 'add',
  VISTA_PAGE_STATISTICS: 'statistics', // not used
  VISTA_PAGE_SETTINGS: 'settings',
  VISTA_PAGE_QR: 'qr',
}

export const AVAILABLE_FONTS = [
  {
    title: 'Montserrat',
    description: `Example of body text using the font: "Montserrat"`,
    font: { header: VISTA_PAGE_FONT_MONTSERRAT, body: VISTA_PAGE_FONT_MONTSERRAT },
  },
  {
    title: 'Playfair Display',
    description: `Example of body text using the font: "Source Sans Pro"`,
    font: { header: VISTA_PAGE_FONT_PLAYFAIR_DISPLAY, body: VISTA_PAGE_FONT_SOURCE_SANS_PRO },
  },
  {
    title: 'Oswald',
    description: `Example of body text using the font: "Poppins"`,
    font: { header: VISTA_PAGE_FONT_OSWALD, body: VISTA_PAGE_FONT_POPPINS },
  },
  {
    title: 'Roboto Slab',
    description: `Example of body text using the font: "Roboto Slab"`,
    font: { header: VISTA_PAGE_FONT_ROBOTO_SLAB, body: VISTA_PAGE_FONT_ROBOTO_SLAB },
  },
  {
    title: 'Quicksand',
    description: `Example of body text using the font: "Inter"`,
    font: { header: VISTA_PAGE_FONT_QUICKSAND, body: VISTA_PAGE_FONT_INTER },
  },
  {
    title: 'Libre Baskerville',
    description: `Example of body text using the font: "Roboto"`,
    font: { header: VISTA_PAGE_FONT_LIBRE_BASKERVILLE, body: VISTA_PAGE_FONT_ROBOTO },
  },
  {
    title: 'Raleway',
    description: `Example of body text using the font: "Cardo"`,
    font: { header: VISTA_PAGE_FONT_RALEWAY, body: VISTA_PAGE_FONT_CARDO },
  },
  {
    title: 'Amatic SC',
    description: `Example of body text using the font: "Vollkorn"`,
    font: { header: VISTA_PAGE_FONT_AMATIC_SC, body: VISTA_PAGE_FONT_VOLLKORN },
  },
  {
    title: 'Lora',
    description: `Example of body text using the font: "Montserrat"`,
    font: { header: VISTA_PAGE_FONT_LORA, body: VISTA_PAGE_FONT_MONTSERRAT },
  },
]

export const { LINK_TYPE_EXTERNAL, LINK_TYPE_INTERNAL } = {
  LINK_TYPE_EXTERNAL: 'external',
  LINK_TYPE_INTERNAL: 'internal',
}

export const LINK_TYPES = [
  { value: LINK_TYPE_EXTERNAL, label: 'Link to website' },
  { value: LINK_TYPE_INTERNAL, label: 'Link to page block' },
]

export const TYPES_THEME_SCHEMA = {
  [VISTA_PAGE_BLOCK_TYPE_HEADER]: HEADER_THEME,
  [VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK]: BUTTON_THEME,
  [VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS]: SOCIAL_LINKS_THEME,
  [VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK]: IMAGE_LINK_THEME,
  [VISTA_PAGE_BLOCK_TYPE_TEXT]: TEXT_THEME,
}
