import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { radius } from 'theme'
import { INSTAGRAM } from 'consts'
import { H4 } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ErrorLabel from 'components/atoms/ErrorLabel'
import PromptComponent from 'components/atoms/PromptComponent'
import Select from 'shared/DropDown'
import { formatOptionLabel, getAccounts } from 'routes/Calendar/helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const SPECIAL_SYMBOL = '@'

const InstagramImageMentionModal = ({ isOpen, handleDismiss, mention, handleClickSaveUserTag, profileId }) => {
  const [userTag, setUserTag] = useState({})
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [errors, setErrors] = useState([])

  const { username } = userTag

  useEffect(() => {
    if (isOpen) {
      setGetSuggestedOptionsErrors({})
      setUserTag({ ...mention })
    }
  }, [isOpen])

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => {
        callback(options)
        // filtering business profiles is switched off due to https://trello.com/c/NEyt4MtS/1406-instagram-tagging-users-on-photo-not-woking
        // const only_business = options.filter((item) => item.value)
        // callback(only_business)
      })
    }, 500),
    []
  )

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Tag a user</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper
            flex="1"
            heigth="100%"
            width="100%"
            flexDirection="column"
            justifyContent="center"
            px="m"
            pt="m"
          >
            <Select
              placeholder="Username"
              value={username ? { value: username, label: username } : null}
              onChange={(option) => {
                if (option) {
                  const { username } = option
                  userTag.username = username
                  userTag.isForEdit = mention.isForEdit
                  setUserTag({ ...userTag })
                  setErrors([])
                  handleClickSaveUserTag(userTag)

                  if (option.warning) {
                    Alert.warning(option.warning, { timeout: 5000 })
                  }
                } else {
                  userTag.username = ''
                  setUserTag({ ...userTag })
                }
              }}
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  { value, network: INSTAGRAM, id: profileId, getSuggestedOptionsErrors, setGetSuggestedOptionsErrors },
                  callback
                )
              }
              defaultValue={[]}
              isMulti={false}
              isClearable
              defaultOptions={[]}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              cacheOptions
              showDropDownArrow={false}
              formatOptionLabel={({ ...props }) => {
                return formatOptionLabel({ ...props, specialSymbol: SPECIAL_SYMBOL })
              }}
              noOptionsMessage={() => {
                return getSuggestedOptionsErrors[INSTAGRAM] ? 'No results found' : null
              }}
            />
            <Flex flexDirection="column">
              {errors.map((error) => (
                <ErrorLabel key={error} error={error} mt="s" />
              ))}
            </Flex>
            <Flex mt="xs" flexDirection="column" width="100%">
              <PromptComponent
                text="WARNING: If a user with a private profile is tagged, Instagram will not allow your post to be published."
                isShown
                type="warning"
              />
            </Flex>
          </StyledDialogBodyWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

InstagramImageMentionModal.defaultProps = {}

InstagramImageMentionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickSaveUserTag: PropTypes.func.isRequired,
  mention: PropTypes.object.isRequired,
  profileId: PropTypes.number.isRequired,
}

export default InstagramImageMentionModal
