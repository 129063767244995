import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { radius, space } from 'theme'
import { ERROR_MESSAGE, FEATURE_WORKFLOW, FEATURE_MULTI_WORKFLOW } from 'consts'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import TextArea from 'components/atoms/TextArea'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import DropDown from 'shared/DropDown'
import { ROUTE_WORKFLOW } from '../../consts'
import CustomizationsBlockComponent from '../Posts/components/CustomizationsBlockComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 560px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: ${({ maxHeight }) => maxHeight};
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledEntityWrapper = styled(Flex)`
  flex-direction: column;
  padding: ${space.m};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  margin-top: ${space.m};
`

const { APPROVAL_VARIANT, APPROVAL_VARIANT_USER, APPROVAL_VARIANT_WORKFLOW, NOTE_TO_REVIEWER } = {
  APPROVAL_VARIANT: 'approval_variant',
  APPROVAL_VARIANT_USER: 'user',
  APPROVAL_VARIANT_WORKFLOW: 'workflow',
  NOTE_TO_REVIEWER: 'note_to_reviewer',
}

const { WORKFLOW_VALUES, USER, WORKFLOW } = {
  WORKFLOW_VALUES: 'workflow_values',
  USER: 'user',
  WORKFLOW: 'workflow',
}

const APPROVAL_VARIANTS = [
  { value: APPROVAL_VARIANT_USER, label: 'Assign to user' },
  { value: APPROVAL_VARIANT_WORKFLOW, label: 'Assign to workflow' },
]

const MAX_NOTE_TO_REVIEWER_LENGTH = 500

const formatOption = ({ value, label, picture_url }, type) => (
  <Flex alignItems="center" key={value}>
    {type && (
      <ImageWithFallback
        source={picture_url}
        fallbackSource={type === 'user' ? '/assets/avatar.svg' : '/assets/company.svg'}
        width="20px"
        height="20px"
        borderRadius={radius.l}
        mr="s"
      />
    )}

    <Flex flexDirection="column">
      <Text fontSize="xs">{label}</Text>
    </Flex>
  </Flex>
)

const AssignModal = ({ user, workflow = [], approvers, isOpen, handleDismiss, handleSave, entityPublishValues }) => {
  const scrollbarsRef = useRef(null)
  const formRef = useRef(null)

  const [workflowData, setWorkflowData] = useState({ visibleData: [], hiddenData: [] })
  const [availableWorkflows, setAvailableWorkflows] = useState([])
  const [isGettingData, setIsGettingData] = useState(true)
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_WORKFLOW]: { enabled: true },
    [FEATURE_MULTI_WORKFLOW]: { enabled: true },
  })
  const [approversByEntities, setApproversByEntities] = useState({})

  const getData = async ({ featuresEnabled }) => {
    let available_workflows = []
    try {
      if (featuresEnabled[FEATURE_MULTI_WORKFLOW].enabled) {
        const response = await request({
          path: `${ROUTE_WORKFLOW}?entity_gid=${entityPublishValues.map(({ entityId }) => entityId).join(',')}`,
        })
        const { error, workflows } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          available_workflows = workflows.filter(({ active }) => active)

          setAvailableWorkflows(
            available_workflows.map(({ name, _id, entity_gid, steps = [] }) => {
              const stepsLength = steps.length
              return {
                value: _id,
                label: `${name} (${stepsLength} ${stepsLength === 1 ? 'step' : 'steps'})`,
                entity_gid,
              }
            })
          )
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: AssignModal.displayName, functionName: 'getData' })
    } finally {
      const workflow_temp = JSON.parse(JSON.stringify(null)) || []

      // here collecting all workflow data for the ui, each profile group has own values
      entityPublishValues.forEach(({ entityId, name: entityName, entityPictureUrl }) => {
        const foundWorkflowIndex = workflow_temp
          ? workflow_temp.findIndex(({ entity_gid }) => entity_gid === entityId)
          : -1

        if (foundWorkflowIndex === -1) {
          const data = { entity_gid: entityId, entityName, entityPictureUrl, isVisible: true }

          const defaultWorkflow = available_workflows.find(
            ({ entity_gid, default: isDefault }) => isDefault && entity_gid === entityId
          )
          if (defaultWorkflow) {
            const stepsLength = defaultWorkflow.steps.length
            data.workflow_gid = {
              value: defaultWorkflow._id,
              label: `${defaultWorkflow.name} (${stepsLength} ${stepsLength === 1 ? 'step' : 'steps'})`,
              entity_gid: entityId,
            }
          }

          workflow_temp.push(data)
        } else {
          const { workflow_gid, user_gid } = workflow_temp[foundWorkflowIndex]

          if (workflow_gid) {
            const foundWorkflow = available_workflows.find(({ _id }) => _id === workflow_gid)

            if (foundWorkflow) {
              const stepsLength = foundWorkflow.steps.length
              workflow_temp[foundWorkflowIndex].workflow_gid = {
                value: foundWorkflow._id,
                label: `${foundWorkflow.name} (${stepsLength} ${stepsLength === 1 ? 'step' : 'steps'})`,
                entity_gid: entityId,
              }
            }
          } else if (user_gid) {
            const foundApprover = approvers.find(({ value }) => value === user_gid)
            if (foundApprover) {
              workflow_temp[foundWorkflowIndex].user_gid = foundApprover
            }
          }

          workflow_temp[foundWorkflowIndex].isVisible = true
          //  workflow[foundWorkflowIndex].hasWorkflow = true
          workflow_temp[foundWorkflowIndex].entityName = entityName
          workflow_temp[foundWorkflowIndex].entityPictureUrl = entityPictureUrl
        }
      })

      const visibleData = []
      const hiddenData = []

      // workflow is an array because publishing group can have posts from different profile groups
      workflow_temp.forEach((workflow) => {
        const { isVisible, hasWorkflow } = workflow

        if (isVisible) {
          if (workflow.user_gid) {
            workflow[USER] = workflow.user_gid
          } else {
            workflow[USER] = null
          }

          if (workflow.workflow_gid) {
            workflow[WORKFLOW] = workflow.workflow_gid
          } else {
            workflow[WORKFLOW] = null
          }

          if (!workflow[NOTE_TO_REVIEWER]) {
            workflow[NOTE_TO_REVIEWER] = ''
          }

          if (workflow[WORKFLOW]) {
            workflow[APPROVAL_VARIANT] = APPROVAL_VARIANTS.find(({ value }) => value === APPROVAL_VARIANT_WORKFLOW)
          } else if (workflow[USER]) {
            workflow[APPROVAL_VARIANT] = APPROVAL_VARIANTS.find(({ value }) => value === APPROVAL_VARIANT_USER)
          } else if (hasWorkflow) {
            workflow[APPROVAL_VARIANT] = APPROVAL_VARIANTS.find(({ value }) => value === APPROVAL_VARIANT_WORKFLOW)
          } else {
            workflow[APPROVAL_VARIANT] = APPROVAL_VARIANTS.find(({ value }) => value === APPROVAL_VARIANT_USER)
          }

          visibleData.push(workflow)
        } else {
          hiddenData.push(workflow)
        }
      })

      // if(visibleData)

      setWorkflowData({ ...{ visibleData, hiddenData } })

      setIsGettingData(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      const featuresEnabled = checkIfPlanHasFeatureEnabled({
        user,
        features: [FEATURE_WORKFLOW, FEATURE_MULTI_WORKFLOW],
      })

      setFeaturesEnabled({ ...featuresEnabled })

      if (featuresEnabled[FEATURE_WORKFLOW].enabled) {
        const approversByEntitiesTemp = {}

        approvers.forEach((approver) => {
          const { entity_gids = [] } = approver

          entity_gids.forEach((entity_gid) => {
            if (!approversByEntitiesTemp[entity_gid]) {
              approversByEntitiesTemp[entity_gid] = { entity_gid, approvers: [] }
            }

            approversByEntitiesTemp[entity_gid].approvers.push(approver)
          })
        })

        setApproversByEntities({ ...approversByEntitiesTemp })

        getData({ featuresEnabled })
      } else {
        setIsGettingData(false)
      }
    }
  }, [isOpen])

  const createValidationSchema = () => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }
    return Yup.object().shape({
      [WORKFLOW_VALUES]: Yup.array().of(
        Yup.object().shape({
          [APPROVAL_VARIANT]: Yup.object().shape(isDropdownValueRequiredShape),

          [USER]: Yup.object()
            .shape(isDropdownValueRequiredShape)
            .nullable()
            .when([APPROVAL_VARIANT], {
              is: (approval_variant) => approval_variant.value === APPROVAL_VARIANT_USER,
              then: Yup.object()
                .shape(isDropdownValueRequiredShape)
                .typeError('Please select a user that you would like to assign this to.')
                .required('Please select a user that you would like to assign this to.'),
            }),
          [WORKFLOW]: Yup.object()
            .shape(isDropdownValueRequiredShape)
            .nullable()
            .when([APPROVAL_VARIANT], {
              is: (approval_variant) => approval_variant.value === APPROVAL_VARIANT_WORKFLOW,
              then: Yup.object()
                .shape(isDropdownValueRequiredShape)
                .typeError('Please select at least one workflow.')
                .required('Please select at least one workflow.'),
            }),
          [NOTE_TO_REVIEWER]: Yup.string().max(
            MAX_NOTE_TO_REVIEWER_LENGTH,
            `Note is too long - should be ${MAX_NOTE_TO_REVIEWER_LENGTH} chars maximum.`
          ),
        })
      ),
    })
  }

  const handleSubmitForm = ({ [WORKFLOW_VALUES]: values }) => {
    const body = [...workflowData.hiddenData]

    values.forEach((workflow) => {
      const data = { entity_gid: workflow.entity_gid, [NOTE_TO_REVIEWER]: workflow[NOTE_TO_REVIEWER] }
      if (workflow[APPROVAL_VARIANT].value === APPROVAL_VARIANT_USER) {
        data.user_gid = workflow[APPROVAL_VARIANT_USER].value
      } else if (workflow[APPROVAL_VARIANT].value === APPROVAL_VARIANT_WORKFLOW) {
        data.workflow_gid = workflow[APPROVAL_VARIANT_WORKFLOW].value
      }

      body.push(data)
    })

    handleSave(body)
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent maxHeight={workflowData.visibleData.length <= 1 ? '500px' : '1200px'} tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">Assign to</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" flex="1" position="relative">
            {isGettingData ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Fragment>
                {!featuresEnabled[FEATURE_WORKFLOW].enabled ? (
                  <CustomizationsBlockComponent
                    message={featuresEnabled[FEATURE_WORKFLOW].description}
                    showUpgradeMessage={false}
                  />
                ) : (
                  <Scrollbars universal ref={scrollbarsRef}>
                    <Formik
                      initialValues={{
                        [WORKFLOW_VALUES]: workflowData.visibleData,
                      }}
                      onSubmit={handleSubmitForm}
                      validationSchema={createValidationSchema}
                      autocomplete="off"
                      enableReinitialize
                      ref={formRef}
                    >
                      {({ values, setFieldValue }) => (
                        <Box px="m" pb="m">
                          <FieldArray
                            name="workflow_values"
                            render={() => (
                              <form autoComplete="off">
                                {values[WORKFLOW_VALUES].map((workflow_value, index) => {
                                  return (
                                    <StyledEntityWrapper key={workflow_value.entity_gid}>
                                      <Flex alignItems="center">
                                        <ImageWithFallback
                                          source={
                                            workflowData.visibleData[index].entityPictureUrl || '/assets/company.svg'
                                          }
                                          fallbackSource="/assets/company.svg"
                                          width="20px"
                                          height="20px"
                                          borderRadius={radius.l}
                                        />
                                        <Text ml="s">{workflowData.visibleData[index].entityName}</Text>
                                      </Flex>

                                      <Field name={`${[WORKFLOW_VALUES]}.${index}.${[APPROVAL_VARIANT]}`}>
                                        {({ form }) => {
                                          return (
                                            <Box mt="m" width="100%">
                                              <DropDown
                                                placeholder="Select approval option"
                                                value={form.values[WORKFLOW_VALUES][index][APPROVAL_VARIANT]}
                                                onChange={(option) => {
                                                  if (
                                                    option.value === APPROVAL_VARIANT_WORKFLOW &&
                                                    !featuresEnabled[FEATURE_MULTI_WORKFLOW].enabled
                                                  ) {
                                                    Alert.error(featuresEnabled[FEATURE_MULTI_WORKFLOW].description, {
                                                      timeout: 5000,
                                                    })
                                                  } else {
                                                    setFieldValue(
                                                      `${[WORKFLOW_VALUES]}.${index}.${[APPROVAL_VARIANT]}`,
                                                      option
                                                    )
                                                  }
                                                }}
                                                options={APPROVAL_VARIANTS}
                                                error={
                                                  form.errors[WORKFLOW_VALUES] &&
                                                  form.touched[WORKFLOW_VALUES] &&
                                                  form.touched[WORKFLOW_VALUES][index] &&
                                                  form.errors[WORKFLOW_VALUES][index] &&
                                                  form.touched[WORKFLOW_VALUES][index][APPROVAL_VARIANT] &&
                                                  form.errors[WORKFLOW_VALUES][index][APPROVAL_VARIANT]
                                                }
                                                openMenuOnFocus
                                                isMulti={false}
                                              />
                                            </Box>
                                          )
                                        }}
                                      </Field>

                                      {workflow_value[APPROVAL_VARIANT].value === APPROVAL_VARIANT_USER && (
                                        <Field name={`${[WORKFLOW_VALUES]}.${index}.${[USER]}`}>
                                          {({ form }) => {
                                            const { approvers = [] } =
                                              approversByEntities[workflow_value.entity_gid] || {}

                                            return (
                                              <Box mt="m" width="100%">
                                                <DropDown
                                                  placeholder="Select approver"
                                                  value={form.values[WORKFLOW_VALUES][index][USER]}
                                                  onChange={(option) => {
                                                    setFieldValue(`${[WORKFLOW_VALUES]}.${index}.${[USER]}`, option)
                                                  }}
                                                  formatOptionLabel={(data) => {
                                                    return formatOption(data, 'user')
                                                  }}
                                                  options={approvers}
                                                  error={
                                                    form.errors[WORKFLOW_VALUES] &&
                                                    form.touched[WORKFLOW_VALUES] &&
                                                    form.touched[WORKFLOW_VALUES][index] &&
                                                    form.errors[WORKFLOW_VALUES][index] &&
                                                    form.touched[WORKFLOW_VALUES][index][USER] &&
                                                    form.errors[WORKFLOW_VALUES][index][USER]
                                                  }
                                                  openMenuOnFocus
                                                  isMulti={false}
                                                />
                                              </Box>
                                            )
                                          }}
                                        </Field>
                                      )}

                                      {workflow_value[APPROVAL_VARIANT].value === APPROVAL_VARIANT_WORKFLOW && (
                                        <Field name={`${[WORKFLOW_VALUES]}.${index}.${[WORKFLOW]}`}>
                                          {({ form }) => {
                                            return (
                                              <Box mt="m" width="100%">
                                                <DropDown
                                                  placeholder="Select workflow"
                                                  value={form.values[WORKFLOW_VALUES][index][WORKFLOW]}
                                                  onChange={(option) => {
                                                    setFieldValue(`${[WORKFLOW_VALUES]}.${index}.${[WORKFLOW]}`, option)
                                                  }}
                                                  formatOptionLabel={(data) => {
                                                    return formatOption(data, null)
                                                  }}
                                                  options={availableWorkflows
                                                    .filter(
                                                      (workflow) => workflow.entity_gid === workflow_value.entity_gid
                                                    )
                                                    .sort((w1, w2) => w1.label.localeCompare(w2.label))}
                                                  error={
                                                    form.errors[WORKFLOW_VALUES] &&
                                                    form.touched[WORKFLOW_VALUES] &&
                                                    form.touched[WORKFLOW_VALUES][index] &&
                                                    form.errors[WORKFLOW_VALUES][index] &&
                                                    form.touched[WORKFLOW_VALUES][index][WORKFLOW] &&
                                                    form.errors[WORKFLOW_VALUES][index][WORKFLOW]
                                                  }
                                                  openMenuOnFocus
                                                  isMulti={false}
                                                />
                                              </Box>
                                            )
                                          }}
                                        </Field>
                                      )}

                                      <Field name={`${[WORKFLOW_VALUES]}.${index}.${[NOTE_TO_REVIEWER]}`}>
                                        {({ field, form }) => {
                                          return (
                                            <Box mt="m" width="100%">
                                              <TextArea
                                                {...field}
                                                placeholder="Note to reviewer"
                                                label="Note to reviewer"
                                                rows={3}
                                                error={
                                                  form.errors[WORKFLOW_VALUES] &&
                                                  form.touched[WORKFLOW_VALUES] &&
                                                  form.touched[WORKFLOW_VALUES][index] &&
                                                  form.errors[WORKFLOW_VALUES][index] &&
                                                  form.touched[WORKFLOW_VALUES][index][NOTE_TO_REVIEWER] &&
                                                  form.errors[WORKFLOW_VALUES][index][NOTE_TO_REVIEWER]
                                                }
                                              />
                                            </Box>
                                          )
                                        }}
                                      </Field>
                                    </StyledEntityWrapper>
                                  )
                                })}
                              </form>
                            )}
                          />
                        </Box>
                      )}
                    </Formik>
                  </Scrollbars>
                )}
              </Fragment>
            )}
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleDismiss} mr="s">
              No, cancel
            </Button.Gray>

            <Button.Gradient
              isSmall
              onClick={() => {
                if (featuresEnabled[FEATURE_WORKFLOW].enabled) {
                  handleSubmitFormFromButton()
                } else {
                  handleDismiss()
                }
              }}
              type="submit"
            >
              Assign
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

AssignModal.defaultProps = {
  approvers: [],
  workflow: [],
  entityPublishValues: [],
}

AssignModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  workflow: PropTypes.array,
  approvers: PropTypes.array,
  entityPublishValues: PropTypes.array,
}

AssignModal.displayName = 'AssignModal'

export default withConfirm(AssignModal)
