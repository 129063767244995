import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { H4 } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import PromptComponent from 'components/atoms/PromptComponent'
import Select from 'shared/DropDown'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: auto;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const MAX_COLLABORATOR_TAGS_PER_MEDIA = 3

const MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE = `Only ${MAX_COLLABORATOR_TAGS_PER_MEDIA} collaborator tags are allowed per media.`

const InstagramMediaCollaboratorsModal = ({
  isOpen,
  handleDismiss,
  data,
  handleClickSaveCollaboratorTags,
  savedCollaboratorTags,
}) => {
  const selectRef = useRef(null)

  const [collaboratorsTags, setCollaboratorsTags] = useState([])

  useEffect(() => {
    if (isOpen) {
      const { collaborator_tags = [] } = data
      setCollaboratorsTags([...collaborator_tags.map((item) => ({ value: item, label: item }))])
    }
  }, [isOpen])

  const handleClickSave = () => {
    handleClickSaveCollaboratorTags({
      collaborator_tags: collaboratorsTags.map(({ value }) => value),
    })
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Invite collaborators</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper
            flex="1"
            heigth="100%"
            width="100%"
            flexDirection="column"
            justifyContent="flex-start"
            px="m"
            pt="m"
          >
            <Select
              innerRef={selectRef}
              isMulti
              label=""
              placeholder="Username"
              value={collaboratorsTags || []}
              onChange={(options) => {
                if (options) {
                  if (options.length <= MAX_COLLABORATOR_TAGS_PER_MEDIA) {
                    setCollaboratorsTags([
                      ...options.map(({ value }) => {
                        const updatedItem = value.replace('@', '')
                        return { value: updatedItem, label: updatedItem }
                      }),
                    ])
                  } else {
                    Alert.error(MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE, { timeout: 5000 })
                    setCollaboratorsTags([...options.slice(0, MAX_COLLABORATOR_TAGS_PER_MEDIA)])
                  }
                } else {
                  setCollaboratorsTags([])
                }
              }}
              options={savedCollaboratorTags}
              openMenuOnFocus
              dropDownType="CreatableSelect"
              noOptionsMessage={() => {
                return 'Invite collaborators'
              }}
              onMenuClose={() => {
                if (selectRef && selectRef.current && selectRef.current.state.inputValue) {
                  const options = [
                    ...new Set([
                      ...selectRef.current.props.value.map(({ value }) => value),
                      selectRef.current.state.inputValue,
                    ]),
                  ].map((item) => {
                    const updatedItem = item.replace('@', '')
                    return { value: updatedItem, label: updatedItem }
                  })

                  if (options.length <= MAX_COLLABORATOR_TAGS_PER_MEDIA) {
                    setCollaboratorsTags([...options])
                  } else {
                    Alert.error(MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE, { timeout: 5000 })
                    setCollaboratorsTags([...options.slice(0, MAX_COLLABORATOR_TAGS_PER_MEDIA)])
                  }
                }
              }}
            />
            <Flex mt="xs" flexDirection="column" width="100%">
              <PromptComponent
                text="Only public Instagram profiles can be invited as collaborators. Once the post is published, invited users will receive a request and need to accept it. <a href='https://support.vistasocial.com/hc/en-us/articles/20049112684443' target='_blank'>Learn more</a>"
                isShown
                type="warning"
              />
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleDismiss}>
              Cancel
            </Button.Gray>

            <Button.Gradient isSmall onClick={handleClickSave}>
              Save
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

InstagramMediaCollaboratorsModal.defaultProps = {}

InstagramMediaCollaboratorsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickSaveCollaboratorTags: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  savedCollaboratorTags: PropTypes.array.isRequired,
}

export default InstagramMediaCollaboratorsModal
