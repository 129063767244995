import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import Scrollbars from 'react-custom-scrollbars-2'
import { transparentize } from 'polished'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { fontSizes, radius, space } from 'theme'
import { isValidUrl } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Switch from 'components/atoms/Switch'
import Icon from 'components/atoms/Icon'
import {
  SOCIAL_LINKS_THEME,
  DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
  DEFAULT_SOCIAL_LINKS_COLORED,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
} from 'routes/VistaPage/consts'
import ThemeComponent from '../../ThemeComponent'

const NavigationLinkIconWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledAddLinkWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledSocialNetworkWrapper = styled(Flex)`
  box-shadow: 0 0 8px 2px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  padding: ${space.m};
  flex-direction: column;
  width: 100%;
  margin-top: ${space.m};
  border-radius: ${radius.l};
`

const StyledNetworkImage = styled(Image)`
  ${({ $colored }) => !$colored && `filter: saturate(0%) brightness(100%) contrast(100%)`}
`

const StyledSocialLinksPreviewWrapper = styled(Flex)`
  gap: ${space.m};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const StyledSocialLinkPreviewWrapper = styled(Text)`
  cursor: pointer;
`

const StyledApplyDefaultThemeText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const { LINKS, DETAILED_APPEARANCE_THEME } = {
  LINKS: 'links',
  DETAILED_APPEARANCE_THEME: 'detailed_appearance_theme',
}

const SOCIAL_PREFIXES = {
  wechat: 'weixin://',
  email: 'mailto:',
  sms: 'tel:',
}

const SOCIALS = [
  {
    name: '500px',
    network: '500px',
    placeholder: 'https://500px.com/p/',
  },
  {
    name: 'Amazon Music',
    network: 'amazonmusic',
    placeholder: 'https://music.amazon.com/',
  },
  {
    name: 'Apple Music',
    network: 'applemusic',
    placeholder: 'https://applemusic.com/',
  },
  {
    name: 'Bandcamp',
    network: 'bandcamp',
    placeholder: 'https://bandcamp.com/',
  },
  {
    name: 'Behance',
    network: 'behance',
    placeholder: 'https://behance.net/',
  },
  {
    name: 'Blog',
    network: 'blog',
    placeholder: 'Your blog URL',
  },
  {
    name: 'Bluesky',
    network: 'bluesky',
    placeholder: 'https://bsky.app/',
  },
  {
    name: 'Clubhouse',
    network: 'clubhouse',
    placeholder: 'https://clubhouse.com/@',
  },
  {
    name: 'Deezer',
    network: 'deezer',
    placeholder: 'https://www.deezer.com/',
  },
  {
    name: 'Discord',
    network: 'discord',
    placeholder: 'https://discord.com/',
  },
  {
    name: 'Dribble',
    network: 'dribble',
    placeholder: 'https://dribble.com/',
  },
  {
    name: 'Email',
    network: 'email',
    placeholder: 'email@domain.com',
  },
  {
    name: 'Facebook',
    network: 'facebook',
    placeholder: 'https://facebook.com/',
  },
  {
    name: 'Github',
    network: 'github',
    placeholder: 'https://github.com/',
  },
  {
    name: 'Google Business',
    network: 'googlebusiness',
    placeholder: 'https://www.google.com/maps/place',
  },
  {
    name: 'Instagram',
    network: 'instagram',
    placeholder: 'https://instagram.com/',
  },
  {
    name: 'Kuaishou',
    network: 'kuaishou',
    placeholder: 'https://kuaishou.com/profile/',
  },
  {
    name: 'LinkedIn',
    network: 'linkedin',
    placeholder: 'https://linkedin.com/',
  },
  {
    name: 'Mastodon',
    network: 'mastodon',
    placeholder: 'https://mastodon.com/',
  },
  {
    name: 'Medium',
    network: 'medium',
    placeholder: 'https://medium.com/@',
  },
  {
    name: 'OnlyFans',
    network: 'onlyfans',
    placeholder: 'https://onlyfans.com/',
  },
  {
    name: 'OpenSea',
    network: 'opensea',
    placeholder: 'https://opensea.io/collection/',
  },
  {
    name: 'Patreon',
    network: 'patreon',
    placeholder: 'https://patreon.com/',
  },
  {
    name: 'PayPal',
    network: 'paypal',
    placeholder: 'https://paypal.com/',
  },
  {
    name: 'Picsart',
    network: 'picsart',
    placeholder: 'https://picsart.com/u/',
  },
  {
    name: 'Pinterest',
    network: 'pinterest',
    placeholder: 'https://pinterest.com/',
  },
  {
    name: 'Quora',
    network: 'quora',
    placeholder: 'https://quora.com/profile/',
  },
  {
    name: 'Reddit',
    network: 'reddit',
    placeholder: 'https://reddit.com/user/',
  },
  {
    name: 'Sina Weibo',
    network: 'sinaweibo',
    placeholder: 'https://weibo.com/u/',
  },
  {
    name: 'Sms',
    network: 'sms',
    placeholder: 'Your phone number',
  },
  {
    name: 'Snapchat',
    network: 'snapchat',
    placeholder: 'https://snapchat.com/',
  },
  {
    name: 'SoundCloud',
    network: 'soundcloud',
    placeholder: 'https://soundcloud.com/',
  },
  {
    name: 'Spotify',
    network: 'spotify',
    placeholder: 'https://spotify.com/',
  },
  {
    name: 'Substack',
    network: 'substack',
    placeholder: 'https://you.substack.com/',
  },
  {
    name: 'Telegram',
    network: 'telegram',
    placeholder: 'https://t.me/',
  },
  {
    name: 'Threads',
    network: 'threads',
    placeholder: 'https://threads.net/',
  },
  {
    name: 'TikTok',
    network: 'tiktok',
    placeholder: 'https://tiktok.com/',
  },
  {
    name: 'Tumblr',
    network: 'tumblr',
    placeholder: 'https://tumblr.com/',
  },
  {
    name: 'Twitter',
    network: 'x',
    placeholder: 'https://x.com/',
  },
  {
    name: 'Twitch',
    network: 'twitch',
    placeholder: 'https://twitch.com/',
  },
  {
    name: 'Viber',
    network: 'viber',
    placeholder: 'https://viber.me/username/',
  },
  {
    name: 'Vimeo',
    network: 'vimeo',
    placeholder: 'https://vimeo.com/',
  },
  {
    name: 'WeChat',
    network: 'wechat',
    placeholder: 'Your WeChat link',
  },
  {
    name: 'WhatsApp',
    network: 'whatsapp',
    placeholder: 'https://wa.me/',
  },
  {
    name: 'YouTube',
    network: 'youtube',
    placeholder: 'https://youtube.com/',
  },
]

const FormValidationSchema = () => {
  return Yup.object().shape({
    [LINKS]: Yup.array().of(
      Yup.object().shape({
        network: Yup.string().required(),
        link: Yup.string().when('network', (network, field) => {
          if (!SOCIAL_PREFIXES[network]) {
            return field.url(`Please enter a valid address starting with 'http'`)
          } else if (network === 'email') {
            return field.email('Must be a valid email address')
          }

          return field
        }),
      })
    ),
  })
}

const SocialLinksComponent = ({
  data,
  vistaPage,
  isEditable,
  handleChangeBlockComponent,
  handleCollectTrackingData,
}) => {
  const { page_id, _id: block_id, data: { links = [], detailed_appearance_theme = false } = {} } = data

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT } = vistaPage

  const appearance_theme = appearance[SOCIAL_LINKS_THEME] || {}

  const theme = data.data ? data.data[SOCIAL_LINKS_THEME] || appearance_theme : appearance_theme

  const has_theme_changed = !!(data.data && data.data[SOCIAL_LINKS_THEME])

  const {
    social_links_colored = appearance_theme.social_links_colored || DEFAULT_SOCIAL_LINKS_COLORED,
    social_links_image_scale = appearance_theme.social_links_image_scale || DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
  } = theme || {}

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result, links, setFieldValue) => {
    if (!result.destination) {
      return
    }

    const reordered_items = reorder(links, result.source.index, result.destination.index)

    setFieldValue(LINKS, reordered_items)

    handleChangeBlockComponent({
      page_id,
      block_id,
      key: LINKS,
      value: reordered_items,
    })
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [LINKS]: links,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, setFieldValue }) => (
              <Box width="100%">
                <FieldArray
                  name="links"
                  render={(arrayHelpers) => (
                    <form autoComplete="off">
                      <Flex justifyContent="flex-end">
                        <DropdownMenu
                          zIndex={16}
                          WrapperComp={
                            <StyledAddLinkWrapper alignItems="center">
                              <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
                              <Text color="primary" fontSize="xs">
                                Add another social link
                              </Text>
                            </StyledAddLinkWrapper>
                          }
                          isDismissedOnClickInside
                        >
                          <Flex flexDirection="column" height="300px" width="160px">
                            <Scrollbars universal>
                              {SOCIALS.map(({ network, name }) => (
                                <DropdownMenu.Item
                                  key={network}
                                  PictureComp={
                                    <Flex alignItems="center" justifyContent="center" mr="s">
                                      <Image src={`/assets/vista_page/networks/${network}.svg`} width="14px" />
                                    </Flex>
                                  }
                                  iconWidth="14px"
                                  label={name}
                                  onClick={() => {
                                    arrayHelpers.push({ network, link: '' })

                                    const links = [...values[LINKS]]

                                    links.push({ network, link: '' })

                                    handleChangeBlockComponent({
                                      page_id,
                                      block_id,
                                      key: LINKS,
                                      value: links,
                                    })
                                  }}
                                  isCursorPointer
                                  labelMaxWidth="280px"
                                />
                              ))}
                            </Scrollbars>
                          </Flex>
                        </DropdownMenu>
                      </Flex>

                      {values[LINKS].length === 0 && <Text mt="m">Add your social links by clicking above</Text>}

                      <DragDropContext
                        onDragEnd={(result) => {
                          onDragEnd(result, values[LINKS], setFieldValue)
                        }}
                      >
                        <Droppable droppableId="droppable" direction="vertical">
                          {(provided) => (
                            <Flex
                              flexDirection="column"
                              width="100%"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {values[LINKS].map((link, index) => (
                                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                  {(provided) => (
                                    <Flex
                                      alignItems="center"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Field name={`${LINKS}.${index}`}>
                                        {({ field, form }) => {
                                          let placeholder = 'Social link'
                                          let socialName = 'Social network'

                                          const foundSocial = SOCIALS.find(
                                            ({ network }) => network === field.value.network
                                          )

                                          if (foundSocial) {
                                            // eslint-disable-next-line prefer-destructuring
                                            placeholder = foundSocial.placeholder
                                            socialName = foundSocial.name
                                          }

                                          return (
                                            <StyledSocialNetworkWrapper key={index}>
                                              <Flex alignItems="center" justifyContent="space-between">
                                                <Flex alignItems="center">
                                                  <NavigationLinkIconWrapper>
                                                    <Icon.Drag fill="icon_color_gray" width="10px" height="10px" />
                                                  </NavigationLinkIconWrapper>

                                                  <StyledNetworkImage
                                                    mx="s"
                                                    src={`/assets/vista_page/networks/${field.value.network}.svg`}
                                                    width="20px"
                                                    height="20px"
                                                    $colored={social_links_colored}
                                                  />
                                                </Flex>

                                                <NavigationLinkIconWrapper
                                                  onClick={() => {
                                                    arrayHelpers.remove(index)

                                                    const links = [...values[LINKS]]

                                                    links.splice(index, 1)

                                                    handleChangeBlockComponent({
                                                      page_id,
                                                      block_id,
                                                      key: LINKS,
                                                      value: links,
                                                    })
                                                  }}
                                                >
                                                  <Image width="8px" height="8px" src="/assets/clear.svg" />
                                                </NavigationLinkIconWrapper>
                                              </Flex>

                                              <Box mt="m">
                                                <Input
                                                  label={socialName}
                                                  placeholder={placeholder}
                                                  type="text"
                                                  {...field}
                                                  value={field.value.link || ''}
                                                  onChange={(event) => {
                                                    handleChangeBlockComponent({
                                                      page_id,
                                                      block_id,
                                                      type: LINKS,
                                                      key: index,
                                                      value: { network: field.value.network, link: event.target.value },
                                                    })

                                                    setFieldValue(`${[LINKS]}.${index}.link`, event.target.value)
                                                  }}
                                                  error={
                                                    form.errors[LINKS] &&
                                                    form.errors[LINKS][index] &&
                                                    form.errors[LINKS][index].link
                                                  }
                                                  width="100%"
                                                />
                                              </Box>
                                            </StyledSocialNetworkWrapper>
                                          )
                                        }}
                                      </Field>
                                    </Flex>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Flex>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </form>
                  )}
                />
              </Box>
            )}
          </Formik>

          <Flex mt="l" alignItems="center" justifyContent="space-between">
            <Switch
              isOn={detailed_appearance_theme}
              onClick={() => {
                handleChangeBlockComponent({
                  page_id,
                  block_id,
                  key: DETAILED_APPEARANCE_THEME,
                  value: !detailed_appearance_theme,
                })
              }}
              text="Edit theme"
              fontWeight="normal"
            />

            {detailed_appearance_theme && has_theme_changed && (
              <StyledApplyDefaultThemeText
                onClick={() => {
                  handleChangeBlockComponent({ page_id, block_id, key: SOCIAL_LINKS_THEME, value: null })
                }}
              >
                Apply default page theme
              </StyledApplyDefaultThemeText>
            )}
          </Flex>

          {detailed_appearance_theme && (
            <Flex flexDirection="column" mt="m">
              <ThemeComponent
                handleChangeParameter={({ key, value }) => {
                  handleChangeBlockComponent({ page_id, block_id, type: SOCIAL_LINKS_THEME, key, value })
                }}
                data={theme}
                showHeaderMediasScaleSettings={false}
                showHeaderFormatSettings={false}
                showImageLinkImageScaleSettings={false}
                showContentWidthSettings={false}
                showCornerRoundnessSettings={false}
                showTransparencySettings={false}
                showShadowSettings={false}
                showTextFontWeightSettings={false}
                showTextScaleSettings={false}
                showTextColorSettings={false}
                showBackgroundColorSettings={false}
                showBackgroundColorSettingsPaletteTypeMedia={false}
                showTextFontStyleSettings={false}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <StyledSocialLinksPreviewWrapper>
          {links.map(({ network, link }, index) => {
            let link_updated = link

            if (SOCIAL_PREFIXES[network]) {
              link_updated = `${SOCIAL_PREFIXES[network]}${link}`
            } else if (!isValidUrl({ url: link })) {
              link_updated = ''
            }

            const alt = `${network} social link`

            return (
              <StyledSocialLinkPreviewWrapper
                as={link_updated ? 'a' : 'span'}
                href={link_updated}
                target="_blank"
                key={index}
                onClick={() => {
                  if (link_updated) {
                    handleCollectTrackingData()
                  }
                }}
                alt={alt}
              >
                <StyledNetworkImage
                  src={`/assets/vista_page/networks/${network}.svg`}
                  width={`${social_links_image_scale * 16}px`}
                  height={`${social_links_image_scale * 16}px`}
                  $colored={social_links_colored}
                  alt={alt}
                />
              </StyledSocialLinkPreviewWrapper>
            )
          })}
        </StyledSocialLinksPreviewWrapper>
      )}
    </Fragment>
  )
}

SocialLinksComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
  handleCollectTrackingData: () => {},
}

SocialLinksComponent.propTypes = {
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  handleCollectTrackingData: PropTypes.func,
}

SocialLinksComponent.displayName = 'SocialLinksComponent'

export default SocialLinksComponent
