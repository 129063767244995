import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import debounce from 'lodash.debounce'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { radius, space } from 'theme'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import { ERROR_MESSAGE, PINTEREST, REDDIT } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import Select from 'shared/DropDown'
// eslint-disable-next-line import/no-cycle
import { formatOptionLabel, getAccounts } from '../../helpers'
import { ROUTE_PINTEREST_BOARDS, ROUTE_PINTEREST_SECTIONS } from '../../consts'
import SocialProfileImage from '../SocialProfileImage'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: 450px;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
`

const StyledTextWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledIconWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledNetworkWrapper = styled(Flex)`
  flex-direction: column;
  margin: ${space.s} 0;
  padding: ${space.s} ${space.m};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_color_light};
`

const SPECIAL_SYMBOL = `r/`
const REDDIT_LINK = `https://reddit.com/${SPECIAL_SYMBOL}`
const PINTEREST_LINK = `https://www.pinterest.com/pin/`

const BulkSocialProfilesCustomizationsModal = ({
  isOpen,
  handleDismiss,
  handleSubmit,
  selectedProfiles,
  data,
  ...props
}) => {
  const { socialNetworksWithProblems } = data
  const [customizationData, setCustomizationData] = useState({})
  const [dataForCustomizations, setDataForCustomizations] = useState({})
  const [isGettingDataForCustomizations, setIsGettingDataForCustomizations] = useState(false)
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [submittedClicked, setSubmittedClicked] = useState(false)

  const getPinterestBoardsDataForCustomizations = async () => {
    let pinterestProfiles = {}
    selectedProfiles.forEach(({ id, code }) => {
      if (code === PINTEREST) {
        pinterestProfiles[id] = id
      }
    })

    pinterestProfiles = Object.keys(pinterestProfiles)

    if (pinterestProfiles.length !== 0) {
      const customizationsArray = pinterestProfiles.map((id) => {
        return request({
          path: `${ROUTE_PINTEREST_BOARDS}?profile_gid=${id}`,
        })
      })

      setIsGettingDataForCustomizations(true)

      try {
        const result = await Promise.all(customizationsArray)

        result.forEach((data) => {
          const { items: boards = [], profileId } = data || {}

          if (!dataForCustomizations[profileId]) {
            dataForCustomizations[profileId] = { boards: [], board_sections: [] }
          }

          dataForCustomizations[profileId].boards = boards.map(({ id, name }) => ({ value: id, label: name }))
        })

        setDataForCustomizations({ ...dataForCustomizations })
      } catch (error) {
        errorHelper({
          error,
          componentName: BulkSocialProfilesCustomizationsModal.displayName,
          functionName: 'getPinterestDataForCustomizations',
        })
      }
      setIsGettingDataForCustomizations(false)
    }
  }

  const getPinterestBoardSectionsDataForCustomizations = async ({ profile_gid, board_id }) => {
    if (
      !dataForCustomizations[profile_gid].board_sections ||
      !dataForCustomizations[profile_gid].board_sections[board_id]
    ) {
      let sections = []
      try {
        const response = await request({
          path: `${ROUTE_PINTEREST_SECTIONS}?profile_gid=${profile_gid}&board_id=${board_id}`,
        })

        const { items = [], error } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          sections = items
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BulkSocialProfilesCustomizationsModal.displayName,
          functionName: 'getPinterestBoardSectionsDataForCustomizations',
        })
      } finally {
        if (!dataForCustomizations[profile_gid].board_sections) {
          dataForCustomizations[profile_gid].board_sections = {}
        }

        if (!dataForCustomizations[profile_gid].board_sections[board_id]) {
          dataForCustomizations[profile_gid].board_sections[board_id] = []
        }

        dataForCustomizations[profile_gid].board_sections[board_id] = sections.map(({ name, id }) => ({
          value: id,
          label: name,
        }))
        setDataForCustomizations({ ...dataForCustomizations })
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSubmittedClicked(false)
      setDataForCustomizations({})

      getPinterestBoardsDataForCustomizations()
      if (props.customizationData) {
        setCustomizationData({ ...props.customizationData })
      }
    }
  }, [isOpen])

  const handleUpdateCustomization = ({ code, id, key, value }) => {
    if (!customizationData[code]) {
      customizationData[code] = {}
    }

    if (!customizationData[code][id]) {
      customizationData[code][id] = {}
    }

    customizationData[code][id][key] = value

    setCustomizationData({ ...customizationData })

    if (code === PINTEREST && key === 'board_id' && value) {
      getPinterestBoardSectionsDataForCustomizations({ profile_gid: id, board_id: value })
    }
  }

  const handleSubmitSocialCustomizations = () => {
    let hasNoData = false

    socialNetworksWithProblems.forEach((network) => {
      selectedProfiles
        .filter(({ code }) => code === network)
        .forEach((profile) => {
          const { id } = profile

          if (!customizationData[network] || !customizationData[network][id]) {
            hasNoData = true
          } else if (network === REDDIT) {
            const { subreddit } = customizationData[network][id]

            if (!subreddit) {
              hasNoData = true
            }
          } else if (network === PINTEREST) {
            const { board_id } = customizationData[network][id]

            if (!board_id) {
              hasNoData = true
            }
          }
        })
    })

    if (hasNoData) {
      setSubmittedClicked(true)
      Alert.error('Please select social profile customizations', { timeout: 5000 })
    } else {
      handleSubmit({ customizationData })
    }
  }

  const handleClickCloseModal = () => {
    handleDismiss({ customizationData })
  }

  const loadSuggestedOptions = useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 500),
    []
  )

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="auto" width="100%" height="100%" p="l" position="relative">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Posts customization</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex my="m" flex="1" flexDirection="column" alignItems="center" position="relative">
            <Scrollbars universal>
              <StyledProgressWrapper
                display={isGettingDataForCustomizations ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
                className="cliploader-wrapper"
              >
                <ClipLoader size="100" />
              </StyledProgressWrapper>
              {socialNetworksWithProblems.map((network) => {
                return selectedProfiles
                  .filter(({ code }) => code === network)
                  .map((profile) => {
                    const { code, entityId, id, name, picture_url } = profile

                    let board_id_customized = ''
                    let board_id_name_customized = ''
                    let section_id_customized = ''
                    let section_id_name_customized = ''
                    let subreddit_customized = ''

                    if (customizationData[code] && customizationData[code][id]) {
                      ;({
                        board_id: board_id_customized,
                        board_id_name: board_id_name_customized,
                        section_id: section_id_customized,
                        section_id_name: section_id_name_customized,
                        subreddit: subreddit_customized,
                      } = customizationData[code][id])
                    }

                    if (code === REDDIT) {
                      const subreddit = subreddit_customized
                      return (
                        <Flex my="s" px="m" width="100%" key={`${entityId}_${id}`}>
                          <SocialProfileImage
                            picture_url={picture_url}
                            code={code}
                            name={name}
                            width={pxToRem(40)}
                            height={pxToRem(40)}
                            imageBorderRadius={radius.xl}
                          />

                          {subreddit ? (
                            <Flex width="100%" ml="m" alignItems="center">
                              <StyledTextWrapper flex="1" alignItems="center">
                                <StyledLink as="a" href={`${REDDIT_LINK}${subreddit}`} color="primary" target="_blank">
                                  {`${SPECIAL_SYMBOL}${subreddit}`}
                                </StyledLink>
                              </StyledTextWrapper>
                              <StyledIconWrapper
                                alignItems="center"
                                justifyContent="flex-end"
                                ml="m"
                                onClick={() => {
                                  handleUpdateCustomization({
                                    code,
                                    entityId,
                                    id,
                                    key: 'subreddit',
                                    value: '',
                                  })
                                }}
                                width="16px"
                              >
                                <Icon.Trash width="16px" height="16px" />
                              </StyledIconWrapper>
                            </Flex>
                          ) : (
                            <Flex width="100%" ml="m" flexDirection="column">
                              <Select
                                placeholder="Subreddit"
                                value={null}
                                onChange={(options) => {
                                  if (options && options.length) {
                                    const {
                                      0: { username },
                                    } = options
                                    handleUpdateCustomization({
                                      code,
                                      entityId,
                                      id,
                                      key: 'subreddit',
                                      value: username,
                                    })
                                  }
                                }}
                                loadOptions={(value, callback) =>
                                  loadSuggestedOptions(
                                    {
                                      value,
                                      id,
                                      network: REDDIT,
                                      getSuggestedOptionsErrors,
                                      setGetSuggestedOptionsErrors,
                                    },
                                    callback
                                  )
                                }
                                defaultValue={[]}
                                isMulti
                                isClearable
                                defaultOptions={[]}
                                openMenuOnFocus
                                dropDownType="AsyncSelect"
                                cacheOptions
                                showDropDownArrow={false}
                                formatOptionLabel={({ ...props }) => {
                                  return formatOptionLabel({ ...props, specialSymbol: SPECIAL_SYMBOL })
                                }}
                                noOptionsMessage={() => {
                                  return getSuggestedOptionsErrors[network] ? 'No results found' : null
                                }}
                                error={
                                  (!subreddit || subreddit.length === 0) && submittedClicked
                                    ? 'Subreddit is required'
                                    : ''
                                }
                                height={pxToRem(40)}
                              />
                            </Flex>
                          )}
                        </Flex>
                      )
                    } else if (code === PINTEREST) {
                      const board_id = board_id_customized
                      const board_id_name = board_id_name_customized
                      const section_id = section_id_customized
                      const section_id_name = section_id_name_customized

                      const foundBoard =
                        board_id && dataForCustomizations[id] && dataForCustomizations[id].boards
                          ? dataForCustomizations[id].boards.find(({ value }) => board_id === value)
                          : null

                      const { label: board_label = '' } = foundBoard || {}

                      if (
                        !board_id &&
                        board_id_name &&
                        dataForCustomizations[id] &&
                        dataForCustomizations[id].boards &&
                        dataForCustomizations[id].boards.length !== 0
                      ) {
                        const board = dataForCustomizations[id].boards.find(
                          (board) => board.label.trim().toLowerCase() === board_id_name.trim().toLowerCase()
                        )

                        if (board) {
                          handleUpdateCustomization({
                            code,
                            entityId,
                            id,
                            key: 'board_id',
                            value: board.value,
                          })
                        } else {
                          handleUpdateCustomization({
                            code,
                            entityId,
                            id,
                            key: 'board_id_name',
                            value: '',
                          })
                        }
                      }

                      let sections = []

                      if (board_id && dataForCustomizations[id] && dataForCustomizations[id].board_sections) {
                        sections = dataForCustomizations[id].board_sections[board_id] || []

                        if (!section_id && section_id_name && sections && sections.length !== 0) {
                          if (section_id_name) {
                            const section = sections.find(
                              (section) => section.label.trim().toLowerCase() === section_id_name.trim().toLowerCase()
                            )

                            if (section) {
                              handleUpdateCustomization({
                                code,
                                entityId,
                                id,
                                key: 'section_id',
                                value: section.value,
                              })
                            }
                          } else {
                            handleUpdateCustomization({
                              code,
                              entityId,
                              id,
                              key: 'section_id_name',
                              value: '',
                            })
                          }
                        }
                      }

                      const foundSection =
                        section_id && sections.length !== 0 ? sections.find(({ value }) => section_id === value) : null

                      const { label: section_label = '' } = foundSection || {}

                      return (
                        <StyledNetworkWrapper key={`${entityId}_${id}`}>
                          <Flex width="100%">
                            <SocialProfileImage
                              picture_url={picture_url}
                              code={code}
                              name={name}
                              width={pxToRem(40)}
                              height={pxToRem(40)}
                              imageBorderRadius={radius.xl}
                            />
                            <Flex width="100%" ml="m" flexDirection="column">
                              <Select
                                placeholder="Select a board"
                                value={foundBoard}
                                onChange={(option) => {
                                  const { value = null, label = null } = option || {}
                                  handleUpdateCustomization({
                                    code,
                                    entityId,
                                    id,
                                    key: 'board_id',
                                    value,
                                  })
                                  handleUpdateCustomization({
                                    code,
                                    entityId,
                                    id,
                                    key: 'board_id_name',
                                    value: label,
                                  })

                                  if (board_id) {
                                    handleUpdateCustomization({
                                      code,
                                      entityId,
                                      id,
                                      key: 'section_id',
                                      value: '',
                                    })
                                    handleUpdateCustomization({
                                      code,
                                      entityId,
                                      id,
                                      key: 'section_id_name',
                                      value: '',
                                    })
                                  }
                                }}
                                options={
                                  dataForCustomizations[id] && dataForCustomizations[id].boards
                                    ? dataForCustomizations[id].boards
                                    : []
                                }
                                openMenuOnFocus
                                error={
                                  !board_id && submittedClicked ? 'Please select a board for posting your pin' : ''
                                }
                              />
                            </Flex>
                          </Flex>

                          {sections.length !== 0 && board_id && (
                            <Flex mt="m" alignItems="center" width="100%">
                              <Flex minWidth={pxToRem(40)} />
                              <Flex ml="m" flexDirection="column" width="100%">
                                <Select
                                  placeholder="Select board section"
                                  value={foundSection}
                                  onChange={(option) => {
                                    const { value = null, label = null } = option || {}
                                    handleUpdateCustomization({
                                      code,
                                      entityId,
                                      id,
                                      key: 'section_id',
                                      value,
                                    })

                                    handleUpdateCustomization({
                                      code,
                                      entityId,
                                      id,
                                      key: 'section_id_name',
                                      value: label,
                                    })
                                  }}
                                  options={sections}
                                  openMenuOnFocus
                                />
                              </Flex>
                            </Flex>
                          )}
                        </StyledNetworkWrapper>
                      )
                    }
                    return null
                  })
              })}
            </Scrollbars>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Return to editing
            </Button.Gray>
            <Button.Gradient isSmall onClick={handleSubmitSocialCustomizations}>
              Save customizations
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

BulkSocialProfilesCustomizationsModal.defaultProps = {
  customizationData: {},
}

BulkSocialProfilesCustomizationsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  customizationData: PropTypes.object,
}

BulkSocialProfilesCustomizationsModal.displayName = 'BulkSocialProfilesCustomizationsModal'

export default BulkSocialProfilesCustomizationsModal
