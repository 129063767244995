import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { IMAGE, VIDEO, AUDIO } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Icon from 'components/atoms/Icon'

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.border_color_light)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
`

const DownloadedWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.white)};
`

const StyledWrapper = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${radius.l};
  height: 180px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
  ${({ selected }) =>
    selected && `box-shadow: 0 0 5px 0 ${({ theme }) => transparentize(0.5, theme.colors.box_shadow)};`}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
    ${SelectWrapper} {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) => selected && `transform: scale(0.8);`}
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.icon_color_gray)};
`

const StyledLoadingFlex = styled(Flex)`
  position: absolute;
  z-index: 3;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => transparentize(0.5, theme.colors.white)};
  width: 100%;
  height: 100%;
`

const MediaItem = ({ data, handleClickSelectMedia, handleClickOpenMediaDetail }) => {
  const { media_type = IMAGE, selected = false, isLoading, mediaData } = data
  let { image: url, thumbnail_url } = data

  if (mediaData) {
    if (mediaData.type === AUDIO) {
      // eslint-disable-next-line prefer-destructuring
      url = mediaData.url
      // eslint-disable-next-line prefer-destructuring
      thumbnail_url = mediaData.thumbnail_url
    }
  }

  return (
    <StyledWrapper
      flexDirection="column"
      onClick={(e) => {
        e.preventDefault()
        handleClickOpenMediaDetail({ data })
      }}
      selected={selected}
    >
      <MediaWrapper selected={selected}>
        {media_type.toLowerCase() === VIDEO || media_type.toLowerCase() === AUDIO ? (
          <VideoWithFallback
            url={url}
            controls={false}
            width="100%"
            style={{ position: 'relative', borderRadius: radius.l }}
            height="180px"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: thumbnail_url || '',
                },
              },
            }}
            playing={false}
          />
        ) : (
          <Flex position="relative" width="100%" height="100%">
            {media_type === 'CAROUSEL_ALBUM' && (
              <Box position="absolute" top={space.xs} right={space.xs} zIndex="2">
                <Image src="/assets/vistasocial/instagram_carousel.svg" />
              </Box>
            )}
            <StyledImage
              source={url}
              selected={selected}
              fallbackSource="/assets/landscape.svg"
              fallbackSourceWidth="50%"
              fallbackSourceHeight="50%"
            />
          </Flex>
        )}
      </MediaWrapper>
      <Box
        position="absolute"
        top={space.xs}
        left={space.xs}
        zIndex="2"
        width="100%"
        onClick={(event) => {
          event.stopPropagation()
          handleClickSelectMedia()
        }}
      >
        <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
          <StyledIconCheckmark selected={selected} width="10px" height="10px" />
        </SelectWrapper>
      </Box>
      {mediaData && (
        <Box position="absolute" bottom={space.xs} right={space.xs} zIndex="2">
          <DownloadedWrapper alignItems="center" justifyContent="center">
            <Icon.Download width="16px" height="14px" fill="icon_color_gray" />
          </DownloadedWrapper>
        </Box>
      )}
      {isLoading && (
        <StyledLoadingFlex className="cliploader-wrapper">
          <ClipLoader size="40" />
        </StyledLoadingFlex>
      )}
    </StyledWrapper>
  )
}

MediaItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleClickSelectMedia: PropTypes.func.isRequired,
  handleClickOpenMediaDetail: PropTypes.func.isRequired,
}

export default MediaItem
