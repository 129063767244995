import React, { Fragment } from 'react'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import { transparentize } from 'polished'
import { getFileIconByExtension, truncate } from 'helpers'
import { radius, COLOR_CONSTANTS, space } from 'theme'
import {
  DEFAULT_SOCIAL_PROFILE_SOURCE,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
  DOCUMENT,
  GOOGLE,
  YOUTUBE,
} from 'consts'
import { getSocialNetworkIconByName, getSpecialPostsIconByType } from 'utils/socialNetworks'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Badge from 'components/atoms/Badge'
import {
  POST_NETWORK_COLORS,
  CALENDAR_POST_PREVIEW_CONDENSED,
  CALENDAR_POST_PREVIEW_DETAILED_NO_PREVIEW,
  CALENDAR_POST_PREVIEW_DETAILED_SMALL_PREVIEW,
  CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW,
  CALENDAR_WEEK_DAY_SUNDAY,
  CALENDAR_WEEK_DAY_MONDAY,
  POST_TYPE_SOCIAL_POST,
  POST_TYPE_EXTERNAL_CALENDAR_EVENT,
  POST_TYPE_NOTE,
  PUBLISHED,
  APPROVED,
} from './consts'
// eslint-disable-next-line import/no-cycle
import { getHeaderTitleAndColor } from './helpers'
import SocialProfileImage from './components/SocialProfileImage'

const StyledEventWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: ${radius.m};
`

const StyledEventGroupedWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.m};
`

const SpecialEventsWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: ${space.xs};
`

const StyledPostText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledHoverWrapper = styled(Box)`
  position: relative;
  width: 330px;
  padding: 0;
  border-radius: ${radius.s};
  ${styledSpace};
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
`

const StyledHoverHeader = styled(Flex)`
  border-radius: ${radius.m} ${radius.m} 0 0;
`

const SourceWrapper = styled(Box)`
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  border: 1px solid;
  border-color: rgb(204, 214, 221);
  border-radius: ${radius.l};
`

const MultipleAttachmentsWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  padding: ${space.xs};
  height: 10px;
  background-color: ${({ theme }) => theme.colors.primaryText};
  border-radius: ${radius.pill};
`

const StyledLargeImage = styled(ImageWithFallback)`
  object-fit: cover;
  border: 1px solid transparent;
`

const StyledPostWrapper = styled(Flex)`
  border-left: 3px solid ${({ borderColor }) => borderColor};
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledFlexWithBackgroundImage = styled(Flex)`
  background-image: ${({ backgroundImage }) => backgroundImage};
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ color, theme }) => transparentize(0.9, theme.colors[color] || theme.colors.error)};
  border-radius: ${radius.l};
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`

const CALENDAR_LARGE_VIEWS = ['listMonth', 'listWeek', 'listDay', 'dayGridDay']

export const hoverComponent = ({ post, profile }) => {
  const {
    via,
    status,
    profile: { network, profilePictureUrl, profileName, profileUsername, display } = {}, // has
    profile_gid,
    postText,
    customizations = {},
    createdBy,
    link,
    type,
    queue_labels = [],
    labels = [],
    comments = 0,
    result,
    has_boost,
    advocacy_groups,
  } = post

  const POST_NETWORK_COLOR = POST_NETWORK_COLORS.find((item) => item.network === network)

  const { url: linkUrl, title: linkTitle = '', active: linkActive } = link || {}

  // eslint-disable-next-line prefer-const
  let { error, boosted, boost_schedule_at } = result || {}

  if (error && !error.includes('<a ')) {
    error = truncate(error, 100)
  }

  let updatedPostText = postText
  let publish_as_image = false

  if (customizations && customizations[network] && customizations[network][profile_gid]) {
    if (customizations[network][profile_gid].message) {
      updatedPostText = customizations[network][profile_gid].message
    }

    // eslint-disable-next-line prefer-destructuring
    publish_as_image = customizations[network][profile_gid].publish_as_image
  }

  let postComponentTemplate = ''

  if (link && linkActive) {
    const { thumbnail_url, picture_url } = link || {}
    if (picture_url) {
      postComponentTemplate = (
        <SourceWrapper mr="s" mt="s">
          <ImageWithFallback
            source={thumbnail_url || picture_url}
            fallbackSource={thumbnail_url || picture_url}
            width="100%"
            height="100%"
            borderRadius={radius.l}
          />
        </SourceWrapper>
      )
    }

    if (network === GOOGLE && !publish_as_image) {
      postComponentTemplate = null
    }
  } else {
    const keys = ['postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

    postComponentTemplate = (
      <Flex alignItems="center" flexWrap="wrap">
        {keys.map((key) => {
          if (!post[key] || post[key].length === 0) {
            return null
          }

          return (
            <Fragment key={key}>
              {post[key].map((media, index) => {
                const { id, type, thumbnail_url, thumbnail_url_small, url, extension } = media
                return (
                  <SourceWrapper key={id || index} mr="s" mt="s">
                    {type === IMAGE && (
                      <ImageWithFallback
                        source={thumbnail_url_small || thumbnail_url || url}
                        fallbackSource={extension === 'gif' ? url : thumbnail_url_small || thumbnail_url || url}
                        width="100%"
                        height="100%"
                        borderRadius={radius.l}
                      />
                    )}

                    {type === VIDEO && (
                      <Flex alignItems="center" justifyContent="center" width="100%" height="100%" position="relative">
                        <Box position="absolute" top={space.xs} right={space.xs}>
                          <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                        </Box>
                        <StyledLargeImage
                          key={id}
                          source={thumbnail_url}
                          width="100%"
                          height="100%"
                          maxHeight="64px"
                          fallbackSourceWidth="0px"
                          fallbackSourceHeight="0px"
                          borderRadius={radius.l}
                        />
                      </Flex>
                    )}

                    {type === DOCUMENT && (
                      <ImageWithFallback
                        source={thumbnail_url_small || thumbnail_url || getFileIconByExtension({ extension })}
                        fallbackSource={getFileIconByExtension({ extension })}
                        width="100%"
                        height="100%"
                        borderRadius={radius.l}
                      />
                    )}

                    {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                      <DynamicMedia key={id} media={media} post={post} profiles={[profile]} isSmall />
                    )}
                  </SourceWrapper>
                )
              })}
            </Fragment>
          )
        })}
      </Flex>
    )
  }

  let boosted_color = 'primaryText'
  let boosted_text =
    status === PUBLISHED ? 'Your post will be boosted shortly.' : 'Post will be boosted once published.'

  if (has_boost) {
    if (boosted) {
      if (boosted.error) {
        boosted_color = 'error'
        boosted_text = `Boost failed due to: ${boosted.error}`
      } else if (boosted.link) {
        boosted_color = 'success'
        boosted_text = 'Successfully boosted.'
      }
    } else if (boost_schedule_at) {
      boosted_text = `This post will be boosted ${boost_schedule_at.since}.`
    }
  }

  if (link && linkActive && !updatedPostText) {
    updatedPostText = linkTitle
  }

  const { statusLabel, bgColor } = getHeaderTitleAndColor({ status, network, post })
  return (
    <StyledHoverWrapper>
      <StyledHoverHeader
        width="100%"
        px="m"
        py="m"
        justifyContent="space-between"
        alignItems="center"
        bg={bgColor}
        flexWrap="wrap"
        position="relative"
      >
        <Text fontSize="xs" color="white" fontWeight="bold">
          {statusLabel}
        </Text>
        {createdBy && (
          <Text color="white" fontSize="xs">
            {createdBy}
          </Text>
        )}

        {comments > 0 && (
          <Flex position="absolute" right={`-${space.xs}`} top={space.xs} zIndex="10">
            <Flex position="relative" borderRadius={radius.pill} mx="xs">
              <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="16px" height="16px" />
              <StyledCommentCounterWrapper>
                <Text fontSize="xxxs" color="ternaryText">
                  {comments}
                </Text>
              </StyledCommentCounterWrapper>
            </Flex>
          </Flex>
        )}

        {has_boost && (
          <Flex position="absolute" right={comments > 0 ? space.m : space.xs} top={space.xs} zIndex="10">
            <Icon.Rocket width="16px" height="16px" fill={boosted_color} />
          </Flex>
        )}
      </StyledHoverHeader>

      <Flex width="100%" flexDirection="column" px="m" pb="s">
        {error && (
          <MessageWrapper alignItems="center" px="m" py="s" mt="s">
            <Text
              fontWeight="bold"
              color="error"
              dangerouslySetInnerHTML={{
                __html: error,
              }}
            />
          </MessageWrapper>
        )}

        {!error && has_boost && (
          <MessageWrapper alignItems="center" px="m" py="s" mt="s" color={boosted_color}>
            <Text
              color={boosted_color}
              dangerouslySetInnerHTML={{
                __html: boosted_text,
              }}
            />
          </MessageWrapper>
        )}

        <Flex flexDirection="column">
          {postComponentTemplate && postComponentTemplate}
          <Flex mt="s" alignItems="center">
            <SocialProfileImage code={network} picture_url={profilePictureUrl} width="32px" height="32px" />
            <Flex flexDirection="column" ml="s">
              <Text>{profileName}</Text>
              {profileUsername && <Text color="secondaryText">@{profileUsername}</Text>}
            </Flex>
          </Flex>
        </Flex>
        <Flex mt="xs">
          <Text>{truncate(updatedPostText, 85)}</Text>
        </Flex>
        {linkUrl && linkActive && (
          <Flex mt="xs" alignItems="center">
            <Icon.Link width="12px" height="12px" fill={POST_NETWORK_COLOR.LAST_LIGHT_BLUE} />
            <Text ml="xs" color={POST_NETWORK_COLOR.LAST_LIGHT_BLUE}>
              {truncate(linkUrl, 35)}
            </Text>
          </Flex>
        )}
        {(status === PUBLISHED || status === APPROVED) && (
          <Text mt="s" fontSize="xs" color="secondaryText">
            {type === POST_TYPE_SOCIAL_POST
              ? `${status === PUBLISHED ? 'Published' : 'Scheduled'} directly on ${display}`
              : `${status === PUBLISHED ? 'Published' : 'Scheduled'} via Vista Social${via ? ` ${via}` : ''}`}
            {advocacy_groups && (
              <Text as="span" fontSize="xs" color="secondaryText">
                {' '}
                {advocacy_groups.length === 1 ? 'and shared to advocacy' : 'and shared to advocacies'}
              </Text>
            )}
          </Text>
        )}
        <Flex flexWrap="wrap">
          {labels &&
            labels.length > 0 &&
            labels.map((label) => (
              <Badge.Status key={label} mr="s" mt="xs" color={COLOR_CONSTANTS.SAPPHIRE}>
                <Text fontSize="xs" textAlign="center">
                  {label}
                </Text>
              </Badge.Status>
            ))}
          {queue_labels &&
            queue_labels.length > 0 &&
            queue_labels.map((label) => (
              <Badge.Status key={label} mr="s" mt="xs">
                <Text fontSize="xs" textAlign="center">
                  {label}
                </Text>
              </Badge.Status>
            ))}
        </Flex>
      </Flex>
    </StyledHoverWrapper>
  )
}

const handleMouseOver = ({ id, currentTarget, eventInfo, profile, setTooltipData }) => {
  const { event: { title = '', startStr, extendedProps } = {} } = eventInfo

  const { code, picture_url, name, display, profile_url } = profile || {}

  const updatedPost = {
    id,
    postText: title || '',
    publish_at: startStr,
    profileNetwork: code,
    profile: {
      profileName: name,
      profilePictureUrl: picture_url,
      network: code,
      display,
      profileUrl: profile_url,
    },
  }

  const hoverTemplate = hoverComponent({ post: { ...updatedPost, ...extendedProps }, profile })
  setTooltipData({
    hoverTemplate,
    currentTarget,
    answeredId: id,
    id,
  })
}

export const renderEventContent = ({
  eventInfo,
  selectedProfiles,
  setTooltipData,
  selectedTimezone,
  calendarPostPreview,
  currentViewType = 'dayGridMonth',
}) => {
  if (calendarPostPreview === CALENDAR_POST_PREVIEW_CONDENSED) {
    const { event: { title = '', extendedProps: { picture_url, code, note_event_type } = {} } = {} } = eventInfo

    let event_picture_component = <Image src={picture_url} width="20px" height="20px" />

    if (note_event_type) {
      event_picture_component = <Icon.Note width="20px" height="20px" />
    }

    return (
      <StyledEventGroupedWrapper key={code} alignItems="center" width="100%">
        {event_picture_component}
        <Text ml="s" color="primaryText" fontSize="m">
          {title || ''}
        </Text>
      </StyledEventGroupedWrapper>
    )
  } else {
    const {
      event: {
        id,
        title = '',
        extendedProps: {
          profile_gid,
          postImages = [],
          postVideos = [],
          postDocuments = [],
          postMixedMedias = [],
          link,
          status,
          type,
          specialEvents = [],
          queue_labels,
          comments,
          has_boost,
          type_icon_path,
          result,
          advocacy_groups,
          customizations = {},
        } = {},
        extendedProps,
      } = {},
      timeText,
    } = eventInfo

    let profile
    if (profile_gid) {
      profile = selectedProfiles.find((item) => item.id === profile_gid)
    }

    const { code, picture_url } = profile || {}
    let networkImage

    if (code) {
      networkImage = getSocialNetworkIconByName(code).enabled
    }

    const showNetworkImage = code !== YOUTUBE || (code === YOUTUBE && process.env.NEXT_PUBLIC_YOUTUBE_AUDIT !== 'yes')

    const { eventBgColor, bgColor } = getHeaderTitleAndColor({ status, network: code, post: extendedProps })

    let postComponentTypeTemplate = ''
    let postComponentPreviewTemplate = null
    let dimensions = {
      blockMaxWidthString: `28px`,
      mediaMaxHeightString: '28px',
      mediaMaxWidthString: `28px`,
    }

    if (calendarPostPreview === CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW) {
      if (CALENDAR_LARGE_VIEWS.includes(currentViewType)) {
        dimensions = {
          blockMaxWidthString: `30%`,
          mediaMaxHeightString: 'auto',
          mediaMaxWidthString: `300px`,
        }
      } else {
        dimensions = {
          blockMaxWidthString: `100%`,
          mediaMaxHeightString: 'auto',
          mediaMaxWidthString: `300px`,
        }
      }
    }

    if (type_icon_path) {
      postComponentTypeTemplate = <Image src={type_icon_path} width="14px" height="13px" />
    }

    if (link && link.active) {
      if (link.picture_url) {
        postComponentPreviewTemplate = {
          component: (
            <ImageWithFallback
              source={link.thumbnail_url || link.picture_url}
              height={dimensions.mediaMaxHeightString}
              width={dimensions.mediaMaxWidthString}
              objectFit="cover"
              fallbackSourceWidth="0px"
              fallbackSourceHeight="0px"
              borderRadius={radius.l}
            />
          ),
          rows: 1,
        }

        if (
          code === GOOGLE &&
          customizations &&
          customizations[code] &&
          customizations[code][profile_gid] &&
          !customizations[code][profile_gid].publish_as_image
        ) {
          postComponentPreviewTemplate = null
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (postImages.length || postVideos.length || postDocuments.length || postMixedMedias.length) {
        const keys = ['postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

        const mediaCount =
          (postImages?.length || 0) +
          (postVideos?.length || 0) +
          (postDocuments?.length || 0) +
          (postMixedMedias?.length || 0)

        if (calendarPostPreview === CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW) {
          let indexCounter = 0

          postComponentPreviewTemplate = {
            component: (
              <Flex width={dimensions.mediaMaxWidthString} flexWrap="wrap">
                {keys.map((key) => {
                  if (!extendedProps[key] || extendedProps[key].length === 0) {
                    return null
                  }

                  return (
                    <Fragment key={key}>
                      {extendedProps[key].map((media, index) => {
                        const { id, thumbnail_url, thumbnail_url_small, url, type, extension } = media
                        let width = '100%'
                        if (mediaCount % 2 === 0 || indexCounter !== 0) {
                          width = '50%'
                        }

                        indexCounter++

                        return (
                          <Fragment key={id || index}>
                            {type === IMAGE && (
                              <StyledLargeImage
                                source={thumbnail_url_small || thumbnail_url || url}
                                width={width}
                                height={dimensions.mediaMaxHeightString}
                                fallbackSource={extension === 'gif' ? url : null}
                                fallbackSourceWidth={extension === 'gif' ? null : '0px'}
                                fallbackSourceHeight={extension === 'gif' ? null : '0px'}
                                maxHeight={mediaCount > 1 ? '150px' : 'auto'}
                                borderRadius={radius.l}
                              />
                            )}

                            {type === VIDEO && (
                              <Flex
                                alignItems="center"
                                justifyContent="center"
                                width={width}
                                height={dimensions.mediaMaxHeightString}
                                position="relative"
                              >
                                <Box position="absolute" top={space.xs} right={space.xs}>
                                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                                </Box>
                                <StyledLargeImage
                                  source={thumbnail_url_small || thumbnail_url}
                                  width="100%"
                                  height={dimensions.mediaMaxHeightString}
                                  fallbackSourceWidth="0px"
                                  fallbackSourceHeight="0px"
                                  maxHeight={mediaCount > 1 ? '150px' : 'auto'}
                                  borderRadius={radius.l}
                                />
                              </Flex>
                            )}

                            {type === DOCUMENT && (
                              <StyledLargeImage
                                source={thumbnail_url_small || thumbnail_url || getFileIconByExtension({ extension })}
                                width={width}
                                height={dimensions.mediaMaxHeightString}
                                maxHeight={mediaCount > 1 ? '150px' : 'auto'}
                                fallbackSource={getFileIconByExtension({ extension })}
                                borderRadius={radius.l}
                              />
                            )}

                            {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                              <Flex
                                width="100%"
                                height="100%"
                                maxHeight="150px"
                                maxWidth="150px"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <DynamicMedia media={media} post={extendedProps} profiles={[profile]} isSmall />
                              </Flex>
                            )}
                          </Fragment>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </Flex>
            ),
            rows: Math.round(mediaCount / 2),
          }
        } else {
          let hasFirstMedia = false

          postComponentPreviewTemplate = {
            component: (
              <Fragment>
                {keys.map((key) => {
                  if (!extendedProps[key] || extendedProps[key].length === 0 || hasFirstMedia) {
                    return null
                  }

                  hasFirstMedia = true

                  const { type, thumbnail_url_small, thumbnail_url, url, extension } = extendedProps[key][0]

                  return (
                    <Fragment key={key}>
                      {type === IMAGE && (
                        <ImageWithFallback
                          source={thumbnail_url_small || thumbnail_url || url}
                          height={dimensions.mediaMaxHeightString}
                          width={dimensions.mediaMaxWidthString}
                          objectFit="cover"
                          fallbackSource={extension === 'gif' ? url : null}
                          fallbackSourceWidth={extension === 'gif' ? null : '0px'}
                          fallbackSourceHeight={extension === 'gif' ? null : '0px'}
                          borderRadius={radius.l}
                        />
                      )}

                      {type === VIDEO && (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          width={dimensions.mediaMaxWidthString}
                          height={dimensions.mediaMaxHeightString}
                          position="relative"
                        >
                          <Box position="absolute" top={space.xs} right={space.xs}>
                            <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                          </Box>
                          <ImageWithFallback
                            source={thumbnail_url_small || thumbnail_url}
                            width={dimensions.mediaMaxWidthString}
                            height={dimensions.mediaMaxHeightString}
                            objectFit="cover"
                            fallbackSourceWidth="0px"
                            fallbackSourceHeight="0px"
                            borderRadius={radius.l}
                          />
                        </Flex>
                      )}

                      {type === DOCUMENT && (
                        <ImageWithFallback
                          source={thumbnail_url_small || thumbnail_url || getFileIconByExtension({ extension })}
                          height={dimensions.mediaMaxHeightString}
                          width={dimensions.mediaMaxWidthString}
                          objectFit="cover"
                          fallbackSource={getFileIconByExtension({ extension })}
                          borderRadius={radius.l}
                        />
                      )}

                      {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                        <Flex
                          maxHeight={dimensions.mediaMaxHeightString}
                          maxWidth={dimensions.mediaMaxWidthString}
                          width="100%"
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DynamicMedia
                            media={extendedProps[key][0]}
                            post={extendedProps}
                            profiles={[profile]}
                            isSmall
                          />
                        </Flex>
                      )}
                    </Fragment>
                  )
                })}
              </Fragment>
            ),
            rows: 1,
            counter: mediaCount - 1,
          }
        }
      }
    }

    const commentTemplate = comments ? (
      <Flex position="relative" borderRadius={radius.pill} mr="xs">
        <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="16px" height="16px" />
        <StyledCommentCounterWrapper>
          <Text fontSize="xxxs" color="ternaryText">
            {comments}
          </Text>
        </StyledCommentCounterWrapper>
      </Flex>
    ) : null

    let boosted_color = 'primaryText'

    if (has_boost && result && result.boosted) {
      if (result.boosted.error) {
        boosted_color = 'error'
      } else if (result.boosted.link) {
        boosted_color = 'success'
      }
    }

    const boostTemplate = has_boost ? (
      <Flex alignItems="center" justifyContent="center" mr="xs">
        <Icon.Rocket width="14px" height="14px" fill={boosted_color} />
      </Flex>
    ) : null

    const advocacyTemplate = advocacy_groups ? (
      <Flex alignItems="center" justifyContent="center" mr="xs">
        <Icon.VistaSocialAdvocacy width="14px" height="14px" fill={COLOR_CONSTANTS.SAPPHIRE} />
      </Flex>
    ) : null

    if (profile) {
      if (calendarPostPreview === CALENDAR_POST_PREVIEW_DETAILED_NO_PREVIEW) {
        return (
          <Flex
            key={id || new Date().getTime()}
            name={id}
            width="100%"
            alignItems="center"
            bg={eventBgColor}
            onMouseEnter={(e) => {
              if (id && setTooltipData) {
                handleMouseOver({
                  id,
                  currentTarget: e.currentTarget,
                  eventInfo,
                  profile,
                  setTooltipData,
                  selectedTimezone,
                })
              }
            }}
            onMouseLeave={() => {
              if (setTooltipData) {
                setTooltipData(null)
              }
            }}
          >
            <StyledPostWrapper borderColor={bgColor} alignItems="center" justifyContent="center" width="100%">
              <ImageWithFallback
                source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                height="16px"
                width="16px"
                mx="xs"
                borderRadius={radius.m}
              />
              <StyledEventWrapper flex="1" alignItems="center">
                <Text mt="2px" fontSize="xxs" as="span" fontWeight="bold">
                  {timeText}
                </Text>
                <Text as="span" fontSize="xs" ml="xs">
                  {title || ''}
                </Text>
              </StyledEventWrapper>
              <Flex justifyContent="flex-end">
                {advocacyTemplate}
                {boostTemplate}
                {commentTemplate}
                {queue_labels && (
                  <Flex alignItems="center" ml="xs">
                    <Image src="/assets/vistasocial/queue.svg" width="14px" height="14px" />
                  </Flex>
                )}
                <Flex alignItems="center" mx="xs">
                  {postComponentTypeTemplate}
                </Flex>
                <Image src={networkImage} width="16px" height="16px" />
              </Flex>
            </StyledPostWrapper>
          </Flex>
        )
      } else if (calendarPostPreview === CALENDAR_POST_PREVIEW_DETAILED_SMALL_PREVIEW) {
        return (
          <StyledEventWrapper
            key={id || new Date().getTime()}
            name={id}
            width="100%"
            alignItems="center"
            bg={eventBgColor}
            onMouseEnter={(e) => {
              if (id && setTooltipData) {
                handleMouseOver({
                  id,
                  currentTarget: e.currentTarget,
                  eventInfo,
                  profile,
                  setTooltipData,
                  selectedTimezone,
                })
              }
            }}
            onMouseLeave={() => {
              if (setTooltipData) {
                setTooltipData(null)
              }
            }}
          >
            <StyledPostWrapper borderColor={bgColor} justifyContent="center" width="100%">
              {postComponentPreviewTemplate && (
                <Flex ml="xs" mt="3px" position="relative" width="28px" height={dimensions.mediaMaxHeightString}>
                  {postComponentPreviewTemplate.component}
                  {postComponentPreviewTemplate.counter > 0 && (
                    <MultipleAttachmentsWrapper bottom="-2px" left="12px">
                      <Text as="span" color="primary_text_reverted" fontSize="6px">
                        +{postComponentPreviewTemplate.counter}
                      </Text>
                    </MultipleAttachmentsWrapper>
                  )}
                </Flex>
              )}
              <Flex
                ml="xs"
                flexDirection="column"
                width={`calc(100% - ${postComponentPreviewTemplate ? '36px' : '4px'})`}
              >
                <Flex alignItems="center">
                  <StyledEventWrapper flex="1" alignItems="center">
                    <Text fontSize="xxs" as="span" fontWeight="bold">
                      {timeText}
                    </Text>
                  </StyledEventWrapper>
                  <Flex alignItems="center" justifyContent="flex-end">
                    {advocacyTemplate}
                    {boostTemplate}
                    {commentTemplate}
                    {queue_labels && (
                      <Flex alignItems="center" mr="xs">
                        <Image src="/assets/vistasocial/queue.svg" width="14px" height="14px" />
                      </Flex>
                    )}
                    <Flex>{postComponentTypeTemplate}</Flex>
                    <ImageWithFallback
                      source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                      fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                      height="16px"
                      width="16px"
                      mx="xs"
                      borderRadius={radius.m}
                    />

                    {showNetworkImage && <Image src={networkImage} width="16px" height="16px" />}
                  </Flex>
                </Flex>
                <StyledEventWrapper flex="1" alignItems="center">
                  <StyledPostText as="span" fontSize="xs">
                    {title || ''}
                  </StyledPostText>
                </StyledEventWrapper>
              </Flex>
            </StyledPostWrapper>
          </StyledEventWrapper>
        )
      } else if (calendarPostPreview === CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW) {
        return (
          <StyledEventWrapper
            key={id || new Date().getTime()}
            name={id}
            width="100%"
            alignItems="center"
            bg={eventBgColor}
            onMouseEnter={(e) => {
              if (id && setTooltipData) {
                handleMouseOver({
                  id,
                  currentTarget: e.currentTarget,
                  eventInfo,
                  profile,
                  setTooltipData,
                  selectedTimezone,
                })
              }
            }}
            onMouseLeave={() => {
              if (setTooltipData) {
                setTooltipData(null)
              }
            }}
          >
            <StyledPostWrapper borderColor={bgColor} alignItems="center" justifyContent="center" width="100%">
              <Flex flexDirection="column" width="100%" pl="xs">
                {CALENDAR_LARGE_VIEWS.includes(currentViewType) ? (
                  <Flex width="100%" alignItems="center">
                    <StyledEventWrapper flex="1" alignItems="center">
                      {timeText && (
                        <Text fontSize="xxs" as="span" fontWeight="bold" mr="xs">
                          {timeText}
                        </Text>
                      )}
                      <StyledPostText as="span" fontSize="xs">
                        {title || ''}
                      </StyledPostText>
                    </StyledEventWrapper>
                    <Flex alignItems="center" justifyContent="flex-end">
                      {advocacyTemplate}
                      {boostTemplate}
                      {commentTemplate}
                      {queue_labels && (
                        <Flex alignItems="center" mr="xs">
                          <Image src="/assets/vistasocial/queue.svg" width="14px" height="14px" />
                        </Flex>
                      )}
                      <Flex>{postComponentTypeTemplate}</Flex>
                      <ImageWithFallback
                        source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                        fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                        height="16px"
                        width="16px"
                        mx="xs"
                        borderRadius={radius.m}
                      />
                      {showNetworkImage && <Image src={networkImage} width="16px" height="16px" />}
                    </Flex>
                  </Flex>
                ) : (
                  <Flex flexDirection="column" width="100%">
                    <Flex alignItems="center">
                      <StyledEventWrapper flex="1" alignItems="center">
                        <Text fontSize="xxs" as="span" fontWeight="bold">
                          {timeText}
                        </Text>
                      </StyledEventWrapper>
                      <Flex alignItems="center" justifyContent="flex-end">
                        {advocacyTemplate}
                        {boostTemplate}
                        {commentTemplate}
                        {queue_labels && (
                          <Flex alignItems="center" mr="xs">
                            <Image src="/assets/vistasocial/queue.svg" width="14px" height="14px" />
                          </Flex>
                        )}
                        <Flex>{postComponentTypeTemplate}</Flex>
                        <ImageWithFallback
                          source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                          fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                          height="16px"
                          width="16px"
                          mx="xs"
                          borderRadius={radius.m}
                        />

                        {showNetworkImage && <Image src={networkImage} width="16px" height="16px" />}
                      </Flex>
                    </Flex>
                    <StyledEventWrapper flex="1" alignItems="center">
                      <StyledPostText as="span" fontSize="xs">
                        {title || ''}
                      </StyledPostText>
                    </StyledEventWrapper>
                  </Flex>
                )}
                {postComponentPreviewTemplate && (
                  <Flex position="relative" width={dimensions.blockMaxWidthString}>
                    {postComponentPreviewTemplate.component}
                  </Flex>
                )}
              </Flex>
            </StyledPostWrapper>
          </StyledEventWrapper>
        )
      }
    } else if (type) {
      if (type === POST_TYPE_EXTERNAL_CALENDAR_EVENT) {
        const {
          event_background_color,
          event_text_color = '#000000',
          description,
          location,
          attachments = [],
          publish_at_formatted,
          type_icon_path,
        } = extendedProps

        const postComponentTypeTemplate = type_icon_path ? (
          <Image src={type_icon_path} width="14px" height="13px" />
        ) : null

        const icon = getSpecialPostsIconByType()
        return (
          <Flex key={id || new Date().getTime()} width="100%" alignItems="center" flexDirection="column">
            <Flex
              width="100%"
              alignItems="center"
              flexDirection="column"
              bg={event_background_color || 'background_item_light'}
              mb="xxs"
              borderRadius={radius.m}
              onMouseEnter={(e) => {
                if (setTooltipData) {
                  setTooltipData({
                    hoverTemplate: (
                      <StyledHoverWrapper>
                        {title && (
                          <StyledHoverHeader
                            width="100%"
                            px="m"
                            py="m"
                            justifyContent="space-between"
                            alignItems="center"
                            bg={event_background_color || 'background_item_light'}
                            flexWrap="wrap"
                          >
                            <Text fontSize="xs" fontWeight="bold" color={event_text_color}>
                              {title || ''}
                            </Text>
                            {publish_at_formatted && (
                              <Text color={event_text_color} fontSize="xs">
                                {publish_at_formatted}
                              </Text>
                            )}
                          </StyledHoverHeader>
                        )}
                        {description && (
                          <Flex width="100%" px="m" py="s" alignItems="center">
                            <SourceWrapper>
                              <Image src={icon} width="100%" height="100%" />
                            </SourceWrapper>
                            <Flex flexDirection="column" pl="s" width={`calc(100% - ${icon ? '64px' : '0px'})`}>
                              <Text fontSize="s">{truncate(description, 80)}</Text>
                            </Flex>
                          </Flex>
                        )}
                        {(location || attachments.length > 0) && (
                          <Flex flexDirection="column" px="m" pb="s">
                            {location && (
                              <Flex alignItems="center">
                                <Flex alignItems="center" minWidth="16px" maxWidth="16px">
                                  <Icon.VistaSocialPin width="16px" height="14px" />
                                </Flex>
                                <Text ml="s" fontSize="s">
                                  {location}
                                </Text>
                              </Flex>
                            )}
                            {attachments.length > 0 && (
                              <Flex alignItems="center" mt="s">
                                <Flex alignItems="center" minWidth="16px" maxWidth="16px">
                                  <Icon.MultipleAttachments
                                    width="16px"
                                    height="14px"
                                    fill={COLOR_CONSTANTS.CERULEAN}
                                  />
                                </Flex>
                                <Text ml="s" fontSize="s">
                                  {attachments.map(({ name }) => name).join(', ')}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        )}
                      </StyledHoverWrapper>
                    ),
                    currentTarget: e.currentTarget,
                    answeredId: id,
                    id,
                  })
                }
              }}
              onMouseLeave={() => {
                if (setTooltipData) {
                  setTooltipData(null)
                }
              }}
            >
              <Flex alignItems="center" justifyContent="center" width="100%">
                <StyledEventWrapper flex="1" alignItems="center">
                  <Text fontSize="xxs" as="span" fontWeight="bold" ml="xxs" color={event_text_color}>
                    {timeText}
                  </Text>
                  <StyledPostText as="span" fontSize="xxs" ml="xs" color={event_text_color}>
                    {title || ''}
                  </StyledPostText>
                </StyledEventWrapper>
                {postComponentTypeTemplate && (
                  <Flex alignItems="center" mx="xs">
                    {postComponentTypeTemplate}
                  </Flex>
                )}
                <Flex justifyContent="flex-end" pr="xs">
                  <Image src={icon} width="14px" height="14px" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )
      } else if (type === POST_TYPE_NOTE) {
        const { event_background_color, description, event_text_color, createdBy } = extendedProps
        return (
          <Flex key={id || new Date().getTime()} width="100%" alignItems="center" flexDirection="column">
            <StyledFlexWithBackgroundImage
              width="100%"
              alignItems="center"
              flexDirection="column"
              backgroundImage={event_background_color}
              mb="xxs"
              borderRadius={radius.m}
              onMouseEnter={(e) => {
                if (setTooltipData) {
                  setTooltipData({
                    hoverTemplate: (
                      <StyledHoverWrapper>
                        <StyledFlexWithBackgroundImage
                          width="100%"
                          px="m"
                          py="m"
                          justifyContent="space-between"
                          alignItems="center"
                          backgroundImage={event_background_color}
                          flexWrap="wrap"
                          borderRadius={`${radius.m} ${radius.m} 0 0`}
                        >
                          <Text fontSize="xs" color={event_text_color || 'white'} fontWeight="bold">
                            Calendar note
                          </Text>

                          {createdBy && (
                            <Text color={event_text_color || 'white'} fontSize="xs">
                              {createdBy}
                            </Text>
                          )}

                          {comments > 0 && (
                            <Flex position="absolute" right={`-${space.xs}`} top={space.xs} zIndex="10">
                              <Flex position="relative" borderRadius={radius.pill} mx="xs">
                                <Image
                                  src="/assets/vistasocial/dashboard/comment_filled.svg"
                                  width="16px"
                                  height="16px"
                                />
                                <StyledCommentCounterWrapper>
                                  <Text fontSize="xxxs" color="ternaryText">
                                    {comments}
                                  </Text>
                                </StyledCommentCounterWrapper>
                              </Flex>
                            </Flex>
                          )}
                        </StyledFlexWithBackgroundImage>

                        <Flex width="100%" px="m" py="s" alignItems="center">
                          <Flex flexDirection="column" width="100%">
                            <Text>{title || ''}</Text>
                            {description && (
                              <Text mt="xs" color="secondaryText" fontSize="xs">
                                {description}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </StyledHoverWrapper>
                    ),
                    currentTarget: e.currentTarget,
                    answeredId: id,
                    id,
                  })
                }
              }}
              onMouseLeave={() => {
                if (setTooltipData) {
                  setTooltipData(null)
                }
              }}
            >
              <Flex alignItems="center" justifyContent="center" width="100%">
                <StyledEventWrapper flex="1" alignItems="center">
                  <StyledPostText as="span" fontSize="xxs" ml="xs" color={event_text_color || COLOR_CONSTANTS.BLACK}>
                    {title || ''}
                  </StyledPostText>
                </StyledEventWrapper>

                <Flex justifyContent="flex-end">
                  {commentTemplate}
                  <Icon.Note stroke={event_text_color} width="14px" height="14px" />
                </Flex>
              </Flex>
            </StyledFlexWithBackgroundImage>
          </Flex>
        )
      } else {
        return (
          <SpecialEventsWrapper key={id || new Date().getTime()}>
            {specialEvents.map((event, index) => {
              const { id, title = '', typeDisplayName, holiday_type } = event
              const icon = getSpecialPostsIconByType(holiday_type)

              return (
                <Flex
                  key={index}
                  width="100%"
                  alignItems="center"
                  flexDirection="column"
                  bg="background_item_light"
                  borderRadius={radius.m}
                  onMouseEnter={(e) => {
                    if (setTooltipData) {
                      setTooltipData({
                        hoverTemplate: (
                          <StyledHoverWrapper>
                            {typeDisplayName && (
                              <StyledHoverHeader
                                width="100%"
                                px="m"
                                py="m"
                                justifyContent="space-between"
                                alignItems="center"
                                bg="background_item_light"
                                flexWrap="wrap"
                              >
                                <Text fontSize="xs" color="white" fontWeight="bold">
                                  {typeDisplayName}
                                </Text>
                              </StyledHoverHeader>
                            )}

                            <Flex width="100%" px="m" py="s" alignItems="center">
                              {icon && (
                                <SourceWrapper>
                                  <Image src={icon} width="100%" height="100%" />
                                </SourceWrapper>
                              )}
                              <Flex flexDirection="column" pl="s" width={`calc(100% - ${icon ? '64px' : '0px'})`}>
                                <Text>{title || ''}</Text>
                              </Flex>
                            </Flex>
                          </StyledHoverWrapper>
                        ),
                        currentTarget: e.currentTarget,
                        answeredId: id,
                        id,
                      })
                    }
                  }}
                  onMouseLeave={() => {
                    if (setTooltipData) {
                      setTooltipData(null)
                    }
                  }}
                >
                  <Flex alignItems="center" justifyContent="center" width="100%">
                    <StyledEventWrapper flex="1" alignItems="center">
                      <StyledPostText as="span" fontSize="xxs" ml="xs">
                        {title || ''}
                      </StyledPostText>
                    </StyledEventWrapper>

                    <Flex justifyContent="flex-end">
                      <Image src={icon} width="14px" height="14px" />
                    </Flex>
                  </Flex>
                </Flex>
              )
            })}
          </SpecialEventsWrapper>
        )
      }
    }
    return <Fragment />
  }
}

export const groupCalendarEvents = ({ events, selectedProfiles }) => {
  let calendarEventsFiltered = events
  const selectedProfilesById = {}
  selectedProfiles.forEach((profile) => {
    const { code, id, display } = profile
    const picture_url = getSocialNetworkIconByName(code).enabled
    selectedProfilesById[id] = { code, display, picture_url }
  })

  const calendarEventByDay = {}
  calendarEventsFiltered.forEach((event) => {
    const { profile_gid, start, type } = event
    const updatedStart = moment(start).format('YYYY-MM-DD')
    const profile = selectedProfilesById[profile_gid]
    if (profile) {
      const { code, display, picture_url } = profile
      if (!calendarEventByDay[updatedStart]) {
        calendarEventByDay[updatedStart] = {
          eventsByNetwork: {},
          start: updatedStart,
          end: updatedStart,
        }
      }
      if (!calendarEventByDay[updatedStart].eventsByNetwork[code]) {
        calendarEventByDay[updatedStart].eventsByNetwork[code] = {
          code,
          display,
          picture_url,
          events: [],
        }
      }
      calendarEventByDay[updatedStart].eventsByNetwork[code].events.push(event)
    } else if (type) {
      if (type === POST_TYPE_EXTERNAL_CALENDAR_EVENT || type === POST_TYPE_NOTE) {
        if (!calendarEventByDay[updatedStart]) {
          calendarEventByDay[updatedStart] = {
            eventsByNetwork: {},
            start: updatedStart,
            end: updatedStart,
          }
        }

        if (!calendarEventByDay[updatedStart].eventsByNetwork[type]) {
          if (type === POST_TYPE_EXTERNAL_CALENDAR_EVENT) {
            calendarEventByDay[updatedStart].eventsByNetwork[type] = {
              external_calendars_event_type: type,
              picture_url: getSpecialPostsIconByType(),
              events: [],
            }
          } else if (type === POST_TYPE_NOTE) {
            calendarEventByDay[updatedStart].eventsByNetwork[type] = {
              note_event_type: type,
              picture_url: null,
              events: [],
            }
          }
        }

        calendarEventByDay[updatedStart].eventsByNetwork[type].events.push(event)
      } else {
        const { specialEvents = [] } = event
        specialEvents.forEach((item) => {
          const { holiday_type } = item

          if (!calendarEventByDay[updatedStart]) {
            calendarEventByDay[updatedStart] = {
              eventsByNetwork: {},
              start: updatedStart,
              end: updatedStart,
            }
          }

          if (!calendarEventByDay[updatedStart].eventsByNetwork[holiday_type]) {
            calendarEventByDay[updatedStart].eventsByNetwork[holiday_type] = {
              holiday_type,
              picture_url: getSpecialPostsIconByType(holiday_type),
              events: [],
            }
          }
          calendarEventByDay[updatedStart].eventsByNetwork[holiday_type].events.push(event)
        })
      }
    }
  })

  calendarEventsFiltered = []
  Object.values(calendarEventByDay).forEach(({ start, end, eventsByNetwork }) => {
    Object.values(eventsByNetwork).forEach(
      ({ code, holiday_type, external_calendars_event_type, note_event_type, display, picture_url, events }) => {
        calendarEventsFiltered.push({
          start,
          end,
          picture_url,
          events,
          code,
          display,
          holiday_type,
          external_calendars_event_type,
          note_event_type,
          title: events.length,
        })
      }
    )
  })
  return calendarEventsFiltered
}

export const getCalendarStartDay = ({ week_starts }) => {
  switch (week_starts) {
    case CALENDAR_WEEK_DAY_MONDAY:
      return 1
    case CALENDAR_WEEK_DAY_SUNDAY:
    default:
      return 0
  }
}
