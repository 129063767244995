import { AI_TYPE_COMMENT, AI_TYPE_POST, AI_TYPE_REPLY } from 'consts'

export const { TONE_OPTION, FORMAT_OPTION, BRAND_VOICE_OPTION } = {
  TONE_OPTION: 'tone',
  FORMAT_OPTION: 'format',
  BRAND_VOICE_OPTION: 'brand_voice',
}

const DEFAULT_HELPER_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/11761305030171-Crafting-social-posts-with-AI-Assistant'

export const HELPER_TEXTS = {
  [TONE_OPTION]: { text: 'Select tone for AI Assistant', link: DEFAULT_HELPER_LINK },
  [FORMAT_OPTION]: { text: 'Select format for AI Assistant', link: DEFAULT_HELPER_LINK },
  [BRAND_VOICE_OPTION]: { text: 'Select brand voice for AI Assistant', link: DEFAULT_HELPER_LINK },
}

export const TONE_OPTIONS = [
  { type: '', title: '😶', description: 'No tone' },
  { type: 'funny', title: '😂', description: 'Funny' },
  { type: 'formal', title: '🎩', description: 'Formal' },
  { type: 'informal', title: '🙂', description: 'Informal' },
  { type: 'promotional', title: '📣', description: 'Promotional' },
  { type: 'engaging', title: '💬', description: 'Engaging' },
  { type: 'assertive', title: '🤓', description: 'Assertive' },
  { type: 'catchy', title: '🧲', description: 'Catchy' },
  { type: 'inspirational', title: '🤩', description: 'Inspirational' },
  { type: 'shocking', title: '😲', description: 'Shocking' },
]

export const FORMAT_OPTIONS = [
  { type: '', title: '😶', description: 'No format' },
  { type: 'shorter', title: '', icon: '/assets/shorter.svg', description: 'Shorten' },
  { type: 'longer', title: '', icon: '/assets/longer.svg', description: 'Expand' },
  { type: 'rephrase', title: '', icon: '/assets/refresh.svg', description: 'Rephrase' },
]

export const { TAB_TYPE_GUIDED, TAB_TYPE_ADVANCED } = { TAB_TYPE_GUIDED: 'guided', TAB_TYPE_ADVANCED: 'advanced' }

export const TABS = [
  {
    name: 'Guided mode',
    type: TAB_TYPE_GUIDED,
    label: 'Your original content',
    placeholder: '', // this would default to AI_EXAMPLES[type].textareaPlaceholder
  },
  {
    name: 'Advanced mode',
    type: TAB_TYPE_ADVANCED,
    label: 'What do you want to write about',
    placeholder:
      'Please provided AI Assistant with detailed instructions on the kind of caption you are looking to create.',
  },
]

export const DEFAULT_BRAND_VOICE = {
  type: '',
  title: '😶',
  description: 'No voice',
  brand_voice: 'Without brand voice',
}

export const AI_EXAMPLES = {
  [AI_TYPE_POST]: {
    title: `Examples of what you can ask AI assistant to generate`,
    description: `Please tell us what the caption should be about`,
    textareaPlaceholder: ``,
    buttonTextEnding: `caption`,
    texts: [
      'Create an Instagram caption about Meta launching Threads.',
      'Create a tweet about importance of content marketing for a business.',
      'Write a caption about the benefits of traveling to France in February.',
      'Write a social post about short-form videos. Include stats showing the rise of TikTok.',
      'Write an Instagram caption for a pizza restaurant. Mention New York. Include a joke.',
      'Paraphrase this: Short-form video is a great way to increase reach across social media channels',
      'Create a tagline for a coffee shop located in Nashville. Mention a latte.',
      'Write a social post for a social media post about country music. Use Texas lingo.',
      'Write a funny caption about heading back to the office after a week-long vacation.',
      'Explain artificial intelligence to a 2-year-old',
      'Find a great soccer quote about Pele',
      'Write a Twitter post about the cost of living in Seattle. Include statistics.',

      // 'Write a social post about pizza. Mention history of pizza.',
      // 'Write a social post about hockey in French. Include hockey fun fact.',
      // 'Write a social post about country music. Use a Texas joke. Mention concert.',

      // 'Write a social post explaining artificial intelligence. Include a scientific statement.',

      // 'Paraphrase this: Short videos are a great way of reaching new followers.',
      // 'Explain artificial intelligence to a 2-year-old',
      // 'Create an analogy for Questions are arrows in that',

      // 'TikTok caption ideas for a social media manager talking about the benefits of scheduling posts.',
      // 'Write a caption that is optimized for Instagram’s algorithm on the benefits of Instagram Reels.',

      // 'Write a Tweet about short-form videos and the rise of TikTok as a platform.',
      // 'Create copy for a social media post about country music. Use Texas lingo and mention a concert.',
      // 'Write a funny caption about heading back to the office after a week-long vacation.',
    ],
    buttonTextSend: 'Send to composer',
  },
  [AI_TYPE_REPLY]: {
    title: `Examples of what you can ask AI assistant to reply to:`,
    description: `Please tell us what you are replying to`,
    textareaPlaceholder: `For example: Reply to ....`,
    buttonTextEnding: `reply`,
    texts: [
      `Reply to Which social networks does Vista Social support?`,
      `Reply to Does Vista Social offer a free plan? Mention Free and Pro plans.`,
      `Reply to Help me reset my password. Mention forgot password link.`,
      `Reply to Is it possible extend my Vista Social trial? Mention 2 week extension.`,
      `Reply to Do you offer discounts? Mention 25% when paying annually.`,
      `Reply to Can we schedule a training session? Include link to https://vist.ly/demo`,
      `Reply to Can you please explain how to use trending sounds in Vista Social?`,
    ],
    buttonTextSend: 'Use as a reply',
  },
  [AI_TYPE_COMMENT]: {
    title: `Examples of what you can ask AI assistant to comment on:`,
    description: `Please tell us what you are commenting on`,
    textareaPlaceholder: `For example: Comment on ....`,
    buttonTextEnding: `comment`,
    texts: [
      `Comment on What a great day to have a cold coffee. Mention Starbucks.`,
      `Comment on We are going to be one of the best in the following year.`,
      `Comment on ou always come in on time, follow your schedule and adhere to your designated lunch break time.`,
      `Comment on His creative skills are an asset to the team. Write a joke about Jone.`,
      `Comment on He maintains a culture of transparency and knowledge-sharing across all levels in your department.`,
      `Comment on Clients usually seek your guidance and expertise.`,
      `Comment on You regularly follow up with existing and new clients to make sure they feel valued and reminded about us.`,
    ],
    buttonTextSend: 'Use as a comment',
  },
}
