export const DEFAULT_TEMPLATES = [
  {
    name: 'Vista Page',
    picture_url: '/assets/vista_page/templates/template_1/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            data: {
              text: 'Vista Page',
              description: 'A place your business can call home',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'png',
                  url: '/assets/vista_page/templates/template_1/header_header_media.png',
                  thumbnail_url: '/assets/vista_page/templates/template_1/header_header_media_thumb.png',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_1/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_1/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            data: { label: 'A link to anything →', link: 'https://vistasocial.com' },
          },
          {
            type: 'text',
            data: {
              text:
                '<p class="ql-align-center">It doesn’t matter who you are or what you sell. A Start Page is your canvas to create an online home for your brand, in just a few minutes.</p>',
            },
          },
          {
            type: 'image_link',
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_1/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_1/image_link_media_thumb.jpeg',
                },
              ],
              text: 'Use image blocks to sell cool products 🛍',
            },
          },
          {
            type: 'text',
            data: {
              text:
                '<p class="ql-align-center">Make it your own! You can edit any of the existing blocks of content on this page or add new blocks.</p>',
            },
          },
          {
            type: 'youtube_video',
            data: { link: 'https://www.youtube.com/watch?v=j59wgd_us6g&ab_channel=VistaSocial' },
          },
          {
            type: 'social_links',
            data: {
              links: [
                { network: 'instagram', link: 'https://instagram.com/vistasocialapp' },
                { network: 'linkedin', link: 'https://www.linkedin.com/company/vistasocial' },
                { network: 'youtube', link: 'https://youtube.com/@vistasocialapp' },
                { network: 'tiktok', link: 'https://www.tiktok.com/@vistasocialapp' },
                { network: 'x', link: 'https://twitter.com/vistasocialapp' },
                { network: 'facebook', link: 'https://facebook.com/vistasocialapp' },
              ],
            },
          },
        ],
      },
    ],
    appearance: {
      background: { background_type: 'COLOR', background_color: '#FFFFFF' },
      header_theme: {
        text_color: { color_type: 'COLOR', color: '#FFFFFF' },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 1.5,
      },
      button_theme: {
        background: { background_type: 'COLOR', background_color: '#0063e3' },
        corner_radius: 0.6,
        text_color: { color_type: 'COLOR', color: '#FFFFFF' },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: { social_links_colored: 0, social_links_image_scale: 1.6 },
      image_link_theme: {
        background: { background_type: 'COLOR', background_color: '#f5f5f5' },
        text_color: { color_type: 'COLOR', color: '#18191F' },
        text_scale: 1,
        text_font_weight: 400,
        text_font_style: 0,
        corner_radius: 0.6,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: { background_type: 'COLOR', background_color: '#f5f5f5' },
        corner_radius: 0.6,
        text_color: { color_type: 'COLOR', color: '#000000' },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: { header: 'Oswald', body: 'Poppins' },
    },
  },
  {
    name: 'Flower shop',
    picture_url: '/assets/vista_page/templates/template_2/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Flower Shop',
              description: 'Send flowers to family and friends',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_2/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_2/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_2/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_2/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Shop flowers →',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'image_link',
            isVisible: true,
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_1/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_1/image_link_media_thumb.jpeg',
                },
              ],
              text: 'Use image blocks to sell cool products 🌸',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'GRADIENT',
        background_color: 'linear-gradient(120deg, rgb(246, 211, 101), rgb(253, 160, 133))',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1,
        text_font_weight: 300,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 2.4,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#e0906a',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Roboto Slab',
        body: 'Roboto Slab',
      },
    },
  },
  {
    name: 'DJ Neo',
    picture_url: '/assets/vista_page/templates/template_3/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Neo',
              description: 'New album is out now',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_3/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_3/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_3/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_3/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Dance all night (New song)',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Book and event',
              link: '',
            },
          },
          {
            type: 'image_link',
            isVisible: true,
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_3/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_3/image_link_media_thumb.jpeg',
                },
              ],
              text: '',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#fc94fd',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 2.7,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#2b0778',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1,
        text_font_weight: 400,
        text_font_style: 0,
        corner_radius: 0.6,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Sky Realty',
    picture_url: '/assets/vista_page/templates/template_4/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Sky Realty',
              description: 'Your forever home',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_4/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_4/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_4/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_4/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Schedule an appointment →',
              link: '',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'My current listings →',
              link: '',
            },
          },
          {
            type: 'image_links_grid',
            isVisible: true,
            data: {
              items: [
                {
                  _id: '1',
                  data: {
                    medias: [
                      {
                        id: '1',
                        media_gid: '1',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_4/image_links_media_1.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_4/image_links_media_1_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '2',
                  data: {
                    medias: [
                      {
                        id: '1',
                        media_gid: '1',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_4/image_links_media_2.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_4/image_links_media_2_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '3',
                  data: {
                    medias: [
                      {
                        id: '1',
                        media_gid: '1',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_4/image_links_media_3.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_4/image_links_media_3_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
              ],
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#010101',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 2.6,
      },
      button_theme: {
        background: {
          background_type: 'GRADIENT',
          background_color: 'linear-gradient(135deg, rgb(121, 40, 202), rgb(255, 0, 128))',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#2c2c2c',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Pet Shop',
    picture_url: '/assets/vista_page/templates/template_5/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Pet Shop',
              description: 'We love pets!',
              header_medias: [],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_5/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_5/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'social_links',
            isVisible: true,
            data: {
              links: [
                {
                  network: 'instagram',
                  link: 'https://instagram.com/vistasocialapp',
                },
                {
                  network: 'linkedin',
                  link: 'https://www.linkedin.com/company/vistasocial',
                },
                {
                  network: 'youtube',
                  link: 'https://youtube.com/@vistasocialapp',
                },
                {
                  network: 'tiktok',
                  link: 'https://www.tiktok.com/@vistasocialapp',
                },
                {
                  network: 'x',
                  link: 'https://twitter.com/vistasocialapp',
                },
                {
                  network: 'facebook',
                  link: 'https://facebook.com/vistasocialapp',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'HOLIDAY SALE!',
              link: '',
              button_theme: {
                background: {
                  background_type: 'GRADIENT',
                  background_color: 'linear-gradient(135deg, rgb(254, 194, 6), rgb(255, 98, 167))',
                },
                corner_radius: 0.6,
                text_color: {
                  color_type: 'COLOR',
                  color: '#FFFFFF',
                },
                text_scale: 1.2,
                text_font_weight: 400,
                text_font_style: 0,
              },
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Directions to store',
              link: '',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Read reviews ⭐⭐⭐⭐⭐',
              link: '',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Shop online →',
              link: 'https://vistasocial.com',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#d3ecda',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 1.5,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#078654',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 1,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#FFFFFF',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Wedding',
    picture_url: '/assets/vista_page/templates/template_6/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Jenny & Paul',
              description: 'Wedding celebration on March 10, 2024',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_6/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_6/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_6/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_6/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Join us →',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'image_link',
            isVisible: true,
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_6/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_6/image_link_media_thumb.jpeg',
                },
              ],
              text: 'Looking forward seeing you on our event 🔔',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'GRADIENT',
        background_color: 'linear-gradient(90deg, rgb(250, 112, 154), rgb(254, 225, 64))',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1,
        text_font_weight: 300,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_medium_background',
        header_media_scale: 3,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#FFFFFF',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1,
        text_font_weight: 400,
        text_font_style: 0,
        corner_radius: 0.6,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Roboto Slab',
        body: 'Roboto Slab',
      },
    },
  },
  {
    name: 'Studio',
    picture_url: '/assets/vista_page/templates/template_7/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Le Studio',
              description: 'New York City Portrait Photography',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_7/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_7/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Book now!',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'image_link',
            isVisible: true,
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_7/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_7/image_link_media_thumb.jpeg',
                },
              ],
              text: '',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'MEDIA',
        background_media: {
          id: '1',
          media_gid: '1',
          type: 'image',
          extension: 'jpeg',
          url: '/assets/vista_page/templates/template_7/page_background_media.jpeg',
          thumbnail_url: '/assets/vista_page/templates/template_7/page_background_media_thumb.jpeg',
        },
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 2.8,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#000000',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1,
        text_font_weight: 400,
        text_font_style: 0,
        corner_radius: 0.6,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#FFFFFF',
        },
        corner_radius: 0.3,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Payment',
    picture_url: '/assets/vista_page/templates/template_8/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Laila',
              description: 'Strength training on your schedule',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_8/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_8/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_8/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_8/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'typeform',
            isVisible: true,
            data: {
              id: 'https://7w7pxaq6zr1.typeform.com/to/tK4YLuTo',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#010101',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_medium_background',
        header_media_scale: 2.7,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f72040',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#2c2c2c',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Design',
    picture_url: '/assets/vista_page/templates/template_9/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Cathy',
              description: 'Boutique Interior Design Firm',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_9/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_9/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_9/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_9/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Schedule Free Consultation',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'image_links_grid',
            isVisible: true,
            data: {
              items: [
                {
                  _id: '1',
                  data: {
                    medias: [
                      {
                        id: '1',
                        media_gid: '1',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_1.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_1_thumb.jpeg',
                      },
                    ],
                    link: 'https://vistasocial.com',
                    alt_text: '',
                  },
                },
                {
                  _id: '2',
                  data: {
                    medias: [
                      {
                        id: '2',
                        media_gid: '2',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_2.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_2_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '3',
                  data: {
                    medias: [
                      {
                        id: '3',
                        media_gid: '3',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_3.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_3_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '4',
                  data: {
                    medias: [
                      {
                        id: '4',
                        media_gid: '4',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_4.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_4_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '5',
                  data: {
                    medias: [
                      {
                        id: '5',
                        media_gid: '5',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_5.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_5_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
                {
                  _id: '6',
                  data: {
                    medias: [
                      {
                        id: '6',
                        media_gid: '6',
                        type: 'image',
                        extension: 'jpeg',
                        url: '/assets/vista_page/templates/template_9/image_links_media_6.jpeg',
                        thumbnail_url: '/assets/vista_page/templates/template_9/image_links_media_6_thumb.jpeg',
                      },
                    ],
                    link: '',
                    alt_text: '',
                  },
                },
              ],
            },
          },
          {
            type: 'social_links',
            isVisible: true,
            data: {
              links: [
                {
                  network: 'instagram',
                  link: 'https://vistasocial.com',
                },
                {
                  network: 'facebook',
                  link: 'https://vistasocial.com',
                },
                {
                  network: 'whatsapp',
                  link: 'https://vistasocial.com',
                },
              ],
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'GRADIENT',
        background_color: 'linear-gradient(135deg, rgb(253, 252, 251), rgb(226, 209, 195))',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#4A4A4A',
        },
        text_scale: 1.1,
        text_font_weight: 600,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_medium_background',
        header_media_scale: 2.7,
      },
      button_theme: {
        background: {
          background_type: 'GRADIENT',
          background_color: 'linear-gradient(135deg, rgb(102, 126, 234), rgb(118, 75, 162))',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#FFFFFF',
        },
        corner_radius: 0.3,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Racer',
    picture_url: '/assets/vista_page/templates/template_10/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Racer',
              description: '',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_10/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_10/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Podcast  →',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Store  →',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Blog  →',
              link: 'https://vistasocial.com',
            },
          },
          {
            type: 'youtube_video',
            isVisible: true,
            data: {
              link: 'https://www.youtube.com/watch?v=ADs8tvU2xDc',
            },
          },
          {
            type: 'youtube_video',
            isVisible: true,
            data: {
              link: 'https://www.youtube.com/watch?v=UqFWCoBDh7s&t=1s',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#8e79a0',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.4,
        text_font_weight: 500,
        text_font_style: 0,
        header_format: 'header_format_without_banner_vertical',
        header_media_scale: 2.4,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#000000',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.5,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
  {
    name: 'Appointment',
    picture_url: '/assets/vista_page/templates/template_11/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'Madelyn',
              description: 'Health insurance and financial planning',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_11/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_11/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: 'Madelyn',
              background_medias: [],
            },
          },
          {
            type: 'calendly',
            isVisible: true,
            data: {
              link: 'https://calendly.com/madelyn2024/30min',
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'MEDIA',
        background_media: {
          id: '1',
          media_gid: '1',
          type: 'image',
          extension: 'jpeg',
          url: '/assets/vista_page/templates/template_11/page_background_media.jpeg',
          thumbnail_url: '/assets/vista_page/templates/template_11/page_background_media.jpeg',
        },
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.1,
        text_font_weight: 300,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 3,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#0063e3',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Roboto Slab',
        body: 'Roboto Slab',
      },
    },
  },
  {
    name: 'Team',
    picture_url: '/assets/vista_page/templates/template_12/preview.webp',
    pages: [
      {
        items: [
          {
            type: 'header',
            isVisible: true,
            data: {
              text: 'FC Soccer',
              description: 'Join the team today!',
              header_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_12/header_header_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_12/header_header_media_thumb.jpeg',
                },
              ],
              alt_text: '',
              background_medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_12/header_background_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_12/header_background_media_thumb.jpeg',
                },
              ],
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Game schedule',
              link: '',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Current Roster',
              link: '',
            },
          },
          {
            type: 'button_link',
            isVisible: true,
            data: {
              label: 'Current Standings',
              link: '',
            },
          },
          {
            type: 'image_link',
            isVisible: true,
            data: {
              description: '',
              link: '',
              alt_text: '',
              medias: [
                {
                  id: '1',
                  media_gid: '1',
                  type: 'image',
                  extension: 'jpeg',
                  url: '/assets/vista_page/templates/template_12/image_link_media.jpeg',
                  thumbnail_url: '/assets/vista_page/templates/template_12/image_link_media_thumb.jpeg',
                },
              ],
            },
          },
        ],
      },
    ],
    appearance: {
      background: {
        background_type: 'COLOR',
        background_color: '#010101',
      },
      header_theme: {
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1,
        text_font_weight: 300,
        text_font_style: 0,
        header_format: 'header_format_with_banner_vertical_full_background',
        header_media_scale: 2.4,
      },
      button_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5e478',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#000000',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      social_links_theme: {
        social_links_colored: 0,
        social_links_image_scale: 1.6,
      },
      image_link_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#f5f5f5',
        },
        corner_radius: 0.6,
        text_color: {
          color_type: 'COLOR',
          color: '#18191F',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
        image_link_image_scale: 10,
      },
      text_theme: {
        background: {
          background_type: 'COLOR',
          background_color: '#2c2c2c',
        },
        corner_radius: 0.9,
        text_color: {
          color_type: 'COLOR',
          color: '#FFFFFF',
        },
        text_scale: 1.2,
        text_font_weight: 400,
        text_font_style: 0,
      },
      font: {
        header: 'Oswald',
        body: 'Poppins',
      },
    },
  },
]

export const TEMPORARY_VISTA_PAGE = {
  active_page_id: '1',
  pages: [
    {
      items: [
        {
          type: 'header',
          isVisible: true,
          page_id: '1',
          data: {
            text: 'Page not found!',
            description: 'Want this Page to be yours? Let’s create it in minutes.',
            background_medias: [
              {
                id: '1',
                media_gid: '1',
                type: 'image',
                extension: 'webp',
                url: '/assets/vista_page/templates/template_no_page_found/header_background_media.webp',
                thumbnail_url: '/assets/vista_page/templates/template_no_page_found/header_background_media_thumb.webp',
              },
            ],
            header_medias: [
              {
                id: '1',
                media_gid: '1',
                type: 'image',
                extension: 'png',
                url: '/assets/vista_page/templates/template_no_page_found/header_header_media.png',
                thumbnail_url: '/assets/vista_page/templates/template_no_page_found/header_header_media_thumb.png',
              },
            ],
            alt_text: 'Page not found! Want this Page to be yours? Let’s create it in minutes.',
          },
          _id: '1',
        },
        {
          type: 'button_link',
          isVisible: true,
          page_id: '1',
          data: {
            label: 'Get your free page now',
            link: 'https://vistasocial.com/pricing',
          },
          _id: '2',
        },
        {
          type: 'social_links',
          isVisible: true,
          page_id: '1',
          data: {
            links: [
              { network: 'instagram', link: 'https://www.instagram.com/vistasocialapp' },
              { network: 'linkedin', link: 'https://www.linkedin.com/company/vistasocial' },
              { network: 'youtube', link: 'https://youtube.com/@vistasocialapp' },
              { network: 'tiktok', link: 'https://www.tiktok.com/@vistasocialapp' },
              { network: 'x', link: 'https://x.com/vistasocialapp' },
              { network: 'facebook', link: 'https://facebook.com/vistasocialapp' },
            ],
          },
          _id: '3',
        },
      ],
      _id: '1',
    },
  ],
  appearance: {
    background: { background_type: 'COLOR', background_color: '#FFFFFF' },
    header_theme: {
      text_color: { color_type: 'COLOR', color: '#FFFFFF' },
      text_scale: 1.1,
      text_font_weight: 500,
      text_font_style: 0,
      header_format: 'header_format_with_banner_vertical_full_background',
      header_media_scale: 1.5,
    },
    button_theme: {
      background: {
        background_type: 'GRADIENT',
        background_color: 'linear-gradient(90deg, rgb(11, 96, 218) 0%, rgb(36, 130, 253) 100%)',
      },
      corner_radius: 0.9,
      text_color: { color_type: 'COLOR', color: '#FFFFFF' },
      text_scale: 1.2,
      text_font_weight: 500,
      text_font_style: 0,
    },
    social_links_theme: { social_links_colored: 0, social_links_image_scale: 1.5 },
    image_link_theme: {
      background: { background_type: 'COLOR', background_color: '#f5f5f5' },
      corner_radius: 0.6,
      text_color: { color_type: 'COLOR', color: '#18191F' },
      text_scale: 1.2,
      text_font_weight: 400,
      text_font_style: 0,
      image_link_image_scale: 10,
    },
    text_theme: {
      background: { background_type: 'COLOR', background_color: '#f5f5f5' },
      corner_radius: 0.6,
      text_color: { color_type: 'COLOR', color: '#18191F' },
      text_scale: 1.2,
      text_font_weight: 400,
      text_font_style: 0,
    },
    font: { header: 'Libre Baskerville', body: 'Roboto' },
  },
}
