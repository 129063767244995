import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import SyncLoader from 'react-spinners/SyncLoader'
import { COLOR_CONSTANTS, fontWeights, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  PINTEREST,
  REDDIT,
  SNAPCHAT,
  THREADS,
  TIKTOK,
  TUMBLR,
  TWITTER,
  YOUTUBE,
  BLUESKY,
} from 'consts'
import request from 'utils/request'
import withConfirm from 'utils/withConfirm'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import errorHelper from 'utils/errorHelper'
import Modal from 'shared/Modal'
import Select from 'shared/DropDown'
import { Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Icon from 'components/atoms/Icon'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Tooltip from 'components/molecules/Tooltip'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import CalendarItemTooltip from '../CalendarItemTooltip'
import { formatOptionLabel, getAccounts } from '../../helpers'
import { MENTION_URL_NETWORK_START, MENTION_GROUP_NAME_REGEX } from '../../consts'

const StyledImg = styled.img`
  width: 16px;
  margin-right: ${space.xxs};
  border-radius: ${radius.s};
  object-fit: contain;
`

const StyledTextWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledIconWrapper = styled(Flex)`
  cursor: pointer;
`

const IconWarning = styled(Icon.Warning)`
  fill: ${({ theme }) => theme.colors.warning};
  cursor: pointer;
  width: 16px;
  height: 16px;
`

const StyledDisableMentionsText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const StyledInput = styled(Input)`
  height: 38px;
`

const { MENTION_GROUP_NAME_ERROR, MENTION_SOCIALS_ERROR, MENTION_REQUIRED_NETWORKS, MENTION_NAME_EXISTS } = {
  MENTION_GROUP_NAME_ERROR: `Mention name is required`,
  MENTION_SOCIALS_ERROR: `Please specify at least one mention`,
  MENTION_REQUIRED_NETWORKS: `Please confirm you mention on each network you are publishing to. Alternatively, you can remove mention for`,
  MENTION_NAME_EXISTS: `Looks like you already have a mention for this user`,
}

const SPECIAL_SYMBOL = '@'

const LEARN_MORE_ABOUT_MENTIONS_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4409607579675-Adding-mentions-tags-in-posts'

const MAJOR_NETWORKS = [
  { network: FACEBOOK, display: 'Facebook', isSearchable: true },
  { network: INSTAGRAM, display: 'Instagram', isSearchable: true },
  { network: LINKEDIN, display: 'LinkedIn', isSearchable: true },
  { network: TWITTER, display: 'Twitter', isSearchable: true },
  { network: BLUESKY, display: 'Bluesky', isSearchable: true },
  { network: TIKTOK, display: 'TikTok' },
  { network: THREADS, display: 'Threads' },
  // { network: REDDIT, display: 'Reddit' }, // not supported, has a copy in src/routes/Calendar/consts.js
  // { network: PINTEREST, display: 'Pinterest' }, // not supported, has a copy in src/routes/Calendar/consts.js
  // { network: TUMBLR, display: 'Tumblr' }, // not supported, has a copy in src/routes/Calendar/consts.js
  { network: SNAPCHAT, display: 'Snapchat' },
  { network: YOUTUBE, display: 'YouTube' },
]

const MentionsModal = ({
  user,
  setUser,
  mentionsForEdit,
  isOpen,
  handleDismiss,
  isSubmitting,
  handleSave,
  allowedNetworks: ALLOWED_NETWORKS,
  isRemoving,
  handleRemove,
  confirm,
  mentionsModalData: mentionsModalDataProps,
  agencyMentions = [],
  handleSkip,
  selectedProfiles = [],
}) => {
  const inputRef = useRef(null)
  const tooltipRef = useRef(null)

  const [mentionsByNetwork, setMentionsByNetwork] = useState(mentionsForEdit)
  const [formErrors, setFormErrors] = useState([])
  const [showGroupNameSymbolError, setShowGroupNameSymbolError] = useState(false)
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [mentionTextPrompt, setMentionTextPrompt] = useState('')
  const [isDisablingUniversalMentions, setIsDisablingUniversalMentions] = useState(false)
  const [mentionsModalData, setMentionsModalData] = useState(null)

  const { settings } = user || {}
  const { universal_mentions = false } = settings || {}

  useEffect(() => {
    if (isOpen) {
      setFormErrors([])
      let data = mentionsForEdit

      if (mentionsModalDataProps) {
        MAJOR_NETWORKS.forEach(({ network }) => {
          if (mentionsModalDataProps[network]) {
            mentionsModalDataProps[network].value = mentionsModalDataProps[network].id
            mentionsModalDataProps[network].label =
              mentionsModalDataProps[network].username || mentionsModalDataProps[network].name
          }
        })

        setMentionsModalData({ ...mentionsModalDataProps })

        data = JSON.parse(JSON.stringify(mentionsModalDataProps))
        let mentionTextPrompt = ''
        const { profiles = [], name = '', screenName = '' } = data

        mentionTextPrompt = `Looks like you are trying to mention <span style="font-weight: ${
          fontWeights.bold
        };">${SPECIAL_SYMBOL}${screenName || name}</span>. Please create a universal mention for it.`

        if (profiles.length > 0) {
          const startProfiles = profiles.length === 1 ? profiles : profiles.slice(0, profiles.length - 1)
          const startProfilesString = startProfiles.map(({ display }) => display).join(', ')

          const endProfiles = profiles.length === 1 ? [] : profiles.slice(profiles.length - 1, profiles.length)
          const endProfilesString = endProfiles.length ? ` and ${endProfiles[0].display}` : ''

          mentionTextPrompt = `Looks like you are trying to mention <span style="font-weight: ${
            fontWeights.bold
          };">${SPECIAL_SYMBOL}${screenName ||
            name}</span> on ${startProfilesString}${endProfilesString}. Please confirm it on each network you are using it on.`
        }
        setMentionTextPrompt(mentionTextPrompt)

        const MAJOR_NETWORKS_UPDATED = JSON.parse(JSON.stringify(MAJOR_NETWORKS))

        MAJOR_NETWORKS_UPDATED.forEach((item) => {
          const { network } = item
          if (!data[network] && !profiles.find((profile) => profile.network === network)) {
            item.isMajor = true
            profiles.push(item)
          }
        })

        profiles.forEach(({ network }) => {
          const { isSearchable } =
            MAJOR_NETWORKS_UPDATED.find((major_network) => major_network.network === network) || {}

          data[network] = { isLoading: !!isSearchable }
        })

        data.name = name.replace(MENTION_GROUP_NAME_REGEX, '')

        setMentionsModalData({ ...data })
      }
      setMentionsByNetwork({ ...data })
    }
  }, [isOpen])

  const collectData = async () => {
    const name = inputRef.current.value
    const { profiles = [], screenName } = mentionsByNetwork

    profiles.forEach((profile) => {
      const { isSearchable } = MAJOR_NETWORKS.find(({ network }) => network === profile.network) || {}
      profile.isSearchable = isSearchable

      if (mentionsByNetwork[profile.network]) {
        mentionsByNetwork[profile.network].isSearchable = isSearchable
      }
    })

    const foundProfileIndex = profiles.findIndex(({ noNeedUpload, isSearchable }) => isSearchable && !noNeedUpload)

    if (foundProfileIndex > -1) {
      const { network } = profiles[foundProfileIndex]

      const ids = []
      if (network === LINKEDIN && selectedProfiles) {
        selectedProfiles.forEach(({ code, id }) => {
          if (code === LINKEDIN) {
            ids.push(id)
          }
        })
      }

      const screenNameCleaned = screenName ? screenName.replace(MENTION_GROUP_NAME_REGEX, '').toLowerCase() : ''

      const options = await getAccounts({
        value: screenNameCleaned !== name ? name : screenName || name,
        network,
        getSuggestedOptionsErrors,
        setGetSuggestedOptionsErrors,
        ids,
      })

      if (options && options.length) {
        // eslint-disable-next-line no-use-before-define
        await handleClickSelectAccount({ network, account: options[0], foundProfileIndex })
      } else {
        mentionsByNetwork.profiles[foundProfileIndex].noNeedUpload = true
        mentionsByNetwork[network].hasSearchError = true
        mentionsByNetwork[network].isLoading = false
        setMentionsByNetwork({ ...mentionsByNetwork })
      }
    }
  }

  useEffect(() => {
    if (mentionsByNetwork.profiles && mentionsByNetwork.profiles.length !== 0) {
      collectData()
    }
  }, [mentionsByNetwork])

  const handleSearch = () => {
    const name = inputRef.current.value

    if (name) {
      mentionsByNetwork.profiles = [
        { network: FACEBOOK },
        { network: INSTAGRAM },
        { network: LINKEDIN },
        { network: TWITTER },
        { network: TIKTOK },
        { network: BLUESKY },
      ]

      mentionsByNetwork.profiles.forEach(({ network }) => {
        mentionsByNetwork[network] = { isLoading: true }
      })

      setMentionsByNetwork({ ...mentionsByNetwork })
    }
  }

  const handleClickSearch = () => {
    const foundLoadingNetwork = MAJOR_NETWORKS.find(
      ({ network, isSearchable }) => isSearchable && mentionsByNetwork[network] && mentionsByNetwork[network].isLoading
    )

    if (foundLoadingNetwork) {
      Alert.error('Please wait a bit longer while mentions are being collected.', { timeout: 5000 })
    } else {
      const foundLoadedProfile = MAJOR_NETWORKS.find(
        ({ network, isSearchable }) => isSearchable && mentionsByNetwork[network]
      )

      if (foundLoadedProfile) {
        confirm({
          fn: () => () => handleSearch(),
          message: `You have mentions that have been matched, would you like to run a new search?`,
          action: 'Search',
          cancel: 'Go back',
        })
      } else {
        handleSearch()
      }
    }
  }

  const handleSkipSubmitForm = () => {
    const name = inputRef.current.value

    const hasMentionWithTheSameName = agencyMentions.find(
      (mention) => mention.id !== mentionsByNetwork.id && name && mention.name.toLowerCase() === name.toLowerCase()
    )

    if (hasMentionWithTheSameName || name.length === 0) {
      if (name.length === 0) {
        Alert.error(`Please do not change the mention name if you are going to skip this step.`, { timeout: 5000 })
      } else {
        Alert.error(`Please do not change the mention name if you are going to skip this step.`, { timeout: 5000 })
      }
    } else {
      const data = {
        name,
      }

      handleSkip(data)
    }
  }

  const handleSubmitForm = async () => {
    const name = inputRef.current.value

    const MAJOR_NETWORKS_UPDATED = JSON.parse(JSON.stringify(MAJOR_NETWORKS))

    let isStillLoadingProfile = false
    MAJOR_NETWORKS_UPDATED.forEach(({ network }) => {
      if (
        mentionsByNetwork[network] &&
        mentionsByNetwork[network].isLoading &&
        mentionsByNetwork[network].isSearchable
      ) {
        isStillLoadingProfile = true
      }
    })

    if (isStillLoadingProfile) {
      Alert.warning(`Please wait a bit while social profiles are being auto matched.`)
    } else {
      let hasAtLeastOneNetwork = false

      ALLOWED_NETWORKS.forEach(({ code, active }) => {
        if (active && mentionsByNetwork[code] && !mentionsByNetwork[code].hasSearchError) {
          hasAtLeastOneNetwork = true
        }
      })

      const notFoundProfiles = []
      if (mentionsModalData) {
        const { profiles = [] } = mentionsModalData
        profiles.forEach(({ network, display, isMajor }) => {
          if (!isMajor && (!mentionsByNetwork[network] || mentionsByNetwork[network].hasSearchError)) {
            notFoundProfiles.push({ network, display })
          }
        })
      }

      const errors = []

      let hasMentionWithTheSameName = false

      agencyMentions.forEach((mention) => {
        if (mention.id !== mentionsByNetwork.id) {
          if (name && mention.name.toLowerCase() === name.toLowerCase()) {
            hasMentionWithTheSameName = true
          }
        }
      })

      if (hasMentionWithTheSameName) {
        errors.push(MENTION_NAME_EXISTS)
      } else if (name.length === 0) {
        errors.push(MENTION_GROUP_NAME_ERROR)
      } else if (!hasAtLeastOneNetwork) {
        errors.push(MENTION_SOCIALS_ERROR)
      }

      if (errors.length !== 0) {
        setFormErrors([...errors])
      } else {
        setFormErrors([])

        const { id } = mentionsByNetwork

        const body = {
          name,
        }

        const data = {
          name,
        }

        let hasGeneratedAccount = false
        let doesntHaveMentionedProfile = false

        if (notFoundProfiles.length !== 0) {
          doesntHaveMentionedProfile = true
          // const mappedProfiles = [...new Set(notFoundProfiles.map(({ display }) => display))]
          //
          // errors.push(
          //   `${MENTION_REQUIRED_NETWORKS} ${mappedProfiles.join(', ')} ${
          //     mappedProfiles.length === 1 ? 'publication' : 'publications'
          //   } specifically.`
          // )
        }

        ALLOWED_NETWORKS.forEach(({ code, active }) => {
          if (active && mentionsByNetwork[code] && !mentionsByNetwork[code].hasSearchError) {
            const { value, username, link, label, warning, generated = false } = mentionsByNetwork[code]

            if (label) {
              if (generated) {
                hasGeneratedAccount = true
              }

              body[code] = { id: value, name: label, warning }
              if (username) {
                body[code].username = username
              }
              data[code] = { link, ...body[code] }
            }
          }
        })
        if (id) {
          body.id = id
          data.id = id
        }

        const confirmUsername = () => {
          setTimeout(() => {
            if (doesntHaveMentionedProfile) {
              confirm({
                fn: () => () => handleSave(body, data),
                message: `You have not confirmed @${name} on all networks. This is fine for as long as you are sure this to be correct.`,
                action: 'Continue',
                cancel: 'Go back',
              })
            } else {
              handleSave(body, data)
            }
          }, 0)
        }

        if (hasGeneratedAccount) {
          confirm({
            fn: () => () => confirmUsername(),
            message: `Have you verified that we have matched your mention to correct profiles for every network?`,
            action: 'Yes, I have',
          })
        } else {
          confirmUsername()
        }
      }
    }
  }

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 1000),
    []
  )

  const handleClickSelectAccount = async ({ network, account, foundProfileIndex = -1 }) => {
    if (network === FACEBOOK) {
      const { value: page_id } = account
      try {
        const response = await request({
          path: `facebook/page?page_id=${page_id}`,
        })
        if (response) {
          if (response.error) {
            Alert.error(response.error, { timeout: 5000 })
          } else {
            const { username } = response
            account.username = username
          }
        }
      } catch (error) {
        errorHelper({ error, componentName: MentionsModal.displayName, functionName: 'handleClickSelectAccount' })
      }
    }

    account.generated = foundProfileIndex !== -1
    mentionsByNetwork[network] = account

    if (foundProfileIndex > -1 && mentionsByNetwork.profiles && mentionsByNetwork.profiles.length > 0) {
      mentionsByNetwork.profiles[foundProfileIndex].noNeedUpload = true
    }

    setMentionsByNetwork({ ...mentionsByNetwork })
  }

  const handleClickLearnMoreAboutUniversalMentions = () => {
    window.open(LEARN_MORE_ABOUT_MENTIONS_LINK, '_blank')
  }

  const handleClickDisableUniversalMentions = async () => {
    try {
      setIsDisablingUniversalMentions(true)

      const response = await request({
        method: 'PATCH',
        path: `me/preferences`,
        body: { universal_mentions: !universal_mentions },
      })
      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        Alert.success(`Saved mentions have been ${universal_mentions ? 'disabled' : 'enabled'}.`, { timeout: 5000 })
        if (!user.settings) {
          user.settings = {}
        }
        user.settings.universal_mentions = !universal_mentions
        setUser(user)
        if (!user.settings.universal_mentions) {
          handleDismiss()
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MentionsModal.displayName,
        functionName: 'handleClickDisableUniversalMentions',
      })
    } finally {
      setIsDisablingUniversalMentions(false)
    }
  }

  const handleClickOpenUserOnNetwork = ({ code, mention }) => {
    let url = ''

    if (MENTION_URL_NETWORK_START[code]) {
      const mentionDisplayInText = mention.username || mention.name

      if (code === FACEBOOK) {
        url = `${MENTION_URL_NETWORK_START[code]}${mention.id || mentionDisplayInText}`
      } else if (code === LINKEDIN) {
        if (mention.id) {
          if (!`${mention.id}`.includes('urn:li:person')) {
            url = `${MENTION_URL_NETWORK_START[code]}company/${mentionDisplayInText}`
          }
        }
      } else if (code === BLUESKY) {
        url = mention.link || `${MENTION_URL_NETWORK_START[code]}${mention.id || mentionDisplayInText}`
      } else {
        url = `${MENTION_URL_NETWORK_START[code]}${mentionDisplayInText}`
      }
    }

    if (url) {
      window.open(url, '_blank')
    } else {
      Alert.warning(`This username can't be opened, but we have all the information for you post to go through.`)
    }
  }

  const handleUpdateMentionDebounced = debounce((code, value) => {
    mentionsByNetwork[code] = {
      label: value,
      username: value,
    }
    setMentionsByNetwork({ ...mentionsByNetwork })
  }, 300)

  const { name = '', screenName = '' } = mentionsByNetwork

  return (
    <Modal isOpen={isOpen} handleDismiss={handleDismiss} isDisabled={isSubmitting} maxwidth="550px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="l">Saved mention</Text>
        <StyledDisableMentionsText
          as="a"
          href={LEARN_MORE_ABOUT_MENTIONS_LINK}
          target="_blank"
          fontSize="xs"
          color="primary"
        >
          What are saved mentions?
        </StyledDisableMentionsText>
      </Flex>
      {mentionsModalData && (
        <Text
          mt="s"
          color="error"
          dangerouslySetInnerHTML={{
            __html: mentionTextPrompt,
          }}
        />
      )}
      <Box mt="m">
        <Flex flexDirection="column">
          <Flex alignItems="center" width="100%" justifyContent="space-between">
            <Flex flexDirection="column" width="calc(100% - 82px)">
              <Input
                innerRef={inputRef}
                iconComp={<Icon.At width="16px" height="16px" color="primaryText" />}
                placeholder="Name of who you are looking to mention"
                defaultValue={name}
                onChange={(e) => {
                  inputRef.current.value = e.target.value.replace(MENTION_GROUP_NAME_REGEX, '')
                }}
                error={showGroupNameSymbolError ? 'Only letters, numbers and underscores are allowed' : ''}
                width="100%"
              />
            </Flex>

            <Button.Gray onClick={handleClickSearch} isSmallFont>
              Search
            </Button.Gray>
          </Flex>

          {formErrors.length !== 0 && (
            <Flex flexDirection="column">
              {formErrors.map((item) => (
                <ErrorLabel key={item} mt="xxs" textAlign="left" error={item} />
              ))}
            </Flex>
          )}
        </Flex>
        {ALLOWED_NETWORKS.map(({ code, active, input_type }) => {
          if (!active) {
            return null
          }
          const networkReadable = MAJOR_NETWORKS.find(({ network }) => network === code).display

          let showDropdown = false

          if (!mentionsByNetwork[code] || mentionsByNetwork[code].hasSearchError || input_type === 'text') {
            showDropdown = true
          }

          const screenNameCleaned = screenName ? screenName.replace(MENTION_GROUP_NAME_REGEX, '').toLowerCase() : ''

          const inputRefName = inputRef && inputRef.current ? inputRef.current.value : ''

          const name_for_error_preview = screenNameCleaned !== inputRefName ? inputRefName : screenName || inputRefName

          let placeholder = `${networkReadable} username`
          let noResultsMessage = `No results found for ${SPECIAL_SYMBOL}${name_for_error_preview}`

          if (code === LINKEDIN) {
            placeholder = `${networkReadable} username`
            noResultsMessage += ` . Please note that personal profiles can only be mentioned if they follow you page.`
          }

          return (
            <Flex mt="m" key={code} flexDirection="column">
              <Flex alignItems="center" minHeight="38px">
                <Flex alignItems="center" minWidth="110px">
                  <StyledImg src={getSocialNetworkIconByName(code).enabled} />
                  <Text ml="s">{networkReadable}</Text>
                </Flex>
                {!showDropdown ? (
                  <Flex width="100%" alignItems="center">
                    {mentionsByNetwork[code].isLoading ? (
                      <Flex width="100%" alignItems="center" justifyContent="center" className="cliploader-wrapper">
                        <SyncLoader size="5" />
                      </Flex>
                    ) : (
                      <Flex width="100%" alignItems="center">
                        <StyledTextWrapper flex="1" alignItems="center">
                          <ImageWithFallback
                            source={mentionsByNetwork[code].picture_url || '/assets/avatar.svg'}
                            width="20px"
                            height="20px"
                          />
                          <StyledText
                            ml="s"
                            as="a"
                            href={mentionsByNetwork[code].link}
                            color="primary"
                            target="_blank"
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: (
                                  <Flex flexDirection="column" p="s" maxWidth="300px">
                                    {mentionsByNetwork[code].name && (
                                      <Text mb="xs">Name: {mentionsByNetwork[code].name}</Text>
                                    )}

                                    {mentionsByNetwork[code].username && (
                                      <Text>Username: {mentionsByNetwork[code].username}</Text>
                                    )}
                                  </Flex>
                                ),
                                wrapperComp: e.currentTarget,
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            {mentionsByNetwork[code].name || mentionsByNetwork[code].username
                              ? `${SPECIAL_SYMBOL}${mentionsByNetwork[code].name || mentionsByNetwork[code].username}`
                              : mentionsByNetwork[code].value}
                          </StyledText>
                        </StyledTextWrapper>
                        <Flex alignItems="center" ml="m">
                          {mentionsByNetwork[code].generated && (
                            <Tooltip
                              iconComp={IconWarning}
                              message="This social profile has been auto matched. Please verify that we have picked the correct profile."
                              width="300px"
                              left="0px"
                              isTriangleVisible={false}
                            />
                          )}
                          {mentionsByNetwork[code].warning && (
                            <Tooltip
                              iconComp={IconWarning}
                              message={mentionsByNetwork[code].warning}
                              width="300px"
                              left="0px"
                              isTriangleVisible={false}
                            />
                          )}
                          <StyledIconWrapper
                            alignItems="center"
                            justifyContent="flex-end"
                            ml="s"
                            onClick={() => {
                              delete mentionsByNetwork[code]
                              setMentionsByNetwork({ ...mentionsByNetwork })
                            }}
                            width="16px"
                          >
                            <Icon.Trash width="16px" height="16px" />
                          </StyledIconWrapper>

                          <StyledIconWrapper
                            alignItems="center"
                            justifyContent="flex-end"
                            ml="s"
                            onClick={() => {
                              handleClickOpenUserOnNetwork({ code, mention: mentionsByNetwork[code] })
                            }}
                            width="16px"
                          >
                            <Icon.OpenInNew width="16px" height="14px" fill="icon_color_gray" />
                          </StyledIconWrapper>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <Flex width="100%">
                    <Flex flexDirection="column" width="100%">
                      {input_type === 'dropdown' && (
                        <Select
                          placeholder={placeholder}
                          value={null}
                          onChange={(options) => {
                            if (options && options.length) {
                              handleClickSelectAccount({ network: code, account: options[0] })
                            }
                          }}
                          loadOptions={(value, callback) => {
                            const ids = []

                            if (code === LINKEDIN && selectedProfiles) {
                              selectedProfiles.forEach(({ code, id }) => {
                                if (code === LINKEDIN) {
                                  ids.push(id)
                                }
                              })
                            }

                            return loadSuggestedOptions(
                              { value, network: code, getSuggestedOptionsErrors, setGetSuggestedOptionsErrors, ids },
                              callback
                            )
                          }}
                          defaultValue={[]}
                          isMulti
                          isClearable
                          defaultOptions={[]}
                          openMenuOnFocus
                          dropDownType="AsyncSelect"
                          cacheOptions
                          showDropDownArrow={false}
                          formatOptionLabel={({ ...props }) => {
                            return formatOptionLabel({ ...props, specialSymbol: SPECIAL_SYMBOL })
                          }}
                          noOptionsMessage={() => {
                            return getSuggestedOptionsErrors[code]
                              ? 'No results found. Please be sure to enter the exact username/handle for the given network.'
                              : null
                          }}
                        />
                      )}

                      {input_type === 'text' && (
                        <StyledInput
                          defaultValue={
                            mentionsByNetwork[code]
                              ? mentionsByNetwork[code].label || mentionsByNetwork[code].username
                              : ''
                          }
                          onChange={(e) => {
                            handleUpdateMentionDebounced(code, e.target.value)
                          }}
                          placeholder={placeholder}
                          width="100%"
                          isSmall
                        />
                      )}
                    </Flex>
                    {input_type === 'text' &&
                      mentionsByNetwork[code] &&
                      (mentionsByNetwork[code].label || mentionsByNetwork[code].username) && (
                        <StyledIconWrapper
                          alignItems="center"
                          justifyContent="flex-end"
                          ml="s"
                          onClick={() => {
                            handleClickOpenUserOnNetwork({ code, mention: mentionsByNetwork[code] })
                          }}
                          width="16px"
                        >
                          <Icon.OpenInNew width="16px" height="14px" fill="icon_color_gray" />
                        </StyledIconWrapper>
                      )}
                  </Flex>
                )}
              </Flex>
              {showDropdown &&
                mentionsByNetwork[code] &&
                mentionsByNetwork[code].hasSearchError &&
                getSuggestedOptionsErrors[code] && (
                  <Flex>
                    <Flex minWidth="110px" />
                    <Flex>
                      <ErrorLabel fontSize="xs" error={getSuggestedOptionsErrors[code] ? noResultsMessage : ''} />
                    </Flex>
                  </Flex>
                )}
            </Flex>
          )
        })}
        <Text mt="m">
          The following networks do not support mentions: Google Business, Pinterest, Reddit and Tumblr
        </Text>
        <Flex mt="m" alignItems="center" justifyContent="space-between">
          {mentionsForEdit.id ? (
            <ButtonWithLoading
              buttonComp={Button.Gray}
              isLoading={isRemoving}
              onClick={() => {
                confirm({
                  fn: () => () => handleRemove({ id: mentionsForEdit.id }),
                  message: `Are you sure you want to remove this universal mention?`,
                  action: 'Remove',
                })
              }}
              isSmall
            >
              Remove
            </ButtonWithLoading>
          ) : (
            <Flex>
              {mentionsModalData && (
                <StyledDisableMentionsText
                  color="primary"
                  fontSize="xs"
                  onClick={() => {
                    if (!isDisablingUniversalMentions) {
                      if (universal_mentions) {
                        confirm({
                          fn: () => () => handleClickLearnMoreAboutUniversalMentions(),
                          message: `Are you sure you want to disable saved mentions? <br/> <br/> Saved mentions ensure that your mentions are always tagging the right users and avoid any possibility of errors. This is especially important when users have different usernames across different social networks. Would you like to learn more about saved mentions?`,
                          action: `I'd like to learn more`,
                          cancel: 'Disable mentions',
                          cancelFn: () => () => handleClickDisableUniversalMentions(),
                        })
                      } else {
                        confirm({
                          fn: () => () => handleClickDisableUniversalMentions(),
                          message: `Are you sure you want to enable saved mentions? <br/> <br/> Saved mentions ensure that your mentions are always tagging the right users and avoid any possibility of errors. This is especially important when users have different usernames across different social networks.`,
                          action: `Enable mentions`,
                          cancel: `I'd like to learn more`,
                          cancelFn: () => () => handleClickLearnMoreAboutUniversalMentions(),
                        })
                      }
                    }
                  }}
                >
                  {universal_mentions ? 'Disable' : 'Enable'} saved mentions
                </StyledDisableMentionsText>
              )}
            </Flex>
          )}
          <Flex justifyContent="flex-end">
            <Button.Gray mr="m" isSmall onClick={handleDismiss} isDisabled={isSubmitting}>
              Cancel
            </Button.Gray>

            {mentionsModalDataProps && (
              <Button.Gray mr="m" isSmall onClick={handleSkipSubmitForm} isDisabled={isSubmitting}>
                Skip
              </Button.Gray>
            )}

            <ButtonWithLoading isLoading={isSubmitting} onClick={handleSubmitForm} isSmall type="button">
              <Text fontWeight="medium">Continue</Text>
            </ButtonWithLoading>
          </Flex>
        </Flex>
      </Box>

      <CalendarItemTooltip ref={tooltipRef} />
    </Modal>
  )
}

MentionsModal.defaultProps = {
  mentionsForEdit: {},
  mentionsModalData: null,
  agencyMentions: [],
  selectedProfiles: [],
}

MentionsModal.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  mentionsForEdit: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  allowedNetworks: PropTypes.array.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  mentionsModalData: PropTypes.object,
  agencyMentions: PropTypes.array,
  handleSkip: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.array,
}

MentionsModal.displayName = 'MentionsModal'

export default withConfirm(MentionsModal)
