import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import { ERROR_MESSAGE, SNIPPET_TYPE_CAPTION, SNIPPET_TYPE_REPLY, SNIPPET_TYPE_COMMENT } from 'consts'
import { radius, space } from 'theme'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import { ROUTE_SNIPPETS } from 'routes/Calendar/consts'
// eslint-disable-next-line import/no-cycle
import { getSelectedProfileGroupsAndTimezone } from 'routes/Calendar/helpers'
import SnippetModal from './SnippetModal'

const Wrapper = styled(Flex)`
  height: 260px;
  overflow: auto;
`

const StyledButton = styled(Button.Gray)`
  min-width: 30px;
  padding: 0;
  width: 30px;
  height: 30px;
`

const StyledText = styled(Text)`
  height: 100%;
  width: 100%;
  line-height: 30px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background_item_hover};
  }
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.white)};
  border: 2px solid ${({ theme }) => theme.colors.border_color};
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ theme }) => theme.colors.icon_color_gray};
`

const StyledSnippetMessageWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  &:hover {
    box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const { TAB_SAVED, TAB_SEARCH } = {
  TAB_SAVED: 'tab_saved',
  TAB_SEARCH: 'tab_search',
}

const MAX_PREVIEW_STRING = 50

const SearchInputComponent = ({ handleFilterMentionsBySearch, searchPlaceholder }) => {
  const [searchString, setSearchString] = useState('')

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMentionsBySearch(text)
  }

  return (
    <Input
      placeholder={searchPlaceholder}
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      mr="m"
      height="30px"
    />
  )
}

SearchInputComponent.propTypes = {
  handleFilterMentionsBySearch: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
}

const SnippetSelector = forwardRef(
  ({ handleClickAddText, user, type, selectedEntities, selectedProfiles }, snippetSelectorRef) => {
    let TABS = []

    if (type === SNIPPET_TYPE_CAPTION) {
      TABS = [{ name: 'Saved captions', type: TAB_SAVED, searchPlaceholder: 'Search saved captions' }]
    } else if (type === SNIPPET_TYPE_REPLY) {
      TABS = [{ name: 'Saved replies', type: TAB_SAVED, searchPlaceholder: 'Search saved replies' }]
    } else if (type === SNIPPET_TYPE_COMMENT) {
      TABS = [{ name: 'Saved comments', type: TAB_SAVED, searchPlaceholder: 'Search saved comments' }]
    }

    const [activeTab, setActiveTab] = useState(TABS[0])
    const [isGettingSnippets, setIsGettingSnippets] = useState(true)

    const [savedSnippets, setSavedSnippets] = useState([])
    const [isSnippetModalOpen, setIsSnippetModalOpen] = useState(false)
    const [snippetForEdit, setSnippetForEdit] = useState({})
    const [isSnippetSaving, setIsSnippetSaving] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [filteredSnippets, setFilteredSnippets] = useState([])
    const [selectedSnippet, setSelectedSnippet] = useState(null)

    useImperativeHandle(snippetSelectorRef, () => ({
      getSnippetModalState() {
        return isSnippetModalOpen
      },
    }))

    const getSavedSnippets = async () => {
      try {
        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
          selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
          selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
        })

        const response = await request({
          path: `${ROUTE_SNIPPETS}?type=${type}&entity_gids=${selectedEntitiesWithSelectedProfiles
            .map(({ id }) => id)
            .join(',')}`,
        })
        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const { snippets } = response
          snippets.forEach((snippet) => {
            snippet.preview = snippet.messages ? snippet.messages.join(' ').substr(0, MAX_PREVIEW_STRING) : ''
          })
          setSavedSnippets([...snippets])
        }
      } catch (error) {
        errorHelper({ error, componentName: SnippetSelector.displayName, functionName: 'getSavedSnippets' })
      } finally {
        setIsGettingSnippets(false)
      }
    }

    useEffect(() => {
      setSelectedSnippet(null)
      getSavedSnippets()
    }, [])

    const filterSnippetsBySearchString = () => {
      if (searchString) {
        setFilteredSnippets(
          savedSnippets.filter(({ title }) => title.toLowerCase().includes(searchString.toLowerCase()))
        )
      } else {
        setFilteredSnippets([...savedSnippets])
      }
    }

    useEffect(() => {
      filterSnippetsBySearchString()
    }, [searchString])

    useEffect(() => {
      if (activeTab.type === TAB_SAVED) {
        filterSnippetsBySearchString()
      }
    }, [savedSnippets])

    const handleOpenSnippetModal = (item) => {
      setSnippetForEdit({ ...item })
      setIsSnippetModalOpen(true)
    }

    const handleCloseSnippetModal = () => {
      setSnippetForEdit({})
      setIsSnippetModalOpen(false)
    }

    const handleSaveSnippet = async ({ id, title, messages, using_ai }) => {
      try {
        setIsSnippetSaving(true)

        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
          selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
          selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
        })

        const data = {
          title,
          messages,
          entity_gids: selectedEntitiesWithSelectedProfiles.map(({ id }) => id),
          type,
          using_ai,
        }

        let response

        if (id) {
          response = await request({
            method: 'PUT',
            body: data,
            path: `${ROUTE_SNIPPETS}/${id}`,
          })
        } else {
          response = await request({
            method: 'POST',
            body: data,
            path: ROUTE_SNIPPETS,
          })
        }

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const body = { id, title, messages, using_ai }
          body.preview = messages.join(' ').substring(0, MAX_PREVIEW_STRING)
          if (id) {
            for (let i = 0; i < savedSnippets.length; i++) {
              if (savedSnippets[i].id === id) {
                savedSnippets[i] = body
                break
              }
            }
          } else {
            body.id = response.id
            savedSnippets.push(body)
          }
          setSavedSnippets([...savedSnippets])
          handleCloseSnippetModal()
        }
      } catch (error) {
        errorHelper({ error, componentName: SnippetSelector.displayName, functionName: 'handleSaveSnippet' })
      } finally {
        setIsSnippetSaving(false)
      }
    }

    const handleRemoveSnippet = async ({ id }) => {
      try {
        setIsRemoving(true)
        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_SNIPPETS}/${id}`,
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const foundIndex = savedSnippets.findIndex((item) => item.id === id)
          if (foundIndex > -1) {
            savedSnippets.splice(foundIndex, 1)
            setSavedSnippets([...savedSnippets])
          }
          handleCloseSnippetModal()
          Alert.success(`Snippet has been removed.`, { timeout: 5000 })
        }
      } catch (error) {
        errorHelper({ error, componentName: SnippetSelector.displayName, functionName: 'handleRemoveSnippet' })
      } finally {
        setIsRemoving(false)
      }
    }

    const handleFilterMentionsBySearch = debounce((text) => {
      setSearchString(text)
    }, 300)

    return (
      <Wrapper flexDirection="column" width="100%" py="s">
        {!selectedSnippet && (
          <Flex justifyContent="space-between" pr="m" alignItems="center" mb="xs">
            <Flex justifyContent="center" height="30px" alignItems="center">
              {TABS.map((item) => {
                const { name, type } = item
                const { type: activeTabType } = activeTab || {}
                return (
                  <Tab
                    key={name}
                    onClick={() => {
                      setActiveTab(item)
                    }}
                    isActive={activeTabType && type === activeTabType}
                  >
                    <Text color={activeTabType && type === activeTabType ? 'primary' : 'secondaryText'} fontSize="s">
                      {name}
                    </Text>
                  </Tab>
                )
              })}
            </Flex>
            {activeTab.type === TAB_SAVED && (
              <StyledButton isSmall onClick={() => handleOpenSnippetModal({})}>
                <Icon.Plus width="13px" />
              </StyledButton>
            )}
          </Flex>
        )}
        {isGettingSnippets ? (
          <Flex alignItems="center" justifyContent="center" height="100%" width="100%" className="cliploader-wrapper">
            <ClipLoader size="50" />
          </Flex>
        ) : (
          <Flex flexDirection="column" height="100%">
            {activeTab.type === TAB_SAVED && (
              <Fragment>
                {!selectedSnippet ? (
                  <Scrollbars universal>
                    <Flex flexDirection="column" py="xs">
                      <SearchInputComponent
                        handleFilterMentionsBySearch={handleFilterMentionsBySearch}
                        searchPlaceholder={activeTab.searchPlaceholder}
                      />
                      {filteredSnippets.map((item) => (
                        <Flex
                          mt="s"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          pr="m"
                          key={item.id}
                        >
                          <Flex alignItems="center" width="calc(100% - 30px)">
                            <Flex alignItems="center" width="100%">
                              <StyledText
                                pr="s"
                                onClick={() => {
                                  if (item.messages.length === 1) {
                                    handleClickAddText({ message: item.messages[0], using_ai: item.using_ai })
                                  } else {
                                    setSelectedSnippet(item)
                                  }
                                }}
                              >
                                <Text as="span" fontWeight="bold">
                                  {item.title}
                                </Text>
                                <Text as="span" ml="xs" color="secondaryText">
                                  {item.preview}
                                </Text>
                              </StyledText>
                            </Flex>
                          </Flex>

                          <StyledButton isSmall onClick={() => handleOpenSnippetModal(item)}>
                            <Icon.Edit />
                          </StyledButton>
                        </Flex>
                      ))}
                    </Flex>
                  </Scrollbars>
                ) : (
                  <Flex flexDirection="column" height="100%">
                    <Flex alignItems="center" height="30px">
                      <StyledButton
                        onClick={() => {
                          setSelectedSnippet(null)
                        }}
                      >
                        <Icon.Reply width="13px" />
                      </StyledButton>
                      <Flex width="calc(100% - 45px)" ml="s">
                        <StyledText>{selectedSnippet.title}</StyledText>
                      </Flex>
                    </Flex>

                    <Flex flexDiection="column" height="calc(100% - 30px)">
                      <Scrollbars universal>
                        <Flex flexDirection="column" mr="m">
                          {selectedSnippet.messages.map((message, index) => {
                            return (
                              <StyledSnippetMessageWrapper
                                my="s"
                                py="xs"
                                key={index}
                                onClick={() => {
                                  setSelectedSnippet(null)
                                  handleClickAddText({
                                    message,
                                    using_ai: selectedSnippet.using_ai,
                                  })
                                }}
                              >
                                <Flex width="24px" position="relative" justifyContent="center" mt="xxs">
                                  <SelectWrapper alignItems="center" justifyContent="center">
                                    <StyledIconCheckmark width="8px" height="8px" />
                                  </SelectWrapper>
                                </Flex>
                                <Flex width="calc(100% - 36px)">
                                  <Text color="secondaryText">{message}</Text>
                                </Flex>
                              </StyledSnippetMessageWrapper>
                            )
                          })}
                        </Flex>
                      </Scrollbars>
                    </Flex>
                  </Flex>
                )}
              </Fragment>
            )}
          </Flex>
        )}

        {isSnippetModalOpen && (
          <SnippetModal
            user={user}
            handleDismiss={handleCloseSnippetModal}
            handleSave={handleSaveSnippet}
            isSubmitting={isSnippetSaving}
            isOpen={isSnippetModalOpen}
            snippetForEdit={snippetForEdit}
            handleRemove={handleRemoveSnippet}
            isRemoving={isRemoving}
            type={type}
          />
        )}
      </Wrapper>
    )
  }
)

SnippetSelector.defaultProps = {
  selectedEntities: null,
  selectedProfiles: null,
}

SnippetSelector.propTypes = {
  user: PropTypes.object.isRequired,
  handleClickAddText: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
}

SnippetSelector.displayName = 'SnippetSelector'

export default SnippetSelector
