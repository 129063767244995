import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { space } from 'theme'
import { IMAGE, VIDEO, AUDIO, IMAGE_DYNAMIC, VIDEO_DYNAMIC, LABELS_TYPE_MEDIA, DOCUMENT } from 'consts'
import { getFileIconByExtension } from 'helpers'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import TextArea from 'components/atoms/TextArea'
import Image from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import AudioWithFallback from 'components/atoms/AudioWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Select from 'shared/DropDown'
import LabelsSelect from 'shared/LabelEditModal/components/LabelsSelect'

const StyledMediaWrapper = styled(Flex)``

const StyledTextArea = styled(TextArea)`
  height: 100%;
  margin: 0;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const MAX_TEXT_LENGTH = 200
const MAX_ALT_TEXT_LENGTH = 1000
const CLASS_NAME = 'media-settings-media'

const MediasItem = ({ media, labels = [], setLabels, entities = [], handleUpdateMedia }) => {
  const {
    media_gid,
    url,
    thumbnail_url,
    type,
    title = '',
    description = '',
    labels: mediaLabels,
    entities: mediaEntities,
    needThumbnail,
    audio_cover_mime,
    extension,
    audio_name = '',
  } = media

  let sourceTypeTemplate

  if (type === IMAGE) {
    sourceTypeTemplate = (
      <ImageWithFallback
        id={`${CLASS_NAME}-${media_gid}`}
        className={CLASS_NAME}
        source={needThumbnail && extension !== 'gif' ? thumbnail_url : url}
        fallbackSource={needThumbnail && extension !== 'gif' ? thumbnail_url : url}
        width="180px"
      />
    )
  } else if (type === VIDEO) {
    if (needThumbnail) {
      const thumbnail_preview_url = thumbnail_url ? thumbnail_url.replace('_thumb', '') : ''
      sourceTypeTemplate = (
        <Flex alignItems="center" justifyContent="center" width="180px" position="relative">
          <Box position="absolute" top={space.xs} right={space.xs}>
            <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
          </Box>
          <ImageWithFallback
            id={`${CLASS_NAME}-${media_gid}`}
            className={CLASS_NAME}
            source={thumbnail_preview_url || thumbnail_url}
            fallbackSource={thumbnail_preview_url || thumbnail_url}
            width="180px"
          />
        </Flex>
      )
    } else {
      sourceTypeTemplate = (
        <VideoWithFallback
          className={CLASS_NAME}
          id={`${CLASS_NAME}-${media_gid}`}
          url={url}
          controls
          width="100%"
          style={{ maxHeight: '250px', background: '#ffffff' }}
        />
      )
    }
  } else if (type === AUDIO) {
    sourceTypeTemplate = (
      <Fragment>
        {url ? (
          <AudioWithFallback
            url={url}
            controls
            width="100%"
            style={{ position: 'relative' }}
            height="100%"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: audio_cover_mime ? thumbnail_url || '' : '/assets/vistasocial/musical_note.svg',
                },
              },
            }}
            light={false}
          />
        ) : (
          <Image src="/assets/vistasocial/musical_note.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
    sourceTypeTemplate = (
      <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <DynamicMedia media={media} isSmall />
      </Flex>
    )
  } else if (type === DOCUMENT) {
    sourceTypeTemplate = (
      <ImageWithFallback
        id={`${CLASS_NAME}-${media_gid}`}
        className={CLASS_NAME}
        source={thumbnail_url || getFileIconByExtension({ extension })}
        fallbackSource={getFileIconByExtension({ extension })}
        width="180px"
      />
    )
  }

  const handleUpdateTextDebounce = debounce(({ value, key }) => {
    media[key] = value
    handleUpdateMedia({ media })
  }, 300)

  return (
    <StyledMediaWrapper width="100%" p="m">
      <Flex width="100%">
        <Flex alignItems="center" justifyContent="center" width="180px" bg="background_item_light" mr="m">
          {sourceTypeTemplate}
        </Flex>
        <Flex width="calc(100% - 180px)" flexDirection="column">
          {(type === VIDEO || type === VIDEO_DYNAMIC) && (
            <Flex width="100%" flexDirection="column">
              <Box>
                <Text mb="xs" fontSize="s">
                  Title
                </Text>
                <Input
                  placeholder="Video title"
                  label=""
                  defaultValue={title}
                  onChange={(e) => {
                    const { value } = e.target
                    if (value.length <= MAX_TEXT_LENGTH) {
                      handleUpdateTextDebounce({ value: e.target.value, key: 'title' })
                    }
                  }}
                  width="100%"
                  maxLength={MAX_TEXT_LENGTH}
                />
              </Box>
              <Box mt="m">
                <Text mb="xs" fontSize="s">
                  Audio name
                </Text>
                <Input
                  placeholder="Audio name"
                  label=""
                  defaultValue={audio_name}
                  onChange={(e) => {
                    const { value } = e.target
                    if (value.length <= MAX_TEXT_LENGTH) {
                      handleUpdateTextDebounce({ value: e.target.value, key: 'audio_name' })
                    }
                  }}
                  width="100%"
                  maxLength={MAX_TEXT_LENGTH}
                />
              </Box>
            </Flex>
          )}
          {(type === IMAGE || type === IMAGE_DYNAMIC) && (
            <Flex width="100%" flexDirection="column">
              <Text mb="xs" fontSize="s">
                Alternative text
              </Text>
              <StyledTextArea
                defaultValue={description}
                onChange={(e) => {
                  const { value } = e.target
                  if (value.length <= MAX_ALT_TEXT_LENGTH) {
                    handleUpdateTextDebounce({ value: e.target.value, key: 'description' })
                  }
                }}
                placeholder="Alternative text"
                maxLength={MAX_ALT_TEXT_LENGTH}
              />
            </Flex>
          )}

          {(type === AUDIO || type === DOCUMENT) && (
            <Fragment>
              <Flex width="100%" flexDirection="column">
                <Text mb="xs" fontSize="s">
                  Title
                </Text>
                <Input
                  placeholder="Title"
                  label=""
                  defaultValue={title}
                  onChange={(e) => {
                    const { value } = e.target
                    if (value.length <= MAX_TEXT_LENGTH) {
                      handleUpdateTextDebounce({ value: e.target.value, key: 'title' })
                    }
                  }}
                  width="100%"
                  maxLength={MAX_TEXT_LENGTH}
                />
              </Flex>
              <Flex mt="m" width="100%" flexDirection="column">
                <Text mb="xs" fontSize="s">
                  Description
                </Text>
                <StyledTextArea
                  defaultValue={description}
                  onChange={(e) => {
                    const { value } = e.target
                    if (value.length <= MAX_TEXT_LENGTH) {
                      handleUpdateTextDebounce({ value: e.target.value, key: 'description' })
                    }
                  }}
                  placeholder="Description"
                  maxLength={MAX_TEXT_LENGTH}
                />
              </Flex>
            </Fragment>
          )}

          <Flex mt="m" width="100%" flexDirection="column">
            <Text mb="xs" fontSize="s">
              Select labels
            </Text>
            <LabelsSelect
              noOptionMessage={`Label your ${type} by adding new labels`}
              setData={(media) => handleUpdateMedia({ media })}
              selectedLabels={mediaLabels}
              labelOptions={labels}
              setLabelOptions={setLabels}
              data={media}
              type={LABELS_TYPE_MEDIA}
            />
          </Flex>
          <Flex mt="m" width="100%" flexDirection="column">
            <Select
              placeholder="Select profile groups"
              label="Select profile groups"
              value={mediaEntities}
              onChange={(options) => {
                if (options) {
                  media.entities = options
                } else {
                  media.entities = []
                }
                handleUpdateMedia({ media })
              }}
              options={entities}
              openMenuOnFocus
              isMulti
            />
          </Flex>
        </Flex>
      </Flex>
    </StyledMediaWrapper>
  )
}

MediasItem.defaultProps = {
  labels: [],
  entities: [],
}

MediasItem.propTypes = {
  media: PropTypes.object.isRequired,
  labels: PropTypes.array,
  setLabels: PropTypes.func.isRequired,
  entities: PropTypes.array,
  handleUpdateMedia: PropTypes.func.isRequired,
}

export default MediasItem
