import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { radius, COLOR_CONSTANTS } from 'theme'

const RadioStyles = createGlobalStyle`
  input[type='radio'].vistasocialradiobutton {
    visibility: hidden;
    position: absolute;
  }
  input[type='radio'].vistasocialradiobutton + label {
    display: flex;
    position: relative;
    cursor: pointer;
  }
  input[type='radio'].vistasocialradiobutton + label:before {
    display: flex;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 3px;
    vertical-align: baseline;
    content: ' ';
    background-color: ${COLOR_CONSTANTS.WHITE};
    border: 1px solid ${COLOR_CONSTANTS.COSMIC_ENERGY};
  }
  input[type='radio'].vistasocialradiobutton:checked + label:before {
    border: 5px solid ${COLOR_CONSTANTS.COBALT};
  }
  input[type='radio'].vistasocialradiobutton + label:before {
    border-radius: ${radius.pill};
  }
`

const Radio = ({ ...props }) => (
  <React.Fragment>
    <RadioStyles />
    <input type="radio" className="vistasocialradiobutton" {...props} />
  </React.Fragment>
)

export default Radio
