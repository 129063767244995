import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import { PUBLISH_WHEN_READY } from '../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    min-height: 250px;
    display: flex;
    flex-direction: column;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  outline: none;
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const StyledLearnMoreText = styled(Text)`
  text-decoration: none;
`

const VideoProcessingWarningModal = ({ isOpen, handleDismiss, videos, handleClickNextStep }) => {
  const videosLength = videos.length

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" tabIndex={0} $isTop>
            <H4 my="m">Your {videosLength === 1 ? 'video is' : 'videos are'} still processing</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column" p="m">
            <Text>
              Your uploaded {videosLength === 1 ? 'video is' : 'videos are'} still being processed. You can wait for
              just a few more moments or we can publish as soon as {videosLength === 1 ? 'it is' : 'they are'} ready.
              Video processing typically takes no more then a few minutes to complete.{' '}
              <StyledLearnMoreText
                as="a"
                href="https://support.vistasocial.com/hc/en-us/articles/10208587806363-Video-processing"
                target="_blank"
                color="primary"
              >
                Learn more
              </StyledLearnMoreText>{' '}
              about video processing.
            </Text>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray onClick={handleDismiss} isSmall>
              Continue editing
            </Button.Gray>
            <Flex>
              <Button.Gradient
                isSmall
                onClick={() => {
                  handleClickNextStep({ publish_at: PUBLISH_WHEN_READY })
                }}
              >
                Publish when ready
              </Button.Gradient>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

VideoProcessingWarningModal.defaultProps = {}

VideoProcessingWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  videos: PropTypes.array.isRequired,
  handleClickNextStep: PropTypes.func.isRequired,
}

export default VideoProcessingWarningModal
