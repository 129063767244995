import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Picker from '@emoji-mart/react'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { radius, space, fontSizes, fontWeights } from 'theme'
import { AI_TYPE_POST } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import Counter from 'components/molecules/Counter'
import Image from 'components/atoms/Image'
import {
  POST_EMOJI,
  POST_HASHTAG,
  POST_TEXT_EDITOR,
  POST_SNIPPET,
  POST_VARIABLE,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
} from 'routes/Calendar/consts'
// eslint-disable-next-line import/no-cycle
import { sanitize, transformTextToUnicode } from 'routes/Calendar/helpers'
// eslint-disable-next-line import/no-cycle
import HashTagSelector from 'routes/Calendar/components/HashTagSelector'
// eslint-disable-next-line import/no-cycle
import SnippetSelector from 'routes/Calendar/components/SnippetSelector'
// eslint-disable-next-line import/no-cycle
import OpenAIModal from 'routes/Calendar/components/OpenAIModal'
import VariableSelector from 'routes/Calendar/components/VariableSelector'
// eslint-disable-next-line import/no-cycle
import UploadMediaComponent from 'routes/Calendar/components/PostCreationComponent/components/UploadMediaComponent'
// eslint-disable-next-line import/no-cycle
import UploadedMediasComponent from 'routes/Calendar/components/PostCreationComponent/components/UploadedMediasComponent'
import VariablesModal from 'routes/Settings/PublishingSettings/components/VariablesModal'

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.m};
`

const StyledTextAreaWrapper = styled(StyledBox)`
  ${({ hasError, theme }) => hasError && `border: 1px solid ${theme.colors.error};`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`};
  border-radius: ${radius.l};
`

const StyledTextAreaComponentWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: ${space.s} ${space.s} 0;
  border-radius: ${radius.l} ${radius.l} 0 0;
  border: none;
  resize: vertical;
  font: 400 ${fontSizes.xs} Poppins, sans-serif;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.denimText};
  background: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 30px;
  max-height: ${({ maxHeight }) => maxHeight || '300px'};
  outline: 0;
  resize: none;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${({ theme }) => theme.colors.input_placeholder_color};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
`

const StyledTextAreaHiddenBox = styled(Box)`
  width: 100%;
  padding: ${space.s} ${space.s} 0;
  font: 400 ${fontSizes.xs} Poppins, sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 30px;
  max-height: ${({ maxHeight }) => maxHeight || '300px'};
  outline: 0;
  resize: none;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
  pointer-events: none;
  border-color: transparent;
  span.selected_range {
    border-radius: ${radius.s};
    background-color: ${({ theme }) => theme.colors.composer_selected_text_range_color};
    color: ${({ theme }) => theme.colors.white};
  }
  span.variable {
    border-radius: ${radius.s};
    background-color: ${({ theme }) => theme.colors.composer_variable_color};
    color: ${({ theme }) => theme.colors.white};
  }
`

const PostTextHelperComponentWrapper = styled(Box)`
  position: absolute;
  top: 36px;
  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 3;
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || '320px'};
  max-width: 380px;
  background-color: ${({ theme }) => theme.colors.background};
  ${({ showOnTop }) => showOnTop && `top: unset; bottom: 36px;`}
`

const IconWrapper = styled(Box)`
  padding: 0 ${space.s};
  width: 36px;
  height: 36px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.composer_icons_color};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
   svg {
      fill: ${theme.colors.primary};
   }
  `}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledVariantOffsetWrapper = styled(Flex)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background_item_light};
  }
`

const StyledCounterWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: ${space.s};
  background-color: ${({ theme }) => theme.colors.background_modal};

  ${({ disabled }) =>
    disabled &&
    `background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    appearance: none;
    cursor: not-allowed !important;`}
`

const StyledControlsWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal};
  border-radius: 0 0 ${radius.l} ${radius.l};
  ${({ disabled }) =>
    disabled &&
    `background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    appearance: none;
    cursor: not-allowed !important;`}
`

const TextAreaComponent = forwardRef(
  (
    {
      textValue = '',
      textAreaID = '',
      handleUpdateTextFromTextArea,
      handleOpenMentionComponent,
      textareaPlaceholder,
      disabled,
      height,
    },
    ref
  ) => {
    const textAreaRef = useRef(null)
    const hiddenBlockRef = useRef(null)

    const [selectionRange, setSelectionRange] = useState(null)
    const [isInitial, setIsInitial] = useState(true)

    const syncSize = () => {
      if (hiddenBlockRef && hiddenBlockRef.current && textAreaRef && textAreaRef.current) {
        hiddenBlockRef.current.style.height = textAreaRef.current.style.height
      }
    }

    useEffect(() => {
      new MutationObserver(syncSize).observe(textAreaRef.current, {
        attributes: true,
        attributeFilter: ['style'],
      })

      syncSize()
    }, [])

    useEffect(() => {
      if (isInitial) {
        // eslint-disable-next-line no-use-before-define
        handleChangePostText(textValue || '')

        if (textAreaRef && textAreaRef.current) {
          if (height) {
            textAreaRef.current.style.height = height
            setIsInitial(false)
          } else if (textValue) {
            textAreaRef.current.style.height = textValue ? `${textAreaRef.current.scrollHeight}px` : 'auto'
            setIsInitial(false)
          }
        }
      }
    }, [textValue])

    useEffect(() => {
      if (height) {
        textAreaRef.current.style.height = height
      }
    }, [height])

    useEffect(() => {
      if (selectionRange) {
        const { start, end } = selectionRange
        const text = textAreaRef.current.value
        hiddenBlockRef.current.innerHTML = `${sanitize(
          text.substring(0, start)
        )}<span class="selected_range">${sanitize(text.substring(start, end))}</span>${sanitize(text.substring(end))}`
        hiddenBlockRef.current.style.display = 'block'
      }
    }, [selectionRange])

    const handleChangePostText = (text, updateText = true) => {
      if (updateText) {
        if (textAreaRef && textAreaRef.current) {
          textAreaRef.current.value = text
        }
      }

      handleUpdateTextFromTextArea(text)
      if (textAreaRef && textAreaRef.current) {
        if (height) {
          textAreaRef.current.style.height = height
        } else {
          textAreaRef.current.style.height = text ? `${textAreaRef.current.scrollHeight}px` : 'auto'
        }
      }
    }

    useImperativeHandle(ref, () => ({
      handleChangePostText(text) {
        handleChangePostText(text)
      },
      getTextAreaValue() {
        return textAreaRef.current.value
      },
      getTextAreaCurrent() {
        return textAreaRef.current
      },
      getSelectedTextRange() {
        return selectionRange
      },
      handleChangeHiddenBoxText(text) {
        hiddenBlockRef.current.innerHTML = text
      },
    }))

    return (
      <StyledTextAreaComponentWrapper>
        <StyledTextAreaHiddenBox ref={hiddenBlockRef} maxHeight={height} />
        <StyledTextArea
          id={textAreaID || ''}
          ref={textAreaRef}
          defaultValue={textValue || ''}
          onChange={(e) => {
            const { value } = e.target
            handleChangePostText(value, false)
            syncSize()
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 50 && e.shiftKey) {
              handleOpenMentionComponent()
            }
          }}
          onBlur={() => {
            setSelectionRange({ start: textAreaRef.current.selectionStart, end: textAreaRef.current.selectionEnd })
          }}
          onFocus={() => {
            hiddenBlockRef.current.style.display = 'none'
          }}
          onScroll={() => {
            hiddenBlockRef.current.style.top = `${-textAreaRef.current.scrollTop}px`
          }}
          placeholder={textareaPlaceholder}
          rows={3}
          disabled={disabled}
          maxHeight={height}
        />
      </StyledTextAreaComponentWrapper>
    )
  }
)

TextAreaComponent.defaultProps = {
  textAreaID: '',
  disabled: false,
  height: null,
}

TextAreaComponent.propTypes = {
  textValue: PropTypes.string.isRequired,
  textAreaID: PropTypes.string,
  handleUpdateTextFromTextArea: PropTypes.func.isRequired,
  handleOpenMentionComponent: PropTypes.func.isRequired,
  textareaPlaceholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  height: PropTypes.string,
}

const OPEN_AI_MODAL_OPTIONS = {
  isOpen: false,
  text: '',
  savedGeneratedTexts: [],
}

const VARIABLES_MODAL_OPTIONS = {
  isOpen: false,
  profiles: null,
  variables: null,
  callbackFunction: null,
  needValidation: true,
}

const TextAreaComponentWithControls = ({
  user,
  text,
  textLength,
  textAreaID,
  handleUpdateText,
  handleUpdateWithAIFlag,
  errorText,
  textMaxLimitNumber: MAX_TEXT_LENGTH,
  textVariantOffsets: TEXT_VARIANT_OFFSETS,
  textareaPlaceholder,
  selectedEntities,
  selectedProfiles,
  typeAI,
  counterComp,
  disabled,
  height,
  borderColor,
  showOnTop,
  showOnLeft,
  withoutSelectedEntitiesAndProfiles,
  network,
  profileId,
  dataForMediaPicker,
  showEmojiComponent,
  showHashTagComponent,
  showSnippetComponent,
  showVariablesComponent,
  showTextEditorComponent,
  showAIHelperComponent,
  showMediaPicker,
  showImageComponent,
  showLinkComponent,
  showVideoComponent,
  showDocumentComponent,
  showMixedMediaComponent,
  specialPostKey,
  maxImageAttachmentsAllowed,
  maxVideoAttachmentsAllowed,
  maxMixedMediaAttachmentsAllowed,
}) => {
  const textAreaRef = useRef(null)
  const emojiRef = useRef(null)
  const hashtagRef = useRef(null)
  const hashTagSelectorRef = useRef(null)
  const snippetRef = useRef(null)
  const snippetSelectorRef = useRef(null)
  const textVariantRef = useRef(null)
  const variableRef = useRef(null)
  const variableSelectorRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [showPostTextHelperComponent, setShowPostTextHelperComponent] = useState(null)
  const [openAIModalOptions, setOpenAIModalOptions] = useState({ ...OPEN_AI_MODAL_OPTIONS })
  const [variablesModalOptions, setVariablesModalOptions] = useState({ ...VARIABLES_MODAL_OPTIONS })

  const handleMouseClick = (e) => {
    if (showPostTextHelperComponent === POST_EMOJI && emojiRef.current && !emojiRef.current.contains(e.target)) {
      setShowPostTextHelperComponent(null)
    } else if (
      showPostTextHelperComponent === POST_HASHTAG &&
      hashtagRef.current &&
      !hashtagRef.current.contains(e.target) &&
      (!hashTagSelectorRef.current ||
        (hashTagSelectorRef.current && !hashTagSelectorRef.current.getHashTagModalState()))
    ) {
      setShowPostTextHelperComponent(null)
    } else if (
      showPostTextHelperComponent === POST_SNIPPET &&
      snippetRef.current &&
      !snippetRef.current.contains(e.target) &&
      (!snippetSelectorRef.current ||
        (snippetSelectorRef.current && !snippetSelectorRef.current.getSnippetModalState()))
    ) {
      setShowPostTextHelperComponent(null)
    } else if (
      showPostTextHelperComponent === POST_TEXT_EDITOR &&
      textVariantRef.current &&
      !textVariantRef.current.contains(e.target)
    ) {
      setShowPostTextHelperComponent(null)
    } else if (
      showPostTextHelperComponent === POST_VARIABLE &&
      variableRef.current &&
      !variableRef.current.contains(e.target)
    ) {
      setShowPostTextHelperComponent(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick, false)

    return () => {
      document.removeEventListener('mousedown', handleMouseClick, false)
    }
  }, [handleMouseClick])

  const handleClickSelectPostTextHelperComponent = (component) => {
    if (showPostTextHelperComponent && showPostTextHelperComponent === component) {
      setShowPostTextHelperComponent(null)
    } else {
      setShowPostTextHelperComponent(component)
    }
  }

  const handleClickSelectEmoji = (e) => {
    const current = textAreaRef.current.getTextAreaCurrent()
    const text = textAreaRef.current.getTextAreaValue()

    const cursorPosition = current.selectionStart

    let start = text.substring(0, cursorPosition)
    const end = text.substring(cursorPosition)

    const sym = e.unified.split('-')
    const codesArray = []
    sym.forEach((el) => codesArray.push(`0x${el}`))
    const emojiPic = String.fromCodePoint(...codesArray)

    start = `${start}${emojiPic}`

    textAreaRef.current.handleChangePostText(`${start}${end}`)
    current.selectionStart = start.length
  }

  const handleClickAddTextToComment = ({ message, using_ai = false }) => {
    const current = textAreaRef.current.getTextAreaCurrent()
    const text = textAreaRef.current.getTextAreaValue()

    const cursorPosition = current.selectionStart

    let start = text.substring(0, cursorPosition)
    const end = text.substring(cursorPosition)

    start = `${start}${start ? '\n' : ''}${message} `

    textAreaRef.current.handleChangePostText(`${start}${end}`)
    current.selectionStart = start.length
    setShowPostTextHelperComponent(null)

    handleUpdateWithAIFlag({ using_ai })
  }

  const handleClickAddHashTagToComment = ({ message }) => {
    const current = textAreaRef.current.getTextAreaCurrent()
    const text = textAreaRef.current.getTextAreaValue()

    const cursorPosition = current.selectionStart

    let start = text.substring(0, cursorPosition)
    const end = text.substring(cursorPosition)

    const hasSpaceBefore = start && start[start.length - 1] === ' '

    start = `${start}${start ? `${hasSpaceBefore ? '' : ' '}` : ''}${message} `
    textAreaRef.current.handleChangePostText(`${start}${end}`)
    current.selectionStart = start.length
  }

  const handleClickOpenAIModal = () => {
    openAIModalOptions.isOpen = true
    setOpenAIModalOptions({ ...openAIModalOptions })
  }

  const handleClickCloseAIModal = () => {
    openAIModalOptions.isOpen = false
    setOpenAIModalOptions({ ...openAIModalOptions })
  }

  const handleUpdateTextFromAIModal = ({ text }) => {
    handleClickAddTextToComment({ message: text, using_ai: true })
    handleClickCloseAIModal()
  }

  const handleClickOpenVariablesModal = () => {
    let savedVariables = []

    if (variableSelectorRef && variableSelectorRef.current) {
      savedVariables = variableSelectorRef.current.getSavedVariables()
    }

    setVariablesModalOptions({
      ...{
        isOpen: true,
        profiles: [],
        variables: savedVariables.map(({ label }) => ({ variable: `{{${label}}}`, for_edit: true })),
        needValidation: false,
      },
    })
  }

  const handleClickCloseVariablesModal = () => {
    setVariablesModalOptions({ ...VARIABLES_MODAL_OPTIONS })
  }

  const handleClickSaveUpdatedVariables = ({ variables }) => {
    let savedVariables = []

    if (variableSelectorRef && variableSelectorRef.current) {
      savedVariables = variableSelectorRef.current.getSavedVariables()
    }

    variables.forEach((variable) => {
      const foundSavedVariableIndex = savedVariables.findIndex(({ id }) => id === variable.id)
      if (foundSavedVariableIndex === -1) {
        savedVariables.push(variable)
      } else {
        savedVariables[foundSavedVariableIndex] = variable
      }
    })

    if (variableSelectorRef && variableSelectorRef.current) {
      variableSelectorRef.current.setSavedVariables({ savedVariables })
    }

    handleClickCloseVariablesModal()
  }

  const textLengthUpdated = textLength || text.length

  return (
    <StyledTextAreaWrapper
      mt="m"
      hasError={errorText || (MAX_TEXT_LENGTH > 0 && textLengthUpdated > MAX_TEXT_LENGTH)}
      borderColor={borderColor}
    >
      <TextAreaComponent
        textValue={text}
        textAreaID={textAreaID}
        handleUpdateTextFromTextArea={handleUpdateText}
        handleOpenMentionComponent={() => {}}
        ref={textAreaRef}
        textareaPlaceholder={textareaPlaceholder}
        disabled={disabled}
        height={height}
      />
      {(counterComp || MAX_TEXT_LENGTH > 0) && (
        <StyledCounterWrapper disabled={disabled}>
          <Flex justifyContent="flex-end" mx="s">
            {counterComp || (
              <Fragment>
                {MAX_TEXT_LENGTH > 0 && <Counter counter={textLengthUpdated} max={MAX_TEXT_LENGTH} fontSize="xxs" />}
              </Fragment>
            )}
          </Flex>
        </StyledCounterWrapper>
      )}
      <StyledControlsWrapper
        alignItems="center"
        pt="s"
        disabled={disabled}
        flexWrap="wrap"
        justifyContent={showMediaPicker && network && profileId ? 'space-between' : 'flex-start'}
      >
        {showEmojiComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                if (!disabled) {
                  handleClickSelectPostTextHelperComponent(POST_EMOJI)
                }
              }}
              isActive={showPostTextHelperComponent === POST_EMOJI}
            >
              <Icon.Smile width="20px" height="20px" />
            </IconWrapper>

            {showPostTextHelperComponent === POST_EMOJI && (
              <PostTextHelperComponentWrapper
                left={showOnLeft ? 'unset' : '-1px'}
                right={showOnLeft ? '0' : 'unset'}
                ref={emojiRef}
                showOnTop={showOnTop}
              >
                <Picker onEmojiSelect={handleClickSelectEmoji} previewPosition="none" theme="light" />
              </PostTextHelperComponentWrapper>
            )}
          </Box>
        )}

        {showHashTagComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                if (!disabled) {
                  handleClickSelectPostTextHelperComponent(POST_HASHTAG)
                }
              }}
              isActive={showPostTextHelperComponent === POST_HASHTAG}
            >
              <Icon.HashTag width="20px" height="20px" />
            </IconWrapper>
            {showPostTextHelperComponent === POST_HASHTAG && (
              <PostTextHelperComponentWrapper
                left={showOnLeft ? 'unset' : '-37px'}
                right={showOnLeft ? '0' : 'unset'}
                minWidth="380px"
                ref={hashtagRef}
                showOnTop={showOnTop}
              >
                <StyledBox pl="m">
                  <HashTagSelector
                    user={user}
                    handleClickAddHashTagToPost={handleClickAddHashTagToComment}
                    ref={hashTagSelectorRef}
                    selectedEntities={selectedEntities}
                    selectedProfiles={selectedProfiles}
                  />
                </StyledBox>
              </PostTextHelperComponentWrapper>
            )}
          </Box>
        )}

        {showSnippetComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                if (!disabled) {
                  handleClickSelectPostTextHelperComponent(POST_SNIPPET)
                }
              }}
              isActive={showPostTextHelperComponent === POST_SNIPPET}
            >
              <Icon.Snippet width="20px" height="20px" />
            </IconWrapper>
            {showPostTextHelperComponent === POST_SNIPPET && (
              <PostTextHelperComponentWrapper
                left={showOnLeft ? 'unset' : '-37px'}
                right={showOnLeft ? '0' : 'unset'}
                minWidth="380px"
                ref={snippetRef}
                showOnTop={showOnTop}
              >
                <StyledBox pl="m">
                  <SnippetSelector
                    user={user}
                    handleClickAddText={handleClickAddTextToComment}
                    ref={snippetSelectorRef}
                    type={typeAI}
                    selectedEntities={selectedEntities}
                    selectedProfiles={selectedProfiles}
                  />
                </StyledBox>
              </PostTextHelperComponentWrapper>
            )}
          </Box>
        )}

        {showVariablesComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                handleClickSelectPostTextHelperComponent(POST_VARIABLE)
              }}
              isActive={showPostTextHelperComponent === POST_VARIABLE}
            >
              <Icon.Brackets width="20px" height="20px" />
            </IconWrapper>
            {showPostTextHelperComponent === POST_VARIABLE && (
              <PostTextHelperComponentWrapper
                left={showOnLeft ? 'unset' : '-37px'}
                right={showOnLeft ? '0' : 'unset'}
                minWidth="300px"
                maxWidth="300px"
                ref={variableRef}
                showOnTop={showOnTop}
              >
                <StyledBox pl="m">
                  <VariableSelector
                    handleClickAddVariableToPost={handleClickAddTextToComment}
                    savedVariables={[]}
                    handleClickOpenVariablesModal={handleClickOpenVariablesModal}
                    user={user}
                    ref={variableSelectorRef}
                  />
                </StyledBox>
              </PostTextHelperComponentWrapper>
            )}
          </Box>
        )}

        {showTextEditorComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                if (!disabled) {
                  handleClickSelectPostTextHelperComponent(POST_TEXT_EDITOR)
                }
              }}
              isActive={showPostTextHelperComponent === POST_TEXT_EDITOR}
            >
              <Icon.Text width="16px" height="18px" />
            </IconWrapper>
            {showPostTextHelperComponent === POST_TEXT_EDITOR && (
              <PostTextHelperComponentWrapper
                left={showOnLeft ? 'unset' : '-73px'}
                right={showOnLeft ? '0' : 'unset'}
                ref={textVariantRef}
                showOnTop={showOnTop}
              >
                <StyledBox>
                  <Grid gridTemplateColumns="repeat(2, 1fr)">
                    {TEXT_VARIANT_OFFSETS.map(({ value, label }) => (
                      <StyledVariantOffsetWrapper
                        key={value}
                        py="xxs"
                        px="s"
                        alignItems="center"
                        onClick={() => {
                          const textAreaValue = textAreaRef.current.getTextAreaValue()
                          const { start, end } = textAreaRef.current.getSelectedTextRange() || {}

                          let textAreaValueString = textAreaValue

                          if (start >= 0 && end > 0) {
                            textAreaValueString = textAreaValueString.substr(start, end - start)
                          }

                          const { text, message, type } = transformTextToUnicode({
                            text: textAreaValueString,
                            value,
                          })

                          if (type) {
                            Alert[type](message, { timeout: 5000 })
                          } else {
                            const firstPart = textAreaValue.substring(0, start)
                            const lastPart = textAreaValue.substring(end)

                            const updatedText = `${firstPart}${text}${lastPart}`

                            textAreaRef.current.handleChangeHiddenBoxText(updatedText)
                            textAreaRef.current.handleChangePostText(updatedText)
                          }
                        }}
                      >
                        <Text fontSize="xs">{label}</Text>
                      </StyledVariantOffsetWrapper>
                    ))}
                  </Grid>
                </StyledBox>
              </PostTextHelperComponentWrapper>
            )}
          </Box>
        )}

        {showAIHelperComponent && (
          <Box position="relative">
            <IconWrapper
              onClick={() => {
                if (!disabled) {
                  handleClickOpenAIModal()
                }
              }}
            >
              <Image source="/assets/magic_wand.svg" width="22px" height="22px" />
            </IconWrapper>
          </Box>
        )}

        {showMediaPicker && network && profileId && (
          <UploadMediaComponent
            ref={uploadMediaComponentRef}
            user={user}
            selectedProfiles={selectedProfiles}
            selectedEntities={selectedEntities}
            post={dataForMediaPicker}
            showImageComponent={showImageComponent}
            showLinkComponent={showLinkComponent}
            showVideoComponent={showVideoComponent}
            showDocumentComponent={showDocumentComponent}
            showMixedMediaComponent={showMixedMediaComponent}
            specialPostKey={specialPostKey}
            network={network}
            profileId={profileId}
            maxImageAttachmentsAllowed={maxImageAttachmentsAllowed}
            maxVideoAttachmentsAllowed={maxVideoAttachmentsAllowed}
            maxMixedMediaAttachmentsAllowed={maxMixedMediaAttachmentsAllowed}
          />
        )}
      </StyledControlsWrapper>

      {showMediaPicker && network && profileId && (
        <UploadedMediasComponent
          user={user}
          post={dataForMediaPicker}
          specialPostKey={specialPostKey}
          network={network}
          profileId={profileId}
          selectedProfiles={selectedProfiles}
          selectedEntities={selectedEntities}
          uploadMediaComponentRef={uploadMediaComponentRef}
          isSmall
        />
      )}

      {openAIModalOptions.isOpen && (
        <OpenAIModal
          user={user}
          handleDismiss={handleClickCloseAIModal}
          handleUpdateText={handleUpdateTextFromAIModal}
          text={openAIModalOptions.text}
          isOpen={openAIModalOptions.isOpen}
          savedGeneratedTexts={openAIModalOptions.savedGeneratedTexts}
          saveOptions={({ text, generatedTexts }) => {
            openAIModalOptions.text = text
            openAIModalOptions.savedGeneratedTexts = generatedTexts
            setOpenAIModalOptions({ ...openAIModalOptions })
          }}
          type={typeAI}
          withoutSelectedEntitiesAndProfiles={withoutSelectedEntitiesAndProfiles}
        />
      )}

      {variablesModalOptions.isOpen && (
        <VariablesModal
          user={user}
          handleSave={handleClickSaveUpdatedVariables}
          isOpen={variablesModalOptions.isOpen}
          profiles={variablesModalOptions.profiles}
          variables={variablesModalOptions.variables}
          callbackFunction={variablesModalOptions.callbackFunction}
          needValidation={variablesModalOptions.needValidation}
        />
      )}
    </StyledTextAreaWrapper>
  )
}

TextAreaComponentWithControls.defaultProps = {
  text: '',
  textAreaID: '',
  handleUpdateWithAIFlag: () => {},
  textLength: 0,
  errorText: '',
  textareaPlaceholder: 'Leave comment',
  selectedEntities: [],
  selectedProfiles: [],
  typeAI: AI_TYPE_POST,
  counterComp: null,
  disabled: null,
  textMaxLimitNumber: 0,
  height: null,
  borderColor: null,
  showOnTop: false,
  showOnLeft: false,
  withoutSelectedEntitiesAndProfiles: false,
  network: '',
  profileId: '',
  dataForMediaPicker: {},
  showEmojiComponent: true,
  showHashTagComponent: true,
  showSnippetComponent: true,
  showVariablesComponent: true,
  showTextEditorComponent: true,
  showAIHelperComponent: true,
  showMediaPicker: false,
  showImageComponent: false,
  showLinkComponent: false,
  showVideoComponent: false,
  showDocumentComponent: false,
  showMixedMediaComponent: false,
  specialPostKey: '',
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  maxVideoAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  maxMixedMediaAttachmentsAllowed: MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
}

TextAreaComponentWithControls.propTypes = {
  user: PropTypes.object.isRequired,
  handleUpdateText: PropTypes.func.isRequired,
  handleUpdateWithAIFlag: PropTypes.func,
  text: PropTypes.string,
  textAreaID: PropTypes.string,
  textLength: PropTypes.number,
  errorText: PropTypes.string,
  textMaxLimitNumber: PropTypes.number,
  textVariantOffsets: PropTypes.array.isRequired,
  textareaPlaceholder: PropTypes.string,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  typeAI: PropTypes.string,
  counterComp: PropTypes.node,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  borderColor: PropTypes.string,
  showOnTop: PropTypes.bool,
  showOnLeft: PropTypes.bool,
  withoutSelectedEntitiesAndProfiles: PropTypes.bool,
  network: PropTypes.string,
  profileId: PropTypes.number,
  dataForMediaPicker: PropTypes.object,
  showEmojiComponent: PropTypes.bool,
  showHashTagComponent: PropTypes.bool,
  showSnippetComponent: PropTypes.bool,
  showVariablesComponent: PropTypes.bool,
  showTextEditorComponent: PropTypes.bool,
  showAIHelperComponent: PropTypes.bool,
  showMediaPicker: PropTypes.bool,
  showImageComponent: PropTypes.bool,
  showLinkComponent: PropTypes.bool,
  showVideoComponent: PropTypes.bool,
  showDocumentComponent: PropTypes.bool,
  showMixedMediaComponent: PropTypes.bool,
  specialPostKey: PropTypes.string,
  maxImageAttachmentsAllowed: PropTypes.number,
  maxVideoAttachmentsAllowed: PropTypes.number,
  maxMixedMediaAttachmentsAllowed: PropTypes.number,
}

export default TextAreaComponentWithControls
