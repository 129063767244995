import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import { IMAGE, VIDEO } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Link from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { IMPORT_FROM_SEARCH_CONTENT } from '../../consts'

const StyledArticleWrapper = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_card};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
`

const StyledLoadingFlex = styled(Flex)`
  position: absolute;
  z-index: 3;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => transparentize(0.5, theme.colors.background_card)};
  width: 100%;
  height: 100%;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const ArticleItem = ({ data, handleClickSendToCompose, type, handleClickAddToMediaLibrary, index }) => {
  const {
    image,
    published,
    title,
    description,
    url,
    source: { source = '' } = {},
    media_type = IMAGE,
    thumbnail_url,
    tempId,
    isLoading,
    mediaData,
  } = data
  return (
    <StyledArticleWrapper flexDirection="column" p="m" my="m" key={title}>
      <Flex>
        <Flex maxWidth="200px" maxHeight="180px" height="100%" width="100%" position="relative">
          {media_type.toLowerCase() === VIDEO ? (
            <VideoWithFallback
              url={image}
              controls
              width="200px"
              style={{ position: 'relative', borderRadius: radius.l }}
              height="180px"
              playIcon={
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
              }
              fallbackSourceImage={thumbnail_url}
              config={{
                file: {
                  attributes: {
                    poster: thumbnail_url || '',
                  },
                },
              }}
            />
          ) : (
            <Flex position="relative">
              {media_type === 'CAROUSEL_ALBUM' && (
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <Image src="/assets/vistasocial/instagram_carousel.svg" />
                </Box>
              )}
              <ImageWithFallback
                source={image || '/assets/emptyData.svg'}
                fallbackSource="/assets/emptyData.svg"
                width="200px"
                height="180px"
                borderRadius={radius.l}
              />
            </Flex>
          )}
          {isLoading && (
            <StyledLoadingFlex className="cliploader-wrapper">
              <ClipLoader size="40" />
            </StyledLoadingFlex>
          )}
        </Flex>
        <Flex flexDirection="column" px="m">
          {(source || published) && (
            <Flex mb="s">
              <Text as="span" fontWeight="bold">
                {source}
              </Text>
              {source && published && (
                <Text mx="xs" as="span" color="secondaryText">
                  ·
                </Text>
              )}
              <Text as="span" color="secondaryText">
                {published}
              </Text>
            </Flex>
          )}
          {title && (
            <Text fontWeight="bold" fontSize="l" mb="s">
              {title}
            </Text>
          )}
          {description && (
            <Text
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          {url && (
            <Link color="primary" href={url} target="_blank" mt="m">
              {type === IMPORT_FROM_SEARCH_CONTENT ? 'Read full article' : 'See the post'}
            </Link>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" mt="m">
        <Flex mr="m" alignItems="center">
          {mediaData ? (
            <Text color="secondaryText">Added to media library</Text>
          ) : (
            <ButtonWithLoading
              isSmall
              width="200px"
              onClick={() => {
                if (!isLoading) {
                  handleClickAddToMediaLibrary({
                    url: image,
                    type: media_type.toLowerCase(),
                    uploadingId: tempId,
                    index,
                  })
                }
              }}
              isLoading={isLoading}
            >
              <Flex alignItems="center">{isLoading ? 'Adding' : 'Add to media library'}</Flex>
            </ButtonWithLoading>
          )}
        </Flex>

        {handleClickSendToCompose && (
          <Button.Gray isSmall onClick={() => handleClickSendToCompose([data])}>
            <Flex alignItems="center">
              <Icon.Send /> <Text ml="s">Create a post</Text>
            </Flex>
          </Button.Gray>
        )}
      </Flex>
    </StyledArticleWrapper>
  )
}

ArticleItem.defaultProps = {
  handleClickSendToCompose: null,
}

ArticleItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleClickSendToCompose: PropTypes.func,
  type: PropTypes.string.isRequired,
  handleClickAddToMediaLibrary: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default ArticleItem
