import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { transparentize } from 'polished'
import { PREVIEW_MEDIA_CLASS_NAME } from 'routes/Calendar/consts'

const StyledBox = styled(Box)`
  overflow: hidden;
`

const SliderWrapper = styled(Flex)`
  overflow-x: visible;
  transition-property: transform;
  will-change: transform;
  transform: translateX(${({ translateX }) => translateX * -1}px);
  transition-duration: ${({ transitionDuration }) => transitionDuration};
`

const DirectionArrow = styled(Flex)`
  width: 1.875em;
  height: 1.875em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.pill};
`

const DotsWrapper = styled(Box)`
  border-radius: 50%;
  height: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 6px;
  cursor: pointer;
  background-color: ${({ activeDotsColor, isActive }) =>
    isActive ? activeDotsColor : transparentize(0.6, activeDotsColor)};
`

const StyledImage = styled(Image)`
  object-fit: contain;
`

const MediaCarousel = ({
  medias,
  width,
  height,
  activeDotsColor,
  showLegend,
  sliderElements: SliderElements,
  slideToIndex,
  setCurrentMedia,
  showFullHeight,
  showArrows,
  post,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [movement, setMovement] = useState(0)
  const [lastTouch, setLastTouch] = useState(0)
  const [transitionDuration, setTransitionDuration] = useState('0s')
  let wheelTimeout
  let transitionTimeout

  useEffect(() => {
    return () => {
      clearTimeout(wheelTimeout)
      clearTimeout(transitionTimeout)
    }
  })

  useEffect(() => {
    if (typeof slideToIndex === 'number') {
      // eslint-disable-next-line no-use-before-define
      transitionTo(slideToIndex, 0.5)
    }
  }, [slideToIndex])

  const transitionTo = (index, duration) => {
    setCurrentIndex(index)
    setCurrentMedia(index)
    setMovement(index * width)
    setTransitionDuration(`${duration}s`)
    transitionTimeout = setTimeout(() => {
      setTransitionDuration('0s')
    }, duration * 100)
  }

  const handleMovement = (delta) => {
    clearTimeout(transitionTimeout)
    const maxLength = medias.length - 1
    let nextMovement = movement + delta
    if (nextMovement < 0) {
      nextMovement = 0
    }
    if (nextMovement > maxLength * width) {
      nextMovement = maxLength * width
    }
    setMovement(nextMovement)
    setTransitionDuration('0s')
  }

  const handleMovementEnd = () => {
    const endPosition = movement / width
    const endPartial = endPosition % 1
    const endingIndex = endPosition - endPartial
    const deltaInteger = endingIndex - currentIndex

    let nextIndex = endingIndex
    if (deltaInteger >= 0) {
      if (endPartial >= 0.1) {
        nextIndex += 1
      }
    } else if (deltaInteger < 0) {
      nextIndex = currentIndex - Math.abs(deltaInteger)
      if (endPartial > 0.9) {
        nextIndex += 1
      }
    }
    transitionTo(nextIndex, Math.min(0.5, 1 - Math.abs(endPartial)))
  }

  const handleWheel = (e) => {
    clearTimeout(wheelTimeout)
    handleMovement(e.deltaX)
    wheelTimeout = setTimeout(() => handleMovementEnd(), 100)
  }

  const handleTouchStart = (e) => {
    setLastTouch(e.nativeEvent.touches[0].clientX)
  }

  const handleTouchMove = (e) => {
    const delta = lastTouch - e.nativeEvent.touches[0].clientX
    handleTouchStart(e)
    handleMovement(delta)
  }

  const handleTouchEnd = () => {
    handleMovementEnd()
    setLastTouch(0)
  }

  const mediasLength = medias.length
  const maxLength = mediasLength - 1
  const maxMovement = maxLength * width
  const isOnlyMedia = mediasLength === 1

  if (mediasLength && currentIndex > maxLength) {
    transitionTo(currentIndex - 1, 0.5)
  }

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <StyledBox
        width={`${width}px`}
        height={isOnlyMedia && !showFullHeight ? 'auto' : `${height}px`}
        maxHeight={isOnlyMedia && !showFullHeight ? `${height}px` : 'auto'}
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        position="relative"
      >
        <SliderWrapper
          translateX={movement}
          transitionDuration={transitionDuration}
          width={`${mediasLength * width}px`}
          height={isOnlyMedia && !showFullHeight ? 'auto' : `${height}px`}
          maxHeight={isOnlyMedia && !showFullHeight ? `${height}px` : 'auto'}
        >
          {SliderElements ||
            medias.map(({ url, id, isNew }, index) => {
              return (
                <Flex width={`${width}px`} height={`${height}px`} key={index}>
                  {isNew ? (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="50" />
                    </Flex>
                  ) : (
                    <StyledImage
                      name={id}
                      src={url}
                      width="100%"
                      height="100%"
                      isOnlyImage={isOnlyMedia}
                      id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                    />
                  )}
                </Flex>
              )
            })}
        </SliderWrapper>
        {movement !== 0 && showArrows && (
          <DirectionArrow
            position="absolute"
            alignItems="center"
            justifyContent="center"
            left="5px"
            onClick={(e) => {
              e.stopPropagation()
              transitionTo(currentIndex - 1, 0.5)
            }}
          >
            <Image src="/assets/vistasocial/instagram_arrow_left.png" width="1.875em" height="1.875em" />
          </DirectionArrow>
        )}
        {movement !== maxMovement && showArrows && (
          <DirectionArrow
            position="absolute"
            alignItems="center"
            justifyContent="center"
            right="5px"
            className="next move"
            onClick={(e) => {
              e.stopPropagation()
              transitionTo(currentIndex + 1, 0.5)
            }}
          >
            <Image src="/assets/vistasocial/instagram_arrow_right.png" width="1.875em" height="1.875em" />
          </DirectionArrow>
        )}
        {showLegend && mediasLength > 1 && (
          <Flex alignItems="center" justifyContent="center" bottom="15px" left="6px" right="6px" position="absolute">
            {medias.map((image, index) => (
              <DotsWrapper
                mr="xs"
                key={index}
                isActive={index === currentIndex}
                activeDotsColor={activeDotsColor}
                onClick={(e) => {
                  e.stopPropagation()
                  transitionTo(index, 0.5)
                }}
              />
            ))}
          </Flex>
        )}
      </StyledBox>
    </Flex>
  )
}
MediaCarousel.defaultProps = {
  medias: [],
  width: 700,
  height: 400,
  activeDotsColor: COLOR_CONSTANTS.WHITE,
  showLegend: true,
  sliderElements: null,
  slideToIndex: null,
  setCurrentMedia: () => {},
  showFullHeight: false,
  post: null,
  showArrows: true,
}

MediaCarousel.propTypes = {
  medias: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  activeDotsColor: PropTypes.string,
  showLegend: PropTypes.bool,
  sliderElements: PropTypes.node,
  slideToIndex: PropTypes.number,
  setCurrentMedia: PropTypes.func,
  showFullHeight: PropTypes.bool,
  post: PropTypes.object,
  showArrows: PropTypes.bool,
}

export default MediaCarousel
