import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const ProgressLine = styled.div`
  height: 5px;
  width: calc(${({ value }) => value} * 1%);
  background-color: ${({ theme }) => theme.colors.primary};
`

export const STEPPER_HEIGHT = pxToRem(80)

const BottomStepper = ({
  total,
  step,
  handleBack,
  handleSubmit,
  isLoading,
  submitButtonText,
  backButtonText,
  forceStepBack,
  borderRadius,
}) => {
  const value = (step / total) * 100
  return (
    <Box width="100%" bg="background" borderRadius={borderRadius}>
      <ProgressLine value={value} />
      <Flex
        justifyContent="space-between"
        px="m"
        height={STEPPER_HEIGHT}
        bg="background"
        alignItems="center"
        borderRadius={borderRadius}
      >
        {handleBack && ((!forceStepBack && step > 1) || (forceStepBack && step !== 0)) && (
          <Flex alignItems="center" as="button" type="button" onClick={handleBack}>
            <Image source="/assets/back.svg" mr="m" />
            <Text color="secondaryText" fontWeight="medium">
              {backButtonText}
            </Text>
          </Flex>
        )}
        {handleSubmit && (
          <ButtonWithLoading
            onClick={handleSubmit}
            type="submit"
            isLoading={isLoading}
            ml="auto"
            primaryColor="primary"
            maxWidth={{ mobile: '230px', tablet: 'initial' }}
          >
            <Flex px="m" alignItems="center">
              {isLoading ? (
                <Text fontWeight="medium">Submitting</Text>
              ) : (
                <React.Fragment>
                  <Text fontWeight="medium" mr="l">
                    {submitButtonText}
                  </Text>
                  <Icon.VistaSocialChevronRight width="8px" height="100%" />
                </React.Fragment>
              )}
            </Flex>
          </ButtonWithLoading>
        )}
      </Flex>
    </Box>
  )
}

BottomStepper.defaultProps = {
  step: 0,
  submitButtonText: 'Continue',
  backButtonText: 'Back',
  handleBack: null,
  handleSubmit: null,
  isLoading: false,
  forceStepBack: false,
  borderRadius: '',
}

BottomStepper.propTypes = {
  total: PropTypes.number.isRequired,
  step: PropTypes.string,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  submitButtonText: PropTypes.string,
  backButtonText: PropTypes.string,
  forceStepBack: PropTypes.bool,
  borderRadius: PropTypes.string,
}

export default BottomStepper
