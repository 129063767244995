export const DEFAULT_POLICY = `To maintain a respectful and inclusive online environment, all content published on our social media channels must adhere to the following guidelines:

1. No Profanity: Content must be free from offensive language or swearing.

2. No Derogatory Language: Avoid using language that is hurtful, insulting, or demeaning to individuals or groups.

3. No Offensive Language: Content should not include any language that is harmful, obscene, or inappropriate.

4. No Inappropriate Content: All posts must be suitable for a wide audience and free from explicit or adult material.

5. No Discrimination: Posts must not promote or support discrimination based on race, gender, religion, sexual orientation, or any other characteristic.

Content that does not ahere to these standards will not be allowed to be published.`
