import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-spring-lightbox'
import styled from 'styled-components'
import { ArrowButton, Header } from './components'

const StyledLightBox = styled(Lightbox)`
  background: hsla(0, 0%, 12.2%, 0.95);
  z-index: 2147483003 !important;
`

const CoolLightBox = ({ images, currentImageIndex, setCurrentIndex, isOpen, onClose }) => {
  const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1)

  const gotoNext = () => currentImageIndex + 1 < images.length && setCurrentIndex(currentImageIndex + 1)

  const { className = '' } = images[currentImageIndex] || {}

  return (
    <StyledLightBox
      isOpen={isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      onClose={onClose}
      images={images}
      currentIndex={currentImageIndex}
      singleClickToZoom
      renderHeader={() => <Header images={images} currentIndex={currentImageIndex} onClose={onClose} />}
      renderPrevButton={({ canPrev }) => <ArrowButton position="left" onClick={gotoPrevious} disabled={!canPrev} />}
      renderNextButton={({ canNext }) => <ArrowButton position="right" onClick={gotoNext} disabled={!canNext} />}
      className={className}
    />
  )
}

CoolLightBox.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ).isRequired,
  currentImageIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CoolLightBox
