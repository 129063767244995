import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import Link from 'components/atoms/Link'

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border-radius: ${radius.l} ${radius.l} 0 0;
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: inherit;
  height: 100%;
  outline: none;
`

const PreviewExternalCalendarEventComponent = ({
  handleClickSendToCompose,
  selectedProfiles,
  selectedEntities,
  postForEdit,
}) => {
  const {
    id,
    postText = '',
    event_text_color = '#000000',
    description,
    location,
    attachments = [],
    postImages = [],
    postVideos = [],
    event_background_color,
    publish_at,
    publish_at_formatted,
  } = postForEdit

  const handleClickOpenEvent = () => {
    const textData = []
    if (postText) {
      textData.push(postText)
    }
    if (description) {
      textData.push(description)
    }

    const post = { postText: textData.join('\n'), postImages, postVideos, publish_at }
    handleClickSendToCompose({
      post,
      selectedEntitiesForSelectedPost: selectedEntities,
      selectedProfilesForSelectedPost: selectedProfiles,
    })
  }

  return (
    <Fragment>
      <StyledDialogEnvironmentWrapper
        px="m"
        justifyContent="space-between"
        alignItems="center"
        bg={event_background_color || 'background_item_light'}
        flexWrap="wrap"
        name={id}
      >
        <Flex my="m" flexDirection="column">
          <Text fontSize="s" fontWeight="bold" color={event_text_color}>
            {postText}
          </Text>
        </Flex>
        {publish_at_formatted && (
          <Text color={event_text_color} fontSize="s">
            {publish_at_formatted}
          </Text>
        )}
      </StyledDialogEnvironmentWrapper>
      <StyledDialogBodyWrapper flex="1" justifyContent="center" py="m" px="m" tabIndex={0} flexDirection="column">
        {description && <Text fontSize="s">{description}</Text>}
        {location && (
          <Flex alignItems="center">
            <Flex alignItems="center" minWidth="16px" maxWidth="16px">
              <Icon.VistaSocialPin width="16px" height="14px" />
            </Flex>
            <Text ml="s" fontSize="s">
              {location}
            </Text>
          </Flex>
        )}
        {attachments.length > 0 && (
          <Flex alignItems="center" flexWrap="wrap">
            {attachments.map(({ name, url }, index) => (
              <Link key={index} mt="s" mr="s" fontSize="s" target="_blank" href={url} color="primary">
                {name}
              </Link>
            ))}
          </Flex>
        )}
      </StyledDialogBodyWrapper>
      {handleClickSendToCompose && (
        <Flex justifyContent="flex-end" mb="m" px="m">
          <Button.Primary isSmall onClick={handleClickOpenEvent} mr="s">
            Create post
          </Button.Primary>
        </Flex>
      )}
    </Fragment>
  )
}

PreviewExternalCalendarEventComponent.defaultProps = {
  selectedProfiles: [],
  selectedEntities: [],
  handleClickSendToCompose: null,
}

PreviewExternalCalendarEventComponent.propTypes = {
  postForEdit: PropTypes.object.isRequired,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  handleClickSendToCompose: PropTypes.func,
}

export default PreviewExternalCalendarEventComponent
