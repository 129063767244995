import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { InlineWidget } from 'react-calendly'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'

const { LINK } = {
  LINK: 'link',
}

const p = /^https:\/\/calendly\.com\/[a-zA-Z0-9_?=/-]+$/

const FormValidationSchema = Yup.object().shape({
  [LINK]: Yup.string()
    .matches(p, 'Please enter a correct Calendly URL')
    .required('Calendly URL is required')
    .max(200, `Calendly link is too long - should be 200 chars maximum.`),
})

const matchCalendlyUrl = ({ link }) => {
  const matches = link.match(p)

  if (matches) {
    return link
  }
  return ''
}

const CalendlyComponent = ({ data, isEditable, handleChangeBlockComponent }) => {
  const { page_id, _id: block_id, data: { link = '' } = {} } = data

  let link_updated = ''

  if (!isEditable) {
    link_updated = matchCalendlyUrl({ link })
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [LINK]: link,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Input
                  placeholder="https://calendly.com/your_scheduling_page"
                  type="text"
                  defaultValue={values[LINK]}
                  onChange={(event) => {
                    handleChangeBlockComponent({ page_id, block_id, key: LINK, value: event.target.value })
                    setFieldValue(LINK, event.target.value)
                  }}
                  error={values[LINK] && errors[LINK]}
                  width="100%"
                />
              </Box>
            )}
          </Formik>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          {link_updated && <InlineWidget url={link_updated} style={{ width: '100%', height: '570px' }} />}
        </Flex>
      )}
    </Fragment>
  )
}

CalendlyComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

CalendlyComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
}

CalendlyComponent.displayName = 'TypeformComponent'

export default CalendlyComponent
