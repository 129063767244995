import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import DropDown from 'shared/DropDown'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const EntitiesSelectorModal = ({
  isOpen,
  handleDismiss,
  selectedEntities: selected_entities,
  entitiesForDropdown,
  handleSaveSelectedEntities,
}) => {
  const [selectedEntities, setSelectedEntities] = useState([])

  useEffect(() => {
    setSelectedEntities([...selected_entities])
  }, [])

  const handleClickSave = () => {
    handleSaveSelectedEntities({ entities: selectedEntities })
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Select profile groups</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" width="100%" px="m" py="m">
            <Text mb="s">Users with access to the following profile groups will see your comment.</Text>
            <DropDown
              placeholder="Select profile groups"
              label=""
              value={selectedEntities}
              onChange={(options) => {
                if (options) {
                  setSelectedEntities([...options])
                } else {
                  setSelectedEntities([])
                }
              }}
              options={entitiesForDropdown}
              openMenuOnFocus={false}
              isMulti
            />
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>
            <Button.Gradient onClick={handleClickSave} isSmall type="button">
              <Text fontWeight="medium">Save</Text>
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

EntitiesSelectorModal.defaultProps = {
  selectedEntities: [],
  entitiesForDropdown: [],
}

EntitiesSelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedEntities: PropTypes.array,
  entitiesForDropdown: PropTypes.array,
  handleSaveSelectedEntities: PropTypes.func.isRequired,
}

export default EntitiesSelectorModal
