import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import {
  REDDIT,
  DEFAULT_VIDEO_THUMBNAIL,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
} from 'consts'
import { radius, COLOR_CONSTANTS, fontWeights, space, breakpoints } from 'theme'
import { linkTransformer, pxToRem, formatToNumberWithCommas } from 'helpers'
import Select from 'shared/DropDown'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Input from 'components/atoms/Input'
import MediaCarousel from 'components/molecules/MediaCarousel'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Counter from 'components/molecules/Counter'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  REDDIT_COLORS,
  SAVED_SEARCH_TYPE_SUBREDDIT,
  PREVIEW_MEDIA_CLASS_NAME,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
// eslint-disable-next-line import/no-cycle
import {
  formatOptionLabel,
  getAccounts,
  getNetworkSavedSearches,
  setSavedSearches,
  transformTextVariantsToUnicodeVariant,
  getRedditFlairs,
  variableTransformer,
  mentionsTransformer,
} from '../../helpers'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const DEFAULT_FONT_SIZE = 16
const EDITABLE_FONT_SIZE = 10.666666

const StyledFlex = styled(Flex)`
  border-radius: 0.25em;
  border: 1px solid #EDEFF1;
  height: fit-content; 
  font-size: ${({ isEditable }) => (isEditable ? `${EDITABLE_FONT_SIZE}px` : `${DEFAULT_FONT_SIZE}px`)};  
    height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};   
  --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  --font-family-default: Helvetica, Arial, sans-serif;
  --font-family-segoe: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
}
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const SourceWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-color: #edeff1;
  border-radius: ${radius.m};
`

const BodyWrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLinkIcon = styled(Icon.OpenInNew)`
  fill: ${REDDIT_COLORS.LAST_LIGHT_BLUE};
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledTextWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledIconWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledVoteCounterWrapper = styled(Flex)`
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.white)};
  border-radius: 0.5em 0 0 0.5em;
`

const StyledRedditIconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.125em;
  &:hover {
    background: rgba(26, 26, 27, 0.1);
  }
`

const StyledFirstImage = styled(ImageWithFallback)`
  object-fit: cover;
  width: auto;
  max-width: 100%;
  max-height: 25em;
`

const StyledFlairWrapper = styled(Box)`
  display: inline-flex;
  margin: 0;
  padding: 0;
  vertical-align: text-top;
`

const StyledFlair = styled.span`
  background-color: #6d7c8f;
  color: #ffffff;
  border-radius: 1.25em;
  padding: 0.125em 0.5em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1em;
  display: inline-block;
  margin-right: 0.3125em;
  margin-left: 0.3125em;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: text-bottom;
  white-space: pre;
  word-break: normal;
  cursor: pointer;
`

const StyledTitleWrapper = styled(Box)`
  display: inline;
  position: relative;
  text-decoration: none;
  word-break: break-word;
  vertical-align: middle;
`

const StyledTitle = styled(Text)`
  display: inline;
  vertical-align: sub;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === REDDIT)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  titleLimit: MAX_TITLE_LENGTH,
  flairTextMaxLimit: MAX_FLAIR_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const SPECIAL_SYMBOL = `r/`
const REDDIT_LINK = `https://reddit.com/${SPECIAL_SYMBOL}`

const RedditPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [isFlairEditOpen, setIsFlairEditOpen] = useState(false)
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_WIDTH = smallPreview ? 250 : 510
  const MAX_HEIGHT = MAX_WIDTH / 1.744

  const MAX_PREVIEW_WIDTH = MAX_WIDTH - 3.5 * (smallPreview ? EDITABLE_FONT_SIZE : DEFAULT_FONT_SIZE)
  const MAX_PREVIEW_HEIGHT = MAX_PREVIEW_WIDTH / 1.744

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
    insights = {},
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { typeData = {}, flairs = [], isGettingFlairs = true } = networkDataForCustomizations || {}
  const { [SAVED_SEARCH_TYPE_SUBREDDIT]: SAVED_SUBREDDIT_OPTIONS = [] } = typeData || {}

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let title = ''
  let subreddit = ''
  let flair_id = ''
  let flair_text = ''
  let flair_text_edited = ''
  let device_gid = null
  let publishing_option = null
  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      subreddit = '',
      title = '',
      flair_id = '',
      flair_text = '',
      flair_text_edited = '',
      device_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  let updatedTitle = title
  if (!updatedTitle) {
    updatedTitle = updatedPostText.substr(0, MAX_TITLE_LENGTH)
  }

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })

    if (isEditable) {
      getNetworkSavedSearches({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_SUBREDDIT,
      })
    }
  }, [props.profile])

  useEffect(() => {
    if (isEditable) {
      getRedditFlairs({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        subreddit,
      })
    }
  }, [subreddit])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const { transformed_text_to_html_for_textarea, updatedPostTextWithMentions = '' } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: REDDIT_COLORS.MENTION,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea =
      linkTransformer({
        body: updatedText,
        color: REDDIT_COLORS.LAST_LIGHT_BLUE,
        fontWeight: fontWeights.semiLight,
        transformHashstag: true,
        hashtagColor: REDDIT_COLORS.LAST_LIGHT_BLUE,
        hashtagFontWeight: fontWeights.semiLight,
      }) || ''

    return { transformed_text_to_html_for_textarea, updatedPostTextWithMentions }
  }, [updatedPostText, savedVariables, agencyMentions])

  const { url } = link || {}
  const { published_link } = result || {}

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationDeviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 500),
    []
  )

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (e) {
      urlHost = url
    }
  }

  let c_ups = 0
  let c_downs = 0
  let c_comments = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ ups: c_ups = 0, downs: c_downs = 0, comments: c_comments = 0 } = profileInsights)
  }

  const c_ups_downs = c_ups - c_downs

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  let postMixedMediasUpdated = []

  if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_medias = customizedPostMixedMedias.filter(({ tempId }) => !tempId)
    if (filtered_medias.length !== 0) {
      postMixedMediasUpdated = [filtered_medias[0]]
    }
  }

  const generateMediaTemplate = ({ media }) => {
    const { url, id, [network]: networkErrors = {}, isNew, type } = media
    const { hasAspectRatioError, hasSizeError } = networkErrors || {}
    const hasError = hasAspectRatioError || hasSizeError
    return (
      <Flex width={`${MAX_PREVIEW_WIDTH}px`} height={`${MAX_PREVIEW_HEIGHT}px`} key={id}>
        <StyledBox position="relative" width="100%" justifyContent="center" hasError={hasError}>
          {isNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              {type === IMAGE && (
                <StyledFirstImage
                  name={id}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  source={url}
                  width="100%"
                  height="100%"
                />
              )}
              {type === IMAGE_DYNAMIC && (
                <Flex width={`${MAX_PREVIEW_HEIGHT}px`} height={`${MAX_PREVIEW_HEIGHT}px`}>
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={smallPreview} />
                </Flex>
              )}
            </Fragment>
          )}
        </StyledBox>
      </Flex>
    )
  }

  const generateImageSourceTypeTemplate = ({ data }) => {
    if (data.length === 1) {
      return <SourceWrapper>{generateMediaTemplate({ media: data[0] })}</SourceWrapper>
    } else {
      return (
        <SourceWrapper>
          <MediaCarousel
            post={post}
            width={MAX_PREVIEW_WIDTH}
            height={MAX_PREVIEW_HEIGHT}
            medias={postImagesUpdated}
            showLegend={false}
            type={IMAGE}
            sliderElements={data.map((media) => {
              return <Fragment>{generateMediaTemplate({ media })}</Fragment>
            })}
            activeDotsColor={REDDIT_COLORS.LAST_LIGHT_BLUE}
          />
        </SourceWrapper>
      )
    }
  }

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { id: videoId, thumbnail_url, type, isNew, url: videoUrl } = data[0] || {}

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <SourceWrapper>
        {noVideoAttachment ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            height={`${MAX_PREVIEW_HEIGHT}px`}
            width={`${MAX_PREVIEW_WIDTH}px`}
            position="relative"
          >
            {type === VIDEO && (
              <Fragment>
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
                <StyledVideoThumbnailImage
                  height="100%"
                  width="100%"
                  source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                  fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                  name={videoId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                />
              </Fragment>
            )}
            {type === VIDEO_DYNAMIC && (
              <Flex
                width={`${MAX_PREVIEW_HEIGHT}px`}
                height={`${MAX_PREVIEW_HEIGHT}px`}
                justifyContent="center"
                alignItems="center"
                mx="auto"
              >
                <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
              </Flex>
            )}
          </Flex>
        ) : (
          <StyledBox
            position="relative"
            height={`${MAX_PREVIEW_HEIGHT}px`}
            width={`${MAX_PREVIEW_WIDTH}px`}
            justifyContent="center"
            hasError={postVideoError}
          >
            {isNew ? (
              <Box className="cliploader-wrapper">
                <ClipLoader size="50" />
              </Box>
            ) : (
              <Fragment>
                {type === VIDEO && (
                  <VideoWithFallback
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                    url={videoUrl}
                    controls
                    width="100%"
                    style={{
                      maxHeight: `${MAX_PREVIEW_HEIGHT}px`,
                      maxWidth: `${MAX_PREVIEW_WIDTH}px`,
                      background: '#ffffff',
                    }}
                    config={{
                      file: {
                        attributes: {
                          poster: videoThumbnailUrl,
                        },
                      },
                    }}
                  />
                )}
                {type === VIDEO_DYNAMIC && (
                  <Flex
                    width={`${MAX_PREVIEW_HEIGHT}px`}
                    height={`${MAX_PREVIEW_HEIGHT}px`}
                    justifyContent="center"
                    alignItems="center"
                    mx="auto"
                  >
                    <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
                  </Flex>
                )}
              </Fragment>
            )}
          </StyledBox>
        )}
      </SourceWrapper>
    )
  }

  let sourceTypeTemplate = ''

  switch (customizedPostComponent) {
    case POST_IMAGE:
      if (postImagesUpdated.length !== 0) {
        sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postImagesUpdated })
      }
      break
    case POST_VIDEO:
      if (
        customizedPostVideos.length !== 0 &&
        (customizedPostVideos[0].url ||
          customizedPostVideos[0].thumbnail_url ||
          customizedPostVideos[0].type === VIDEO_DYNAMIC)
      ) {
        sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
      }

      break
    case POST_LINK:
      if (urlHost) {
        sourceTypeTemplate = (
          <SourceWrapper minHeight="auto">
            <Flex flexDirection="column">
              <Flex px="0.5em" alignItems="center">
                <Text fontSize="0.75em" color={REDDIT_COLORS.LAST_LIGHT_BLUE} mr="0.25em">
                  {urlHost}
                </Text>
                <StyledLinkIcon width="0.75em" height="0.75em" />
              </Flex>
            </Flex>
          </SourceWrapper>
        )
      }
      break
    case POST_MIXED_MEDIA:
      if (postMixedMediasUpdated.length !== 0) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postMixedMediasUpdated })
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: postMixedMediasUpdated })
        }
      }
      break
    default:
      break
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_item_light' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
                width={`${MAX_WIDTH}px`}
                height={`${MAX_HEIGHT}px`}
                mx="auto"
                bg={isPostFormDirty ? 'white' : 'transparent'}
              >
                {isPostFormDirty ? (
                  <Fragment>
                    <StyledVoteCounterWrapper py="0.5em" flexDirection="column" alignItems="center" width="2.5em">
                      <StyledRedditIconWrapper width="1.5em" height="1.5em">
                        <Image src="/assets/vistasocial/reddit_upvote.svg" width="1.25em" height="1.25em" />
                      </StyledRedditIconWrapper>
                      <Text my="0.25em" fontSize="0.75em" fontWeight="extraBold" color="rgb(26, 26, 27)">
                        {c_ups_downs > 0 ? c_ups_downs : 'Vote'}
                      </Text>
                      <StyledRedditIconWrapper width="1.5em" height="1.5em">
                        <Image src="/assets/vistasocial/reddit_downvote.svg" width="1.25em" height="1.25em" />
                      </StyledRedditIconWrapper>
                    </StyledVoteCounterWrapper>
                    <Flex
                      flexDirection="column"
                      pt="0.5em"
                      pb="0.125em"
                      width="calc(100% - 2.5em)"
                      borderRadius="0 0.5em 0.5em 0"
                    >
                      <Box px="0.5em">
                        <Flex>
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width="1.25em"
                              height="1.25em"
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width="1.25em"
                              height="1.25em"
                              imageBorderRadius={radius.pill}
                            />
                          )}
                          <Flex ml="0.25em" alignItems="center">
                            <Flex
                              alignItems="center"
                              onMouseEnter={(e) => {
                                if (!isMultipleProfilesPreview) {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code: network,
                                          picture_url: profilePictureUrl,
                                          profile_url: profileUrl,
                                          name: profileName,
                                          username: profileUsername,
                                          display,
                                          profile_id,
                                          type: profile_type,
                                        }}
                                        message=""
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <StyledLink
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                color={REDDIT_COLORS.GLUON_GREY}
                                fontWeight="bold"
                                fontSize="0.75em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : `u/${profileName}`}
                              </StyledLink>
                            </Flex>
                            <Text as="span" fontSize="0.375em" color={REDDIT_COLORS.TARNISHED_SILVER} mx="0.25em">
                              •
                            </Text>
                            <StyledLink
                              fontSize="0.75em"
                              color="rgb(120,124,126)"
                              as={published_link ? 'a' : 'p'}
                              href={published_link || ''}
                              target="_blank"
                            >
                              {publish_at ? `${moment(publish_at).fromNow()} ${zoneShort || ''}` : 'Now'}
                            </StyledLink>
                          </Flex>
                        </Flex>
                      </Box>
                      {updatedTitle && (
                        <Box px="0.5em" mt="0.5em">
                          <StyledTitleWrapper>
                            <StyledTitle fontSize="1.125em" color={REDDIT_COLORS.TARNISHED_SILVER}>
                              {updatedTitle}
                            </StyledTitle>
                          </StyledTitleWrapper>
                          {flair_text && (
                            <StyledFlairWrapper>
                              <Box display="inline-block">
                                <StyledFlair>{flair_text_edited || flair_text}</StyledFlair>
                              </Box>
                            </StyledFlairWrapper>
                          )}
                        </Box>
                      )}

                      <Flex
                        flexDirection="column"
                        width="100%"
                        onClick={() => {
                          if (published_link) {
                            window.open(published_link)
                          }
                        }}
                      >
                        <Box px="0.5em">
                          {transformed_text_to_html_for_textarea && (
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: transformed_text_to_html_for_textarea,
                              }}
                              mt="0.5em"
                              fontSize="0.875em"
                              whiteSpace="pre-line"
                              color={REDDIT_COLORS.GLUON_GREY}
                            />
                          )}
                        </Box>
                        {customizedPostComponent ? (
                          <Box mt="0.25em" px="0.5em">
                            {sourceTypeTemplate}
                          </Box>
                        ) : (
                          <Box mt="0.25em" px="0.5em" mb="0.5em" />
                        )}
                        <Flex px="0.5em" alignItems="center">
                          <StyledRedditIconWrapper p="0.5em" mr="0.25em">
                            <Image src="/assets/vistasocial/reddit_comment.png" width="1.25em" height="1.25em" />
                            <Text
                              fontSize="0.75em"
                              fontWeight="extraBold"
                              color={REDDIT_COLORS.LIGHT_GRAY}
                              ml="0.25em"
                              pl="0.125em"
                            >
                              {`${formatToNumberWithCommas(c_comments)} ${c_comments === 1 ? 'Comment' : 'Comments'}`}
                            </Text>
                          </StyledRedditIconWrapper>
                          <StyledRedditIconWrapper p="0.5em" mr="0.25em">
                            <Image src="/assets/vistasocial/reddit_share.png" width="1.5em" height="1.5em" />
                            <Text
                              fontSize="0.75em"
                              fontWeight="extraBold"
                              color={REDDIT_COLORS.LIGHT_GRAY}
                              ml="0.25em"
                              pl="0.125em"
                            >
                              Share
                            </Text>
                          </StyledRedditIconWrapper>
                          <StyledRedditIconWrapper p="0.5em" mr="0.25em">
                            <Image src="/assets/vistasocial/reddit_bookmark.svg" width="1.25em" height="1.25em" />
                            <Text
                              fontSize="0.75em"
                              fontWeight="extraBold"
                              color={REDDIT_COLORS.LIGHT_GRAY}
                              ml="0.25em"
                              pl="0.125em"
                            >
                              Save
                            </Text>
                          </StyledRedditIconWrapper>
                          <StyledRedditIconWrapper mr="0.25em" width="1.5em" height="1.5em" px="0.125em">
                            <Image src="/assets/vistasocial/reddit_dots.png" width="1.5em" height="1.5em" />
                          </StyledRedditIconWrapper>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Fragment>
                ) : (
                  <Box mt="xs" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'}
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={REDDIT} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <Flex mt="m" alignItems="center" width="100%">
                    <Flex flexDirection="column" width="100%">
                      {subreddit ? (
                        <Flex width="100%" alignItems="center">
                          <StyledTextWrapper flex="1" alignItems="center">
                            <StyledLink as="a" href={`${REDDIT_LINK}${subreddit}`} color="primary" target="_blank">
                              {`${SPECIAL_SYMBOL}${subreddit}`}
                            </StyledLink>
                          </StyledTextWrapper>
                          <StyledIconWrapper
                            alignItems="center"
                            justifyContent="flex-end"
                            ml="m"
                            onClick={() => {
                              if (!post.customizations) {
                                post.customizations = {}
                              }
                              if (!post.customizations[network]) {
                                post.customizations[network] = {}
                              }
                              if (!post.customizations[network][profileId]) {
                                post.customizations[network][profileId] = {}
                              }

                              post.customizations[network][profileId].subreddit = ''
                              post.customizations[network][profileId].subreddit_name = ''
                              post.customizations[network][profileId].flair_id = ''
                              delete post.customizations[network][profileId].flair_text
                              delete post.customizations[network][profileId].flair_text_edited
                              onChangeUpdatePost(post)
                            }}
                            width="16px"
                          >
                            <Icon.Trash width="16px" height="16px" />
                          </StyledIconWrapper>
                        </Flex>
                      ) : (
                        <Flex flexDirection="column">
                          <Select
                            placeholder="Subreddit"
                            value={null}
                            onChange={(option) => {
                              const { username = '' } = option || {}

                              handleUpdateCustomization({
                                type: 'subreddit',
                                value: username,
                              })

                              if (option) {
                                setSavedSearches({
                                  type: SAVED_SEARCH_TYPE_SUBREDDIT,
                                  id: profileId,
                                  result: option,
                                  dataForCustomizations,
                                  setDataForCustomizations,
                                })
                              }
                            }}
                            loadOptions={(value, callback) =>
                              loadSuggestedOptions(
                                {
                                  value,
                                  network: REDDIT,
                                  id: profileId,
                                  getSuggestedOptionsErrors,
                                  setGetSuggestedOptionsErrors,
                                },
                                callback
                              )
                            }
                            defaultValue={[]}
                            isClearable
                            defaultOptions={SAVED_SUBREDDIT_OPTIONS}
                            openMenuOnFocus
                            dropDownType="AsyncSelect"
                            cacheOptions
                            showDropDownArrow={false}
                            formatOptionLabel={({ ...props }) => {
                              return formatOptionLabel({ ...props, specialSymbol: SPECIAL_SYMBOL })
                            }}
                            noOptionsMessage={() => {
                              return getSuggestedOptionsErrors[network] ? 'No results found' : null
                            }}
                            error={!subreddit || subreddit.length === 0 ? 'Subreddit is required' : ''}
                            height={pxToRem(40)}
                          />
                        </Flex>
                      )}
                    </Flex>
                    <TooltipPromptComponent prompt_key="subreddit" network={REDDIT} mt={subreddit ? '-3px' : '-18px'} />
                  </Flex>

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      {subreddit && subreddit.length !== 0 && (
                        <Flex mb="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            {flairs.length !== 0 || flair_id ? (
                              <Fragment>
                                {flair_id ? (
                                  <Flex width="100%" alignItems="center">
                                    {isFlairEditOpen ? (
                                      <Flex flexDirection="column" justifyContent="center" width="100%">
                                        <Input
                                          width="100%"
                                          type="text"
                                          placeholder="Enter flair"
                                          defaultValue={flair_text_edited}
                                          onChange={(e) => {
                                            const { value } = e.target
                                            if (value.length <= MAX_FLAIR_LENGTH) {
                                              handleUpdateCustomizationDebounce({ type: 'flair_text_edited', value })
                                            }
                                          }}
                                          onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                              if (!flair_text_edited) {
                                                flair_text_edited = flair_text
                                                handleUpdateCustomization({
                                                  type: 'flair_text_edited',
                                                  value: flair_text,
                                                })
                                              }
                                              setIsFlairEditOpen(!isFlairEditOpen)
                                            }
                                          }}
                                        />
                                      </Flex>
                                    ) : (
                                      <StyledTextWrapper flex="1" alignItems="center">
                                        <StyledLink
                                          as="a"
                                          href={`${REDDIT_LINK}${subreddit}?f=flair_name:"${flair_text_edited ||
                                            flair_text}"`}
                                          color="primary"
                                          target="_blank"
                                        >
                                          <Icon.VistaSocialLabel width="16" /> {flair_text_edited || flair_text}
                                        </StyledLink>
                                      </StyledTextWrapper>
                                    )}

                                    <StyledIconWrapper
                                      alignItems="center"
                                      justifyContent="flex-end"
                                      ml="m"
                                      onClick={() => {
                                        if (isFlairEditOpen && !flair_text_edited) {
                                          flair_text_edited = flair_text
                                          handleUpdateCustomization({ type: 'flair_text_edited', value: flair_text })
                                        }
                                        setIsFlairEditOpen(!isFlairEditOpen)
                                      }}
                                      width="16px"
                                    >
                                      {isFlairEditOpen ? (
                                        <Icon.Checkmark fill="icon_color_gray" width="16px" height="16px" />
                                      ) : (
                                        <Icon.Edit width="16px" height="16px" />
                                      )}
                                    </StyledIconWrapper>

                                    <StyledIconWrapper
                                      alignItems="center"
                                      justifyContent="flex-end"
                                      ml="m"
                                      onClick={() => {
                                        if (isFlairEditOpen) {
                                          handleUpdateCustomization({
                                            type: 'flair_text_edited',
                                            value: flair_text,
                                          })
                                          setIsFlairEditOpen(false)
                                        } else {
                                          post.customizations[network][profileId].flair_id = ''
                                          delete post.customizations[network][profileId].flair_text
                                          delete post.customizations[network][profileId].flair_text_edited
                                          onChangeUpdatePost(post)
                                        }
                                      }}
                                      width="16px"
                                    >
                                      {isFlairEditOpen ? (
                                        <Icon.Undo fill="icon_color_gray" width="16px" height="16px" />
                                      ) : (
                                        <Icon.Trash width="16px" height="16px" />
                                      )}
                                    </StyledIconWrapper>
                                  </Flex>
                                ) : (
                                  <Flex flexDirection="column">
                                    <Select
                                      placeholder="Select a flair"
                                      value={flair_id ? flairs.find(({ value }) => flair_id === value) : null}
                                      onChange={(option) => {
                                        const { value = null, label = null, editable } = option || {}

                                        if (!post.customizations) {
                                          post.customizations = {}
                                        }
                                        if (!post.customizations[network]) {
                                          post.customizations[network] = {}
                                        }
                                        if (!post.customizations[network][profileId]) {
                                          post.customizations[network][profileId] = {}
                                        }

                                        post.customizations[network][profileId].flair_id = value
                                        post.customizations[network][profileId].flair_text = label
                                        post.customizations[network][profileId].flair_text_edited = editable
                                          ? label
                                          : ''

                                        onChangeUpdatePost(post)
                                      }}
                                      options={flairs}
                                      openMenuOnFocus
                                    />
                                  </Flex>
                                )}
                              </Fragment>
                            ) : (
                              <Flex alignItems="center">
                                {!isGettingFlairs && <Text>This subreddit does not have flair.</Text>}
                              </Flex>
                            )}
                          </Flex>
                          <TooltipPromptComponent
                            prompt_key="flair"
                            network={REDDIT}
                            mt={flair_id && !isFlairEditOpen ? '-3px' : '0'}
                          />
                        </Flex>
                      )}

                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Input
                            placeholder="Title"
                            label=""
                            defaultValue={title}
                            name="title"
                            id="title"
                            onChange={(e) => {
                              const { value } = e.target
                              if (value.length <= MAX_TITLE_LENGTH) {
                                handleUpdateCustomizationDebounce({ type: 'title', value })
                              }
                            }}
                            maxLength={MAX_TITLE_LENGTH}
                            width="100%"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="title" network={REDDIT} />
                      </Flex>
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

RedditPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

RedditPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isPostFormDirty: PropTypes.bool,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
}

export default RedditPost
