import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import dateFnsFormat from 'date-fns/format'
import moment from 'moment-timezone'
import { fontSizes, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Select from '../atoms/Select'
import Button from '../atoms/Button'

const StyledSelect = styled(Select)`
  padding: ${space.s} 0 ${space.s} ${space.s};
  background-position: calc(100% - ${space.s});
  background-color: transparent;
`

const StyledDateNavigationButton = styled(Button.Gray)`
  min-width: auto;
  width: 26px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background_card};

  svg {
    min-width: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const VistaSocialDashboardArrowRight = styled(Icon.VistaSocialDashboardArrow)`
  transform: rotate(-180deg);
`

const StyledSelectYearWrapper = styled(StyledSelect)`
  min-width: 70px;
  background-color: ${({ theme }) => theme.colors.background_card};
  color: ${({ theme }) => theme.colors.primaryText};
`

const StyledSelectMonthWrapper = styled(StyledSelect)`
  min-width: 110px;
  background-color: ${({ theme }) => theme.colors.background_card};
  color: ${({ theme }) => theme.colors.primaryText};
`

const StyledOption = styled.option`
  font-size: ${fontSizes.xs};
  color: ${({ theme }) => theme.colors.primaryText};
`

export const DEFAULT_FORMAT = 'MMM d, yyyy'

const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const fromMonth = new Date(2000, 0)
const toMonth = new Date(currentYear + 5, 11)
const years = []
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
  years.push(i)
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const Datepicker = ({ label, error, id, value, width, onChange, isClearDateEnabled, showCalendarIcon, ...props }) => {
  return (
    <React.Fragment>
      {label && (
        <Text mb="xs" fontSize="s">
          {label}
        </Text>
      )}
      <Flex position="relative" alignItems="center" width="100%">
        <Flex width={width} id={id} flex={1} alignItems="center">
          <DatePicker
            renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth }) => {
              return (
                <Flex justifyContent="space-between" ml="m">
                  <StyledSelectYearWrapper
                    name="year"
                    onChange={({ target: { value } }) => changeYear(value)}
                    value={getYear(date)}
                  >
                    {years.map((year) => (
                      <StyledOption key={year} value={year}>
                        {year}
                      </StyledOption>
                    ))}
                  </StyledSelectYearWrapper>
                  <Flex alignItems="center">
                    <StyledDateNavigationButton
                      onClick={decreaseMonth}
                      // height={DEFAULT_MENU_HEIGHT}
                    >
                      <Icon.VistaSocialDashboardArrow width="16px" height="16px" color="primaryText" />
                    </StyledDateNavigationButton>
                    <StyledSelectMonthWrapper
                      name="month"
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                      value={months[getMonth(date)]}
                    >
                      {months.map((month) => (
                        <StyledOption key={month} value={month}>
                          {month}
                        </StyledOption>
                      ))}
                    </StyledSelectMonthWrapper>
                    <StyledDateNavigationButton
                      onClick={increaseMonth}
                      // height={DEFAULT_MENU_HEIGHT}
                    >
                      <VistaSocialDashboardArrowRight height="16px" width="16px" fill="primaryText" />
                    </StyledDateNavigationButton>
                  </Flex>
                </Flex>
              )
            }}
            selected={value ? moment(value).toDate() : ''}
            disabledKeyboardNavigation
            dateFormat={DEFAULT_FORMAT}
            onChange={onChange}
            className={error ? 'red-border' : ''}
            {...props}
          />
        </Flex>
        {showCalendarIcon && (
          <Flex position="absolute" ml="s">
            <Image p="s" source="/assets/calendar_gray.svg" />
          </Flex>
        )}
        {value && isClearDateEnabled && (
          <Flex
            onClick={() => {
              onChange('')
            }}
            alignItems="center"
            position="absolute"
            right={{ mobile: '-5px', mobileLarge: '10px' }}
          >
            <Icon.Dismiss />
          </Flex>
        )}
      </Flex>
      {error && <ErrorLabel mt="s" error={error} />}
    </React.Fragment>
  )
}

Datepicker.defaultProps = {
  label: '',
  error: null,
  value: '',
  placeholderText: dateFnsFormat(new Date(), DEFAULT_FORMAT),
  isClearDateEnabled: true,
  showCalendarIcon: true,
}

Datepicker.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholderText: PropTypes.string,
  isClearDateEnabled: PropTypes.bool,
  showCalendarIcon: PropTypes.bool,
}

export default Datepicker
