import React, { useState, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { space as styledSpace, maxWidth as styledMaxWidth } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { truncate } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import TextArea from 'components/atoms/TextArea'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import Divider from 'components/atoms/Divider'
import Badge from 'components/atoms/Badge'
import { ROUTE_AI, ROUTE_SNIPPETS, ROUTE_ENTITIES } from '../../consts'
import RunningText from './components/RunningText'
// eslint-disable-next-line import/no-cycle
import SnippetModal from '../SnippetSelector/SnippetModal'
import { generateWriter } from './helpers'
// eslint-disable-next-line import/no-cycle
import { getSelectedProfileGroupsAndTimezone } from '../../helpers'
import CalendarItemTooltip from '../CalendarItemTooltip'
import {
  TONE_OPTION,
  FORMAT_OPTION,
  BRAND_VOICE_OPTION,
  TONE_OPTIONS,
  FORMAT_OPTIONS,
  TAB_TYPE_GUIDED,
  TABS,
  DEFAULT_BRAND_VOICE,
  AI_EXAMPLES,
} from './consts'
import TooltipPromptComponent from './components/TooltipPromptComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    overflow: hidden;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: transparent;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    ${styledMaxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    transition: all 350ms ease-in-out 0s;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const LeftColumnWrapper = styled(Flex)`
  outline: none;
  background: ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.l};
  transition: all 350ms ease-in-out 0s;
`

const RightColumnWrapper = styled(Flex)`
  @keyframes some {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  visibility: hidden;
  width: 0%;

  ${({ isVisible }) => isVisible && `visibility: visible; width: 50%;`}

  outline: none;
  background: ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.l};
  animation: 0.3s ease-in-out 0s 1 normal none running some;
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const LeftColumnBodyWrapper = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const StyledGeneratedTextWrapper = styled(Flex)`
  padding: ${space.m};
  margin: ${space.m} 0;
  cursor: pointer;
  border-radius: ${radius.l};
  flex-direction: column;
  box-shadow: 0 0 8px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};

  ${({ $isSelected }) => $isSelected && `box-shadow: rgba(0, 99, 227, 0.5) 0px 0px 4px;`}
`

const StyledGeneratedTextBodyWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
`

const StyledGeneratedText = styled(Text)`
  background-color: #e7f1ff;
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.white)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.border_color)};
`

const StyledLinkText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const StyledTextSelector = styled(Text)`
  cursor: pointer;
`

const StyledOptionsWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: ${space.s};
`

const StyledOptionWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: ${space.s} ${space.m};
  margin-top: ${space.s};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `border-color: ${theme.colors.primary}; background-color: ${transparentize(0.9, theme.colors.primary)};`}

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledActionWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.icon_background};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  opacity: 0.5;
  background: ${({ theme }) => theme.colors.background_loading_progress};
`

const WarningMessageWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_item_light};
  border-radius: ${radius.l};
  padding: ${space.s} ${space.m};
  margin-top: ${space.s};
`

const StyledRephraseButton = styled(Button.Gradient)`
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

const OpenAIModal = ({
  user,
  isOpen,
  handleDismiss,
  type,
  text,
  handleUpdateText,
  savedGeneratedTexts,
  saveOptions,
  generateOnOpen,
  withoutSelectedEntitiesAndProfiles,
  rephrase,
}) => {
  const textAreaRef = useRef(null)
  const tooltipRef = useRef(null)

  const [isInitial, setIsInitial] = useState(true)
  const [usage, setUsage] = useState(null)
  const [isGettingData, setIsGettingData] = useState(false)
  const [generatedTexts, setGeneratedTexts] = useState([])
  const [selectedTexts, setSelectedTexts] = useState([])
  const [isSnippetModalOpen, setIsSnippetModalOpen] = useState(false)
  const [isSnippetSaving, setIsSnippetSaving] = useState(false)
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true)
  const [brandVoiceOptions, setBrandVoiceOptions] = useState([DEFAULT_BRAND_VOICE])
  const [savedTextAreaTextsByTabs, setSavedTextAreaTextsByTabs] = useState({})

  const [selectedPromptOptions, setSelectedPromptOptions] = useState({
    [TONE_OPTION]: TONE_OPTIONS[0],
    [FORMAT_OPTION]: FORMAT_OPTIONS[0],
    [BRAND_VOICE_OPTION]: DEFAULT_BRAND_VOICE,
  })
  const [activeTab, setActiveTab] = useState({ ...TABS[0] })

  const { remaining } = usage || {}

  const getUsage = async () => {
    try {
      const response = await request({
        method: 'GET',
        path: `${ROUTE_AI}/usage`,
      })
      const { error, data } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const { type } = data || {}
        if (type === 'total') {
          setUsage({ ...data })
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: OpenAIModal.displayName, functionName: 'getUsage' })
    }
  }

  const getEntities = async () => {
    try {
      const response = await request({
        method: 'GET',
        path: `${ROUTE_ENTITIES}?include_profiles=false`,
      })
      const { error, entities = [] } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
        })

        const entities_with_brand_voices = [DEFAULT_BRAND_VOICE]

        selectedEntitiesWithSelectedProfiles.forEach(({ id }) => {
          const foundEntity = entities.find((entity) => entity.id === id)

          if (foundEntity && foundEntity.brand_voice) {
            entities_with_brand_voices.push({
              type: id,
              icon: foundEntity.picture_url || '/assets/company.svg',
              description: foundEntity.name,
              brand_voice: foundEntity.brand_voice,
            })
          }
        })

        setBrandVoiceOptions([...entities_with_brand_voices])
      }
    } catch (error) {
      errorHelper({ error, componentName: OpenAIModal.displayName, functionName: 'getEntities' })
    }
  }

  const collectInitialData = async () => {
    try {
      await getUsage()
      await getEntities()
      // eslint-disable-next-line no-empty
    } catch (error) {
      errorHelper({
        error,
        componentName: OpenAIModal.displayName,
        functionName: 'collectInitialData',
        showAlert: false,
        sendError: false,
      })
    } finally {
      setIsLoadingInitialData(false)
    }
  }

  useEffect(() => {
    collectInitialData()

    setGeneratedTexts([...savedGeneratedTexts])

    if (text && generateOnOpen) {
      // eslint-disable-next-line no-use-before-define
      handleClickGenerateText({ text, rephrase })
    }
  }, [isOpen])

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      const { value = '' } = textAreaRef.current || {}
      saveOptions({ text: value, generatedTexts })
    }
  }, [generatedTexts])

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false)
    } else {
      // eslint-disable-next-line no-use-before-define
      handleClickGenerateText({})
    }
  }, [selectedPromptOptions])

  const handleClickChangeTab = ({ item }) => {
    savedTextAreaTextsByTabs[activeTab.type] = textAreaRef.current.value

    setSavedTextAreaTextsByTabs({ ...savedTextAreaTextsByTabs })

    if (savedTextAreaTextsByTabs[item.type]) {
      textAreaRef.current.value = savedTextAreaTextsByTabs[item.type]
    } else {
      textAreaRef.current.value = ''
    }

    setActiveTab({ ...item })
  }

  const handleClickGenerateText = async ({ text, rephrase = false }) => {
    if (isGettingData) {
      Alert.error(`Please wait a bit longer while AI Assistant responds.`, { timeout: 5000 })
    } else {
      const { value } = textAreaRef.current || {}
      const searchText = text || value
      if (searchText) {
        setIsGettingData(true)

        try {
          const body = { text: searchText, mode: activeTab.type }

          if (rephrase) {
            body.rephrase = true
          }

          if (activeTab.type === TAB_TYPE_GUIDED) {
            const keys = [
              { key: TONE_OPTION, option: 'type' },
              { key: FORMAT_OPTION, option: 'type' },
              { key: BRAND_VOICE_OPTION, option: 'brand_voice' },
            ]

            keys.forEach(({ key, option }) => {
              if (selectedPromptOptions[key] && selectedPromptOptions[key].type) {
                body[key] = selectedPromptOptions[key][option]
              }
            })
          }

          const response = await request({
            method: 'POST',
            body,
            path: ROUTE_AI,
          })

          const { results = [], error } = response || {}

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          } else {
            for (const text of results) {
              let data = {
                id: new Date().getTime() + Math.floor(Math.random() * 1000),
                // text: text.replace(/\n/gi, ''),
                text,
                isNew: true,
                mode: activeTab.type,
              }

              if (activeTab.type === TAB_TYPE_GUIDED) {
                data = { ...data, ...JSON.parse(JSON.stringify(selectedPromptOptions)) }
              }

              const generatedText = await generateWriter({ text })
              data.transformedText = generatedText.join('')

              generatedTexts.unshift(data)
              if (generatedTexts.length === 1) {
                selectedTexts.push(data)
                setSelectedTexts([...selectedTexts])
              }
            }

            setGeneratedTexts([...generatedTexts])
          }
        } catch (error) {
          errorHelper({
            error,
            componentName: OpenAIModal.displayName,
            functionName: 'handleClickGenerateText',
          })
        } finally {
          setIsGettingData(false)
          getUsage()
        }
      } else {
        Alert.error(`Please include some content before using AI generation`, { timeout: 5000 })
      }
    }
  }

  const handleClickSaveSelectedText = () => {
    if (selectedTexts.length !== 0) {
      handleUpdateText({ text: selectedTexts.map(({ text }) => text).join('\n\n') })
    } else {
      Alert.error(`Please make a selection.`, { timeout: 5000 })
    }
  }

  const handleClickOpenSnippetModal = () => {
    setIsSnippetModalOpen(true)
  }

  const handleClickCloseSnippetModal = () => {
    setIsSnippetModalOpen(false)
  }

  const handleSaveSnippet = async ({ id, title, messages }) => {
    try {
      setIsSnippetSaving(true)

      let selectedEntitiesWithSelectedProfiles = []

      if (!withoutSelectedEntitiesAndProfiles) {
        ;({ selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
        }))
      }

      const data = {
        title,
        messages,
        entity_gids: selectedEntitiesWithSelectedProfiles.map(({ id }) => id),
        type,
        using_ai: true,
      }

      let response

      if (id) {
        response = await request({
          method: 'PUT',
          body: data,
          path: `${ROUTE_SNIPPETS}/${id}`,
        })
      } else {
        response = await request({
          method: 'POST',
          body: data,
          path: ROUTE_SNIPPETS,
        })
      }

      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        Alert.success(`Selected texts have been saved.`, { timeout: 5000 })
        handleClickCloseSnippetModal()
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: OpenAIModal.displayName,
        functionName: 'handleSaveSnippet',
      })
    } finally {
      setIsSnippetSaving(false)
    }
  }

  const handleClickOption = ({ key, data }) => {
    if (isGettingData) {
      Alert.error(`Please wait a bit longer while AI will respond to your request.`, { timeout: 5000 })
    } else {
      selectedPromptOptions[key] = data
      setSelectedPromptOptions({ ...selectedPromptOptions })
    }
  }

  const handleClickCopyText = async ({ text }) => {
    await navigator.clipboard.writeText(text)

    Alert.success(`Text has been copied.`, { timeout: 5000 })
  }

  const handleClickCloseModal = () => {
    if (!isGettingData && !isLoadingInitialData) {
      handleDismiss()
    } else {
      Alert.error(`Please wait a bit while we finish getting data.`, { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent
          maxWidth={{ mobile: '560px', tablet: generatedTexts.length === 0 && !isGettingData ? '880px' : '108vw' }}
        >
          {isLoadingInitialData ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              <LeftColumnWrapper
                flexDirection="column"
                width={{ mobile: '100%', tablet: generatedTexts.length === 0 && !isGettingData ? '100%' : '50%' }}
                height="100%"
                mr={generatedTexts.length > 0 || isGettingData ? 'm' : 0}
              >
                <StyledDialogEnvironmentWrapper
                  px="m"
                  py="m"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  $isTop
                >
                  <H4>AI Assistant</H4>

                  {usage && (
                    <Flex position="absolute" bottom="0" left={space.m}>
                      <Text as="span" color="error" fontSize="xs">
                        You have{' '}
                        <Text as="span" fontSize="xs">
                          {remaining} remaining trial AI credits
                        </Text>
                        . Please consider{' '}
                        <StyledLinkText as="a" href="/pricing" color="error" fontSize="xs">
                          upgrading
                        </StyledLinkText>
                        .
                      </Text>
                    </Flex>
                  )}
                </StyledDialogEnvironmentWrapper>

                <StyledDialogBodyWrapper flex="1">
                  <LeftColumnBodyWrapper width="100%" height="100%" flexDirection="column">
                    <Scrollbars universal>
                      <Flex flexDirection="column" pl="m" pr="xm" pb="m" height="100%">
                        <Flex mt="m" minHeight="30px" alignItems="center">
                          {TABS.map((item) => {
                            const { name, type } = item
                            const { type: activeTabType } = activeTab || {}
                            return (
                              <Tab
                                key={name}
                                onClick={() => {
                                  if (isGettingData) {
                                    Alert.error(`Please wait a bit longer while AI will respond to your request.`, {
                                      timeout: 5000,
                                    })
                                  } else {
                                    handleClickChangeTab({ item })
                                  }
                                }}
                                isActive={activeTabType && type === activeTabType}
                              >
                                <Text
                                  color={activeTabType && type === activeTabType ? 'primary' : 'secondaryText'}
                                  fontSize="s"
                                >
                                  {name}
                                </Text>
                              </Tab>
                            )
                          })}
                        </Flex>

                        <Text mt="m" textAlign="left" fontSize="s">
                          {/* {AI_EXAMPLES[type].description} */}
                          {activeTab.label}
                        </Text>

                        <Flex flexDirection="column" position="relative">
                          <TextArea
                            ref={textAreaRef}
                            defaultValue={text}
                            placeholder={activeTab.placeholder || AI_EXAMPLES[type].textareaPlaceholder}
                            rows={activeTab.type === TAB_TYPE_GUIDED ? 4 : 10}
                            fontSize="s"
                          />
                          <CloseIconWrapper
                            onClick={() => {
                              textAreaRef.current.value = ''
                            }}
                            zIndex="2"
                            top="-1px"
                            right="-9px"
                          >
                            <Image width="10px" height="10px" src="/assets/clear.svg" />
                          </CloseIconWrapper>
                        </Flex>

                        {activeTab.type === 'advanced' && (
                          <React.Fragment>
                            <Flex width="100%" mt="s" justifyContent="flex-end" alignItems="center">
                              <ButtonWithLoading
                                isLoading={isGettingData}
                                onClick={() => {
                                  handleClickGenerateText({})
                                }}
                              >
                                <Flex alignItems="center">
                                  <Icon.Splash width="12px" height="12px" fill="white" />
                                  <Text ml="xs">
                                    {isGettingData ? 'Generating' : 'Generate'} {AI_EXAMPLES[type].buttonTextEnding}
                                  </Text>
                                </Flex>
                              </ButtonWithLoading>
                            </Flex>
                          </React.Fragment>
                        )}

                        {activeTab.type === TAB_TYPE_GUIDED && (
                          <Fragment>
                            <Flex mt="s" flexDirection="column">
                              <Flex alignItems="center">
                                <Text>Tone</Text>
                                <TooltipPromptComponent optionType={TONE_OPTION} />
                              </Flex>
                              <StyledOptionsWrapper flexWrap="wrap">
                                {TONE_OPTIONS.map((item) => {
                                  const { type, title, description } = item

                                  const isSelected = selectedPromptOptions[TONE_OPTION].type === type

                                  return (
                                    <StyledOptionWrapper
                                      key={type}
                                      $isSelected={isSelected}
                                      onClick={() => {
                                        handleClickOption({ key: TONE_OPTION, data: item })
                                      }}
                                    >
                                      <Box height="24px">
                                        <Text lineHeight="24px">{title}</Text>
                                      </Box>
                                      <Text
                                        color={isSelected ? 'primaryText' : 'secondaryText'}
                                        fontSize="xs"
                                        fontWeight="medium"
                                      >
                                        {description}
                                      </Text>
                                    </StyledOptionWrapper>
                                  )
                                })}
                              </StyledOptionsWrapper>
                            </Flex>

                            <Flex mt="m" flexDirection="column">
                              <Flex alignItems="center">
                                <Text>Format</Text>
                                <TooltipPromptComponent optionType={FORMAT_OPTION} />
                              </Flex>
                              <StyledOptionsWrapper flexWrap="wrap">
                                {FORMAT_OPTIONS.map((item) => {
                                  const { type, title, description, icon } = item

                                  const isSelected = selectedPromptOptions[FORMAT_OPTION].type === type

                                  return (
                                    <StyledOptionWrapper
                                      key={type}
                                      $isSelected={isSelected}
                                      onClick={() => {
                                        handleClickOption({ key: FORMAT_OPTION, data: item })
                                      }}
                                    >
                                      <Box height="24px">
                                        {title ? (
                                          <Text lineHeight="24px">{title}</Text>
                                        ) : (
                                          <Image width="16px" height="16px" src={icon} />
                                        )}
                                      </Box>

                                      <Text
                                        color={isSelected ? 'primaryText' : 'secondaryText'}
                                        fontSize="xs"
                                        fontWeight="medium"
                                      >
                                        {description}
                                      </Text>
                                    </StyledOptionWrapper>
                                  )
                                })}
                              </StyledOptionsWrapper>
                            </Flex>

                            <Flex mt="m" pb="m" flexDirection="column">
                              <Flex alignItems="center">
                                <Text>Brand voice</Text>
                                <TooltipPromptComponent optionType={BRAND_VOICE_OPTION} />
                              </Flex>
                              {brandVoiceOptions.length > 1 ? (
                                <StyledOptionsWrapper flexWrap="wrap">
                                  {brandVoiceOptions.map((item) => {
                                    const { type, title, description, icon, brand_voice } = item

                                    const isSelected = selectedPromptOptions[BRAND_VOICE_OPTION].type === type

                                    return (
                                      <Flex alignItems="center" key={type}>
                                        <Flex alignItems="center">
                                          <StyledOptionWrapper
                                            $isSelected={isSelected}
                                            onClick={() => {
                                              handleClickOption({ key: BRAND_VOICE_OPTION, data: item })
                                            }}
                                            onMouseEnter={(e) => {
                                              tooltipRef.current.handleShowTooltip({
                                                contentComp: (
                                                  <Flex flexDirection="column" maxWidth="350px" p="m">
                                                    <Text fontWeight="bold">{description}</Text>
                                                    <Text>{brand_voice}</Text>
                                                  </Flex>
                                                ),
                                                wrapperComp: e.currentTarget,
                                              })
                                            }}
                                            onMouseLeave={() => {
                                              tooltipRef.current.handleHideTooltip()
                                            }}
                                          >
                                            <Box height="24px">
                                              {title ? (
                                                <Text lineHeight="24px">{title}</Text>
                                              ) : (
                                                <Image width="20px" height="20px" src={icon} borderRadius={radius.m} />
                                              )}
                                            </Box>
                                            <Text
                                              color={isSelected ? 'primaryText' : 'secondaryText'}
                                              fontSize="xs"
                                              fontWeight="medium"
                                            >
                                              {truncate(description, 15)}
                                            </Text>
                                          </StyledOptionWrapper>
                                        </Flex>
                                      </Flex>
                                    )
                                  })}
                                </StyledOptionsWrapper>
                              ) : (
                                <WarningMessageWrapper>
                                  <Text color="denimText">
                                    Selected profile groups do not have brand voice setup. Please navigate to profile
                                    group{' '}
                                    <Text color="denimText" as="a" href="/settings/profile-groups" target="_blank">
                                      settings
                                    </Text>{' '}
                                    to configure it.{' '}
                                    <Text
                                      color="denimText"
                                      as="a"
                                      href="https://support.vistasocial.com/hc/en-us/articles/11761305030171-Crafting-social-posts-with-AI-Assistant"
                                      target="_blank"
                                    >
                                      Learn more
                                    </Text>{' '}
                                    about brand voice.
                                  </Text>
                                </WarningMessageWrapper>
                              )}
                            </Flex>
                          </Fragment>
                        )}
                      </Flex>
                    </Scrollbars>
                  </LeftColumnBodyWrapper>
                </StyledDialogBodyWrapper>

                <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-start" $isBottom>
                  <Button.Gray isSmall onClick={handleClickCloseModal}>
                    Cancel
                  </Button.Gray>
                </StyledDialogEnvironmentWrapper>
              </LeftColumnWrapper>

              <RightColumnWrapper
                width="50%"
                height="100%"
                flexDirection="column"
                isVisible={generatedTexts.length !== 0 || isGettingData}
              >
                <StyledDialogBodyWrapper flex="1">
                  <Flex flexDirection="column" width="100%" height="100%" position="relative">
                    <StyledProgressWrapper
                      display={isGettingData ? 'flex' : 'none'}
                      alignItems="center"
                      justifyContent="center"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="100" />
                    </StyledProgressWrapper>

                    <Scrollbars universal>
                      <Flex flexDirection="column" width="100%" height="100%" px="m" pt="m">
                        <Flex width="100%" justifyContent="space-between" alignItems="center">
                          {generatedTexts.length > 0 ? (
                            <Flex mt="s" alignItems="center">
                              <StyledTextSelector
                                onClick={() => {
                                  setSelectedTexts([...generatedTexts])
                                }}
                                fontSize="xs"
                                color="primary"
                              >
                                Select all
                              </StyledTextSelector>

                              {selectedTexts.length > 0 && (
                                <Fragment>
                                  <Divider width="2px" mx="s" height="10px" />
                                  <StyledTextSelector
                                    onClick={() => {
                                      setSelectedTexts([])
                                    }}
                                    fontSize="xs"
                                    color="primary"
                                  >
                                    Unselect all
                                  </StyledTextSelector>
                                </Fragment>
                              )}
                            </Flex>
                          ) : (
                            <Flex />
                          )}
                        </Flex>

                        <Flex flexDirection="column" mt="s">
                          {generatedTexts.map((generated, index) => {
                            const {
                              id,
                              text,
                              transformedText,
                              isNew,
                              mode,
                              [TONE_OPTION]: toneOption,
                              [FORMAT_OPTION]: formatOption,
                              [BRAND_VOICE_OPTION]: brandVoiceOption,
                            } = generated
                            const selectedIndex = selectedTexts.findIndex((item) => item.id === id)
                            const selected = selectedIndex > -1

                            const badges =
                              mode === TAB_TYPE_GUIDED ? [toneOption, formatOption, brandVoiceOption] : null

                            return (
                              <StyledGeneratedTextWrapper
                                key={id}
                                onClick={() => {
                                  if (selected) {
                                    selectedTexts.splice(selectedIndex, 1)
                                  } else {
                                    selectedTexts.push(generated)
                                  }
                                  setSelectedTexts([...selectedTexts])
                                }}
                                $isSelected={selected}
                                position="relative"
                              >
                                <Flex alignItems="center" justifyContent="space-between">
                                  <Flex alignItems="center">
                                    <Box width="36px">
                                      <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
                                        <StyledIconCheckmark selected={selected} width="10px" height="10px" />
                                      </SelectWrapper>
                                    </Box>

                                    {badges ? (
                                      <Fragment>
                                        {badges.map(({ title, description, icon }, index) => (
                                          <Badge.Status key={index} mr="s" color="secondaryText" height="32px">
                                            {title ? (
                                              <Text>{title}</Text>
                                            ) : (
                                              <Image width="19px" height="14px" src={icon} borderRadius={radius.m} />
                                            )}
                                            <Text ml="xs" fontSize="xs" textAlign="center">
                                              {truncate(description, 15)}
                                            </Text>
                                          </Badge.Status>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <Badge.Status mr="s" color="secondaryText" height="32px">
                                          <Text fontSize="xs" textAlign="center">
                                            Advanced mode
                                          </Text>
                                        </Badge.Status>
                                      </Fragment>
                                    )}
                                  </Flex>

                                  <Flex alignItems="center">
                                    <StyledActionWrapper
                                      ml="s"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleClickCopyText({ text })
                                      }}
                                    >
                                      <Icon.Copy width="14px" height="14px" />
                                    </StyledActionWrapper>

                                    {!isNew && (
                                      <StyledActionWrapper
                                        mx="s"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          generatedTexts.splice(index, 1)
                                          setGeneratedTexts([...generatedTexts])

                                          if (selected) {
                                            selectedTexts.splice(selectedIndex, 1)
                                            setSelectedTexts([...selectedTexts])
                                          }
                                        }}
                                      >
                                        <Icon.Trash width="14px" height="14px" />
                                      </StyledActionWrapper>
                                    )}

                                    <StyledActionWrapper
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleUpdateText({ text })
                                      }}
                                    >
                                      <Icon.VistaSocialPaperPlane width="16px" height="16px" stroke="icon_color_gray" />
                                    </StyledActionWrapper>
                                  </Flex>
                                </Flex>
                                <StyledGeneratedTextBodyWrapper mt="m" p="m">
                                  <Flex flexDirection="column" width="100%" position="relative">
                                    <Box position="absolute">
                                      <RunningText
                                        text={text}
                                        onFinish={() => {
                                          generatedTexts[index].isNew = false
                                          setGeneratedTexts([...generatedTexts])
                                        }}
                                        isNew={isNew}
                                      />
                                    </Box>

                                    <Box zIndex="-1" bg="white">
                                      <StyledGeneratedText
                                        dangerouslySetInnerHTML={{
                                          __html: transformedText,
                                        }}
                                      />
                                    </Box>
                                  </Flex>
                                </StyledGeneratedTextBodyWrapper>
                              </StyledGeneratedTextWrapper>
                            )
                          })}
                        </Flex>
                      </Flex>
                    </Scrollbars>
                  </Flex>
                </StyledDialogBodyWrapper>

                <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
                  {selectedTexts.length > 0 ? (
                    <Button.Gray isSmall onClick={handleClickOpenSnippetModal}>
                      Save selected
                    </Button.Gray>
                  ) : (
                    <Flex />
                  )}

                  <StyledRephraseButton onClick={handleClickSaveSelectedText} isSmall>
                    <Flex alignItems="center">
                      <Icon.VistaSocialPaperPlane width="20px" height="18px" stroke="white" />
                      <Text ml="xs" fontWeight="medium">
                        {rephrase ? 'Replace with selected' : AI_EXAMPLES[type].buttonTextSend}
                      </Text>
                    </Flex>
                  </StyledRephraseButton>
                </StyledDialogEnvironmentWrapper>
              </RightColumnWrapper>

              {isSnippetModalOpen && (
                <SnippetModal
                  user={user}
                  isOpen={isSnippetModalOpen}
                  handleDismiss={handleClickCloseSnippetModal}
                  handleSave={handleSaveSnippet}
                  isSubmitting={isSnippetSaving}
                  type={type}
                  newSnippets={selectedTexts.map(({ text }) => text)}
                />
              )}

              <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} top="-6px" right="-9px">
                <ImageWithFallback
                  width="10px"
                  height="10px"
                  source="/assets/clear.svg"
                  fallbackSource="/assets/clear.svg"
                />
              </CloseIconWrapper>
            </Fragment>
          )}
        </StyledDialogContent>

        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

OpenAIModal.defaultProps = {
  saveOptions: () => {},
  generateOnOpen: false,
  withoutSelectedEntitiesAndProfiles: false,
  rephrase: false,
}

OpenAIModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleUpdateText: PropTypes.func.isRequired,
  savedGeneratedTexts: PropTypes.array.isRequired,
  saveOptions: PropTypes.func,
  generateOnOpen: PropTypes.bool,
  withoutSelectedEntitiesAndProfiles: PropTypes.bool,
  rephrase: PropTypes.bool,
}

OpenAIModal.displayName = 'OpenAIModal'

export default OpenAIModal
