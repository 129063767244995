import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { IMAGE_DYNAMIC, IMAGE } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import DynamicMedia from 'components/molecules/DynamicMedia'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
import { PREVIEW_MEDIA_CLASS_NAME } from 'routes/Calendar/consts'

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledFlex = styled(Flex)`
  gap: 0.25em;
`

const StyledImage = styled(ImageWithFallback)`
  object-fit: cover;
  width: 100%;
  max-width: 100%;
`

const ImageTemplate = ({ media, post, socialPreviewProfiles, network }) => {
  const { url, id, [network]: networkErrors = {}, isNew, type } = media
  const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
  const hasError = hasAspectRatioError || hasSizeError

  return (
    <StyledBox width="100%" height="100%" hasError={hasError}>
      {isNew ? (
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
          <ClipLoader size="50" />
        </Flex>
      ) : (
        <Fragment>
          {type === IMAGE && (
            <StyledImage
              id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
              name={id}
              source={url}
              width="inherit"
              height="inherit"
              objectFit="cover"
            />
          )}

          {type === IMAGE_DYNAMIC && (
            <DynamicMedia media={media} post={post} profiles={socialPreviewProfiles} isSmall />
          )}
        </Fragment>
      )}
    </StyledBox>
  )
}

ImageTemplate.defaultProps = {
  post: null,
  socialPreviewProfiles: [],
}

ImageTemplate.propTypes = {
  media: PropTypes.object.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  network: PropTypes.string.isRequired,
}

const ImagesGridTumblr = ({ images, network, post, socialPreviewProfiles, maxWidth }) => {
  const tooltipRef = useRef(null)

  const imagesCount = images.length

  const rowsConfiguration = []

  if (imagesCount > 0) {
    rowsConfiguration.push({ count: 1, images_before: 0, height: '100%' })

    switch (imagesCount) {
      case 2:
        rowsConfiguration.push({ count: 1, images_before: 1, height: '100%' })
        break
      case 3:
      case 4:
        rowsConfiguration.push({ count: imagesCount - 1, images_before: 1, height: 0 })
        break
      case 5:
        rowsConfiguration.push({ count: 2, images_before: 1, height: 0 })
        rowsConfiguration.push({ count: 2, images_before: 3, height: 0 })
        break
      case 6:
        rowsConfiguration.push({ count: 2, images_before: 1, height: 0 })
        rowsConfiguration.push({ count: 3, images_before: 3, height: 0 })
        break
      case 7:
        rowsConfiguration.push({ count: 2, images_before: 1, height: 0 })
        rowsConfiguration.push({ count: 2, images_before: 3, height: 0 })
        rowsConfiguration.push({ count: 2, images_before: 5, height: 0 })
        break
      case 8:
        rowsConfiguration.push({ count: 2, images_before: 1, height: 0 })
        rowsConfiguration.push({ count: 3, images_before: 3, height: 0 })
        rowsConfiguration.push({ count: 2, images_before: 6, height: 0 })
        break
      default:
        break
    }
  }

  return (
    <Flex width="100%">
      {imagesCount !== 0 && (
        <StyledFlex flexDirection="column" width="100%" height="100%">
          {rowsConfiguration.map(({ count, images_before, height }, index) => {
            let height_updated = height

            const row_images = images.slice(images_before, images_before + count)

            if (!height_updated) {
              // here must calc the smallest image height in the row
              const maxWidthUpdated = maxWidth / count

              height_updated = 0

              row_images.forEach(({ width, height }) => {
                if (width && height) {
                  const heightTransformed = (height * maxWidthUpdated) / width

                  if (!height_updated || heightTransformed < height_updated) {
                    height_updated = heightTransformed
                  }
                }
              })

              height_updated = height_updated ? `${height_updated}px` : '100%'
            }

            return (
              <Grid key={index} gridTemplateColumns={`repeat(${count}, 1fr)`} gridGap="0.25em">
                {row_images.map((media, index) => {
                  return (
                    <Flex width="100%" height={height_updated} index={index}>
                      <ImageTemplate
                        network={network}
                        media={media}
                        post={post}
                        socialPreviewProfiles={socialPreviewProfiles}
                      />
                    </Flex>
                  )
                })}
              </Grid>
            )
          })}
        </StyledFlex>
      )}

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

ImagesGridTumblr.defaultProps = {
  images: [],
  post: null,
  socialPreviewProfiles: [],
}

ImagesGridTumblr.propTypes = {
  images: PropTypes.array,
  network: PropTypes.string.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  maxWidth: PropTypes.number.isRequired,
}

export default ImagesGridTumblr
