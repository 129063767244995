import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, radius } from 'theme'
import { IMAGE, VIDEO, AUDIO, IMAGE_DYNAMIC, VIDEO_DYNAMIC, DOCUMENT } from 'consts'
import { getFileIconByExtension } from 'helpers'
import { Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Badge from 'components/atoms/Badge'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import AudioPlayer from 'components/molecules/AudioPlayer'
import DynamicMedia from 'components/molecules/DynamicMedia'

const MediaPreviewComponentWrapper = styled(Flex)`
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  box-shadow: 0 0 10px 5px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
`

const StyledBadgeStatus = styled(Badge.Status)`
  border-radius: unset;
`

const MediaPreviewComponent = ({ type, url, title, description, labels, entities, meta, ...other }) => {
  const { id, filename, thumbnail_url, extension, thumbnail_url_small, duration } = other
  return (
    <MediaPreviewComponentWrapper
      width="100%"
      height="100%"
      minHeight="100px"
      minWidth="100px"
      maxWidth="300px"
      flexDirection="column"
    >
      {type === IMAGE && (
        <ImageWithFallback
          source={url}
          width="100%"
          height="100%"
          maxWidth="300px"
          maxHeight="300px"
          fallbackSource="/assets/landscape.svg"
          fallbackSourceWidth="100%"
          fallbackSourceHeight="100%"
        />
      )}
      {type === VIDEO && (
        <Box width="300px" maxWidth="300px" height="300px" maxHeight="300px">
          <VideoWithFallback
            url={url}
            controls
            width="100%"
            style={{ position: 'relative' }}
            height="100%"
            volume={0}
            muted
            playing
          />
        </Box>
      )}
      {type === AUDIO && (
        <Box width="300px" maxWidth="300px" height="300px" maxHeight="300px">
          <AudioPlayer
            playlist={[
              {
                id: `audio-preview-${id}`,
                source: url,
                trackName: filename,
                trackArtist: title,
                trackImage: thumbnail_url_small || thumbnail_url || '',
                loop: false,
                duration,
              },
            ]}
            isSmall={false}
          />
        </Box>
      )}

      {type === DOCUMENT && (
        <ImageWithFallback
          source={thumbnail_url || getFileIconByExtension({ extension })}
          width="100%"
          height="100%"
          maxWidth="300px"
          maxHeight="300px"
          fallbackSource={getFileIconByExtension({ extension })}
          fallbackSourceWidth="100%"
          fallbackSourceHeight="100%"
        />
      )}

      {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
        <Flex
          width="300px"
          maxWidth="300px"
          height="300px"
          maxHeight="300px"
          alignItems="center"
          justifyContent="center"
        >
          <DynamicMedia media={other} isSmall />
        </Flex>
      )}
      {(title || description || entities.length > 0 || labels.length > 0 || meta) && (
        <Flex flexDirection="column" width="100%" mb="s" px="s">
          {meta && (
            <Text mt="s" color="secondaryText" fontSize="xs">
              {meta}
            </Text>
          )}
          {title && (
            <Text mt="s" fontSize="xs">
              {title}
            </Text>
          )}
          {description && (
            <Text mt="s" color="secondaryText" fontSize="xs">
              {description}
            </Text>
          )}
          {entities.length !== 0 && (
            <Flex flexWrap="wrap" mt="s">
              {entities.map(({ label }) => (
                <StyledBadgeStatus key={label} mr="s" mt="xs" color={COLOR_CONSTANTS.PUMPKIN_PIE}>
                  <Text fontSize="xs" textAlign="center">
                    {label}
                  </Text>
                </StyledBadgeStatus>
              ))}
            </Flex>
          )}
          {labels && labels.length !== 0 && (
            <Flex flexWrap="wrap" mt="s">
              {labels.map((label) => (
                <Badge.Status key={label} mr="s" mt="xs" color={COLOR_CONSTANTS.SAPPHIRE}>
                  <Text fontSize="xs" textAlign="center">
                    {label}
                  </Text>
                </Badge.Status>
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </MediaPreviewComponentWrapper>
  )
}

MediaPreviewComponent.defaultProps = {
  title: '',
  description: '',
  labels: [],
  entities: [],
  type: IMAGE,
  meta: '',
  url: '',
  thumbnail_url: '',
  extension: '',
}

MediaPreviewComponent.propTypes = {
  url: PropTypes.string,
  thumbnail_url: PropTypes.string,
  extension: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  labels: PropTypes.array,
  entities: PropTypes.array,
  type: PropTypes.string,
  meta: PropTypes.string,
}

export default MediaPreviewComponent
