import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { ROUTE_LABELS } from 'routes/Calendar/consts'

export const getLabels = async ({ setLabels, type, isCommon = false }) => {
  try {
    const response = await request({
      method: 'GET',
      path: `${ROUTE_LABELS}?type=${type.toLowerCase()}&common=${isCommon ? true : ''}`,
    })
    if (response && !response.error) {
      setLabels([...response])
    }
  } catch (error) {
    errorHelper({ error, componentName: 'LabelEditModal/helpers', functionName: 'getLabels' })
  }
}
