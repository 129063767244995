import React from 'react'
import dynamic from 'next/dynamic'

const NoSSRComponent = dynamic(() => import('./ImageEditModal'), {
  ssr: false,
})

const ImageEditModalWrapper = (props) => <NoSSRComponent {...props} />

export default ImageEditModalWrapper
