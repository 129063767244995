import React from 'react'
import styled from 'styled-components'
import { radius, space } from 'theme'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

const WatermarkWrapper = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: ${space.s} 0;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: ${radius.l};
`

const WatermarkComponent = () => {
  return (
    <WatermarkWrapper
      py="s"
      alignItems="center"
      justifyContent="center"
      href={`${process.env.NEXT_PUBLIC_SITE_URL}/vista-page/`}
      target="_blank"
    >
      <Text fontSize={`${0.813}em`} color="denimText" fontWeight="bold">
        Made with
      </Text>
      <Image mx="s" src="/assets/logo_big.svg" width="135px" height="30px" />
    </WatermarkWrapper>
  )
}

WatermarkComponent.displayName = 'WatermarkComponent'

export default WatermarkComponent
