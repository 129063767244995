import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'shared/Modal'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from '../components/molecules/ButtonWithLoading'

const URL = 'url'

const ValidationSchema = Yup.object().shape({
  [URL]: Yup.string()
    .required('URL is required')
    .url('Must be a valid URL address'),
})

const StyledInput = styled(Input)`
  z-index: 2;
`

const StyledImage = styled(Image)`
  position: absolute;
  top: -50px;
  right: -10px;
  z-index: 1;
  pointer-events: none;
`

const PLACEHOLDERS = {
  trustpilot: 'https://trustpilot.com/review/your-company-link',
  yelp: 'https://www.yelp.com/biz/your-company-link',
  tripadvisor: 'https://www.tripadvisor.com/Type_Review-g60999-d142956-Your-Location-Name.html',
  opentable: 'https://www.opentable.com/r/your-company-link',
}

const ConnectModal = ({ isOpen, onDismiss, onSubmit, type, code, display, isSubmitting }) => (
  <Modal isOpen={isOpen} handleDismiss={onDismiss} isDisabled={isSubmitting}>
    <Flex alignItems="center">
      <Image source={`/assets/${type === 'BLOG' ? 'feed' : 'sms'}.svg`} />
      <Text ml="m" fontWeight="bold">
        CONNECT {type === 'BLOG' ? 'BLOG' : 'REVIEW SITE'}
      </Text>
    </Flex>
    <Formik
      initialValues={{ [URL]: '' }}
      validationSchema={ValidationSchema}
      onSubmit={(values, { setErrors }) => {
        onSubmit({ url: values[URL], type, code, setErrors })
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <React.Fragment>
          <Box mt="m">
            <Box position="relative">
              <StyledInput
                value={values[URL]}
                onChange={handleChange(URL)}
                label={type === 'BLOG' ? 'Blog RSS link' : `${display} site link`}
                error={errors[URL] && touched[URL] && errors[URL]}
                placeholder={PLACEHOLDERS[code] || ''}
              />
              <StyledImage source="/assets/connect_rss_illustration.svg" />
            </Box>
            <Flex mt="m" justifyContent="flex-end">
              <Button.Gray onClick={onDismiss} isDisabled={isSubmitting}>
                <Text>Cancel</Text>
              </Button.Gray>
              <ButtonWithLoading isLoading={isSubmitting} ml="s" onClick={handleSubmit} type="submit">
                <Text>Connect {type === 'BLOG' ? 'blog' : 'review site'}</Text>
              </ButtonWithLoading>
            </Flex>
          </Box>
        </React.Fragment>
      )}
    </Formik>
  </Modal>
)

ConnectModal.defaultProps = {
  isOpen: false,
  isSubmitting: false,
  type: 'BLOG',
  code: '',
  display: '',
}

ConnectModal.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['BLOG', 'REVIEW']),
  code: PropTypes.string,
  display: PropTypes.string,
  isSubmitting: PropTypes.bool,
}

export default ConnectModal
