import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import debounce from 'lodash.debounce'
import Cookies from 'js-cookie'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  AUDIO,
  LABELS_TYPE_MEDIA,
  IMAGE,
  VIDEO,
  DOCUMENT,
  VIDEO_DYNAMIC,
  COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED,
  COOKIE_EXPIRATION_DAYS,
  FEATURE_FIND_CONTENT,
  FEATURE_DRIVES,
} from 'consts'
import { truncate } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Divider from 'components/atoms/Divider'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Button from 'components/atoms/Button'
import NoData from 'components/molecules/NoData'
import AudioPlayer from 'components/molecules/AudioPlayer'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Input from 'components/atoms/Input'
import DynamicMedia from 'components/molecules/DynamicMedia'
import { getLabels } from 'shared/LabelEditModal/helpers'
import { POST_VIDEO, ROUTE_MEDIA } from 'routes/Calendar/consts'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
import MediaFilters from './MediaFilters'
import MediaPreviewComponent from './MediaPreviewComponent'
import VideoApplySoundModal from './VideoApplySoundModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 80vw;
    height: 100%;
    width: 100%;
    min-height: 460px;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const Tab = styled(Box)`
  cursor: pointer;
  padding-bottom: ${space.s};
  margin-left: ${space.s};
  margin-right: ${space.m};
  text-decoration: none;
  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 2px solid ${theme.colors.primary};
  `}
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${({ theme }) => transparentize(0.5, theme.colors.background_loading_progress)};
`

const StyledWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.background_item_light};
  border-radius: ${radius.l};
  ${({ selected, theme }) => selected && `  box-shadow: 0 0 5px 0 ${transparentize(0.7, theme.colors.box_shadow)};`}
`

const StyledMediaWrapper = styled(Box)`
  width: 100%;
  height: 150px;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.white)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
  &:hover {
    border-color: ${({ theme }) => theme.colors.background_card};
  }
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.icon_color_gray)};
`

const StyledText = styled(Text)`
  text-align: center;
`

const StyledTitleWrapper = styled(Flex)`
  height: 13px;
  overflow: hidden;
  justify-content: center;
`

const StyledPromptWrapper = styled(Flex)`
  border-radius: 3px;
  background: ${({ theme }) => transparentize(0.9, theme.colors.primaryText)};
`

const StyledClosePromptWrapper = styled(Flex)`
  cursor: pointer;
`

const MediaSectionWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
`

const MediaFolderWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  &:hover {
    box-shadow: 0 0 10px 5px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const StyledFolderWrapper = styled(Flex)`
  border-radius: ${radius.l};
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  padding: ${space.sm};
`

const StyledFolderBackNavigationText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
`

const { AUDIO_TRENDING, AUDIO_MEDIA_LIBRARY } = { AUDIO_TRENDING: 'trending', AUDIO_MEDIA_LIBRARY: 'media_library' }

const TABS = [
  {
    name: 'Trending sounds',
    type: AUDIO_TRENDING,
    prompt: `Trending sounds are curated by Vista Social product team. <a style="color: ${COLOR_CONSTANTS.COBALT};" href="https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts" target="_blank">Learn more</a>.`,
  },
  { name: 'Media library', type: AUDIO_MEDIA_LIBRARY },
]

const DEFAULT_BLOCK_MEDIA_OPTIONS = {
  iconNameEnding: 'Lock',
  iconWidthEnding: '14px',
  iconColorEnding: 'error',
}

const DEFAULT_MENU_HEIGHT = '34px'

const SearchInputComponent = forwardRef(({ handleFilterMediaBySearch }, inputSearchText) => {
  const [searchString, setSearchString] = useState('')

  useImperativeHandle(inputSearchText, () => ({
    getSearchString() {
      return searchString
    },
  }))

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMediaBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      height={DEFAULT_MENU_HEIGHT}
      width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
    />
  )
})

SearchInputComponent.propTypes = {
  handleFilterMediaBySearch: PropTypes.func.isRequired,
}

const VideoPickSoundModal = ({
  user,
  isOpen,
  handleDismiss,
  selectedVideo,
  handleUpdateMedia,
  featuresEnabled,
  handleClickOpenMediaUploadByLinkModal,
  handleClickOpenMediaUploadByFileModal,
  uploadingMedias,
  newMedias,
  handleClickSendToCompose,
  handleClickGoogleDrive,
  handleClickDropbox,
  handleClickOneDrive,
}) => {
  const mediaFiltersRef = useRef(null)
  const inputSearchText = useRef(null)
  const tooltipRef = useRef(null)
  const scrollbarsRef = useRef(null)

  const [video, setVideo] = useState({})
  const [activeTab, setActiveTab] = useState(TABS[0])
  const [audios, setAudios] = useState([])
  const [folderMedias, setFolderMedias] = useState([])
  const [isGettingAudio, setIsGettingAudio] = useState(false)
  const [isFinalPage, setIsFinalPage] = useState(false)
  const [page, setPage] = useState(0)
  const [isInitial, setIsInitial] = useState(true)
  const [selectedAudioId, setSelectedAudioId] = useState(null)
  const [isSettingAudio, setIsSettingAudio] = useState(false)
  const [uploadingAudios, setUploadingAudios] = useState([])
  const [labels, setLabels] = useState([])
  const [isSoundOptionsModalOpen, setIsSoundOptionsModalOpen] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [folderId, setFolderId] = useState()
  const [folder, setFolder] = useState(null)

  const { entity: { id: root_entity_gid } = {} } = user
  const { id, url, thumbnail_url, type, dynamic } = video || {}
  const { audio } = dynamic || {}
  const { audio_gid, audio_root_entity_gid } = audio || {}

  let audioUrl

  if (type === VIDEO) {
    audioUrl = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/media/${root_entity_gid}/${id}.mp3`
  } else if (type === VIDEO_DYNAMIC && audio_gid) {
    audioUrl = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/media/${audio_root_entity_gid}/${audio_gid}.mp3`
  } else {
    audioUrl = ''
  }

  const showUploadAudio = handleClickOpenMediaUploadByFileModal && handleClickOpenMediaUploadByLinkModal

  const redirectToMediaLibrary = () => {
    setFolder(null)
    setFolderId(null)
  }

  const collectFiltersToQueryParameters = ({ selectedFilters }) => {
    const query_items = []

    if (folder) {
      query_items.push(`media_path=${folder.media_path.join(',')}`)
    }

    const updatedSelectedFilters =
      selectedFilters ||
      (mediaFiltersRef && mediaFiltersRef.current ? mediaFiltersRef.current.getFiltersData() : { filters: {} })

    const { filters: { selectedLabels = [] } = {} } = updatedSelectedFilters

    if (selectedLabels.length !== 0) {
      query_items.push(`labels=${encodeURIComponent(selectedLabels.map(({ value }) => value).join(','))}`)
    }

    const searchTextValue = inputSearchText && inputSearchText.current ? inputSearchText.current.getSearchString() : ''

    query_items.push(`q=${encodeURIComponent(searchTextValue)}`)

    return query_items.join('&')
  }

  const handleGetAudios = async ({ firstTab, page, selectedFilters }) => {
    try {
      setIsGettingAudio(true)

      const tabType = firstTab ? firstTab.type : activeTab.type

      let path = `${ROUTE_MEDIA}?types=audio&page=${page}&common=${tabType === AUDIO_TRENDING ? true : ''}`

      path += `&${collectFiltersToQueryParameters({ selectedFilters })}`

      const response = await request({
        path,
      })

      const { error, data, hasNextPage } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setIsFinalPage(!hasNextPage)

        if (page === 0) {
          setAudios([...data])
        } else {
          setAudios([...audios, ...data])
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: VideoPickSoundModal.displayName,
        functionName: 'handleGetAudios',
        errorMessage: `Can't get sounds. Please contact support.`,
      })
    } finally {
      setIsGettingAudio(false)
    }
  }

  const getFolderMedias = async ({ selectedFilters }) => {
    if (activeTab.type === AUDIO_TRENDING) {
      setFolderMedias([])
    } else {
      try {
        let path = `${ROUTE_MEDIA}/folders`

        path += `?${collectFiltersToQueryParameters({ selectedFilters })}`

        const response = await request({
          path,
        })

        const { error, data } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setFolderMedias([...data])
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: VideoPickSoundModal.displayName,
          functionName: 'getFolderMedias',
          errorMessage: `Can't get your folders. Please contact support.`,
        })
      }
    }
  }

  const getFolderData = async () => {
    try {
      const response = await request({
        path: `${ROUTE_MEDIA}/folder/${folderId}`,
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        redirectToMediaLibrary()
      } else {
        setFolder({ ...data })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: VideoPickSoundModal.displayName,
        functionName: 'getFolderData',
      })
      redirectToMediaLibrary()
    }
  }

  const checkPrompt = async () => {
    const isCookieAccepted = await Cookies.get(COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED)
    if (!isCookieAccepted) {
      setShowPrompt(!isCookieAccepted)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setVideo({ ...selectedVideo })
      getLabels({
        setLabels: (data) => {
          setLabels(data.map((label) => ({ value: label, label })))
        },
        type: LABELS_TYPE_MEDIA,
        isCommon: activeTab.type === AUDIO_TRENDING,
      })
      checkPrompt()
    }
  }, [])

  useEffect(() => {
    if (!isInitial) {
      if (page === 0) {
        handleGetAudios({ page, selectedFilters: { filters: {} } })
        getFolderMedias({ selectedFilters: { filters: {} } })
        getLabels({
          setLabels: (data) => {
            setLabels(data.map((label) => ({ value: label, label })))
          },
          type: LABELS_TYPE_MEDIA,
          isCommon: activeTab.type === AUDIO_TRENDING,
        })
        mediaFiltersRef.current.setFiltersData({
          data: { labels: [], extensions: { [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] } },
        })
      } else {
        handleGetAudios({ page: 0 })
        getFolderMedias({})

        if (!isGettingAudio) {
          setIsGettingAudio(true)
        }

        if (isFinalPage) {
          setIsFinalPage(false)
        }

        if (page !== 0) {
          setPage(0)
        }
      }
    }
  }, [activeTab])

  useEffect(() => {
    if (folderId) {
      getFolderData()
    } else {
      handleGetAudios({ page: 0 })
      getFolderMedias({})
    }

    if (!isGettingAudio) {
      setIsGettingAudio(true)
    }

    if (isFinalPage) {
      setIsFinalPage(false)
    }

    if (page !== 0) {
      setPage(0)
    }
  }, [folderId])

  useEffect(() => {
    if (folder) {
      handleGetAudios({ page: 0 })
      getFolderMedias({})

      if (!isGettingAudio) {
        setIsGettingAudio(true)
      }

      if (isFinalPage) {
        setIsFinalPage(false)
      }

      if (page !== 0) {
        setPage(0)
      }
    }
  }, [folder])

  useEffect(() => {
    if (!isGettingAudio && !isFinalPage) {
      if (isInitial) {
        setIsInitial(false)
      }
      if (!isInitial) {
        if (process.browser && page === 0 && scrollbarsRef && scrollbarsRef.current) {
          let top = 0

          const el = document.getElementById('media-section-wrapper')

          if (el) {
            const bounding = el.getBoundingClientRect()

            // eslint-disable-next-line prefer-destructuring
            top = bounding.top
          }

          scrollbarsRef.current.scrollTop(top)
        }

        handleGetAudios({ page })
      }
    }
  }, [page])

  useEffect(() => {
    const filteredAudios = uploadingMedias.filter(({ type }) => type === AUDIO)
    if (filteredAudios.length !== uploadingAudios) {
      setUploadingAudios([...filteredAudios])
    }
  }, [uploadingMedias])

  useEffect(() => {
    const filteredAudios = newMedias.filter(({ type }) => type === AUDIO)
    if (filteredAudios.length !== 0) {
      setAudios([...filteredAudios, ...audios])
    }
  }, [newMedias])

  const handleMouseOver = ({ currentTarget, defaultXPosition, ...audio }) => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.handleShowTooltip({
        contentComp: <MediaPreviewComponent {...audio} />,
        wrapperComp: currentTarget,
        defaultXPosition,
      })
    }
  }

  const debouncedMouseOver = debounce(handleMouseOver, 200)

  const handleFilterMediaBySearch = debounce(() => {
    if (page === 0) {
      handleGetAudios({ page })
      getFolderMedias({})
    } else {
      setIsFinalPage(false)
      setPage(0)
    }
  }, 300)

  const handleClickCloseModal = () => {
    if (!isSettingAudio) {
      debouncedMouseOver.cancel()
      tooltipRef.current.handleHideTooltip()
      handleDismiss()
    } else {
      Alert.error('Please wait a bit while your video is processing.', { timeout: 5000 })
    }
  }

  const handleClickCloseSoundOptionsModal = () => {
    setIsSoundOptionsModalOpen(false)
  }

  const handleSaveUpdatedMedia = ({ id, url, audio_gid, updated_at_time }) => {
    const foundAudio = audios.find(({ id }) => id === audio_gid)

    handleUpdateMedia({ id, url, audio_gid, audio: foundAudio, updated_at_time })
  }

  const handleClickClosePrompt = () => {
    Cookies.set(COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED, true, { expires: COOKIE_EXPIRATION_DAYS })
    setShowPrompt(false)
  }

  const handleApplyAudio = async ({ volume, audioId, silent = false }) => {
    let hasError = false
    try {
      setIsSettingAudio(true)

      const updatedAudioId = audioId || selectedAudioId

      const response = await request({
        method: 'PATCH',
        path: `${ROUTE_MEDIA}/${id}/video-audio-track/${updatedAudioId}?volume=${volume || ''}`,
      })
      setIsSettingAudio(false)
      const { error, url, updated_at_time } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        hasError = true
      } else if (!silent) {
        Alert.success(
          id === selectedAudioId
            ? `Video has been reverted to its original sound.`
            : `This sound has been applied to this video.`,
          { timeout: 5000 }
        )
        video.url = url
        video.playing = true
        setVideo({ ...video })
        handleSaveUpdatedMedia({ id, url, audio_gid: updatedAudioId, updated_at_time })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: VideoPickSoundModal.displayName,
        functionName: 'handleGetAudios',
        errorMessage: `Can't apply sound to the video. Please contact support.`,
      })

      hasError = true
      setIsSettingAudio(false)
    }
    return hasError
  }

  const handleClickSaveAudio = async () => {
    if (!selectedAudioId) {
      Alert.error(`Please select a sound for the video.`, { timeout: 5000 })
    } else if (type === VIDEO_DYNAMIC && !audio_gid) {
      handleSaveUpdatedMedia({ audio_gid: selectedAudioId })
    } else {
      setIsSoundOptionsModalOpen(true)
    }
  }

  const handleClickFolderNavigation = ({ folder, type }) => {
    let folder_gid = null

    if (type === 'forward') {
      folder_gid = folder.media_gid
    } else if (type === 'back' && folder.parent) {
      folder_gid = folder.parent.media_gid
    }

    setFolder(null)
    setFolderId(folder_gid)
  }

  let blockedAddMediaOptions = {}
  let blockedAddMediaDriversOptions = {}

  if (featuresEnabled[FEATURE_FIND_CONTENT] && !featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
    blockedAddMediaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
  }
  if (!featuresEnabled[FEATURE_DRIVES].enabled) {
    blockedAddMediaDriversOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Flex m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogBodyWrapper flex="1">
            <Flex
              width="460px"
              bg="background_item_light"
              flexDirection="column"
              borderRadius={`${radius.l} 0 0 ${radius.l}`}
            >
              <Flex flexDirection="column" width="100%" height={`calc(100% - ${audioUrl ? 52 : 0}px)`}>
                {type === VIDEO && (
                  <VideoWithFallback
                    url={url}
                    controls
                    width="100%"
                    style={{ position: 'relative', borderRadius: `${radius.l} 0 0 ${radius.l}` }}
                    height="100%"
                    fallbackSourceImage={thumbnail_url}
                    config={{
                      file: {
                        attributes: {
                          poster: thumbnail_url || '',
                        },
                      },
                    }}
                    light={false}
                    playing={video.playing}
                  />
                )}

                {type === VIDEO_DYNAMIC && <DynamicMedia media={video} />}
              </Flex>
              {audioUrl && (
                <Flex height="52px" width="100%" flexDirection="row" p="s" alignItems="center">
                  <CheckBoxWrapper pl="s">
                    <SelectWrapper
                      alignItems="center"
                      justifyContent="center"
                      selected={selectedAudioId === id}
                      onClick={() => {
                        if (selectedAudioId === id) {
                          setSelectedAudioId(null)
                        } else {
                          setSelectedAudioId(id)
                        }
                      }}
                    >
                      <StyledIconCheckmark selected={selectedAudioId === id} width="10px" height="10px" />
                    </SelectWrapper>
                  </CheckBoxWrapper>
                  <AudioPlayer
                    playlist={[
                      {
                        id: `trending-audio-${id}`,
                        source: audioUrl,
                        trackName: 'Original video sound',
                        trackArtist: '',
                        trackImage: '',
                        loop: false,
                      },
                    ]}
                    showCover={false}
                  />
                </Flex>
              )}
            </Flex>
            <Flex width="calc(100% - 460px)" flexDirection="column">
              <Flex flexDirection="column" width="100%" bg="background">
                <Flex alignItems="center" justifyContent="space-between" px="s" flexWrap="wrap">
                  <Flex justifyContent="center" my="s">
                    {TABS.map((item) => {
                      const { name, type: tabType } = item
                      const { type: activeTabType } = activeTab || {}

                      const isActive = activeTabType && tabType === activeTabType

                      return (
                        <Tab
                          key={name}
                          onClick={() => {
                            if (!isActive) {
                              if (!isSettingAudio) {
                                setActiveTab(item)
                              } else {
                                Alert.error(`Please wait a bit while we apply the sound to the video.`, {
                                  timeout: 5000,
                                })
                              }
                            }
                          }}
                          isActive={isActive}
                        >
                          {tabType === AUDIO_MEDIA_LIBRARY ? (
                            <Fragment>
                              <Flex alignItems="center">
                                {!folderId && (
                                  <Text color={isActive ? 'primary' : 'secondary'} fontSize="m">
                                    Media library
                                  </Text>
                                )}

                                {folder && (
                                  <Flex>
                                    {folder.media_path.length > 1 && (
                                      <Fragment>
                                        <StyledFolderBackNavigationText
                                          color="primary"
                                          fontSize="m"
                                          onClick={() => {
                                            handleClickFolderNavigation({ type: 'back', folder: {} })
                                          }}
                                        >
                                          Media library
                                        </StyledFolderBackNavigationText>
                                        <Text color="text_slash_divider_color" fontSize="m">
                                          &nbsp;/&nbsp;
                                        </Text>
                                        {folder.media_path.length > 2 && (
                                          <Fragment>
                                            <Text color="primary" fontSize="m">
                                              ...
                                            </Text>
                                            <Text color="text_slash_divider_color" fontSize="m">
                                              &nbsp;/&nbsp;
                                            </Text>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    )}

                                    <StyledFolderBackNavigationText
                                      color="primary"
                                      fontSize="m"
                                      onClick={() => {
                                        handleClickFolderNavigation({ type: 'back', folder })
                                      }}
                                    >
                                      {folder?.parent?.title ? '...' : 'Media library'}
                                    </StyledFolderBackNavigationText>
                                    <Text color="text_slash_divider_color" fontSize="m">
                                      &nbsp;/&nbsp;
                                    </Text>
                                    <Text fontWeight="bold" fontSize="m">
                                      {folder.title}
                                    </Text>
                                  </Flex>
                                )}
                              </Flex>
                            </Fragment>
                          ) : (
                            <Text color={isActive ? 'primary' : 'secondary'} fontSize="m">
                              {name}
                            </Text>
                          )}
                        </Tab>
                      )
                    })}
                  </Flex>

                  <Flex alignItems="center" my="s">
                    <SearchInputComponent handleFilterMediaBySearch={handleFilterMediaBySearch} ref={inputSearchText} />

                    <Flex
                      justifyContent="center"
                      flexDirection="column"
                      width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
                      mx="s"
                    >
                      <MediaFilters
                        ref={mediaFiltersRef}
                        handleFetchMedia={({ filters }) => {
                          if (page === 0) {
                            handleGetAudios({ selectedFilters: { filters }, page })
                          } else {
                            setIsFinalPage(false)
                            setPage(0)
                          }

                          getFolderMedias({ selectedFilters: { filters } })
                        }}
                        labels={labels}
                        extensions={{ [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] }}
                        users={[]}
                        types={[]}
                        usage={[]}
                        showMobileIcon={{ mobile: 'flex', desktop: 'none' }}
                        removeMobileIcon={{ mobile: 'none', desktop: 'flex' }}
                      />
                    </Flex>
                  </Flex>
                </Flex>

                {activeTab.prompt && showPrompt && (
                  <StyledPromptWrapper mx="m" alignItems="center" py="xs" px="s" mb="xs" justifyContent="space-between">
                    <StyledText color="primary" dangerouslySetInnerHTML={{ __html: activeTab.prompt }} />
                    <StyledClosePromptWrapper onClick={handleClickClosePrompt} width="10px" ml="s">
                      <Icon.Dismiss fill="primary" />
                    </StyledClosePromptWrapper>
                  </StyledPromptWrapper>
                )}
              </Flex>

              <Flex width="100%" height="100%" position="relative">
                <StyledProgressWrapper
                  display={isGettingAudio ? 'flex' : 'none'}
                  alignItems="center"
                  justifyContent="center"
                  className="cliploader-wrapper"
                >
                  <ClipLoader size="100" />
                </StyledProgressWrapper>
                <Fragment>
                  {audios.length === 0 &&
                  folderMedias.length === 0 &&
                  (activeTab.type === AUDIO_TRENDING ||
                    (activeTab.type === AUDIO_MEDIA_LIBRARY && uploadingAudios.length === 0)) ? (
                    <Flex height="100%" alignItems="center" justifyContent="center" width="100%" px="m" bg="background">
                      <NoData
                        showHeaderText={false}
                        message={isGettingAudio ? '' : `No sounds found`}
                        image="/assets/noResultsIllustration.svg"
                      />
                    </Flex>
                  ) : (
                    <Flex width="100%" height="100%" id="media-section-wrapper">
                      <Scrollbars
                        ref={scrollbarsRef}
                        universal
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                        onScrollFrame={({ top }) => {
                          if (top >= 0.99) {
                            if (!isGettingAudio) {
                              setPage((page) => {
                                return page + 1
                              })
                            }
                          }
                        }}
                      >
                        {folderMedias.length !== 0 && (
                          <Flex flexDirection="column" mb="m" width="100%" px="s">
                            <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                              <Grid
                                gridTemplateColumns="repeat(4, 1fr)"
                                width="100%"
                                gridGap="m"
                                justifyItems="center"
                                mt="m"
                              >
                                {folderMedias.map((media) => {
                                  const { id, title, description } = media

                                  const mediaDescription = truncate(title || description, 25)

                                  return (
                                    <MediaFolderWrapper key={id} flexDirection="column">
                                      <StyledFolderWrapper
                                        bg="background_card"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          handleClickFolderNavigation({ type: 'forward', folder: media })
                                        }}
                                      >
                                        <Flex alignItems="center">
                                          <Icon.Folder width="20px" height="20px" stroke="icon_color_gray" />

                                          <Flex ml="s">
                                            <Text color="secondaryText" fontSize="xxs" textAlign="center">
                                              {mediaDescription}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </StyledFolderWrapper>
                                    </MediaFolderWrapper>
                                  )
                                })}
                              </Grid>
                            </MediaSectionWrapper>
                          </Flex>
                        )}

                        <Flex flexDirection="column" mb="m" width="100%" px="s">
                          <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                            <Grid
                              px="m"
                              py="xs"
                              gridTemplateColumns={{
                                mobile: 'repeat(1, 1fr)',
                                desktop: 'repeat(3, 1fr)',
                                desktopWide: 'repeat(4, 1fr)',
                              }}
                              width="100%"
                              gridGap="m"
                              justifyItems="center"
                            >
                              {activeTab.type === AUDIO_MEDIA_LIBRARY &&
                                uploadingAudios.map(({ id, media_gid }, index) => (
                                  <StyledWrapper
                                    key={id || media_gid || index}
                                    alignItems="center"
                                    justifyContent="center"
                                    className="cliploader-wrapper"
                                  >
                                    <ClipLoader size="50" />
                                  </StyledWrapper>
                                ))}
                              {audios.map((audio, index) => {
                                const {
                                  id,
                                  url,
                                  thumbnail_url,
                                  thumbnail_url_small,
                                  title,
                                  filename,
                                  audio_cover_mime,
                                  duration,
                                  metaInformation,
                                } = audio
                                const isChecked = selectedAudioId === id
                                const column = Math.floor((index - Math.floor(index / 4) * 4) / 2)
                                return (
                                  <StyledWrapper selected={isChecked} flexDirection="column" key={id}>
                                    <Flex width="100%" height="100%">
                                      <Flex
                                        flexDirection="column"
                                        width="100%"
                                        height="100%"
                                        onMouseEnter={(e) => {
                                          if (url) {
                                            debouncedMouseOver({
                                              type: AUDIO,
                                              ...audio,
                                              currentTarget: e.currentTarget,
                                              defaultXPosition: column === 0 ? 'right' : 'left',
                                            })
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          debouncedMouseOver.cancel()
                                          if (url) {
                                            tooltipRef.current.handleHideTooltip()
                                          }
                                        }}
                                      >
                                        <StyledMediaWrapper selected={isChecked}>
                                          <AudioPlayer
                                            playlist={[
                                              {
                                                id: `trending-audio-${id}`,
                                                source: url,
                                                trackName: filename,
                                                trackArtist: title,
                                                trackImage: audio_cover_mime
                                                  ? thumbnail_url_small || thumbnail_url
                                                  : '',
                                                loop: false,
                                                duration,
                                              },
                                            ]}
                                            isSmall={false}
                                          />
                                        </StyledMediaWrapper>
                                        <StyledTitleWrapper>
                                          <StyledText color="secondaryText" fontSize="xxs" px="xs">
                                            {title}
                                          </StyledText>
                                        </StyledTitleWrapper>
                                        {metaInformation && (
                                          <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                            {metaInformation}
                                          </StyledText>
                                        )}
                                      </Flex>
                                    </Flex>

                                    <Box
                                      position="absolute"
                                      top={space.xs}
                                      left={space.xs}
                                      zIndex="2"
                                      onClick={(event) => {
                                        event.stopPropagation()
                                        if (isChecked) {
                                          setSelectedAudioId(null)
                                        } else {
                                          setSelectedAudioId(id)
                                        }
                                      }}
                                    >
                                      <SelectWrapper alignItems="center" justifyContent="center" selected={isChecked}>
                                        <StyledIconCheckmark selected={isChecked} width="10px" height="10px" />
                                      </SelectWrapper>
                                    </Box>
                                  </StyledWrapper>
                                )
                              })}
                            </Grid>
                          </MediaSectionWrapper>
                        </Flex>
                      </Scrollbars>
                    </Flex>
                  )}
                </Fragment>
              </Flex>

              <Flex flexDirection="column" px="m">
                <Divider />
                <Flex justifyContent="space-between" alignItems="center" height="52px">
                  {showUploadAudio ? (
                    <DropdownMenu
                      zIndex={2}
                      WrapperComp={
                        <Button.Gray isSmall>
                          <Flex alignItems="center">
                            <Icon.VistaSocialPlus width="20px" height="20px" fill={COLOR_CONSTANTS.SALUTE} />
                            <Flex alignItems="center">
                              <Text ml="xs">Upload sound</Text>
                            </Flex>
                          </Flex>
                        </Button.Gray>
                      }
                      isDismissedOnClickInside
                      isTriangleVisible={false}
                      top={-163}
                      left={0}
                    >
                      {handleClickOpenMediaUploadByFileModal && (
                        <DropdownMenu.Item
                          label="Upload from computer"
                          onClick={handleClickOpenMediaUploadByFileModal}
                          iconName="Upload"
                          iconWidth="14px"
                          iconColor="icon_color_gray"
                          isCursorPointer
                        />
                      )}
                      {handleClickOpenMediaUploadByLinkModal && (
                        <DropdownMenu.Item
                          label="Upload from URL"
                          onClick={handleClickOpenMediaUploadByLinkModal}
                          iconName="Link"
                          iconWidth="14px"
                          iconColor="icon_color_gray"
                          isCursorPointer
                        />
                      )}

                      {(handleClickGoogleDrive || handleClickDropbox || handleClickOneDrive) && (
                        <Fragment>
                          <Divider height="1px" />
                          {handleClickGoogleDrive && (
                            <DropdownMenu.Item
                              label="Google drive"
                              iconName="GoogleDrive"
                              iconWidth="14px"
                              iconColor="icon_color_gray"
                              onClick={handleClickGoogleDrive}
                              isCursorPointer
                              {...blockedAddMediaDriversOptions}
                            />
                          )}

                          {handleClickDropbox && (
                            <DropdownMenu.Item
                              label="Dropbox"
                              iconName="Dropbox"
                              iconWidth="14px"
                              onClick={handleClickDropbox}
                              isCursorPointer
                              {...blockedAddMediaDriversOptions}
                            />
                          )}

                          {handleClickOneDrive && (
                            <DropdownMenu.Item
                              label="OneDrive"
                              iconName="OneDrive"
                              iconWidth="18px"
                              onClick={handleClickOneDrive}
                              isCursorPointer
                              {...blockedAddMediaDriversOptions}
                            />
                          )}
                        </Fragment>
                      )}
                    </DropdownMenu>
                  ) : (
                    <Button.Gray isSmall onClick={handleClickCloseModal}>
                      Back to video
                    </Button.Gray>
                  )}
                  <Flex alignItems="center">
                    {handleClickSendToCompose && (
                      <Button.Gray
                        mx="s"
                        isSmall
                        onClick={() => {
                          if (!isSettingAudio) {
                            handleClickSendToCompose({
                              post: {
                                isFromFindContentModal: true,
                                postVideos: [selectedVideo],
                                postComponent: POST_VIDEO,
                              },
                            })
                          } else {
                            Alert.error(`Please wait a bit while your video is updating.`, { timeout: 5000 })
                          }
                        }}
                      >
                        <Flex alignItems="center">
                          <Icon.Send /> <Text ml="s">Create a post</Text>
                        </Flex>
                      </Button.Gray>
                    )}
                    <ButtonWithLoading
                      isSmall
                      onClick={handleClickSaveAudio}
                      isLoading={isSettingAudio}
                      width="162px"
                      id="apply-trending-sound"
                    >
                      <Flex alignItems="center">
                        <Text>{isSettingAudio ? 'Applying sound' : 'Apply sound'}</Text>
                      </Flex>
                    </ButtonWithLoading>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </StyledDialogBodyWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
        <CalendarItemTooltip ref={tooltipRef} />
        {isSoundOptionsModalOpen && (
          <VideoApplySoundModal
            handleDismiss={handleClickCloseSoundOptionsModal}
            handleApplyAudio={handleApplyAudio}
            isSettingAudio={isSettingAudio}
            isOpen={isSoundOptionsModalOpen}
            selectedVideo={selectedVideo}
          />
        )}
      </Flex>
    </StyledDialogOverlay>
  )
}

VideoPickSoundModal.defaultProps = {
  featuresEnabled: {},
  handleClickOpenMediaUploadByLinkModal: null,
  handleClickOpenMediaUploadByFileModal: null,
  uploadingMedias: [],
  newMedias: [],
  handleClickSendToCompose: null,
  handleClickGoogleDrive: null,
  handleClickDropbox: null,
  handleClickOneDrive: null,
}

VideoPickSoundModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedVideo: PropTypes.object.isRequired,
  handleUpdateMedia: PropTypes.func.isRequired,
  featuresEnabled: PropTypes.object,
  handleClickOpenMediaUploadByLinkModal: PropTypes.func,
  handleClickOpenMediaUploadByFileModal: PropTypes.func,
  uploadingMedias: PropTypes.array,
  newMedias: PropTypes.array,
  handleClickSendToCompose: PropTypes.func,
  handleClickGoogleDrive: PropTypes.func,
  handleClickDropbox: PropTypes.func,
  handleClickOneDrive: PropTypes.func,
}

VideoPickSoundModal.displayName = 'VideoPickSoundModal'

export default VideoPickSoundModal
