import React from 'react'
import { radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

const NoPost = () => {
  return (
    <Flex flexDirection="column" alignItems="center" height="100%">
      <Flex alignItems="center" width="100%">
        <Flex minWidth="2.5em" width="2.5em" height="2.5em" bg="background_item_light" borderRadius={radius.pill} />
        <Flex ml="1em" flexDirection="column" justifyContent="center" width="100%" height="2.5em">
          <Flex height="0.7em" width="50%" bg="background_item_light" borderRadius={radius.l} />
          <Flex mt="0.5em" height="0.5em" width="40%" bg="background_item_light" borderRadius={radius.l} />
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        mt="1em"
        alignItems="center"
        justifyContent="center"
        p="1em"
        width="100%"
        height="100%"
      >
        <Image src="/assets/emptyData.svg" maxWidth="15.625em" width="100%" />
        <Text mt="0.5em" color="secondaryText" fontSize="xs" textAlign="center">
          Create a post on the left to preview it on each network
        </Text>
      </Flex>
    </Flex>
  )
}

export default NoPost
