import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import { ERROR_MESSAGE, SNIPPET_TYPE_TAG } from 'consts'
import { radius, space } from 'theme'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import { ROUTE_SNIPPETS } from 'routes/Calendar/consts'
// eslint-disable-next-line import/no-cycle
import { getSelectedProfileGroupsAndTimezone } from 'routes/Calendar/helpers'
import HashTagModal from './HashTagModal'
import CalendarItemTooltip from '../CalendarItemTooltip'

const Wrapper = styled(Flex)`
  height: 260px;
  overflow: auto;
`

const StyledButton = styled(Button.Gray)`
  min-width: 30px;
  padding: 0;
  width: 30px;
  height: 30px;
`

const StyledText = styled(Text)`
  height: 100%;
  width: 100%;
  line-height: 30px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: ${radius.l};
  &:hover {
    background-color: ${({ theme }) => theme.colors.background_item_hover};
  }
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const ProgressLine = styled(Flex)`
  border-radius: ${radius.xl};
`

const ProgressLineBg = styled(ProgressLine)`
  opacity: 0.2;
`

const { TAB_SAVED, TAB_SEARCH } = {
  TAB_SAVED: 'tab_saved',
  TAB_SEARCH: 'tab_search',
}

const TABS = [
  { name: 'Saved hashtags', type: TAB_SAVED },
  { name: 'Hashtag suggestions', type: TAB_SEARCH },
]

const SearchInputComponent = ({ handleFilterMentionsBySearch }) => {
  const [searchString, setSearchString] = useState('')

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMentionsBySearch(text)
  }

  return (
    <Input
      placeholder="Search for hashtags"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      mr="m"
      height="30px"
    />
  )
}

SearchInputComponent.propTypes = {
  handleFilterMentionsBySearch: PropTypes.func.isRequired,
}

const HashTagSelector = forwardRef(
  ({ handleClickAddHashTagToPost, user, selectedEntities, selectedProfiles }, hashTagSelectorRef) => {
    const tooltipRef = useRef(null)
    const searchInputRef = useRef(null)

    const [activeTab, setActiveTab] = useState(TABS[0])
    const [isGettingHashTags, setIsGettingHashTags] = useState(true)
    const [savedHashTags, setSavedHashTags] = useState([])
    const [isHashTagModalOpen, setIsHashTagModalOpen] = useState(false)
    const [hashTagForEdit, setHashTagForEdit] = useState({})
    const [isHashTagSaving, setIsHashTagSaving] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)
    const [searchedHashTags, setSearchedHashTags] = useState([])
    const [hashtagSearchString, setSavedHashTagSearchString] = useState('')
    const [searchString, setSearchString] = useState('')
    const [filteredHashTags, setFilteredHashTags] = useState([])

    useImperativeHandle(hashTagSelectorRef, () => ({
      getHashTagModalState() {
        return isHashTagModalOpen
      },
    }))

    const getSavedHashTags = async () => {
      try {
        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
          selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
          selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
        })

        const response = await request({
          path: `${ROUTE_SNIPPETS}?type=${SNIPPET_TYPE_TAG}&entity_gids=${selectedEntitiesWithSelectedProfiles
            .map(({ id }) => id)
            .join(',')}`,
        })
        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const { snippets } = response
          snippets.forEach((snippet) => {
            snippet.message = snippet.messages ? snippet.messages[0] : ''
          })
          setSavedHashTags([...snippets])
        }
      } catch (error) {
        errorHelper({ error, componentName: HashTagSelector.displayName, functionName: 'getSavedHashTags' })
      } finally {
        setIsGettingHashTags(false)
      }
    }

    useEffect(() => {
      getSavedHashTags()
    }, [])

    const filterHashtagsBySearchString = () => {
      if (searchString) {
        setFilteredHashTags(
          savedHashTags.filter(({ title, message }) =>
            `${title} ${message}`.toLowerCase().includes(searchString.toLowerCase())
          )
        )
      } else {
        setFilteredHashTags([...savedHashTags])
      }
    }

    useEffect(() => {
      filterHashtagsBySearchString()
    }, [searchString])

    useEffect(() => {
      if (activeTab.type === TAB_SAVED) {
        filterHashtagsBySearchString()
      }
    }, [savedHashTags])

    const handleOpenHashTagModal = (item) => {
      setHashTagForEdit({ ...item })
      setIsHashTagModalOpen(true)
    }

    const handleCloseHashTagModal = () => {
      setHashTagForEdit({})
      setIsHashTagModalOpen(false)
    }

    const getSearchedHashTags = async () => {
      try {
        setIsGettingHashTags(true)

        const searchValue = searchInputRef.current.value
        setSavedHashTagSearchString(searchValue)

        const response = await request({
          path: `instagram/hashtag/suggestions?q=${searchValue}`,
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const { data } = response
          setSearchedHashTags([...data])
        }
      } catch (error) {
        errorHelper({ error, componentName: HashTagSelector.displayName, functionName: 'getSearchedHashTags' })
      } finally {
        setIsGettingHashTags(false)
      }
    }

    const handleSaveHashTag = async ({ id, title, message }) => {
      try {
        setIsHashTagSaving(true)

        const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
          user,
          selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
          selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
        })

        const data = {
          title,
          messages: [message],
          entity_gids: selectedEntitiesWithSelectedProfiles.map(({ id }) => id),
          type: SNIPPET_TYPE_TAG,
        }

        let response

        if (id) {
          response = await request({
            method: 'PUT',
            body: data,
            path: `${ROUTE_SNIPPETS}/${id}`,
          })
        } else {
          response = await request({
            method: 'POST',
            body: data,
            path: ROUTE_SNIPPETS,
          })
        }

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const body = { id, title, message, messages: [message] }
          if (id) {
            for (let i = 0; i < savedHashTags.length; i++) {
              if (savedHashTags[i].id === id) {
                savedHashTags[i] = body
                break
              }
            }
          } else {
            body.id = response.id
            savedHashTags.push(body)
          }
          setSavedHashTags([...savedHashTags])
          handleCloseHashTagModal()
        }
      } catch (error) {
        errorHelper({ error, componentName: HashTagSelector.displayName, functionName: 'handleSaveHashTag' })
      } finally {
        setIsHashTagSaving(false)
      }
    }

    const handleRemoveHashtag = async ({ id }) => {
      try {
        setIsRemoving(true)
        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_SNIPPETS}/${id}`,
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const foundIndex = savedHashTags.findIndex((item) => item.id === id)
          if (foundIndex > -1) {
            savedHashTags.splice(foundIndex, 1)
            setSavedHashTags([...savedHashTags])
          }
          handleCloseHashTagModal()
          Alert.success(`Hashtag group has been removed.`, { timeout: 5000 })
        }
      } catch (error) {
        errorHelper({ error, componentName: HashTagSelector.displayName, functionName: 'handleRemoveHashtag' })
      } finally {
        setIsRemoving(false)
      }
    }

    const handleFilterMentionsBySearch = debounce((text) => {
      setSearchString(text)
    }, 300)

    return (
      <Wrapper flexDirection="column" width="100%" py="s">
        <Flex justifyContent="space-between" pr="m" alignItems="center" mb="xs">
          <Flex justifyContent="center" height="30px" alignItems="center">
            {TABS.map((item) => {
              const { name, type } = item
              const { type: activeTabType } = activeTab || {}
              return (
                <Tab
                  key={name}
                  onClick={() => {
                    setActiveTab(item)
                  }}
                  isActive={activeTabType && type === activeTabType}
                >
                  <Text color={activeTabType && type === activeTabType ? 'primary' : 'secondaryText'} fontSize="s">
                    {name}
                  </Text>
                </Tab>
              )
            })}
          </Flex>
          {activeTab.type === TAB_SAVED && (
            <StyledButton isSmall onClick={() => handleOpenHashTagModal({})}>
              <Icon.Plus width="13px" />
            </StyledButton>
          )}
        </Flex>
        {isGettingHashTags ? (
          <Flex alignItems="center" justifyContent="center" height="100%" width="100%" className="cliploader-wrapper">
            <ClipLoader size="50" />
          </Flex>
        ) : (
          <Flex flexDirection="column" height="100%">
            <Scrollbars universal>
              {activeTab.type === TAB_SAVED && (
                <Flex flexDirection="column" py="xs">
                  <SearchInputComponent handleFilterMentionsBySearch={handleFilterMentionsBySearch} />
                  {filteredHashTags.map((item) => (
                    <Flex mt="s" justifyContent="space-between" alignItems="center" width="100%" pr="m" key={item.id}>
                      <Flex alignItems="center" width="calc(100% - 30px)">
                        <Flex alignItems="center" width="100%">
                          <StyledText
                            pr="s"
                            onClick={() => {
                              handleClickAddHashTagToPost({ message: item.message })
                            }}
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: (
                                  <Text px="s" color="secondaryText">
                                    {item.message}
                                  </Text>
                                ),
                                defaultXPosition: 'right',
                                wrapperComp: e.currentTarget,
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            <Text as="span" fontWeight="bold">
                              {item.title}
                            </Text>
                            <Text as="span" ml="xs" color="secondaryText">
                              {item.message}
                            </Text>
                          </StyledText>
                        </Flex>
                      </Flex>

                      <StyledButton isSmall onClick={() => handleOpenHashTagModal(item)}>
                        <Icon.Edit />
                      </StyledButton>
                    </Flex>
                  ))}
                </Flex>
              )}
              {activeTab.type === TAB_SEARCH && (
                <Flex flexDirection="column" py="xs">
                  <Flex alignItems="center" width="100%" justifyContent="space-between" pr="m">
                    <Flex flexDirection="column">
                      <Input
                        innerRef={searchInputRef}
                        label=""
                        placeholder="Search for hashtags"
                        defaultValue={hashtagSearchString}
                        height="30px"
                        width="250px"
                        onKeyUp={(e) => {
                          if (searchInputRef && searchInputRef.current && searchInputRef.current.value) {
                            if (e.keyCode === 13 && !isGettingHashTags) {
                              getSearchedHashTags()
                            }
                          }
                        }}
                      />
                    </Flex>

                    <Button.Gradient
                      isSmall
                      onClick={() => {
                        if (!isGettingHashTags && searchInputRef.current && searchInputRef.current.value) {
                          getSearchedHashTags()
                        }
                      }}
                    >
                      Suggest
                    </Button.Gradient>
                  </Flex>
                  {searchedHashTags.map(({ tag, percentage }, index) => (
                    <Flex mt="s" key={index} alignItems="center">
                      <Flex alignItems="center" width="175px">
                        <Flex alignItems="center" width="100%">
                          <StyledText
                            color="secondaryText"
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: (
                                  <Text px="s" color="secondaryText">
                                    #{tag}
                                  </Text>
                                ),
                                wrapperComp: e.currentTarget,
                                defaultXPosition: 'right',
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                            onClick={() => {
                              handleClickAddHashTagToPost({ message: `#${tag}` })
                            }}
                          >
                            #{tag}
                          </StyledText>
                        </Flex>
                      </Flex>
                      <Flex ml="m" position="relative" width="150px" height="12px">
                        <ProgressLineBg width="100%" bg="progress_line_background" height="100%" position="absolute" />
                        <ProgressLine
                          width={`calc(${percentage}%)`}
                          bg="progress_line_color"
                          height="100%"
                          position="absolute"
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )}
            </Scrollbars>
          </Flex>
        )}

        {isHashTagModalOpen && (
          <HashTagModal
            handleDismiss={handleCloseHashTagModal}
            handleSave={handleSaveHashTag}
            isSubmitting={isHashTagSaving}
            isOpen={isHashTagModalOpen}
            hashTagForEdit={hashTagForEdit}
            handleRemove={handleRemoveHashtag}
            isRemoving={isRemoving}
          />
        )}
        <CalendarItemTooltip ref={tooltipRef} />
      </Wrapper>
    )
  }
)

HashTagSelector.defaultProps = {
  selectedEntities: null,
  selectedProfiles: null,
}

HashTagSelector.propTypes = {
  user: PropTypes.object.isRequired,
  handleClickAddHashTagToPost: PropTypes.func.isRequired,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
}

HashTagSelector.displayName = 'HashTagSelector'

export default HashTagSelector
