import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import { radius, COLOR_CONSTANTS, space, fontWeights, breakpoints } from 'theme'
import {
  DEFAULT_VIDEO_THUMBNAIL,
  SNAPCHAT,
  VIDEO,
  VIDEO_DYNAMIC,
  FEATURE_POST_ENGAGEMENT,
  FEATURE_POST_TARGETING,
  IMAGE,
  IMAGE_DYNAMIC,
} from 'consts'
import { linkTransformer } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Image from 'components/atoms/Image'
import Switch from 'components/atoms/Switch'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Counter from 'components/molecules/Counter'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  NETWORK_LIMITS,
  SNAPCHAT_COLORS,
  PREVIEW_MEDIA_CLASS_NAME,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  PUBLISH_AS_SPOTLIGHT,
  PUBLISH_AS_STORY,
  PUBLISH_AS_SAVED_STORY,
  POST_IMAGE,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import {
  transformTextVariantsToUnicodeVariant,
  getPublishAsDropDownValuesForSnapchat,
  variableTransformer,
  mentionsTransformer,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const StyledPreviewWrapper = styled(Flex)`
  flex-direction: column;
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  margin: 0 auto;
`

const StoryHeaderWrapper = styled(Flex)`
  width: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  padding: 1.5625em;
  text-shadow: 0 0.0625em 0.25em rgba(0, 0, 0, 0.6);
  margin: 0;
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
`

const StyledStoryHeaderProfileImageWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.14);
  margin-right: 0.5em;
  height: 2.5625em;
  width: 2.5625em;
  border: 0.125em solid ${SNAPCHAT_COLORS.MAIN};
  padding: 0.0625em;
  transition: 0.25s ease;
  align-items: center;
  justify-content: center;
`

const StyledStoryControlWrapper = styled(Flex)`
  height: 2.5em;
  width: 2.5em;
  background: rgba(0, 0, 0, 0.5);
  border-radius: ${radius.pill};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-out;
  -webkit-tap-highlight-color: transparent;
`

const StyledSpotlightWrapper = styled(Flex)`
  filter: drop-shadow(0 0.25em 1.25em rgba(0, 0, 0, 0.25));
`

const StyledSpotlightFooterWrapper = styled(Flex)`
  border-radius: 1em;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding-bottom: 1.25em;
  padding-left: 1.25em;
  width: 100%;
  z-index: 1;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledSpotlightSoundWrapper = styled(Flex)`
  margin-top: 0.25em;
  margin-right: 0.5em;
  background-color: rgba(18, 19, 20, 0.5);
  height: 2.5em;
  border-radius: 0.25em;
  align-items: center;
  width: fit-content;
`

const StyledSpotlightSoundProfileWrapper = styled(Flex)`
  flex-wrap: wrap;
  word-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0.375em 0.25em 0.375em;
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${fontWeights.medium};
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 4px;
  box-orient: vertical;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledHtmlCollapseText = styled(Text)`
  font-size: 0.875em;
  line-height: 1.125em;
  white-space: normal;
  word-break: break-word;
  display: inline;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const CaptionWrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  font-size: 0.9375em;
  font-weight: normal;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  display: -webkit-box;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === SNAPCHAT)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  spotlightDescriptionTextMaxLimit: MAX_SPOTLIGHT_DESCRIPTION_TEXT_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const PROCESSED_VIDEO_ENDING = '_snapchat'

const SnapchatPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [profile, setProfile] = useState({ ...props.profile })
  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)
  const [isDescriptionTextEditOpen, setIsDescriptionTextEditOpen] = useState(false)

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const {
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    customizations = {},
    postText,
    insights = {},
    postComponent,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias

  // const publish_as = ['STORY', 'SPOTLIGHT']

  let publish_as = null
  let save_to_profile = true
  let description = ''
  let locale = null
  let device_gid = null
  let publishing_option = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      publish_as = null,
      save_to_profile = true,
      description = '',
      locale = null,
      device_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  let MAX_PREVIEW_HEIGHT = smallPreview ? 461.95 : 850
  let MAX_PREVIEW_WIDTH = smallPreview ? 250 : 460

  if (publish_as === PUBLISH_AS_SPOTLIGHT) {
    MAX_PREVIEW_HEIGHT = smallPreview ? 415.29 : 660
    MAX_PREVIEW_WIDTH = smallPreview ? 250 : 397.1
  }

  const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForSnapchat({
    postComponent: customizedPostComponent,
    postMixedMedias: customizedPostMixedMedias,
  })

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationDeviceLengthLimit,
      spotlightDescriptionTextLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const { locales: formDataLocales = [] } = dataForCustomizations

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value

    onChangeUpdatePost(post)
  }

  const {
    transformed_text_to_html_for_textarea,
    transformed_spotlight_description_to_html_for_textarea,
    transformed_spotlight_description_to_html_for_preview,
    updatedPostTextWithMentions = '',
  } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: SNAPCHAT_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea =
      linkTransformer({
        body: updatedText,
        color: SNAPCHAT_COLORS.MAIN,
        transformHashstag: true,
        hashtagColor: SNAPCHAT_COLORS.MAIN,
      }) || ''

    const { variableTransformedText: variableTransformedDescription } = variableTransformer({
      str: description,
      savedVariables,
      post,
      profileId,
    })

    const transformed_spotlight_description_to_html_for_preview =
      linkTransformer({
        body: description,
        color: COLOR_CONSTANTS.WHITE,
        transformHashstag: true,
        hashtagColor: COLOR_CONSTANTS.WHITE,
      }) || ''

    const transformed_spotlight_description_to_html_for_textarea =
      linkTransformer({
        body: variableTransformedDescription,
        color: SNAPCHAT_COLORS.LAST_LIGHT_BLUE,
        transformHashstag: true,
        hashtagColor: SNAPCHAT_COLORS.LAST_LIGHT_BLUE,
      }) || ''

    return {
      transformed_text_to_html_for_textarea,
      transformed_spotlight_description_to_html_for_preview,
      transformed_spotlight_description_to_html_for_textarea,
      updatedPostTextWithMentions,
    }
  }, [updatedPostText, savedVariables, description, agencyMentions])

  let updatedPostMedia = null

  if (customizedPostComponent === POST_IMAGE) {
    if (customizedPostImages.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      updatedPostMedia = customizedPostImages[0]
    }
  } else if (customizedPostComponent === POST_VIDEO) {
    if (customizedPostVideos.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      updatedPostMedia = customizedPostVideos[0]
    }
  } else if (customizedPostComponent === POST_MIXED_MEDIA) {
    if (customizedPostMixedMedias.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      updatedPostMedia = customizedPostMixedMedias[0]
    }
  }

  const { url: mediaUrl, thumbnail_url, isNew, id: mediaId, type, imageURL } = updatedPostMedia || {}

  let thumbnailUrl = ''
  if (typeof isNew === 'undefined' && (thumbnail_url || imageURL)) {
    thumbnailUrl = thumbnail_url || imageURL
  }
  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  const mediaTemplate = updatedPostMedia && (
    <Fragment>
      {noVideoAttachment ? (
        <Flex justifyContent="center" alignItems="center" position="relative" width="100%" height="100%">
          {type === IMAGE && (
            <ImageWithFallback
              id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${mediaId || ''}`}
              source={thumbnailUrl || '/assets/landscape.svg'}
              width="100%"
              fallbackSource={mediaUrl || '/assets/landscape.svg'}
              borderRadius={
                publish_as === PUBLISH_AS_STORY || publish_as === PUBLISH_AS_SAVED_STORY ? '0.625em' : '1em'
              }
              objectFit="cover"
            />
          )}
          {type === VIDEO && (
            <Fragment>
              <Box position="absolute" top={space.xs} right={space.xs}>
                <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
              </Box>
              <StyledVideoThumbnailImage
                height="100%"
                width="100%"
                source={thumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                name={mediaId}
                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${mediaId}`}
                borderRadius={
                  publish_as === PUBLISH_AS_STORY || publish_as === PUBLISH_AS_SAVED_STORY ? '0.625em' : '1em'
                }
              />
            </Fragment>
          )}
          {type === VIDEO_DYNAMIC && (
            <DynamicMedia
              media={customizedPostVideos[0]}
              post={post}
              profiles={[profile]}
              verticalPreview
              isSmall={smallPreview}
            />
          )}
        </Flex>
      ) : (
        <StyledBox position="relative" width="100%" height="100%" justifyContent="center" hasError={postVideoError}>
          {isNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              {type === IMAGE && (
                <ImageWithFallback
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${mediaId}`}
                  source={thumbnailUrl || '/assets/landscape.svg'}
                  width="100%"
                  fallbackSource={mediaUrl || '/assets/landscape.svg'}
                  borderRadius={
                    publish_as === PUBLISH_AS_STORY || publish_as === PUBLISH_AS_SAVED_STORY ? '0.625em' : '1em'
                  }
                  objectFit="cover"
                />
              )}
              {type === VIDEO && (
                <VideoWithFallback
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${mediaId}`}
                  url={mediaUrl}
                  controls={false}
                  loop
                  width="100%"
                  style={{
                    position: 'relative',
                    borderRadius:
                      publish_as === PUBLISH_AS_STORY || publish_as === PUBLISH_AS_SAVED_STORY ? '0.625em' : '1em',
                  }}
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        poster: thumbnailUrl,
                      },
                    },
                  }}
                  fallbackSourceImage={thumbnailUrl}
                  name={mediaId}
                  processedVideoEnding={PROCESSED_VIDEO_ENDING}
                />
              )}
              {(type === VIDEO_DYNAMIC || type === IMAGE_DYNAMIC) && (
                <DynamicMedia
                  media={updatedPostMedia || {}}
                  post={post}
                  profiles={[profile]}
                  verticalPreview
                  isSmall={smallPreview}
                />
              )}
            </Fragment>
          )}
        </StyledBox>
      )}
    </Fragment>
  )

  let previewComponent = null

  switch (publish_as) {
    case PUBLISH_AS_SPOTLIGHT:
      previewComponent = (
        <StyledSpotlightWrapper
          height={`${MAX_PREVIEW_HEIGHT}px`}
          width={`${MAX_PREVIEW_WIDTH}px`}
          mx="auto"
          bg={COLOR_CONSTANTS.BLACK}
          borderRadius="1em"
          position="relative"
        >
          {mediaTemplate}

          <StyledSpotlightFooterWrapper maxWidth={smallPreview ? '165px' : '300px'}>
            <Flex alignItems="center" mt="0.3125em" mb="0.1875em">
              <ImageWithFallback
                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage}
                fallbackSource={fallbackLogoImage}
                borderRadius={radius.pill}
                width="1.5em"
                height="1.5em"
              />
              <Excerpt ml="0.5em" fontWeight="extraBold" fontSize="1.125em" color="white">
                @{isMultipleProfilesPreview ? 'Multiple Snapchat Profiles' : profileUsername || profileName}
              </Excerpt>
            </Flex>

            {transformed_spotlight_description_to_html_for_preview && (
              <StyledHtmlCollapseText
                color="white"
                dangerouslySetInnerHTML={{
                  __html: transformed_spotlight_description_to_html_for_preview,
                }}
              />
            )}
            <StyledSpotlightSoundWrapper>
              <Flex width="1.125em" height="1.125em" borderRadius="0.1875em" my="0.6875em" mx="0.5em">
                <Image src="/assets/snapchat_spotlight_music.webp" />
              </Flex>
              <Flex flexDirection="column">
                <StyledSpotlightSoundProfileWrapper maxWidth={smallPreview ? '50px' : '90px'}>
                  @{isMultipleProfilesPreview ? 'Multiple Snapchat Profiles' : profileUsername || 'Original'} Sound
                </StyledSpotlightSoundProfileWrapper>
              </Flex>
            </StyledSpotlightSoundWrapper>
          </StyledSpotlightFooterWrapper>

          <Flex position="absolute" right="1.25em" bottom="1.25em" flexDirection="column">
            <StyledStoryControlWrapper>
              <Image src="/assets/snapchat_spotlight_link.svg" width="1.5em" height="1.5em" />
            </StyledStoryControlWrapper>
            <StyledStoryControlWrapper mb="0.5em">
              <Image src="/assets/snapchat_spotlight_more.svg" width="1.5em" height="1.5em" />
            </StyledStoryControlWrapper>
            <StyledStoryControlWrapper>
              <Image src="/assets/snapchat_spotlight_sound.svg" width="1.5em" height="1.5em" />
            </StyledStoryControlWrapper>
          </Flex>
        </StyledSpotlightWrapper>
      )
      break
    case PUBLISH_AS_STORY:
    case PUBLISH_AS_SAVED_STORY:
    default:
      previewComponent = (
        <Flex
          height={`${MAX_PREVIEW_HEIGHT}px`}
          width={`${MAX_PREVIEW_WIDTH}px`}
          mx="auto"
          bg={COLOR_CONSTANTS.BLACK}
          borderRadius="0.625em"
          position="relative"
        >
          <StoryHeaderWrapper>
            <StyledStoryHeaderProfileImageWrapper>
              <Flex width="2.1875em" height="2.1875em" bg="white" borderRadius={radius.pill}>
                <ImageWithFallback
                  source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage}
                  fallbackSource={fallbackLogoImage}
                  borderRadius={radius.pill}
                  width="100%"
                  height="100%"
                />
              </Flex>
            </StyledStoryHeaderProfileImageWrapper>
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <Flex alignItems="center">
                  <Flex
                    alignItems="center"
                    onMouseEnter={(e) => {
                      if (!isMultipleProfilesPreview) {
                        tooltipRef.current.handleShowTooltip({
                          contentComp: (
                            <ProfileItemTooltip
                              profile={{
                                code: network,
                                picture_url: profilePictureUrl,
                                profile_url: profileUrl,
                                name: profileName,
                                username: profileUsername,
                                display,
                                profile_id,
                                type: profile_type,
                              }}
                              message=""
                            />
                          ),
                          wrapperComp: e.currentTarget,
                        })
                      }
                    }}
                    onMouseLeave={() => {
                      tooltipRef.current.handleHideTooltip()
                    }}
                  >
                    {!isMultipleProfilesPreview && (
                      <StyledLink
                        as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                        href={(!isMultipleProfilesPreview && profileUrl) || ''}
                        target="_blank"
                        color="white"
                        fontSize="1em"
                        fontWeight="extraBold"
                        lineHeight="1.375em"
                      >
                        @{isMultipleProfilesPreview ? 'Multiple Snapchat Profiles' : profileUsername || profileName}
                      </StyledLink>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              {updatedPostMedia && (updatedPostMedia.type === VIDEO || updatedPostMedia.type === VIDEO_DYNAMIC) && (
                <Flex alignItems="center">
                  <Text fontSize="0.75" fontWeight="medium" lineHeight="0.875em" color={COLOR_CONSTANTS.WHITE}>
                    View more episodes
                  </Text>
                  <Image src="/assets/snapchat_double_arrow_white.svg" width="1em" height="1em" />
                </Flex>
              )}
            </Flex>
          </StoryHeaderWrapper>

          {mediaTemplate}

          <Flex position="absolute" right="0.875em" bottom="0.9375em" flexDirection="column">
            <StyledStoryControlWrapper mb="0.5em">
              <Image src="/assets/snapchat_story_more.svg" width="1.5em" height="1.5em" />
            </StyledStoryControlWrapper>
            <StyledStoryControlWrapper mb="0.5em">
              <Image src="/assets/snapchat_story_link.svg" width="1.5em" height="1.5em" />
            </StyledStoryControlWrapper>
            {updatedPostMedia && (updatedPostMedia.type === VIDEO || updatedPostMedia.type === VIDEO_DYNAMIC) && (
              <StyledStoryControlWrapper mb="0.5em">
                <Image src="/assets/snapchat_story_sound.svg" width="1.5em" height="1.5em" />
              </StyledStoryControlWrapper>
            )}
          </Flex>
        </Flex>
      )
      break
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={customizedPostMixedMedias}
          initialCustomizedPostMixedMedias={[]}
        />
      )}

      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_item_light' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <StyledPreviewWrapper isEditable={smallPreview} isPostFormDirty={isPostFormDirty}>
              <Flex pb="m" width="100%" height="100%" justifyContent="center">
                {customizedPostComponent && isPostFormDirty ? (
                  <Flex flexDirection="column">{previewComponent}</Flex>
                ) : (
                  <Box px="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </Flex>
            </StyledPreviewWrapper>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <CaptionWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column" width="100%">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </CaptionWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <CaptionWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={
                                  transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'
                                }
                              />
                            </CaptionWrapper>
                            <Box>
                              <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={SNAPCHAT} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            showImageComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_VIDEO ||
                                customizedPostComponent === POST_MIXED_MEDIA) &&
                              updatedPostMedia
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <Flex flexDirection="column">
                    {hasMobileNotificationOption && (
                      <PublicationOptionComponent
                        handleUpdateCustomization={handleUpdateCustomization}
                        publishing_option={publishing_option}
                        device_gid={device_gid}
                        devices={devices}
                        notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                      />
                    )}

                    {(customizedPostComponent === POST_VIDEO ||
                      customizedPostComponent === POST_IMAGE ||
                      customizedPostComponent === POST_MIXED_MEDIA) &&
                      updatedPostMedia && (
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <DropDown
                              placeholder="Select publishing option"
                              value={
                                publish_as
                                  ? PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
                                  : PUBLISH_AS_DROPDOWN_VALUES[0]
                              }
                              onChange={(option) => {
                                handleUpdateCustomization({
                                  type: 'publish_as',
                                  value: option.value,
                                })
                              }}
                              options={PUBLISH_AS_DROPDOWN_VALUES}
                              openMenuOnFocus
                              isSearchable={false}
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="publish_as" network={SNAPCHAT} />
                        </Flex>
                      )}
                  </Flex>

                  {publish_as === PUBLISH_AS_SPOTLIGHT && (
                    <Flex mt="m" minHeight="max-content" flexDirection="column">
                      {(customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) &&
                        updatedPostMedia && (
                          <Flex mb="m" flexDirection="column" position="relative">
                            {featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                              !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                                <CustomizationsBlockComponent
                                  showUpgradeMessage={false}
                                  showUpgradePlan={false}
                                  message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                                />
                              )}

                            <Flex
                              flexDirection="column"
                              p={
                                featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                                !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                                  ? 's'
                                  : '0'
                              }
                            >
                              <Flex alignItems="center" width="100%">
                                <Flex flexDirection="column" width="100%">
                                  {isDescriptionTextEditOpen ? (
                                    <BodyWrapper>
                                      <CloseIconWrapper onClick={() => setIsDescriptionTextEditOpen(false)}>
                                        <Image width="8px" height="8px" src="/assets/clear.svg" />
                                      </CloseIconWrapper>
                                      <Flex flexDirection="column">
                                        <TextAreaComponentWithControls
                                          user={user}
                                          handleUpdateText={(text) => {
                                            handleUpdateCustomizationDebounce({ type: 'description', value: text })
                                          }}
                                          text={description || ''}
                                          textMaxLimitNumber={MAX_SPOTLIGHT_DESCRIPTION_TEXT_LENGTH}
                                          textVariantOffsets={textVariantOffsets}
                                          textareaPlaceholder="Optional spotlight description"
                                          errorText={
                                            spotlightDescriptionTextLengthLimit
                                              ? 'Your spotlight description is too long'
                                              : ''
                                          }
                                          selectedEntities={selectedEntities}
                                          selectedProfiles={selectedProfiles}
                                        />
                                      </Flex>
                                    </BodyWrapper>
                                  ) : (
                                    <StyledCaptionWrapper
                                      onClick={() => {
                                        setIsDescriptionTextEditOpen(true)
                                      }}
                                    >
                                      <BodyWrapper>
                                        <Text
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              transformed_spotlight_description_to_html_for_textarea ||
                                              'Spotlight description',
                                          }}
                                          fontSize="xs"
                                          color={
                                            transformed_spotlight_description_to_html_for_textarea
                                              ? 'denimText'
                                              : 'input_placeholder_color'
                                          }
                                          whiteSpace="pre-line"
                                        />
                                      </BodyWrapper>
                                      <Box>
                                        <Counter
                                          counter={description.length}
                                          max={MAX_SPOTLIGHT_DESCRIPTION_TEXT_LENGTH}
                                          fontSize="xxs"
                                        />
                                      </Box>
                                    </StyledCaptionWrapper>
                                  )}
                                </Flex>
                                <TooltipPromptComponent prompt_key="description" network={SNAPCHAT} />
                              </Flex>

                              <Flex mt="m" alignItems="center" width="100%">
                                <Flex flexDirection="column" width="100%">
                                  <Switch
                                    isOn={save_to_profile}
                                    onClick={() => {
                                      handleUpdateCustomization({
                                        type: 'save_to_profile',
                                        value: !save_to_profile,
                                      })
                                    }}
                                    text="Save to profile"
                                    fontWeight="normal"
                                  />
                                </Flex>
                                <TooltipPromptComponent prompt_key="save_to_profile" network={SNAPCHAT} />
                              </Flex>
                            </Flex>
                          </Flex>
                        )}

                      <Flex flexDirection="column" position="relative">
                        {featuresEnabled[FEATURE_POST_TARGETING] &&
                          !featuresEnabled[FEATURE_POST_TARGETING].enabled && (
                            <CustomizationsBlockComponent
                              showUpgradeMessage={false}
                              showUpgradePlan={false}
                              message={featuresEnabled[FEATURE_POST_TARGETING].description}
                            />
                          )}

                        <Flex
                          flexDirection="column"
                          p={
                            featuresEnabled[FEATURE_POST_TARGETING] && !featuresEnabled[FEATURE_POST_TARGETING].enabled
                              ? 's'
                              : '0'
                          }
                        >
                          <Flex alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <DropDown
                                placeholder="Language and country"
                                value={locale ? formDataLocales.find(({ value }) => value === locale) : null}
                                onChange={(option) => {
                                  handleUpdateCustomization({
                                    type: 'locale',
                                    value: option.value,
                                  })
                                }}
                                options={formDataLocales}
                                openMenuOnFocus
                                isSearchable={false}
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="locale" network={SNAPCHAT} />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

SnapchatPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

SnapchatPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
}

export default SnapchatPost
