import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'
import { Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { space as styledSpace } from 'styled-system'
import Scrollbars from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { lineHeights, radius } from 'theme'
import { SNIPPET_TYPE_CAPTION, SNIPPET_TYPE_REPLY, SNIPPET_TYPE_COMMENT, ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, H5, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import TextArea from 'components/atoms/TextArea'
import Image from 'components/atoms/Image'
import BottomStepper from 'components/organisms/BottomStepper'
import DropDown from 'shared/DropDown'
import { ROUTE_SNIPPETS } from '../../consts'
import { getSelectedProfileGroupsAndTimezone } from '../../helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 550px;
    height: 80vh;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const RemoveSnippetIconWrapper = styled(Box)`
  position: absolute;
  top: 0px;
  right: -8px;
  background: ${({ theme }) => theme.colors.white};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledAddSnippetWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledH5 = styled(H5)`
  line-height: ${lineHeights.xl};
`

const BottomStepperWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const MAX_SNIPPETS = 10

const { TITLE, MESSAGES, SNIPPET_ID } = {
  TITLE: 'title',
  MESSAGES: 'messages',
  SNIPPET_ID: 'id',
}

const DEFAULT_COMPONENTS_HEIGHT = pxToRem(56)

const SnippetModal = ({
  user,
  snippetForEdit,
  isOpen,
  handleDismiss,
  isSubmitting,
  handleSave,
  isRemoving,
  handleRemove,
  confirm,
  type,
  newSnippets,
}) => {
  const formRef = useRef(null)

  const [selectedSnippet, setSelectedSnippet] = useState({ ...snippetForEdit })
  const [step, setStep] = useState(2)
  const [isGettingSnippets, setIsGettingSnippets] = useState(false)
  const [savedSnippets, setSavedSnippets] = useState([])

  let typeTexts = {
    one: 'Snippet',
    multiple: 'Snippets',
  }
  if (type === SNIPPET_TYPE_CAPTION) {
    typeTexts = {
      one: 'Caption',
      multiple: 'Captions',
    }
  } else if (type === SNIPPET_TYPE_REPLY) {
    typeTexts = {
      one: 'Reply',
      multiple: 'Replies',
    }
  } else if (type === SNIPPET_TYPE_COMMENT) {
    typeTexts = {
      one: 'Comment',
      multiple: 'Comments',
    }
  }

  const getSavedSnippets = async () => {
    try {
      setIsGettingSnippets(true)

      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
      })

      const response = await request({
        path: `${ROUTE_SNIPPETS}?type=${type}&entity_gids=${selectedEntitiesWithSelectedProfiles
          .map(({ id }) => id)
          .join(',')}`,
      })
      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const { snippets } = response
        setSavedSnippets([...snippets])
      }
    } catch (error) {
      errorHelper({ error, componentName: SnippetModal.displayName, functionName: 'getSavedSnippets' })
    } finally {
      setIsGettingSnippets(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (newSnippets) {
        setStep(1)
        getSavedSnippets()
      }
    }
  }, [isOpen])

  const createValidationSchemaForStep = (step) => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }

    switch (step) {
      case 1:
        return Yup.object().shape({
          [SNIPPET_ID]: Yup.object()
            .shape(isDropdownValueRequiredShape)
            .typeError(`${typeTexts.one} is required`),
        })
      case 2:
        return Yup.object().shape({
          [TITLE]: Yup.string().required('Group name is required'),
          [MESSAGES]: Yup.array().of(Yup.string().required('Text is required')),
        })
      default:
        return null
    }
  }

  const handleSubmitForm = async (values) => {
    const { id } = selectedSnippet

    const { using_ai = false } = snippetForEdit || {}

    const body = {
      [TITLE]: values[TITLE],
      [MESSAGES]: values[MESSAGES],
      using_ai,
    }

    if (id) {
      body.id = id
    }

    handleSave(body)
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const handleClickBackStep = () => {
    if (!isSubmitting) {
      if (Object.keys(formRef.current.state.touched).length > 0) {
        confirm({
          fn: () => () => {
            setStep(1)
          },
          message: `Your ${typeTexts.multiple.toLowerCase()} won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        setStep(1)
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isSubmitting) {
      if (formRef && formRef.current && Object.keys(formRef.current.state.touched).length > 0) {
        confirm({
          fn: () => handleDismiss,
          message: `Your ${typeTexts.multiple.toLowerCase()} won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        handleDismiss()
      }
    }
  }

  const { title, messages } = selectedSnippet

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <StyledDialogContent>
        <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
          <H4 my="m">{typeTexts.multiple}</H4>
        </StyledDialogEnvironmentWrapper>
        <Flex flex="1" height="100%" width="100%">
          {step === 1 && (
            <Flex flexDirection="column" width="100%" px="m" mt="m" tabIndex="0">
              {isGettingSnippets ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  width="100%"
                  className="cliploader-wrapper"
                >
                  <ClipLoader size="50" />
                </Flex>
              ) : (
                <Fragment>
                  <Text>Please select a {typeTexts.multiple.toLowerCase()} group or create one</Text>
                  <Formik
                    initialValues={{
                      [SNIPPET_ID]: null,
                    }}
                    validationSchema={createValidationSchemaForStep(1)}
                    onSubmit={() => {
                      setStep(2)
                    }}
                  >
                    {({ values, touched, errors, setFieldValue, handleSubmit }) => {
                      return (
                        <Box my="m" textAlign="left">
                          <DropDown
                            placeholder={`Select existing ${typeTexts.one.toLowerCase()} group`}
                            label=""
                            value={values[SNIPPET_ID]}
                            id={[SNIPPET_ID]}
                            onChange={async (option) => {
                              setSelectedSnippet({
                                ...{
                                  id: option.value,
                                  title: option.label,
                                  messages: [...newSnippets, ...option.messages],
                                },
                              })
                              await setFieldValue(SNIPPET_ID, option)
                              handleSubmit()
                            }}
                            options={savedSnippets.map(({ id, title, messages }) => ({
                              value: id,
                              label: title,
                              messages,
                            }))}
                            error={errors[SNIPPET_ID] && touched[SNIPPET_ID] && errors[SNIPPET_ID]}
                            openMenuOnFocus
                            height={DEFAULT_COMPONENTS_HEIGHT}
                          />

                          <Flex mt="m" alignItems="center">
                            <StyledText
                              color="primary"
                              onClick={async () => {
                                setSelectedSnippet({ ...{ messages: newSnippets } })
                                setStep(2)
                              }}
                            >
                              Create new {typeTexts.one.toLowerCase()} group
                            </StyledText>
                          </Flex>

                          <BottomStepperWrapper>
                            <BottomStepper
                              total={2}
                              step={step.toString()}
                              handleBack={() => {}}
                              handleSubmit={() => {}}
                            />
                          </BottomStepperWrapper>
                        </Box>
                      )
                    }}
                  </Formik>
                </Fragment>
              )}
            </Flex>
          )}
          {step === 2 && (
            <Scrollbars universal>
              <Formik
                initialValues={{
                  [TITLE]: title || '',
                  [MESSAGES]: messages || [''],
                }}
                validationSchema={createValidationSchemaForStep(2)}
                onSubmit={handleSubmitForm}
                autocomplete="off"
                ref={formRef}
              >
                {({ values, touched, errors, handleChange }) => (
                  <Box my="m" px="m" width="100%">
                    <Box>
                      <Input
                        placeholder={`${typeTexts.multiple} group name`}
                        value={values[TITLE]}
                        name={[TITLE]}
                        id={[TITLE]}
                        onChange={handleChange(TITLE)}
                        error={errors[TITLE] && touched[TITLE] && errors[TITLE]}
                        width="100%"
                      />
                    </Box>
                    <FieldArray
                      name="messages"
                      render={(arrayHelpers) => (
                        <form autoComplete="off">
                          <React.Fragment>
                            {values.messages.map((message, index) => (
                              <Flex key={index} mt="m" alignItems="center">
                                <Field name={`messages.${index}`}>
                                  {({ field, form }) => (
                                    <React.Fragment>
                                      <Flex flexDirection="column" position="relative" key={index} width="100%">
                                        {index !== 0 && (
                                          <RemoveSnippetIconWrapper
                                            onClick={() => {
                                              arrayHelpers.remove(index)
                                            }}
                                          >
                                            <Image width="8px" height="8px" src="/assets/clear.svg" />
                                          </RemoveSnippetIconWrapper>
                                        )}
                                        <TextArea
                                          placeholder={`${typeTexts.one} text`}
                                          error={
                                            form.errors.messages &&
                                            form.touched.messages &&
                                            form.touched.messages[index] &&
                                            form.errors.messages[index]
                                          }
                                          width="100%"
                                          rows="4"
                                          {...field}
                                        />
                                      </Flex>
                                    </React.Fragment>
                                  )}
                                </Field>
                              </Flex>
                            ))}
                            <Flex mt="s" alignItems="center" justifyContent="flex-end">
                              {values.messages.length < MAX_SNIPPETS ? (
                                <StyledAddSnippetWrapper
                                  alignItems="center"
                                  onClick={() => {
                                    arrayHelpers.push('')
                                  }}
                                >
                                  <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
                                  <Text color="primary" fontSize="xs">
                                    Add another {typeTexts.one}
                                  </Text>
                                </StyledAddSnippetWrapper>
                              ) : (
                                <Text color="secondaryText" fontSize="xs">
                                  You've reach maximum number of {typeTexts.multiple}
                                </Text>
                              )}
                            </Flex>
                          </React.Fragment>
                        </form>
                      )}
                    />
                  </Box>
                )}
              </Formik>
            </Scrollbars>
          )}
        </Flex>
        <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
          <Flex alignItems="center">
            {newSnippets ? (
              <Button.Gray mr="m" isSmall onClick={handleClickBackStep} isDisabled={isSubmitting}>
                Back
              </Button.Gray>
            ) : (
              <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isSubmitting}>
                Cancel
              </Button.Gray>
            )}
            {selectedSnippet.id && handleRemove ? (
              <ButtonWithLoading
                buttonComp={Button.Gray}
                isLoading={isRemoving}
                onClick={() => {
                  confirm({
                    fn: () => () => handleRemove({ id: selectedSnippet.id }),
                    message: `Are you sure you want to remove this snippet?`,
                    action: 'Remove',
                  })
                }}
                isSmall
              >
                Remove
              </ButtonWithLoading>
            ) : (
              <Flex />
            )}
          </Flex>

          <ButtonWithLoading isLoading={isSubmitting} onClick={handleSubmitFormFromButton} isSmall type="button">
            <Text fontWeight="medium">{isSubmitting ? 'Saving' : 'Save'}</Text>
          </ButtonWithLoading>
        </StyledDialogEnvironmentWrapper>
        <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
          <Image width="10px" height="10px" src="/assets/clear.svg" />
        </CloseIconWrapper>
      </StyledDialogContent>
    </StyledDialogOverlay>
  )
}

SnippetModal.defaultProps = {
  snippetForEdit: {},
  newSnippets: null,
  handleRemove: null,
  isRemoving: false,
}

SnippetModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  snippetForEdit: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool,
  handleRemove: PropTypes.func,
  type: PropTypes.string.isRequired,
  newSnippets: PropTypes.array,
}

SnippetModal.displayName = 'SnippetModal'

export default withConfirm(SnippetModal)
