import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { display, space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { fontSizes, fontWeights, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import NoData from 'components/molecules/NoData'
import { ROUTE_CALENDAR } from '../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 500px;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const cellStyle = `
  padding-left: ${space.m};
  border-width: 1px 0;
`

const StyledTable = styled('table')`
  width: 100%;
  font-size: ${fontSizes.s};
  text-align: left;
`

const Row = styled('tr')`
  height: 50px;
`

const HeaderCell = styled('th')`
  ${display};
  font-weight: ${fontWeights.medium};
  border: solid ${({ theme }) => theme.colors.border_color};
  ${cellStyle};
  white-space: nowrap;
`

const BodyCell = styled('td')`
  ${display};
  border: solid ${({ theme }) => theme.colors.border_color};
  ${cellStyle};
  & > * {
    vertical-align: middle;
  }
`

const CustomizationsPostModal = ({ isOpen, handleClickCloseModal, post }) => {
  const [isGettingData, setIsGettingData] = useState(true)
  const [customizationForNetwork, setCustomizationsForNetwork] = useState([])

  const {
    profile: { network, profileId },
    customizations,
  } = post

  const getPostPerformance = async () => {
    try {
      const response = await request({
        method: 'POST',
        body: { network, id: profileId, customizations },
        path: `${ROUTE_CALENDAR}/post/transform-customizations`,
      })
      const { error, data } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setCustomizationsForNetwork([...data])
      }
    } catch (error) {
      errorHelper({ error, componentName: CustomizationsPostModal.displayName, functionName: 'getPostPerformance' })
    } finally {
      setIsGettingData(false)
    }
  }

  useEffect(() => {
    getPostPerformance()
  }, [])

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Post settings</H4>
          </StyledDialogEnvironmentWrapper>

          {isGettingData ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Fragment>
              {customizationForNetwork.length > 0 ? (
                <Flex flexDirection="column" height="100%" pb="m">
                  <Scrollbars universal>
                    <StyledTable>
                      <thead>
                        <Row>
                          <HeaderCell width="200px">Name</HeaderCell>
                          <HeaderCell>Value</HeaderCell>
                        </Row>
                      </thead>
                      <tbody>
                        {customizationForNetwork.map(({ value, label }) => {
                          return (
                            <Row key={value}>
                              <BodyCell width="200px">
                                <Text>{value}</Text>
                              </BodyCell>
                              <BodyCell>
                                <Flex flexDirection="column">
                                  <Text>{label}</Text>
                                </Flex>
                              </BodyCell>
                            </Row>
                          )
                        })}
                      </tbody>
                    </StyledTable>
                  </Scrollbars>
                </Flex>
              ) : (
                <Flex
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  py="xxl"
                  px="m"
                  bg="background_slider"
                >
                  <NoData message="No customizations found" />
                </Flex>
              )}
            </Fragment>
          )}

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

CustomizationsPostModal.defaultProps = {}

CustomizationsPostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClickCloseModal: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
}

CustomizationsPostModal.displayName = 'CustomizationsPostModal'

export default CustomizationsPostModal
