import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Select from 'shared/DropDown'
import { Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import Tooltip from 'components/molecules/Tooltip'
import LabelsEditModal from './LabelsEditModal'

const StyledGearWrapper = styled(Flex)`
  width: 48px;
  border-radius: 0 6px 6px 0;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  height: auto;
  border-left: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const LabelsSelect = ({
  data,
  selectedLabels,
  labelOptions,
  setLabelOptions,
  noOptionMessage,
  setData,
  type,
  calledFromModal,
  menuPlacement,
  placeholder,
}) => {
  const labelsRef = useRef(null)

  const [isLabelsEditModalOpen, setIsLabelsEditModalOpen] = useState(false)

  const handleClickOpenLabelsEditModal = () => {
    setIsLabelsEditModalOpen(true)
  }

  const handleClickCloseLabelsEditModal = () => {
    setIsLabelsEditModalOpen(false)
  }

  const handleUpdateDataLabels = ({ labels }) => {
    setLabelOptions(labels.filter(({ removed }) => !removed).map(({ label }) => label))

    if (data.labels && data.labels.length > 0) {
      labels.forEach(({ value, oldValue, removed }) => {
        if (removed) {
          const foundDataLabelIndex = data.labels.findIndex((label) => label === value)
          if (foundDataLabelIndex > -1) {
            data.labels.splice(foundDataLabelIndex, 1)
          }
        } else if (oldValue) {
          const foundDataLabelIndex = data.labels.findIndex((label) => label === oldValue)
          if (foundDataLabelIndex > -1) {
            data.labels[foundDataLabelIndex] = value
          }
        }
      })
      setData({ ...data })
    }

    // handleClickCloseLabelsEditModal()
  }

  return (
    <Flex flexDirection="column">
      <Flex width="100%">
        <Flex flexDirection="column" width="100%">
          <Select
            innerRef={labelsRef}
            isMulti
            label=""
            placeholder={placeholder}
            value={selectedLabels ? selectedLabels.map((item) => ({ value: item, label: item })) : []}
            onChange={(options) => {
              if (options) {
                const optionsTemp = {}
                options.forEach(({ value }) => {
                  const values = value.split(',')
                  values.forEach((item) => {
                    const updatedItem = item.trim()
                    optionsTemp[updatedItem] = updatedItem
                  })
                })

                data.labels = Object.keys(optionsTemp)
              } else {
                data.labels = []
              }
              setData({ ...data })
            }}
            options={labelOptions.map((item) => ({ value: item, label: item }))}
            openMenuOnFocus
            dropDownType="CreatableSelect"
            noOptionsMessage={() => {
              return noOptionMessage
            }}
            onMenuClose={() => {
              if (labelsRef && labelsRef.current && labelsRef.current.state.inputValue) {
                if (!data.labels) {
                  data.labels = []
                }
                data.labels = [
                  ...new Set([
                    ...labelsRef.current.props.value.map(({ value }) => value),
                    labelsRef.current.state.inputValue,
                  ]),
                ]
                setData({ ...data })
              }
            }}
            menuPlacement={menuPlacement}
            stylesControlOverride={{ borderRadius: '6px 0 0 6px' }}
          />
        </Flex>

        <Tooltip
          wrapperComp={
            <StyledGearWrapper onClick={handleClickOpenLabelsEditModal}>
              <Icon.Gear width="12px" height="12px" />
            </StyledGearWrapper>
          }
          message="Manage labels"
          width="120px"
          right="0px"
          left="unset"
          isTriangleVisible={false}
        />
      </Flex>

      {isLabelsEditModalOpen && (
        <LabelsEditModal
          handleDismiss={handleClickCloseLabelsEditModal}
          handleUpdateDataLabels={handleUpdateDataLabels}
          type={type}
          labelOptions={labelOptions}
          isOpen={isLabelsEditModalOpen}
          calledFromModal={calledFromModal}
        />
      )}
    </Flex>
  )
}

LabelsSelect.defaultProps = {
  selectedLabels: [],
  calledFromModal: false,
  menuPlacement: 'bottom',
  placeholder: 'Select labels',
}

LabelsSelect.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLabels: PropTypes.array,
  labelOptions: PropTypes.array.isRequired,
  setLabelOptions: PropTypes.func.isRequired,
  noOptionMessage: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  calledFromModal: PropTypes.bool,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
}

export default LabelsSelect
