import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Slider } from '@material-ui/core'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Radio from 'components/atoms/Radio'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Image from 'components/atoms/Image'

const MessageWrapper = styled(Flex)`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.primary)};
  border-radius: ${radius.l};
`

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 520px;
    height: 420px;
    min-height: 300px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledSlider = styled(Slider)`
  color: ${({ theme }) => theme.colors.primaryText};
`

const LEARN_MORE =
  'https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts#apply-sound-options'

const { REPLACE_SOUND, ADD_SOUND_CURRENT, ADD_SOUND_ORIGINAL } = {
  REPLACE_SOUND: 'REPLACE_SOUND',
  ADD_SOUND_CURRENT: 'ADD_SOUND_CURRENT',
  ADD_SOUND_ORIGINAL: 'ADD_SOUND_ORIGINAL',
}

const SOUND_OPTIONS = [
  { label: 'Replace current video sound', value: REPLACE_SOUND },
  {
    label: "Add sound to video's current sound",
    value: ADD_SOUND_CURRENT,
  },
  {
    label: "Add sound to video's original sound",
    value: ADD_SOUND_ORIGINAL,
  },
]

const MIN_VOLUME = 1
const MAX_VOLUME = 300
const DEFAULT_VOLUME = 100

const VideoApplySoundModal = ({ isOpen, selectedVideo, isSettingAudio, handleApplyAudio, handleDismiss }) => {
  const [soundOption, setSoundOption] = useState(REPLACE_SOUND)
  const [volume, setVolume] = useState(DEFAULT_VOLUME)

  const handleClickApplySound = async () => {
    let updatedVolume
    if (soundOption !== REPLACE_SOUND) {
      updatedVolume = volume / 100
    }

    if (soundOption === ADD_SOUND_ORIGINAL) {
      const hasError = await handleApplyAudio({ volume: undefined, audioId: selectedVideo.id, silent: true })
      if (!hasError) {
        await handleApplyAudio({ volume: updatedVolume })
      }
    } else {
      await handleApplyAudio({ volume: updatedVolume })
    }
    handleDismiss()
  }

  const handleClickCloseModal = () => {
    if (!isSettingAudio) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Flex m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Apply sound options</H4>
          </StyledDialogEnvironmentWrapper>
          <MessageWrapper ml="m" mr="m" mt="m" p="s">
            <Text fontSize="s">
              This sound can replace this video's current sound or be to added to video's current or original sounds at
              lower or higher volume.{' '}
              <Text as="a" target="_blank" href={LEARN_MORE} color="primary">
                Learn more
              </Text>
            </Text>
          </MessageWrapper>
          <StyledDialogBodyWrapper flex="1">
            <Flex mt="m" flexDirection="column">
              {SOUND_OPTIONS.map(({ label, value }) => (
                <Flex flexDirection="column" key={value} mx="m" mb="s">
                  <Flex alignItems="center" mb="s">
                    <Radio
                      id={value}
                      name="soundSettings"
                      onChange={({ target: { id } }) => {
                        setSoundOption(id)
                      }}
                      value={soundOption}
                      checked={soundOption === value}
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label htmlFor={value}>
                      <Text ml="m" pl="xs" mt="0px" fontSize="s">
                        {label}
                      </Text>
                    </label>
                  </Flex>
                  {(soundOption === ADD_SOUND_CURRENT || soundOption === ADD_SOUND_ORIGINAL) && value === soundOption && (
                    <Flex mt="xs" width="100%" alignItems="center" ml="m" flexDirection="column">
                      <Flex alignItems="center" width="100%">
                        <Image src="/assets/volume_down.svg" width="24px" height="24px" />
                        <Flex width="100%" maxWidth="180px" mx="s">
                          <StyledSlider
                            defaultValue={volume}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            step={1}
                            min={MIN_VOLUME}
                            max={MAX_VOLUME}
                            value={volume}
                            onChange={(e, value) => {
                              setVolume(value)
                            }}
                          />
                        </Flex>
                        <Image src="/assets/volume_up.svg" width="24px" height="24px" />
                      </Flex>

                      <Flex mt="xs" justifyContent="flex-start" width="100%" pl="xs">
                        <Text color="secondaryText" fontSize="xxs">
                          Volume expressed in percent of the current volume in video.{' '}
                          <Text
                            fontSize="xxs"
                            color="secondaryText"
                            as="a"
                            target="_blank"
                            href="https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts"
                          >
                            Learn more
                          </Text>
                          .
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray as="a" href={LEARN_MORE} target="_blank" isSmall>
              Learn more
            </Button.Gray>
            <ButtonWithLoading isSmall onClick={handleClickApplySound} isLoading={isSettingAudio} id="apply-sound">
              {isSettingAudio ? 'Applying sound' : 'Apply sound'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Flex>
    </StyledDialogOverlay>
  )
}

VideoApplySoundModal.defaultProps = {}

VideoApplySoundModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedVideo: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  isSettingAudio: PropTypes.bool.isRequired,
  handleApplyAudio: PropTypes.func.isRequired,
}

export default VideoApplySoundModal
