import styled from 'styled-components'
import { Box } from 'components/atoms/Layout'
import { COLOR_CONSTANTS } from 'theme'

const Divider = styled(Box)`
  background-color: ${({ bg }) => bg || COLOR_CONSTANTS.SOLITUDE};
  height: ${({ height }) => height};
  width: ${({ width }) => width || '100%'};
`

Divider.defaultProps = {
  height: '2px',
}

export default Divider
