import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { pxToRem } from 'helpers'
import { Box } from 'components/atoms/Layout'
import DropDown from 'shared/DropDown'

import {
  OBJECTIVES,
  AGES,
  GENDERS,
  INTERESTS,
  LOCATIONS,
  OBJECTIVE,
  GENDER,
  AGE_BUCKET,
  INTEREST,
  GEO,
  LOCALE,
  LOCALES,
  ACCOUNT,
  INCLUDE_AUDIENCES,
  EXCLUDE_AUDIENCES,
} from '../consts'

const DEFAULT_HEIGHT = pxToRem(40)

const PinterestConfiguration = ({ values, setFieldValue, errors, touched, formDataProfile }) => {
  let audiences = []

  if (values[ACCOUNT]) {
    audiences = formDataProfile[values[ACCOUNT].value] ? formDataProfile[values[ACCOUNT].value].audiences : []
  }

  return (
    <Fragment>
      <Box mt="m">
        <DropDown
          label="Included audiences"
          placeholder="Select audiences"
          value={values[INCLUDE_AUDIENCES]}
          onChange={(options) => {
            setFieldValue(INCLUDE_AUDIENCES, options || [])
          }}
          options={audiences.filter(
            ({ value }) => !values[EXCLUDE_AUDIENCES] || !values[EXCLUDE_AUDIENCES].find((item) => item.value === value)
          )}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[INCLUDE_AUDIENCES] && touched[INCLUDE_AUDIENCES] && errors[INCLUDE_AUDIENCES]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Excluded audiences"
          placeholder="Select audiences"
          value={values[EXCLUDE_AUDIENCES]}
          onChange={(options) => {
            setFieldValue(EXCLUDE_AUDIENCES, options || [])
          }}
          options={audiences.filter(
            ({ value }) => !values[INCLUDE_AUDIENCES] || !values[INCLUDE_AUDIENCES].find((item) => item.value === value)
          )}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[EXCLUDE_AUDIENCES] && touched[EXCLUDE_AUDIENCES] && errors[EXCLUDE_AUDIENCES]}
        />
      </Box>

      {/* 03-12-2024 Objectives removed from Pinterest, no need to delete the code, maybe we would add this field later*/}

      {/*<Box mt="m">*/}
      {/*  <DropDown*/}
      {/*    label="Objectives"*/}
      {/*    placeholder="Select objectives"*/}
      {/*    value={values[OBJECTIVE]}*/}
      {/*    onChange={(options) => {*/}
      {/*      setFieldValue(OBJECTIVE, options || [])*/}
      {/*    }}*/}
      {/*    options={formDataProfile[OBJECTIVES] || []}*/}
      {/*    openMenuOnFocus*/}
      {/*    height={DEFAULT_HEIGHT}*/}
      {/*    error={errors[OBJECTIVE] && touched[OBJECTIVE] && errors[OBJECTIVE]}*/}
      {/*  />*/}
      {/*</Box>*/}

      <Box mt="m">
        <DropDown
          label="Interests"
          placeholder="Select interests"
          value={values[INTEREST]}
          onChange={(options) => {
            setFieldValue(INTEREST, options || [])
          }}
          options={formDataProfile[INTERESTS] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[INTEREST] && touched[INTEREST] && errors[INTEREST]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Language"
          placeholder="Select languages"
          value={values[LOCALE]}
          onChange={(options) => {
            setFieldValue(LOCALE, options || [])
          }}
          options={formDataProfile[LOCALES] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[LOCALE] && touched[LOCALE] && errors[LOCALE]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Gender"
          placeholder="Select gender"
          value={values[GENDER]}
          onChange={(options) => {
            setFieldValue(GENDER, options || [])
          }}
          options={formDataProfile[GENDERS] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[GENDER] && touched[GENDER] && errors[GENDER]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Ages"
          placeholder="Select ages"
          value={values[AGE_BUCKET]}
          onChange={(options) => {
            setFieldValue(AGE_BUCKET, options || [])
          }}
          options={formDataProfile[AGES] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          menuPlacement="top"
          error={errors[AGE_BUCKET] && touched[AGE_BUCKET] && errors[AGE_BUCKET]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Locations"
          placeholder="Select locations"
          value={values[GEO]}
          onChange={(options) => {
            setFieldValue(GEO, options || [])
          }}
          options={formDataProfile[LOCATIONS] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          menuPlacement="top"
          error={errors[GEO] && touched[GEO] && errors[GEO]}
        />
      </Box>
    </Fragment>
  )
}

PinterestConfiguration.defaultProps = {
  formDataProfile: {},
}

PinterestConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  formDataProfile: PropTypes.object,
}

PinterestConfiguration.displayName = 'PinterestConfiguration'

export default PinterestConfiguration
