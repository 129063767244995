import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace, maxWidth as styledMaxWidth, maxHeight as styledMaxHeight } from 'styled-system'
import { Formik } from 'formik'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius } from 'theme'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import TextArea from 'components/atoms/TextArea'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
// eslint-disable-next-line import/no-cycle
import PostItem from '../ReviewPostsModal/PostItem'
import CalendarItemTooltip from '../CalendarItemTooltip'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
    overflow: hidden;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: transparent;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    ${styledMaxWidth};
    ${styledMaxHeight};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    transition: all 350ms ease-in-out 0s;
    top: 50%;
    transform: translate(0%, -50%);
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const LeftColumnWrapper = styled(Flex)`
  outline: none;
  background-color: ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.l};
`

const RightColumnWrapper = styled(Flex)`
  outline: none;
  background-color: ${({ theme }) => theme.colors.background_card};
  border-radius: ${radius.l};
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const RightColumnBodyWrapper = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const { NOTE_FROM_REVIEWER } = { NOTE_FROM_REVIEWER: 'note_from_reviewer' }

const RejectApproveNotesModal = ({
  review,
  isOpen,
  handleDismiss,
  handleRejectApprove,
  isPostSubmitting,
  type,
  postData,
}) => {
  const formRef = useRef(null)
  const tooltipRef = useRef(null)

  const [reviewData, setReviewData] = useState({})

  useEffect(() => {
    if (isOpen) {
      setReviewData({ ...review })
    }
  }, [isOpen])

  const handleSubmitForm = async () => {
    formRef.current.handleSubmit()
  }

  const handleSubmit = (values) => {
    reviewData[NOTE_FROM_REVIEWER] = values[NOTE_FROM_REVIEWER]
    handleRejectApprove(reviewData)
  }

  const handleClickCloseModal = () => {
    if (!isPostSubmitting) {
      handleDismiss()
    } else {
      Alert.error(`Please wait a bit longer while we finish saving post data.`, { timeout: 5000 })
    }
  }

  const { note_from_reviewer = '' } = reviewData

  let title = 'Confirm rejection'
  let placeholder = 'Please provide reasons for rejection'
  let buttonName = 'Reject post'
  let buttonNameLoading = 'Rejecting'
  let buttonComp = Button.Error
  let loaderColor = COLOR_CONSTANTS.SAPPHIRE

  if (type === 'approve') {
    title = 'Confirm approval'
    placeholder = 'Optionally please provide additional notes'
    buttonName = 'Approve post'
    buttonNameLoading = 'Approving'
    buttonComp = Button.Primary
    loaderColor = COLOR_CONSTANTS.SALUTE
  }

  return (
    <Fragment>
      <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
        <Box m="0 auto" width="100%" height="100%" p="l">
          <StyledDialogContent
            maxWidth={{ mobile: '450px', tablet: postData ? '800px' : '450px' }}
            maxHeight={{ mobile: '380px', tablet: postData ? '600px' : '380px' }}
          >
            {postData && (
              <LeftColumnWrapper
                flexDirection="column"
                width="350px"
                height="100%"
                display={{ mobile: 'none', tablet: 'flex' }}
              >
                <StyledDialogBodyWrapper flex="1">
                  <Flex flexDirection="column" width="100%" height="100%" position="relative">
                    <Scrollbars universal autoHide>
                      <Flex flexDirection="column" width="100%" height="100%" mx="auto">
                        {postData.post_preview_type === 'postItem' && (
                          <PostItem
                            {...postData}
                            handleClickRejectPost={() => {}}
                            handleClickApprovePost={() => {}}
                            hasControls={false}
                            hasComponentControls={false}
                            tooltipRef={tooltipRef}
                            isSmallPreview
                            sharedCalendarData={{}} // this is a special flag not to show review/reject notes in post preview
                          />
                        )}
                      </Flex>
                    </Scrollbars>
                  </Flex>
                </StyledDialogBodyWrapper>
              </LeftColumnWrapper>
            )}

            <RightColumnWrapper
              width={{ mobile: '100%', tablet: postData ? '450px' : '100%' }}
              height="100%"
              flexDirection="column"
              ml={postData ? 'm' : 0}
            >
              <StyledDialogEnvironmentWrapper p="m" alignItems="center" $isTop>
                <H4>{title}</H4>
              </StyledDialogEnvironmentWrapper>

              <StyledDialogBodyWrapper flex="1">
                <RightColumnBodyWrapper width="100%" height="100%" flexDirection="column">
                  <Scrollbars universal>
                    <Flex flexDirection="column" p="m" height="100%">
                      <Formik
                        ref={formRef}
                        initialValues={{
                          [NOTE_FROM_REVIEWER]: note_from_reviewer || '',
                        }}
                        validationSchema={() => {}}
                        onSubmit={handleSubmit}
                        autocomplete="off"
                        enableReinitialize
                      >
                        {({ values, handleChange }) => (
                          <Box>
                            <TextArea
                              value={values[NOTE_FROM_REVIEWER]}
                              onChange={handleChange(NOTE_FROM_REVIEWER)}
                              placeholder={placeholder}
                              rows={22}
                            />
                          </Box>
                        )}
                      </Formik>
                    </Flex>
                  </Scrollbars>
                </RightColumnBodyWrapper>
              </StyledDialogBodyWrapper>

              <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
                <Button.Gray isSmall onClick={handleClickCloseModal}>
                  Cancel
                </Button.Gray>

                <ButtonWithLoading
                  buttonComp={buttonComp}
                  loaderColor={loaderColor}
                  onClick={handleSubmitForm}
                  isSmall
                  type="button"
                  isLoading={isPostSubmitting}
                >
                  <Text fontWeight="medium">{isPostSubmitting ? buttonNameLoading : buttonName}</Text>
                </ButtonWithLoading>
              </StyledDialogEnvironmentWrapper>
            </RightColumnWrapper>

            <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
              <ImageWithFallback
                width="10px"
                height="10px"
                source="/assets/clear.svg"
                fallbackSource="/assets/clear.svg"
              />
            </CloseIconWrapper>

            <CalendarItemTooltip ref={tooltipRef} />
          </StyledDialogContent>
        </Box>
      </StyledDialogOverlay>
    </Fragment>
  )
}

RejectApproveNotesModal.defaultProps = {
  review: {},
  postData: null,
}

RejectApproveNotesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleRejectApprove: PropTypes.func.isRequired,
  review: PropTypes.object,
  isPostSubmitting: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  postData: PropTypes.object,
}

export default RejectApproveNotesModal
