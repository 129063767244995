import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import { space } from 'theme'

const StyledItem = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${space.s};
  text-align: left;
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.primaryText};
  min-width: 150px;
  ${({ isCursorPointer }) => isCursorPointer && `cursor: pointer;`}
  ${({ height }) => `height: ${height || '30px'}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryText};
    color: ${({ theme }) => theme.colors.primary_text_reverted} !important;

    svg {
      stroke: ${({ theme }) => theme.colors.primary_text_reverted} !important;
      fill: ${({ theme }) => theme.colors.primary_text_reverted} !important;
    }

    p {
      color: ${({ theme }) => theme.colors.primary_text_reverted};
    }
  }
`

const StyledText = styled(Text)`
  max-width: ${({ labelMaxWidth }) => labelMaxWidth || '150px'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Item = ({
  id,
  iconName,
  label,
  labelDescription,
  isCursorPointer,
  onClick,
  PictureComp,
  LabelComp,
  labelMaxWidth,
  labelFontWeight,
  iconColor,
  iconStroke,
  iconWidth,
  iconHeight,
  iconNameEnding,
  iconWidthEnding,
  iconHeightEnding,
  iconColorEnding,
  iconStrokeEnding,
  theme,
}) => {
  const IconComp = iconName ? Icon[iconName] : null
  const StyledIconComp =
    iconName &&
    styled(IconComp)`
      max-width: 20px;
      width: ${iconWidth || 'auto'};
      height: ${iconHeight || 'auto'};
      ${iconColor && `fill: ${theme.colors[iconColor] || iconColor} !important;`}
      ${iconStroke && `stroke: ${theme.colors[iconStroke] || iconStroke} !important;`}
    `

  const IconCompEnding = iconNameEnding ? Icon[iconNameEnding] : null
  const StyledIconCompEnding =
    iconNameEnding &&
    styled(IconCompEnding)`
      max-width: 20px;
      width: ${iconWidthEnding || 'auto'};
      height: ${iconHeightEnding || 'auto'};
      ${iconColorEnding && `fill: ${theme.colors[iconColorEnding] || iconColorEnding};`}
      ${iconStrokeEnding && `stroke: ${theme.colors[iconStrokeEnding] || iconStrokeEnding} !important;`}
    `

  return (
    <StyledItem
      height={labelDescription ? 'auto' : '30px'}
      type="button"
      onMouseUp={onClick}
      isCursorPointer={isCursorPointer}
      id={id}
    >
      <Flex alignItems="center" width="100%" height="100%">
        {iconName && (
          <Flex alignItems="center" justifyContent="center" width="20px" mr="xs">
            <StyledIconComp />
          </Flex>
        )}
        {PictureComp && PictureComp}
        {LabelComp || (
          <Flex flexDirection="column" py={labelDescription ? 'xs' : '0'}>
            <StyledText labelMaxWidth={labelMaxWidth} fontSize="xs" fontWeight={labelFontWeight}>
              {label}
            </StyledText>
            {labelDescription && (
              <StyledText labelMaxWidth={labelMaxWidth} fontSize="xxs" color="secondaryText">
                {labelDescription}
              </StyledText>
            )}
          </Flex>
        )}
      </Flex>
      {iconNameEnding && (
        <Flex alignItems="center" justifyContent="center" width="20px" ml="xs">
          <StyledIconCompEnding />
        </Flex>
      )}
    </StyledItem>
  )
}

Item.defaultProps = {
  id: '',
  isCursorPointer: false,
  iconColor: null,
  iconStroke: null,
  labelMaxWidth: null,
  labelFontWeight: 'normal',
  iconWidth: null,
  iconHeight: null,
  labelDescription: '',
  iconName: '',
  label: '',
  onClick: () => {},
  PictureComp: null,
  LabelComp: null,
  iconNameEnding: '',
  iconWidthEnding: '',
  iconHeightEnding: '',
  iconColorEnding: '',
  iconStrokeEnding: '',
}

Item.propTypes = {
  id: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  isCursorPointer: PropTypes.bool,
  onClick: PropTypes.func,
  PictureComp: PropTypes.node,
  LabelComp: PropTypes.node,
  iconColor: PropTypes.string,
  iconStroke: PropTypes.string,
  labelMaxWidth: PropTypes.string,
  labelFontWeight: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  labelDescription: PropTypes.string,
  iconNameEnding: PropTypes.string,
  iconWidthEnding: PropTypes.string,
  iconHeightEnding: PropTypes.string,
  iconColorEnding: PropTypes.string,
  iconStrokeEnding: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Item)
